export function animalSpeciesHelper(value: string) {
  switch (value.toLowerCase()) {
    case 'beef':
      return { label: 'Beef', order: 0, value };

    case 'hog':
      return { label: 'Hog', order: 1, value };

    default:
      return { label: value, order: 99, value };
  }
}
