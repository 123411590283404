import { Field, ErrorMessage, useFormikContext, FieldAttributes } from 'formik';
import { camelCase, get } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { FloatingLabel, Form, FormControlProps } from 'react-bootstrap';
import { NumberFormatValues, PatternFormat } from 'react-number-format';

import { InputComponent } from '.';

const PHONE_FORMAT = '(###) ### - ####';

export interface InputPhoneProps extends InputComponent {
  format?: string;
  mask?: string;
}

type InputFieldProps = FieldAttributes<FormControlProps> & {
  format: string;
  mask: string;
  onChange: (value: NumberFormatValues) => void;
};

const PhoneField = ({
  required,
  placeholder,
  format,
  mask,
  id,
  value,
  onChange,
}: InputFieldProps) => {
  const handleValueChange = (value: NumberFormatValues, event: any) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <PatternFormat
      id={id}
      format={format}
      mask={mask}
      className="form-control"
      placeholder={placeholder}
      required={required}
      value={value}
      onValueChange={handleValueChange}
    />
  );
};

export function InputPhone({
  label,
  disabled,
  hidden,
  required,
  className,
  hint,
  nameOveride,
  placeholder,
  floatingLabel = false,
  showLabel = !hidden,
  readOnly = false,
  format = PHONE_FORMAT,
  mask = '_',
}: InputPhoneProps) {
  const {
    errors,
    setFieldTouched,
    setFieldValue,
    getFieldMeta,
    submitCount,
    values,
  } = useFormikContext<Record<string, string>>();

  const validate = useCallback(
    (value: string | number | undefined) => {
      if (required && (!value || value === undefined)) {
        return `${label} is required.`;
      }
      // validate phone number matches the format.
      if (required && value && value.toString().length < 10) {
        return `${label} must be a valid 10 digit U.S. phone number.`;
      }
    },
    [label, required],
  );

  const name = useMemo(
    () => nameOveride ?? camelCase(label),
    [label, nameOveride],
  );

  const inputPlaceholder = useMemo(() => {
    return placeholder ?? label?.replace(/[?:]/g, '');
  }, [placeholder, label]);

  const handleChange = useCallback(
    (values: NumberFormatValues) => {
      setFieldValue(name, values.value, true);
    },
    [name, setFieldValue],
  );

  const currentValue = useMemo(() => {
    return get(values, name);
  }, [name, values]);

  // Handle setting field as touched once form submits so errors show thanks Formik
  useEffect(() => {
    const meta = getFieldMeta(name);
    if (submitCount > 0 && !meta.touched) {
      setFieldTouched(name, true);
    }
  }, [submitCount, getFieldMeta, setFieldTouched, name]);

  const fieldProps: FieldAttributes<FormControlProps> = useMemo(() => {
    const props: any = {
      name,
      disabled,
      hidden,
      validate,
      readOnly,
      required: required && !readOnly,
      plaintext: readOnly,
      as: Form.Control,
      placeholder: inputPlaceholder,
      isInvalid: !!errors[name],
      component: PhoneField,
      id: floatingLabel ? `floatingInput.${name}` : `form.${name}`,
      mask,
      format,
      onChange: handleChange,
      value: currentValue,
    };

    return props;
  }, [
    disabled,
    errors,
    hidden,
    name,
    inputPlaceholder,
    readOnly,
    required,
    validate,
    mask,
    format,
    floatingLabel,
    handleChange,
    currentValue,
  ]);

  return (
    <Form.Group controlId={`form.${name}`} className={className}>
      {floatingLabel ? (
        <FloatingLabel
          controlId={`floatingInput.${name}`}
          label={
            <>
              {label}
              {fieldProps.required && (
                <sup className="text-danger fw-bold">&nbsp;*</sup>
              )}
            </>
          }
        >
          <Field {...fieldProps} />
        </FloatingLabel>
      ) : (
        <>
          {showLabel && (
            <Form.Label className="fw-light">
              {label}
              {fieldProps.required && (
                <sup className="text-danger fw-bold">&nbsp;*</sup>
              )}
            </Form.Label>
          )}
          <Field {...fieldProps} />
        </>
      )}
      {hint && <Form.Text>{hint}</Form.Text>}
      <ErrorMessage
        name={name}
        render={(msg: string) => {
          return (
            <div>
              <Form.Control.Feedback
                type="invalid"
                // Since we are not using bootstrap Form elements but formik we should force this to display the error message
                style={{ display: 'block' }}
              >
                <div>{msg}</div>
              </Form.Control.Feedback>
            </div>
          );
        }}
      />
    </Form.Group>
  );
}
