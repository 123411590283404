import { Button, useModal } from '@farmshare/ui-components';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import {
  EnumUserRole,
  TenantByIdQuery,
  useUserCreateMutation,
} from 'lib/graphql';

import { UserModal, type UserModalForm } from './user-modal';

interface AddUserButtonProps {
  tenant: TenantByIdQuery['tenantById'];
  refetch: () => Promise<unknown>;
}

export function AddUserButton(props: AddUserButtonProps) {
  const [createUser, { loading }] = useUserCreateMutation();

  const { save } = useModal();

  return (
    <Button
      content="Add User"
      size="sm"
      icon={faPlus}
      isLoading={loading}
      onClick={() =>
        save<UserModalForm>({
          type: 'save',
          title: 'Add User',
          icon: faPlus,
          initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            role: EnumUserRole.Vendor,
          },
          body: <UserModal />,
          onSubmit: async ({ firstName, lastName, email, role }) => {
            if (role) {
              await createUser({
                variables: {
                  newUser: {
                    tenant: props.tenant?._id,
                    first_name: firstName,
                    last_name: lastName,
                    enabled: true,
                    email,
                    role,
                  },
                },
              });
              await props.refetch();
            }
          },
        })
      }
    />
  );
}
