import {
  Button,
  DataDetailList,
  Loading,
  PageTitle,
  useToastr,
} from '@farmshare/ui-components';
import { faCheckCircle, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map } from 'lodash';
import moment from 'moment';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { userState, vendorState } from 'state';

import { addressFieldsArray } from 'lib/addressFieldsArray';
import {
  EnumIntegrationName,
  UserTenantVendorDocument,
  useIntegrationSyncMutation,
  type Integration,
} from 'lib/graphql';
import { EnumUserRole } from 'lib/graphql';

import { TenantDetails } from './_views/tenant-details';
import { UserDetails } from './_views/user-details';
import shopifyLogo from '../../assets/images/shopify.svg';
import wooLogo from '../../assets/images/woo.svg';

export default function ProfilePage() {
  const user = useRecoilValue(userState);
  const vendor = useRecoilValue(vendorState);
  const { push } = useToastr();

  const isBasicUser = user?.role === EnumUserRole.Basic;

  const [sync, syncOp] = useIntegrationSyncMutation({
    refetchQueries: [{ query: UserTenantVendorDocument }],
  });

  const renderIntegration = (i: Integration, key: number) => {
    let logo: string = '';

    switch (i.name) {
      case EnumIntegrationName.Shopify:
      case EnumIntegrationName.ShopifyPrivate:
        logo = shopifyLogo;
        break;
      case EnumIntegrationName.WooCommerce:
        logo = wooLogo;
        break;

      default:
        break;
    }

    if (logo) {
      return (
        <Row
          key={key}
          className="align-items-center p-3 m-2 rounded-2 g-3 bg-body-secondary border"
        >
          <Col xs="auto" className="m-0">
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ fontSize: '1.5em' }}
              className="text-primary"
            />
          </Col>
          <Col className="m-0" style={{ height: 50 }}>
            <img src={logo} height="100%" alt={`${i.name} logo`} />
          </Col>
          <Col className="m-0">
            <Button
              content="Sync"
              icon={faRefresh}
              size="sm"
              isLoading={syncOp.loading}
              onClick={async () => {
                try {
                  await sync({
                    variables: { integrationId: i._id },
                  });
                  push({
                    title: 'Success',
                    body: 'Integration sync successful.',
                    bg: 'primary',
                    delay: 4000,
                  });
                } catch (e) {
                  push({
                    title: 'Error',
                    body: (e as string).toString(),
                    bg: 'danger',
                    delay: 4000,
                  });
                }
              }}
            />
          </Col>
          {i.lastSynced && (
            <Col className="m-0 pt-2 fs-6 justify-content-end" xs={12}>
              Last Updated: {moment(i.lastSynced).calendar()}
            </Col>
          )}
        </Row>
      );
    }
  };

  if (!user || (!isBasicUser && !vendor)) {
    return <Loading />;
  }

  return (
    <div className="my-4">
      <PageTitle title="Profile" />
      <Container>
        <Row className="g-3">
          <Col lg={4}>
            <UserDetails user={user} />
          </Col>
          {vendor && (
            <Col lg={4}>
              <Card className="h-100">
                <Card.Header>Address</Card.Header>
                <Card.Body>
                  <DataDetailList rows={addressFieldsArray(vendor.address)} />
                </Card.Body>
              </Card>
            </Col>
          )}
          {user?.tenant && (
            <Col lg={4}>
              <TenantDetails user={user} />
            </Col>
          )}
          <Col lg={6}>
            <Card className="h-100">
              <Card.Header>Integrations</Card.Header>
              <Card.Body>
                {map(user.active_vendor?.integrations, renderIntegration)}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
