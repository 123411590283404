import {
  Button,
  DataDetailListMinimal,
  InputCheck,
  InputPhone,
  PhoneDisplay,
  useModal,
  useToastr,
} from '@farmshare/ui-components';
import { InputText } from '@farmshare/ui-components';
import { formatFullName } from '@farmshare/utils';
import { faEdit, faFileEdit } from '@fortawesome/free-solid-svg-icons';
import { useFormikContext } from 'formik';
import { set } from 'lodash';
import { useCallback } from 'react';
import { Card } from 'react-bootstrap';
import { Alert, AlertHeading, Col, Row, Stack } from 'react-bootstrap';

import { EnumUserRole } from 'lib/graphql';

import { type ProcessingJobDetailsSectionProps } from './job-details-tab';

type EditRequestedByFormData = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  changeUser?: boolean;
  company?: string;
};

const EditRequestedByForm = ({ canEdit }: { canEdit: boolean }) => {
  const { touched, values } = useFormikContext<EditRequestedByFormData>();

  return (
    <Stack gap={3}>
      {!canEdit && (
        <>
          <Row>
            <Col>
              <Alert variant="warning">
                <AlertHeading>
                  <div>Unable to edit user information</div>
                </AlertHeading>
                <p>
                  The requested by user doesn't allow updating there
                  information. You may only change the user to a new one.
                </p>
              </Alert>
            </Col>
          </Row>

          <Row>
            <Col>
              <InputCheck
                type="switch"
                inline
                label="Would you like to change this user?"
                nameOveride="changeUser"
              />
            </Col>
          </Row>
        </>
      )}

      <Row>
        <Col className="px-2 pb-3">
          <InputText
            type="text"
            label="First Name"
            floatingLabel
            nameOveride="firstName"
            disabled={!canEdit && !values.changeUser}
            required
          />
        </Col>
      </Row>
      <Row>
        <Col className="px-2 pb-3">
          <InputText
            type="text"
            label="Last Name"
            floatingLabel
            nameOveride="lastName"
            disabled={!canEdit && !values.changeUser}
            required
          />
        </Col>
      </Row>
      <Row>
        <Col className="px-2 pb-3">
          <InputPhone
            label="Phone"
            floatingLabel
            nameOveride="phone"
            disabled={!canEdit && !values.changeUser}
            required={touched.phone && (!values.email || !!values.phone)}
          />
        </Col>
      </Row>
      <Row>
        <Col className="px-2 pb-3">
          <InputText
            type="email"
            label="Email"
            floatingLabel
            nameOveride="email"
            disabled={!canEdit && !values.changeUser}
            required={touched.email && (!values.phone || !!values.email)}
          />
        </Col>
      </Row>
      <Row>
        <Col className="px-2 pb-3">
          <InputText
            type="text"
            label="Company/Farm"
            floatingLabel
            nameOveride="company"
            disabled={!canEdit && !values.changeUser}
          />
        </Col>
      </Row>
    </Stack>
  );
};

export function RequestedByDetails({
  processingJob,
  updateProcessingJob,
}: ProcessingJobDetailsSectionProps) {
  const { save } = useModal();
  const { push } = useToastr();

  const handleEditRequestedBy = useCallback(() => {
    const initialValues = {
      firstName: processingJob?.requestedBy?.first_name,
      lastName: processingJob?.requestedBy?.last_name ?? undefined,
      phone: processingJob?.requestedBy?.phone ?? undefined,
      email: processingJob?.requestedBy?.email ?? undefined,
      company: processingJob?.requestedBy?.company ?? undefined,
    };

    return save<EditRequestedByFormData>({
      type: 'save',
      title:
        processingJob?.requestedBy?.role === EnumUserRole.Basic
          ? 'Edit Requested By'
          : 'Change Requested By',
      icon: faFileEdit,
      initialValues: {
        ...initialValues,
      },
      body: (
        <EditRequestedByForm
          canEdit={processingJob?.requestedBy?.role === EnumUserRole.Basic}
        />
      ),
      validate: (values) => {
        const errors: Partial<EditRequestedByFormData> = {};

        if (!values.email && !values.phone) {
          set(errors, 'email', 'Please provide either a phone or email.');
          set(errors, 'phone', 'Please provide either a phone or email.');
        }

        return errors;
      },
      onSubmit: async (values) => {
        await updateProcessingJob({
          variables: {
            processorSchedulingId: processingJob?._id,
            record: {
              requestedBy: {
                firstName: values.firstName,
                lastName: values.lastName,
                phone: values.phone,
                email: values.email,
                company: values.company,
              },
            },
          },
        });

        push({
          title: 'Processing Job Updated',
          body: `The requested by user has been updated.`,
          bg: 'success',
          delay: 4000,
        });
      },
    });
  }, [
    processingJob?.requestedBy,
    save,
    processingJob?._id,
    updateProcessingJob,
    push,
  ]);

  return (
    <Card className="h-100">
      <Card.Header className="d-flex align-items-center">
        <span className="fw-bold fs-5">Requested By</span>
        <Button
          className="ms-auto"
          content={
            processingJob?.requestedBy?.role === EnumUserRole.Basic
              ? 'Edit'
              : 'Change'
          }
          onClick={handleEditRequestedBy}
          icon={faEdit}
          size="sm"
        />
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <DataDetailListMinimal
              rows={[
                {
                  label: 'Name',
                  value: processingJob?.requestedBy?.first_name
                    ? formatFullName(
                        processingJob.requestedBy.first_name,
                        processingJob.requestedBy.last_name,
                      )
                    : undefined,
                },
                {
                  label: 'Phone',
                  value: processingJob?.requestedBy?.phone ? (
                    <PhoneDisplay phone={processingJob.requestedBy.phone} />
                  ) : undefined,
                },
                {
                  label: 'Email',
                  value: processingJob?.requestedBy?.email,
                },
                {
                  label: 'Farm/Company',
                  value: processingJob?.requestedBy?.company,
                },
              ]}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
