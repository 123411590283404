import { DataDetailList, ExternalLink } from '@farmshare/ui-components';
import { formatToShortDate } from '@farmshare/utils';
import { join, map, sortBy, startCase } from 'lodash';
import { Card } from 'react-bootstrap';

import { User } from 'lib/graphql';

export const TenantDetails = ({ user }: { user: User }) => {
  return (
    <Card className="h-100">
      <Card.Header>Tenant</Card.Header>
      <Card.Body>
        <DataDetailList
          rows={[
            {
              label: 'Tenant',
              value: user?.tenant?.name,
            },
            {
              label: 'Domain',
              value: user?.tenant?.domain,
            },
            {
              label: 'Created',
              value: formatToShortDate(user?.tenant?.date_created),
            },
            {
              label: 'Modules',
              value: join(
                map(sortBy(user?.tenant?.modules, 'order'), 'label'),
                ', ',
              ),
            },
          ]}
        />
        <Card.Link
          href="https://pay.farmshare.co/p/login/cN2fZT9VzcEKfKg000"
          text="Manage your subscription."
          as={ExternalLink}
        />
      </Card.Body>
    </Card>
  );
};
