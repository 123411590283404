import {
  ThemeSelector,
  type Theme,
} from '../../components/theme-selector/theme-selector';

export interface SiteFooterProps {
  theme: Theme;
  setTheme: (theme: Theme) => void;
}

export function SiteFooter(props: SiteFooterProps) {
  return (
    <div className="position-fixed bottom-0 start-0">
      <ThemeSelector theme={props.theme} setTheme={props.setTheme} />
    </div>
  );
}
