import {
  Error as ErrorComponent,
  Loading,
  PageTitle,
  useModal,
} from '@farmshare/ui-components';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useMemo } from 'react';
import { Card, Container } from 'react-bootstrap';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { ShipmentButtons } from 'components/shipment-buttons/shipment-buttons';
import { ShipmentInformation } from 'components/shipment-information/shipment-information';
import { ShipmentPackages } from 'components/shipment-packages/shipment-packages';

import { Shipment, useShipmentByIdLazyQuery } from 'lib/graphql';

import { ShipmentProgress } from './_views/shipment-progress';

export default function ShipmentDetailsPage() {
  const { ask } = useModal();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [getShipment, getShipmentOperation] = useShipmentByIdLazyQuery();

  useEffect(() => {
    if (id) {
      getShipment({ variables: { id } });
    }
  }, [id, getShipment]);

  const shipment = useMemo(() => {
    if (getShipmentOperation.data?.shipmentById) {
      return getShipmentOperation.data?.shipmentById as Shipment;
    }
  }, [getShipmentOperation.data]);

  useEffect(() => {
    if (shipment?.label_url && searchParams.get('print') === 'yes') {
      ask({
        type: 'ask',
        title: 'Print Label',
        icon: faPrint,
        body: <>Would you like to print your label?</>,
        onConfirm: () => {
          window.open(shipment.label_url!, '_blank');
        },
      });
    }
  }, [ask, searchParams, shipment?.label_url]);

  if (
    !getShipmentOperation.data &&
    !getShipmentOperation.loading &&
    getShipmentOperation.called
  ) {
    return <ErrorComponent error={new Error('Shipment not found.')} />;
  }

  return getShipmentOperation.loading ? (
    <Loading />
  ) : (
    <div className="py-4">
      <PageTitle
        title="Shipment Details"
        innerBreadcrumbs={[{ text: 'Shipments', to: '/shipments' }]}
        ellipsis={
          shipment && (
            <ShipmentButtons
              shipment={shipment}
              onVoid={() => getShipmentOperation.refetch()}
              onDelete={() => navigate('/shipments')}
            />
          )
        }
      />
      <Container>
        {shipment && (
          <div>
            <Card body>
              <ShipmentProgress shipment={shipment} />
            </Card>
            <Card className="mt-3">
              <Card.Header>Information</Card.Header>
              <Card.Body>
                <ShipmentInformation shipment={shipment} />
              </Card.Body>
            </Card>
            <Card className="mt-3">
              <Card.Header>Packages</Card.Header>
              <Card.Body>
                <ShipmentPackages packages={shipment.packages} />
              </Card.Body>
            </Card>
          </div>
        )}
      </Container>
    </div>
  );
}
