import { map } from 'lodash';
import { useMemo } from 'react';
import { Form } from 'react-bootstrap';

import {
  EnumProcessorSchedulingStatus,
  ProcessorScheduling,
} from 'lib/graphql';

import styles from './status-selector.module.scss';
import {
  ALL_SHOWN_STATUSES,
  renderStatusText,
} from '../helpers/processorStatusPresentation';
import { StatusChanges } from '../hooks/useStatusChanges';

const statusStyles: { [key: string]: string } = {
  [EnumProcessorSchedulingStatus.Requested]: styles.statusRequested,
  [EnumProcessorSchedulingStatus.Scheduled]: styles.statusScheduled,
  [EnumProcessorSchedulingStatus.DroppedOff]: styles.statusInProgress,
  [EnumProcessorSchedulingStatus.Killed]: styles.statusInProgress,
  [EnumProcessorSchedulingStatus.Aging]: styles.statusInProgress,
  [EnumProcessorSchedulingStatus.Curing]: styles.statusInProgress,
  [EnumProcessorSchedulingStatus.ReadyPayAtPickup]: styles.statusInProgress,
  [EnumProcessorSchedulingStatus.Invoicing]: styles.statusInProgress,
  [EnumProcessorSchedulingStatus.InvoicePaid]: styles.statusInProgress,
  [EnumProcessorSchedulingStatus.Completed]: styles.statusCompleted,
};

export default function StatusSelector({
  row,
  updateScheduling,
  updateSchedulingOp,
  handleInvoicing,
  handleKilled,
  handleDroppedOff,
  onChange,
}: {
  row: ProcessorScheduling;
  updateScheduling: StatusChanges['updateScheduling'];
  updateSchedulingOp: StatusChanges['updateSchedulingOp'];
  handleInvoicing: StatusChanges['handleInvoicing'];
  handleKilled: StatusChanges['handleKilled'];
  handleDroppedOff: StatusChanges['handleDroppedOff'];
  onChange?: (newStatus: EnumProcessorSchedulingStatus) => void;
}) {
  const validOptions = useMemo(() => {
    const statusIndex = ALL_SHOWN_STATUSES.indexOf(row.status);
    const validOptions = ALL_SHOWN_STATUSES.slice(statusIndex);

    return validOptions;
  }, [row.status]);

  const handleUpdateStatus = async (val: any) => {
    if (val === EnumProcessorSchedulingStatus.Killed) {
      handleKilled(row, onChange);
    } else if (val === EnumProcessorSchedulingStatus.Invoicing) {
      handleInvoicing(row, onChange);
    } else if (val === EnumProcessorSchedulingStatus.DroppedOff) {
      handleDroppedOff(row, onChange);
    } else {
      await updateScheduling({
        variables: {
          id: row._id,
          record: {
            status: val,
          },
        },
      });

      if (onChange) {
        onChange(val);
      }
    }
  };

  if (updateSchedulingOp.loading) {
    return null;
  }

  return (
    <Form.Select
      value={row.status}
      onChange={(e) => handleUpdateStatus(e.target.value)}
      className={statusStyles[row.status]}
    >
      {map(validOptions, (option) => (
        <option key={`${row._id}-${option}`} value={option}>
          {renderStatusText(option)}
        </option>
      ))}
    </Form.Select>
  );
}
