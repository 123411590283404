import {
  InputSelect,
  InputTextArea,
  InputChipOption,
} from '@farmshare/ui-components';
import { RecursivePartial, formatFullName } from '@farmshare/utils';
import { FieldArray, useFormikContext } from 'formik';
import { filter, map } from 'lodash';
import { useMemo } from 'react';
import { Badge, Card, Col, Row, Stack } from 'react-bootstrap';

import {
  EnumCutsheetInspectionLevels,
  EnumProcessorSchedulingAnimalHeadsInspectionLevel,
  Maybe,
  ProcessorSchedulingAnimalHeadsCutsheetInformation,
} from 'lib/graphql';

interface CutsheetOptions {
  splitTypes: Maybe<string>[];
  inspectionLevels: Maybe<EnumCutsheetInspectionLevels>[];
  label: string;
  value: string;
}

export interface CutsheetRequest {
  animalHeadId: string;
  splitType: string | undefined;
  inspectionLevel:
    | EnumProcessorSchedulingAnimalHeadsInspectionLevel
    | undefined;
  heading: string;
  cutsheets: RecursivePartial<ProcessorSchedulingAnimalHeadsCutsheetInformation | null>[];
}

export interface EditCutsheetsForm {
  cutsheetRequests: CutsheetRequest[];
}

function EditCutsheet({
  cutsheetOptions,
  cutsheetRequest,
  idx,
}: {
  animalType: string;
  cutsheetOptions: CutsheetOptions[];
  cutsheetRequest: CutsheetRequest;
  idx: number;
}) {
  const validCutsheetOptions: InputChipOption[] = useMemo(() => {
    const filtered: CutsheetOptions[] = filter(cutsheetOptions, (option) => {
      // Custom cutsheets don't seem to have split types so we allow them to match on all requests.
      const hasMatchingSplitType =
        cutsheetRequest.splitType && option.splitTypes?.length > 0
          ? option.splitTypes?.includes(cutsheetRequest.splitType)
          : option.splitTypes.length === 0;

      const hasMatchingInspectionLevel = cutsheetRequest.inspectionLevel
        ? option.inspectionLevels?.includes(
            cutsheetRequest.inspectionLevel as unknown as EnumCutsheetInspectionLevels,
          )
        : option.inspectionLevels?.length === 0;

      return (
        Boolean(hasMatchingSplitType) && Boolean(hasMatchingInspectionLevel)
      );
    });

    return map(filtered, (o) => ({ value: o.value, label: o.label }));
  }, [cutsheetOptions, cutsheetRequest]);

  return (
    <div>
      <Card>
        <Card.Header>
          <Stack direction="horizontal" gap={3}>
            <div> {cutsheetRequest.heading}</div>
            <Badge bg="secondary">{cutsheetRequest.splitType}</Badge>
            <Badge bg="secondary">{cutsheetRequest.inspectionLevel}</Badge>
          </Stack>
        </Card.Header>
        <Card.Body>
          <Stack gap={3}>
            {map(cutsheetRequest.cutsheets, (cr, jdx) => {
              return (
                <div key={jdx}>
                  <Row>
                    <Col>
                      Please select the cutsheet for {cr?.splitPart} of{' '}
                      {cutsheetRequest.heading} for{' '}
                      <span className="fw-bold">
                        {formatFullName(
                          cr?.contactUser?.first_name ?? '',
                          cr?.contactUser?.last_name ?? '',
                        )}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <InputSelect
                        label="Cutsheet"
                        nameOveride={`cutsheetRequests.[${idx}].cutsheets.[${jdx}].cutsheet._id`}
                        floatingLabel
                        options={validCutsheetOptions}
                      />
                    </Col>
                    <Col>
                      <InputTextArea
                        label="Notes"
                        nameOveride={`cutsheetRequests.[${idx}].cutsheets.[${jdx}].notes`}
                        floatingLabel
                      />
                    </Col>
                  </Row>
                </div>
              );
            })}
          </Stack>
        </Card.Body>
      </Card>
    </div>
  );
}

export function EditCutsheets({
  cutsheetOptions,
}: {
  cutsheetOptions: CutsheetOptions[];
}) {
  const { values } = useFormikContext<EditCutsheetsForm>();

  return (
    <Stack gap={3}>
      <FieldArray
        name="cutsheetRequests"
        render={() =>
          map(values.cutsheetRequests, (cutsheetRequest, idx) => {
            return (
              <EditCutsheet
                cutsheetOptions={cutsheetOptions}
                key={idx}
                animalType="cattle"
                cutsheetRequest={cutsheetRequest}
                idx={idx}
              />
            );
          })
        }
      />
    </Stack>
  );
}
