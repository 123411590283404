import {
  Form,
  InputDateRange,
  InputSelectBar,
  Loading,
  InputCheck,
} from '@farmshare/ui-components';
import { animalSpeciesHelper } from '@farmshare/utils';
import { filter, find, first, map, reduce, some, sortBy } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';

import {
  useViewProcessingPartnerQuery,
  useProcessorSchedulingManyQuery,
  EnumProcessorSchedulingStatus,
  EnumProcessorSettingsAnimalSettingsSpecies,
  EnumProcessorSettingsCapacitySettingsDailyCapacitiesType,
  ComplexAnimalCapacity,
  ProcessorSchedulingSlimFragmentFragment,
  EnumProcessorSchedulingAnimalSpecies,
} from 'lib/graphql';
import { getCapacity } from 'lib/processingJobUtils';

import { ScheduleBlock } from './schedule-block';

export type Spot = {
  date: moment.Moment;
  spotsRemaining: number;
};

type SpotGroup = {
  label: string;
  key: string;
  spots: Spot[];
};

type Groups = {
  [key: string]: SpotGroup;
};

interface SchedulingSelectFormForm {
  animal: string;
  dates: { start: string; end: string };
  displayUnavailableSpotGroups: boolean;
}

interface SchedulingSelectFormProps {
  onSelect: (animalType: string, date: moment.Moment) => void;
  vendorId?: string;
  slug?: string;
}

type CurrentSchedulings = Record<
  string,
  Partial<Record<EnumProcessorSchedulingAnimalSpecies, number>> & {
    total: number;
  }
>;

export const dateFormat = 'M/DD/YYYY';
const groupFormat = 'MM/YYYY';

export const occupiedStatuses = [
  EnumProcessorSchedulingStatus.Initial,
  EnumProcessorSchedulingStatus.Requested,
  EnumProcessorSchedulingStatus.Scheduled,
  EnumProcessorSchedulingStatus.Killed,
  EnumProcessorSchedulingStatus.Aging,
  EnumProcessorSchedulingStatus.Curing,
  EnumProcessorSchedulingStatus.Invoicing,
  EnumProcessorSchedulingStatus.ReadyPayAtPickup,
  EnumProcessorSchedulingStatus.InvoicePaid,
  EnumProcessorSchedulingStatus.Completed,
];

export function SchedulingSelectForm({
  onSelect,
  vendorId,
  slug,
}: SchedulingSelectFormProps) {
  const [customCapacities, setCustomCapacities] = useState<any>({});
  const [currentSchedulings, setCurrentSchedulings] =
    useState<CurrentSchedulings>({});
  const [spotGroups, setSpotGroups] = useState<Groups>({});

  // infinite scroll state
  const [displayedSpotGroups, setDisplayedSpotGroups] = useState<SpotGroup[]>(
    [],
  );
  const [spotGroupKeys, setSpotGroupKeys] = useState<string[]>([]);
  const [currentSpotGroupIndex, setCurrentSpotGroupIndex] = useState(0);
  const intersectionObserverRef = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useRef<HTMLDivElement | null>(null);

  const { data, loading } = useViewProcessingPartnerQuery({
    variables: {
      vendorId,
      slug,
    },
  });

  const { data: schedulingData, loading: loadingScheduling } =
    useProcessorSchedulingManyQuery({
      skip: !data?.processingPartnersOne?.vendor?._id,
      variables: {
        filter: {
          vendor: data?.processingPartnersOne?.vendor?._id,
          _operators: {
            status: { in: occupiedStatuses },
          },
        },
        limit: 1000,
        skip: 0,
      },
    });

  const showSpots = useMemo(() => {
    return some(spotGroups, (group) => group.spots.length > 0);
  }, [spotGroups]);

  const selectAnimalSpeciesOptions = useMemo(() => {
    return sortBy(
      map(data?.processingPartnersOne?.enabledAnimals, (value) =>
        animalSpeciesHelper(value ?? ''),
      ),
      'order',
    );
  }, [data?.processingPartnersOne?.enabledAnimals]);

  // When dates change, update schedule blocks
  const calculateOpeningsShown = useCallback(
    (selectedAnimalSpecies: string, startDate: string, endDate: string) => {
      const groups: Groups = {};
      let currDate = moment(startDate);
      const animalSetting = data?.processingPartnersOne?.animalSettings?.find(
        (o) => o?.species === selectedAnimalSpecies,
      );

      // Go through each date within selected date range
      for (
        const c = currDate;
        currDate.diff(endDate, 'days') <= 0;
        c.add(1, 'day')
      ) {
        const dayOfWeek = c.format('dddd');
        const groupKey = c.format(groupFormat);
        const capacityConfigurationType =
          data?.processingPartnersOne?.capacityConfigurationType;
        const capacitySettings =
          data?.processingPartnersOne?.capacitySettings ?? [];
        const currDateFormatted = c.format(dateFormat);
        const dayCapacitySetting = find(capacitySettings, {
          dayOfWeek: dayOfWeek.toLowerCase(),
        });

        const dailyCapacityConfiguration = find(
          dayCapacitySetting?.dailyCapacities,
          (o) => o?.type === capacityConfigurationType,
        );

        const capacities = dailyCapacityConfiguration?.capacities;

        let currDateCapacity =
          dayCapacitySetting?.isEnabled && dailyCapacityConfiguration
            ? getCapacity(
                capacityConfigurationType,
                selectedAnimalSpecies as EnumProcessorSettingsAnimalSettingsSpecies,
                capacities as ComplexAnimalCapacity[],
                animalSetting ?? undefined,
              )
            : { total: 0 };

        const useCustomCapacity = Boolean(customCapacities[currDateFormatted]);

        // Update slots based on Processor's custom days
        if (useCustomCapacity) {
          // find correct daily Capacity object for this configuration
          const dailyCapacity = find(
            customCapacities[currDateFormatted].dailyCapacities,
            (o) =>
              o.type ===
              EnumProcessorSettingsCapacitySettingsDailyCapacitiesType.SpeciesInspection,
          );
          currDateCapacity = getCapacity(
            EnumProcessorSettingsCapacitySettingsDailyCapacitiesType.SpeciesInspection,
            selectedAnimalSpecies as EnumProcessorSettingsAnimalSettingsSpecies,
            dailyCapacity.capacities ?? [],
            animalSetting ?? undefined,
          );
        }

        // Update slots based on already scheduled appointments
        // If we are configured for weighted animal units the logic is slightly different
        // We have to look at the custom capacities configuration type if we are using a custom capacity for the day.
        if (
          !useCustomCapacity &&
          data?.processingPartnersOne?.capacityConfigurationType ===
            EnumProcessorSettingsCapacitySettingsDailyCapacitiesType.WeightedAnimalUnit
        ) {
          const currentSchedulingTotalCapacity =
            currentSchedulings?.[currDateFormatted]?.total;
          if (currentSchedulingTotalCapacity) {
            currDateCapacity.total -= currentSchedulingTotalCapacity;
          }
        } else {
          const currentSchedulingTotal =
            currentSchedulings?.[currDateFormatted]?.[
              selectedAnimalSpecies as EnumProcessorSchedulingAnimalSpecies
            ];
          if (currentSchedulings[currDateFormatted] && currentSchedulingTotal) {
            currDateCapacity.total -= currentSchedulingTotal;
          }
        }

        let currentGroup = groups[groupKey];
        if (!currentGroup) {
          groups[groupKey] = {
            label: `${c.format('MMMM')} ${c.format('YYYY')}`,
            key: groupKey,
            spots: [],
          };
          currentGroup = groups[groupKey];
        }

        currentGroup.spots.push({
          date: c.clone(),
          spotsRemaining: currDateCapacity.total,
        });
      }

      const groupsKeys = Object.keys(groups);
      setSpotGroupKeys(groupsKeys);
      setCurrentSpotGroupIndex(0);
      setDisplayedSpotGroups([groups[groupsKeys[0]]]);
      setSpotGroups(groups);
    },
    [
      currentSchedulings,
      customCapacities,
      data?.processingPartnersOne?.capacityConfigurationType,
      data?.processingPartnersOne?.capacitySettings,
      data?.processingPartnersOne?.animalSettings,
    ],
  );

  useEffect(() => {
    if (data?.processingPartnersOne) {
      const animal = first(selectAnimalSpeciesOptions)?.value ?? '';
      if (animal) {
        calculateOpeningsShown(
          animal,
          moment().format('YYYY-MM-DD'),
          moment().add(6, 'days').format('YYYY-MM-DD'),
        );
      }
    }
  }, [
    data?.processingPartnersOne,
    customCapacities,
    currentSchedulings,
    calculateOpeningsShown,
    selectAnimalSpeciesOptions,
  ]);

  // When data loads, format data and set animal options
  useEffect(() => {
    // Format custom capacities
    // TODO this is broken but custom capacities needs to be migrated to split the species and inspection level out
    // This will end up looking similar to the capacitysetting. Except instead of a day it will have a
    // date on it instead. Then a list of daily capacities with a type.
    // Fow now they will all be species_inspection type.

    // Then the front end code I can just use the smae logic on the capacity I did for the
    // capacity settings. Badabing Badaboom
    const newCapacities = reduce(
      data?.processingPartnersOne?.customCapacities,
      (acc: Record<string, any>, c) => {
        if (c?.date) {
          const momentDate = moment.utc(c.date);
          acc[momentDate.format(dateFormat)] = { ...c };
        }
        return acc;
      },
      {},
    );

    // Format current schedulings
    const newSchedulings = reduce(
      schedulingData?.processorSchedulingMany ?? [],
      (
        acc: CurrentSchedulings,
        scheduling: ProcessorSchedulingSlimFragmentFragment,
      ) => {
        const momentDate = moment.utc(scheduling.dropoffDate);
        const animalSpecies = scheduling.animalSpecies;
        // If we have existing schedulings, increment by headcount
        if (acc[momentDate.format(dateFormat)]) {
          // add the new headcount to the total
          acc[momentDate.format(dateFormat)].total += scheduling.headCount;
          // If we have the current animalSpecies, increment
          if (acc[momentDate.format(dateFormat)][animalSpecies]) {
            const existingHeadCount =
              acc[momentDate.format(dateFormat)][animalSpecies] ?? 0;
            acc[momentDate.format(dateFormat)][animalSpecies] =
              existingHeadCount + scheduling.headCount;
          } else {
            // Otherwise, add animalSpecies and headCount
            acc[momentDate.format(dateFormat)][animalSpecies] =
              scheduling.headCount;
          }
        } else {
          // Otherwise if new, add current headcount
          acc[momentDate.format(dateFormat)] = {
            total: scheduling.headCount,
            [animalSpecies]: scheduling.headCount,
          };
        }
        return acc;
      },
      {},
    );

    setCustomCapacities(newCapacities);
    setCurrentSchedulings(newSchedulings);
  }, [data?.processingPartnersOne, schedulingData?.processorSchedulingMany]);

  // infinite scroll logic
  useEffect(() => {
    intersectionObserverRef.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if (currentSpotGroupIndex < spotGroupKeys.length - 1) {
            setCurrentSpotGroupIndex((previousIndex) => previousIndex + 1);
            setDisplayedSpotGroups([
              ...displayedSpotGroups,
              spotGroups[spotGroupKeys[currentSpotGroupIndex + 1]],
            ]);
          }
        }
      },
      { threshold: 0.5 },
    );

    if (lastElementRef.current) {
      intersectionObserverRef.current.observe(lastElementRef.current);
    }

    return () => {
      if (intersectionObserverRef.current) {
        intersectionObserverRef.current.disconnect();
      }
    };
  }, [displayedSpotGroups, spotGroupKeys, currentSpotGroupIndex, spotGroups]);

  if (loading || loadingScheduling) {
    return <Loading />;
  }

  return (
    <Form<SchedulingSelectFormForm>
      initialValues={{
        animal: first(selectAnimalSpeciesOptions)?.value ?? '',
        dates: {
          start: moment().format('YYYY-MM-DD'),
          end: moment().add(6, 'days').format('YYYY-MM-DD'),
        },
        displayUnavailableSpotGroups: false,
      }}
      validate={({ animal, dates: { start, end } }) => {
        if (animal && start && end) {
          calculateOpeningsShown(animal, start, end);
        }
      }}
      onSubmit={({ animal, dates: { start, end } }) => {
        if (animal && start && end) {
          calculateOpeningsShown(animal, start, end);
        }
      }}
    >
      {({ values, submitCount }) => (
        <>
          <Row className="mb-md-1 align-items-end g-3">
            <Col xs={12} lg={6}>
              <p className="fs-5 fw-bold">Select Animal:</p>
              <InputSelectBar
                nameOveride="animal"
                options={map(
                  data?.processingPartnersOne?.enabledAnimals,
                  (value) => animalSpeciesHelper(value ?? ''),
                )}
              />
            </Col>
            <Col xs={12} lg={6}>
              <p className="fs-5 fw-bold">Date Range:</p>
              <InputDateRange nameOveride="dates" step={6} />
            </Col>
          </Row>
          <hr />
          {showSpots && (
            <>
              <Row>
                <Col>
                  <p className="fs-5 mt-md-1 mb-4">
                    Showing{' '}
                    {moment
                      .utc(values.dates.end)
                      .diff(moment.utc(values.dates.start), 'days') + 1}{' '}
                    Days for <b>{animalSpeciesHelper(values.animal).label}</b>{' '}
                    Drop Off:
                  </p>
                </Col>
                <Col>
                  <InputCheck
                    type="switch"
                    label="Display unavailable dates"
                    nameOveride="displayUnavailableSpotGroups"
                    inline
                  />
                </Col>
              </Row>
              {displayedSpotGroups.length > 0 &&
                map(displayedSpotGroups, (spotGroup, i) => (
                  <div key={i}>
                    <h3>{spotGroup.label}</h3>
                    <hr />
                    <Row xs={1} md={3} lg={4} key={i}>
                      {map(
                        filter(
                          spotGroup.spots,
                          (filteredSpot) =>
                            values.displayUnavailableSpotGroups ||
                            filteredSpot.spotsRemaining > 0,
                        ),
                        (spot, j) => (
                          <Col className="pb-4" key={`${i}-${j}`}>
                            <ScheduleBlock
                              spot={spot}
                              onClick={() => onSelect(values.animal, spot.date)}
                            />
                          </Col>
                        ),
                      )}
                    </Row>
                  </div>
                ))}
              {/* Bottom of the page */}
              <div ref={lastElementRef} />
            </>
          )}
          {!showSpots && submitCount > 0 && (
            <Row>
              <div className="text-center pt-5">
                <h4 className="fw-bold mb-2">No openings match your filters</h4>
                <p>Try adjusting or extending your date range</p>
              </div>
            </Row>
          )}
        </>
      )}
    </Form>
  );
}
