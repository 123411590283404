import {
  type AddressFormForm,
  Form,
  PageTitle,
  InputText,
  AddressForm,
  Button,
  useModal,
  useToastr,
  Loading,
  InputSelect,
  InfoBox,
} from '@farmshare/ui-components';
import {
  faPlus,
  faSearch,
  faTrash,
  faTruckFast,
  faUndo,
  faUser,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { ArrayHelpers, FieldArray } from 'formik';
import {
  filter,
  find,
  join,
  map,
  omit,
  replace,
  some,
  startCase,
} from 'lodash';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { Alert, Card, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { vendorState } from 'state';

import { addressFormToCamelCase } from 'lib/addressFormToCamelCase';
import {
  EnumAddressType,
  type SaveShipmentPurchaseLabelMutationVariables,
  type ShipmentRate,
  useCreateShipmentGetRatesMutation,
  useSaveShipmentPurchaseLabelMutation,
  useViewShipmentCreateQuery,
} from 'lib/graphql';

import {
  RatesModal,
  type RatesModalForm,
} from 'pages/shipments/_views/rates-modal';

import {
  CustomerLookupModal,
  CustomerLookupModalForm,
} from './_views/customer-modal';
import {
  ShipmentPackageForm,
  ShipmentPackageFormForm,
} from '../../components/shipment-package-form/shipment-package-form';

interface NewShipmentForm {
  shipDate: string;
  reference?: string;
  shipFrom?: AddressFormForm;
  shipTo: AddressFormForm;
  chosenRate?: string;
  packages: ShipmentPackageFormForm[];
  confirmation:
    | 'none'
    | 'delivery'
    | 'signature'
    | 'adult_signature'
    | 'direct_signature'
    | 'delivery_mailed'
    | 'verbal_confirmation';
  recommendations: boolean;
  schedulePickup: boolean;
}

export default function CreateShipmentPage() {
  const [getRates, getRatesOperation] = useCreateShipmentGetRatesMutation();
  const [purchaseLabel, purchaseLabelOperations] =
    useSaveShipmentPurchaseLabelMutation();
  const viewQuery = useViewShipmentCreateQuery();

  const vendor = useRecoilValue(vendorState);

  const { save } = useModal();
  const navigate = useNavigate();
  const { push } = useToastr();

  const handleGetRates = useCallback(
    async (values: NewShipmentForm) => {
      if (values.shipFrom && values.shipTo && values.packages) {
        try {
          const reference = values.reference;

          const packages = map(values.packages, (p, i) => ({
            externalId: `${p.reference || values.reference || 'package'}-${
              i + 1
            }`,
            reference: p.reference || undefined,
            declaredValue: p.declaredValue || undefined,
            dimensions: {
              length: p.length || 0,
              width: p.width || 0,
              height: p.height || 0,
            },
            weight: p.weight || 0,
            packageCode: p.packaging,
          }));

          const { data } = await getRates({
            variables: {
              shipDate: values.shipDate,
              shipFrom: addressFormToCamelCase(values.shipFrom),
              shipTo: addressFormToCamelCase(values.shipTo),
              confirmation: values.confirmation,
              packages,
              reference,
            },
          });

          if (data?.createShipmentGetRates) {
            const { rates, address } = data.createShipmentGetRates;

            if (rates) {
              save<RatesModalForm>({
                type: 'save',
                title: 'Select Rate',
                size: 'lg',
                icon: faTruckFast,
                saveIcon: faTruckFast,
                saveText: 'Purchase Label',
                isLoading: purchaseLabelOperations.loading,
                body: (formikProps) => (
                  <RatesModal
                    formikProps={formikProps}
                    rates={rates as ShipmentRate[]}
                    hasFarmsharePackaging={some(
                      values.packages,
                      (p) => p.packageType === 'farmsharePackaging',
                    )}
                  />
                ),
                validate: (values) => {
                  const errors: Partial<Record<keyof RatesModalForm, string>> =
                    {};

                  if (!values.chosenRate) {
                    errors.chosenRate = 'A shipping rate needs to be chosen.';
                  }

                  return errors;
                },
                initialValues: {
                  pickupDate: moment.utc(values.shipDate).format('yyyy-MM-DD'),
                  notificationEmails: [values.shipTo.email || ''],
                },
                onSubmit: async ({
                  chosenRate,
                  notificationEmails,
                  pickupDate,
                  sendNotifications = false,
                  schedulePickup = false,
                }) => {
                  const _chosenRate = find(
                    rates,
                    (r) => r?.external_id === chosenRate,
                  );

                  if (_chosenRate) {
                    const variables: SaveShipmentPurchaseLabelMutationVariables =
                      {
                        chosenRate: omit(_chosenRate, '__typename'),
                        customerAddressId: address?._id,
                        recommendations: values.recommendations,
                        schedulePickup,
                        sendNotifications,
                        notificationEmails,
                        reference,
                      };

                    if (pickupDate) {
                      variables.pickupStart = moment(pickupDate)
                        .hours(6)
                        .toISOString();
                      variables.pickupEnd = moment(pickupDate)
                        .hours(19)
                        .toISOString();
                    }

                    const p = await purchaseLabel({ variables });
                    if (p.data?.saveShipmentPurchaseLabel?._id) {
                      push({
                        title: 'Success!',
                        bg: 'primary',
                        body: 'Your label has been purchased.',
                        delay: 5000,
                      });
                      navigate(
                        `/shipments/${p.data.saveShipmentPurchaseLabel._id}?print=yes`,
                      );
                    }
                    return true;
                  }

                  return false;
                },
              });
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    [
      getRates,
      navigate,
      purchaseLabel,
      purchaseLabelOperations.loading,
      push,
      save,
    ],
  );

  const shipFrom: AddressFormForm | undefined = useMemo(() => {
    if (vendor) {
      return {
        firstName: vendor.address.first_name || '',
        lastName: vendor.address.last_name || '',
        company: vendor.address.company || '',
        address1: vendor.address.address_1,
        address2: vendor.address.address_2 || '',
        city: vendor.address.city,
        state: vendor.address.state,
        postcode: vendor.address.postcode,
        country: vendor.address.country,
        phone: vendor.address.phone || '',
        email: vendor.address.email || '',
        type: vendor.address.type || EnumAddressType.Unknown,
      };
    }
  }, [vendor]);

  // const getPackageDetails = useCallback((p: NewShipmentFormPackage) => {
  //   const boxSizes: { package_code: string; maxSize: number }[] = [
  //     { package_code: 'fedex_medium_box_onerate', maxSize: 650 },
  //     { package_code: 'fedex_large_box_onerate', maxSize: 1100 },
  //     { package_code: 'fedex_extra_large_box_onerate', maxSize: 2200 },
  //   ];

  //   const volume = (p.length || 0) * (p.width || 0) * (p.height || 0);

  //   return [
  //     {
  //       label: 'Volume',
  //       value: volume ? (
  //         <span>
  //           {numeral(round(volume)).format('0,00')}
  //           <span className="small text-muted">&nbsp;(cu. in.)</span>
  //         </span>
  //       ) : null,
  //     },
  //     {
  //       label: 'Options',
  //       value: volume ? (
  //         <ul className="list-unstyled">
  //           {map(
  //             filter(boxSizes, (b) => b.maxSize >= volume),
  //             (o) => (
  //               <li key={o.package_code} className="small">
  //                 {startCase(o.package_code)}
  //               </li>
  //             ),
  //           )}
  //         </ul>
  //       ) : null,
  //     },
  //   ];
  // }, []);

  return !vendor || viewQuery.loading ? (
    <Loading />
  ) : (
    <div className="py-4">
      <PageTitle
        title="Create Shipment"
        innerBreadcrumbs={[{ text: 'Shipments', to: '/shipments' }]}
      />
      <Form<NewShipmentForm>
        initialValues={{
          schedulePickup: false,
          recommendations: false,
          confirmation: 'none',
          packages: [{}],
          shipDate: moment.utc().add(1, 'day').format('yyyy-MM-DD'),
          shipTo: {
            address1: '',
            city: '',
            state: '',
            postcode: '',
            type: EnumAddressType.Residential,
          },
          shipFrom,
        }}
        onSubmit={(values) => handleGetRates(values)}
      >
        {(formikProps) => (
          <Container className="d-flex flex-column gap-3">
            {getRatesOperation.error && (
              <Alert variant="danger" className="mb-0">
                {getRatesOperation.error.message}
              </Alert>
            )}
            <Row>
              <Col>
                <Card>
                  <Card.Header style={{ minHeight: '44px' }}>
                    <span className="fw-bold border-bottom border-4">
                      Details
                    </span>
                  </Card.Header>
                  <Card.Body>
                    <Row xs={1} lg={3} className="g-3">
                      <Col>
                        <InputText
                          type="date"
                          label="Ship Date"
                          hint="The date you plan to ship this package."
                          floatingLabel
                          required
                        />
                      </Col>
                      <Col>
                        <InputText
                          type="text"
                          label="Reference"
                          hint="Any reference number to help identify this shipment."
                          max={30}
                          floatingLabel
                        />
                      </Col>
                      <Col>
                        <InputSelect
                          label="Confirmation"
                          options={map(
                            [
                              'none',
                              'delivery',
                              'signature',
                              'adult_signature',
                              'direct_signature',
                              'delivery_mailed',
                              'verbal_confirmation',
                            ],
                            (c) => ({
                              label: startCase(c),
                              value: c,
                            }),
                          )}
                          floatingLabel
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row xs={1} lg={2} className="g-3">
              <Col>
                <Card>
                  <Card.Header style={{ minHeight: '44px' }}>
                    Ship&nbsp;
                    <span className="fw-bold border-bottom border-4">From</span>
                  </Card.Header>
                  <Card.Body>
                    <AddressForm
                      prefixes={['shipFrom']}
                      states={viewQuery.data?.stateMany}
                      floatingLabel
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card className="mt-xs-3 mt-lg-0">
                  <Card.Header style={{ minHeight: '44px' }}>
                    <div className="d-flex align-itemas-center">
                      <div className="flex-grow-1">
                        Ship&nbsp;
                        <span className="fw-bold border-bottom border-4">
                          To
                        </span>
                      </div>
                      <Button
                        content="Search"
                        icon={faSearch}
                        size="sm"
                        disabled={getRatesOperation.loading}
                        onClick={() =>
                          save<CustomerLookupModalForm>({
                            type: 'save',
                            title: 'Customer Lookup',
                            icon: faUserCircle,
                            saveIcon: faUser,
                            saveText: 'Select',
                            initialValues: {},
                            body: (formikProps) => (
                              <CustomerLookupModal formikProps={formikProps} />
                            ),
                            validate: (values) => {
                              const errors: Partial<CustomerLookupModalForm> =
                                {};

                              if (!values.address1) {
                                errors.address1 = 'Please select an address.';
                              }

                              return errors;
                            },
                            onSubmit: (values) =>
                              formikProps.setFieldValue('shipTo', {
                                email: values.email,
                                firstName: values.firstName,
                                lastName: values.lastName,
                                company: values.company,
                                address1: values.address1 || '',
                                address2: values.address2,
                                city: values.city || '',
                                state: values.state || '',
                                postcode: values.postcode || '',
                                phone: values.phone,
                                type: values.type,
                              }),
                          })
                        }
                      />
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <AddressForm
                      prefixes={['shipTo']}
                      states={viewQuery.data?.stateMany}
                      floatingLabel
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <Card.Header style={{ minHeight: '44px' }}>
                    <span className="fw-bold border-bottom border-4">
                      Packages
                    </span>
                  </Card.Header>
                  <Card.Body>
                    {viewQuery.data?.carrierMany &&
                      formikProps.values.packages?.length > 1 && (
                        <InfoBox
                          content={
                            <div>
                              {join(
                                map(
                                  filter(
                                    viewQuery.data.carrierMany,
                                    (f) =>
                                      !f.has_multi_package_supporting_services,
                                  ),
                                  (f) =>
                                    replace(
                                      f.friendly_name,
                                      /stamps\.com/i,
                                      'USPS',
                                    ),
                                ),
                                ', ',
                              )}
                              &nbsp;can only accept 1 package per shipment.
                            </div>
                          }
                        />
                      )}
                    <FieldArray
                      name="packages"
                      render={(
                        arrayHelpers: ArrayHelpers<NewShipmentForm['packages']>,
                      ) => (
                        <div>
                          {map(formikProps.values.packages, (_, idx) => (
                            <Card key={idx} className="mb-3">
                              <Card.Header>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>Package #{idx + 1}</div>
                                  {idx > 0 && (
                                    <Button
                                      content="Remove"
                                      size="sm"
                                      variant="secondary"
                                      icon={faTrash}
                                      className="me-2"
                                      onClick={() => arrayHelpers.remove(idx)}
                                      isLoading={getRatesOperation.loading}
                                    />
                                  )}
                                </div>
                              </Card.Header>
                              <Card.Body>
                                <ShipmentPackageForm<NewShipmentForm>
                                  formikProps={formikProps}
                                  idx={idx}
                                />
                              </Card.Body>
                            </Card>
                          ))}
                          <div className="d-flex justify-content-end">
                            <Button
                              content="Add Package"
                              variant="secondary"
                              icon={faPlus}
                              className="me-2"
                              onClick={() => arrayHelpers.push({})}
                              disabled={getRatesOperation.loading}
                              size="sm"
                            />
                          </div>
                        </div>
                      )}
                    />
                  </Card.Body>
                </Card>
                <div className="d-flex justify-content-end gap-2 mt-3">
                  <Button
                    type="reset"
                    content="Reset"
                    icon={faUndo}
                    variant="ghost"
                    disabled={getRatesOperation.loading}
                  />
                  <Button
                    type="submit"
                    content="Get Rates"
                    variant="primary"
                    icon={faTruckFast}
                    isLoading={getRatesOperation.loading}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </Form>
    </div>
  );
}
