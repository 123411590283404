import { Button, useModal } from '@farmshare/ui-components';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import {
  EnumIntegrationType,
  type TenantByIdQuery,
  useIntegrationCreateOneMutation,
} from 'lib/graphql';

import {
  IntegrationModal,
  type IntegrationModalForm,
} from './integration-modal';

interface AddIntegrationButtonProps {
  tenant: TenantByIdQuery['tenantById'];
  refetch: () => Promise<unknown>;
}

export function AddIntegrationButton(props: AddIntegrationButtonProps) {
  const [createIntegration, { loading }] = useIntegrationCreateOneMutation();

  const { save } = useModal();

  return (
    <Button
      content="Add Integration"
      size="sm"
      icon={faPlus}
      isLoading={loading}
      onClick={() =>
        save<IntegrationModalForm>({
          type: 'save',
          title: 'Add Integration',
          icon: faPlus,
          initialValues: {
            vendor: '',
            name: undefined,
            type: EnumIntegrationType.Api,
            endpoint: '',
            key: '',
          },
          body: props.tenant?.vendors && (
            <IntegrationModal vendors={props.tenant.vendors} />
          ),
          onSubmit: async ({ name, type, key, secret, endpoint, vendor }) => {
            if (name) {
              await createIntegration({
                variables: {
                  record: { vendor, name, type, key, secret, endpoint },
                },
              });
              await props.refetch();
            }
          },
        })
      }
    />
  );
}
