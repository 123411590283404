import {
  // AddressForm,
  Button,
  Form,
  Loading,
  PageTitle,
  PaginationTable,
  useToastr,
} from '@farmshare/ui-components';
import { formatToCurrency, formatToLongDate } from '@farmshare/utils';
import { faSave, faUndo } from '@fortawesome/free-solid-svg-icons';
// import { FieldArray } from 'formik';
import { filter, map, startCase } from 'lodash';
import { useMemo } from 'react';
import { Card, Container, Stack } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import {
  EnumOrderStatus,
  Order,
  OrderPagination,
  OrderPaginationQuery,
  OrderPaginationQueryVariables,
  SortFindManyOrderInput,
  useCustomerByIdQuery,
  useCustomerUpdateByIdMutation,
  useOrderPaginationLazyQuery,
} from 'lib/graphql';

import { CustomerForm, CustomerFormForm } from '../_views/customer-form';

export default function CustomerDetailsPage() {
  const { id } = useParams();
  const { push } = useToastr();

  const { data, loading } = useCustomerByIdQuery({ variables: { id } });
  const [updateCustomer, updateCustomerOp] = useCustomerUpdateByIdMutation();
  const orderQuery = useOrderPaginationLazyQuery();

  const customer = useMemo(() => data?.customerById, [data?.customerById]);

  if (loading || orderQuery[1].loading) {
    return <Loading />;
  }

  return (
    <div>
      <PageTitle
        title="Customer Details"
        innerBreadcrumbs={[{ text: 'Customers', to: '/marketing/customers' }]}
      />
      <Container>
        <Form<CustomerFormForm>
          initialValues={{
            firstName: customer?.first_name || '',
            lastName: customer?.last_name || '',
            email: customer?.email || '',
          }}
          onSubmit={async ({ firstName, lastName, email }) => {
            if (customer) {
              await updateCustomer({
                variables: {
                  id: customer._id,
                  record: { first_name: firstName, last_name: lastName, email },
                },
              });
              push({
                title: 'Success',
                body: 'Customer updated successfully.',
                bg: 'primary',
                delay: 4000,
              });
            }
          }}
        >
          {({ dirty }) => (
            <Card>
              <Card.Header>Details</Card.Header>
              <Card.Body>
                <CustomerForm />
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="mt-3 justify-content-end"
                >
                  <Button content="Reset" icon={faUndo} variant="ghost" />
                  <Button
                    content="Save"
                    type="submit"
                    icon={faSave}
                    disabled={!dirty}
                    isLoading={updateCustomerOp.loading}
                  />
                </Stack>
              </Card.Body>
            </Card>
          )}
        </Form>
        {/* <Card className="mt-3">
          <Card.Header>Addresses</Card.Header>
          <Card.Body>
            <FieldArray
              name="Addresses"
              render={() =>
                map(customer?.addresses, (address) => <AddressForm />)
              }
            />
          </Card.Body>
        </Card> */}
        <Card className="mt-3">
          <Card.Header>Orders</Card.Header>
          <Card.Body>
            <PaginationTable<
              Order,
              OrderPagination,
              OrderPaginationQuery,
              OrderPaginationQueryVariables,
              SortFindManyOrderInput
            >
              paginationQuery={orderQuery}
              dataAccessor={(a) => a.orderPagination as OrderPagination}
              columns={[
                {
                  label: 'Id',
                  field: 'external_id',
                  formatter: (row) => (
                    <Link to={`/orders/${row._id}`}>{row.external_id}</Link>
                  ),
                },
                {
                  label: 'Date Created',
                  field: 'date_created',
                  formatter: (row) => formatToLongDate(row.date_created),
                },
                {
                  label: 'Status',
                  field: 'status',
                  formatter: (row) => startCase(row.status),
                },
                {
                  label: 'Items',
                  field: 'line_items',
                  formatter: (row) => row.line_items?.length,
                },
                {
                  label: 'Total',
                  field: 'total',
                  formatter: (row) => formatToCurrency(row.total),
                },
              ]}
              defaultSort={SortFindManyOrderInput.OrderNumberDesc}
              buildFilterQuery={(allFilters, defaultSort, page, perPage) => {
                const activeFilters = filter(
                  allFilters,
                  (f) => f.isActive || false,
                );

                if (activeFilters?.length > 0) {
                  return {
                    filter: {
                      OR: map(activeFilters, (a) => ({
                        status: a.value as EnumOrderStatus,
                      })),
                      customer: customer?._id,
                    },
                    sort: defaultSort,
                    page,
                    perPage,
                  };
                }
                return {
                  filter: { customer: customer?._id },
                  sort: defaultSort,
                  page,
                  perPage,
                };
              }}
            />
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}
