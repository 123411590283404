import { PaginationTable, useModal, useToastr } from '@farmshare/ui-components';
import { formatToShortDate } from '@farmshare/utils';
import { faPeopleGroup, faPlus } from '@fortawesome/free-solid-svg-icons';
import { filter, isEmpty, map, startCase } from 'lodash';
import moment from 'moment';
import { useRecoilValue } from 'recoil';
import { vendorState } from 'state';

import {
  type Campaign,
  type CampaignPagination,
  SortFindManyCampaignInput,
  useCampaignPaginationLazyQuery,
  type CampaignPaginationQuery,
  type CampaignPaginationQueryVariables,
  EnumCampaignType,
  useCampaignCreateMutation,
  EnumCampaignMedium,
  EnumCampaignStatus,
} from 'lib/graphql';

import { CampaignButtons } from './_views/campaign-buttons';
import {
  CampaignCreateModal,
  type CampaignCreateModalForm,
} from './_views/campaign-create-modal';

export function CampaignsView() {
  const { save } = useModal();
  const { push } = useToastr();

  const vendor = useRecoilValue(vendorState);

  const [createCampaign, createCampaignOp] = useCampaignCreateMutation();

  return (
    <div>
      <PaginationTable<
        Campaign,
        CampaignPagination,
        CampaignPaginationQuery,
        CampaignPaginationQueryVariables,
        SortFindManyCampaignInput
      >
        paginationQuery={useCampaignPaginationLazyQuery({
          notifyOnNetworkStatusChange: true,
        })}
        defaultSort={SortFindManyCampaignInput.NameAsc}
        filters={map(EnumCampaignStatus, (e) => ({
          label: e.toString(),
          value: e,
        }))}
        defaultFilters={[
          EnumCampaignStatus.Draft,
          EnumCampaignStatus.Scheduled,
          EnumCampaignStatus.Triggered,
        ]}
        columns={[
          { label: 'Name', field: 'name' },
          {
            label: 'Type',
            field: 'type',
            formatter: (row) => startCase(row.type),
          },
          {
            label: 'Medium',
            field: 'medium',
            formatter: (row) => startCase(row.medium),
          },
          {
            label: 'Status',
            field: 'status',
            formatter: (row) => startCase(row.status),
          },
          { label: 'Segment', field: 'segment.name', minimumBreakpoint: 'md' },
          { label: 'Subject', field: 'subject', minimumBreakpoint: 'lg' },
          {
            label: 'Send Date',
            field: 'send_date',
            formatter: (row) => formatToShortDate(row.send_date),
            minimumBreakpoint: 'lg',
          },
          {
            formatter: (row, _, operation) =>
              operation && (
                <CampaignButtons<
                  CampaignPaginationQuery,
                  CampaignPaginationQueryVariables
                >
                  campaign={row}
                  operation={operation}
                />
              ),
          },
        ]}
        dataAccessor={(a) => a.campaignPagination as CampaignPagination}
        buildFilterQuery={(allFilters, defaultSort, page, perPage) => {
          const activeFilters = filter(allFilters, (f) => f.isActive || false);

          if (activeFilters?.length > 0) {
            return {
              filter: {
                OR: map(activeFilters, (a) => ({
                  status: a.value as EnumCampaignStatus,
                })),
              },
              sort: defaultSort,
              page,
              perPage,
            };
          }
          return { filter: {}, sort: defaultSort, page, perPage };
        }}
        actionButtons={(queryOp) => [
          {
            content: (
              <span className="d-none d-md-inline">New&nbsp;Campaign</span>
            ),
            icon: faPlus,
            onClick: () => {
              save<CampaignCreateModalForm>({
                type: 'save',
                title: 'New Campaign',
                icon: faPeopleGroup,
                body: (formikProps) => (
                  <CampaignCreateModal
                    formikProps={formikProps}
                    vendor={vendor}
                  />
                ),
                initialValues: {
                  type: EnumCampaignType.Newsletter,
                  campaignTitle: `${startCase(
                    EnumCampaignType.Newsletter,
                  )} ${moment().format('l')}`,
                },
                fullscreen: true,
                isLoading: createCampaignOp.loading,
                validate: (values) => {
                  const errors: Record<keyof CampaignCreateModalForm, string> =
                    {};

                  if (!values.body || isEmpty(values.body)) {
                    errors.body = 'Campaign body cannot be empty.';
                  }

                  return errors;
                },
                onSubmit: async (values) => {
                  if (
                    vendor &&
                    values.body &&
                    values.campaignTitle &&
                    values.subject &&
                    values.type
                  ) {
                    await createCampaign({
                      variables: {
                        newCampaign: {
                          vendor: vendor._id,
                          name: values.campaignTitle,
                          medium: EnumCampaignMedium.Email,
                          type: values.type,
                          segment: values.segment,
                          subject: values.subject,
                          body: values.body,
                          status: EnumCampaignStatus.Draft,
                          external_id: '',
                        },
                      },
                    });
                    push({
                      title: 'Success',
                      body: 'New campaign created successfully.',
                      bg: 'primary',
                      delay: 4000,
                    });
                    queryOp.refetch();
                  }
                },
              });
            },
          },
        ]}
      />
    </div>
  );
}
