import {
  Button,
  DataDetailList,
  Form,
  InputCheck,
  InputSelect,
  InputText,
  InputTextArea,
  Loading,
  PageTitle,
  Table,
  useModal,
  useToastr,
} from '@farmshare/ui-components';
import { formatToCurrency } from '@farmshare/utils';
import { faCartShopping, faMessage } from '@fortawesome/free-solid-svg-icons';
import { FormikProps } from 'formik';
import {
  every,
  filter,
  find,
  flatMap,
  forEach,
  fromPairs,
  isEmpty,
  map,
  omit,
  reduce,
  round,
  set,
  startCase,
  sum,
  toPairs,
} from 'lodash';
import { useEffect, useState } from 'react';
import { Col, Container, Row, Stack } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { vendorState } from 'state';

import { addressFieldsArray } from 'lib/addressFieldsArray';
import {
  useSuppliesCreateOrderMutation,
  useSupplyGroupManyQuery,
  useSupplyPricesByZoneLazyQuery,
  useSupplyRequestCreateMutation,
} from 'lib/graphql';

import SupplyItem from './SupplyItem';

export type SuppliesForm = Record<string, number>;

interface SuppliesExtraOptions {
  liftgateRequired?: boolean;
  acknowledgement?: boolean;
  addressType?: string;
}

export default function SuppliesPage() {
  const { save } = useModal();
  const { push } = useToastr();

  const vendor = useRecoilValue(vendorState);
  const [supplyPrices, setSupplyPrices]: any = useState({});
  const [dataWithNewPrices, setDataWithNewPrices]: any = useState([]);

  const [createOrder, createOrderOperation] = useSuppliesCreateOrderMutation();
  const [createRequest, createRequestOperation] =
    useSupplyRequestCreateMutation();
  const [getSupplyPrices, getSupplyPricesOperation] =
    useSupplyPricesByZoneLazyQuery();
  const { data, loading } = useSupplyGroupManyQuery();

  useEffect(() => {
    if (vendor?.zone) {
      getSupplyPrices({ variables: { zone: vendor?.zone } }).then(
        (supplyPricesRes) => {
          setSupplyPrices(
            reduce(
              supplyPricesRes?.data?.supplyPricesByZone,
              (acc: any, res: any) => {
                const itemName = res?.item?.name;
                if (itemName) {
                  acc[itemName] = res;
                }
                return acc;
              },
              {},
            ),
          );
        },
      );
    }
  }, [vendor, getSupplyPrices]);

  useEffect(() => {
    const asyncUpdatePrices = async () => {
      const newPrices = await data?.supplyGroupMany.map((supplyGroup: any) => {
        return {
          ...supplyGroup,
          supplies: supplyGroup.supplies.map((supply: any) => {
            return {
              ...supply,
              price: supplyPrices[supply.name]?.price || supply.price,
            };
          }),
        };
      });

      if (newPrices) {
        await setDataWithNewPrices(newPrices);
      }
    };
    // Confirm that supply group data and supply price data have both loaded before updating
    if (data?.supplyGroupMany) {
      asyncUpdatePrices();
    }
  }, [data, supplyPrices]);

  return !vendor ||
    loading ||
    !dataWithNewPrices ||
    isEmpty(dataWithNewPrices) ? (
    <Loading />
  ) : (
    <div className="pt-4">
      <PageTitle title="Supplies" />
      <Container>
        <Form<SuppliesForm>
          initialValues={{}}
          validate={(values) => {
            const errors: Partial<SuppliesForm> = {};

            if (every(values, (val) => val === 0)) {
              forEach(values, (_, key) => {
                set(errors, key, 'Please select at least 1 item.');
              });
              console.error(errors);
            }

            return errors;
          }}
          onSubmit={(values, { resetForm }) => {
            const items = flatMap(dataWithNewPrices, (d) =>
              map(d.supplies, (s) => ({ ...s, group: omit(d, 'supplies') })),
            );
            // Remove items with quantity of 0 from showing up
            const valuesWithQuantity = fromPairs(
              filter(toPairs(values), ([key, value]) => value > 0),
            );

            const subtotal = reduce(
              valuesWithQuantity,
              (res, qty, key) => {
                const item = find(items, (i) => key === i._id);
                if (item) {
                  res += item.price * qty;
                }
                return res;
              },
              0,
            );

            const rows = map(valuesWithQuantity, (quantity, key) => {
              const item = find(items, (i) => key === i._id);

              if (item) {
                const total = round(quantity * item.price, 2);
                return { ...item, quantity, total };
              }
            });

            save({
              type: 'save',
              title: 'Confirm Order',
              initialValues: {},
              saveIcon: faCartShopping,
              saveText: 'Confirm Purchase',
              icon: faCartShopping,
              iconClassName: 'ps-3',
              onSubmit: async (values: SuppliesExtraOptions) => {
                await createOrder({
                  variables: {
                    vendorId: vendor._id,
                    items: map(
                      filter(rows, (f) => (f?.quantity || 0) > 0),
                      (r) => ({
                        itemId: r?._id,
                        quantity: r?.quantity || 0,
                      }),
                    ),
                    liftgateRequired: values.liftgateRequired || false,
                    addressType: values.addressType || 'Unknown',
                    shippingCost: 0,
                    total: subtotal,
                  },
                });
                push({
                  title: 'Order Placed!',
                  body: 'Your supply order has been received. A Farmshare representative will be in contact shortly.',
                  bg: 'primary',
                  delay: 4000,
                });
                resetForm();
              },
              body: (
                <div className="px-3">
                  {!isEmpty(supplyPrices) && (
                    <>
                      <Table
                        rows={rows}
                        columns={[
                          {
                            label: 'Item',
                            formatter: (row) =>
                              `${row?.name} ${startCase(row?.group.type)}`,
                          },
                          { label: 'Quantity', field: 'quantity' },
                          {
                            label: 'Price',
                            field: 'price',
                            formatter: (row) => formatToCurrency(row?.price),
                          },
                          {
                            label: 'Total',
                            field: 'total',
                            formatter: (row) => formatToCurrency(row?.total),
                            // footer: {
                            //   aggregation: sumBy,
                            //   formatter: formatToCurrency,
                            // },
                          },
                        ]}
                      />
                      <hr />
                      <div className="d-flex py-1">
                        <div className="me-auto">
                          <p className="m-0 fs-5 fw-bold">Total</p>
                        </div>
                        <div className="">
                          <p className="m-0 fs-5 fw-bold">
                            {formatToCurrency(subtotal)}
                          </p>
                        </div>
                      </div>
                      <hr />
                    </>
                  )}
                  <div className="py-2">
                    <DataDetailList
                      heading="Confirm Shipping Address"
                      rows={addressFieldsArray(vendor.address, false, false)}
                    />
                    <hr />
                    <InputCheck
                      label="Liftgate Required"
                      className="pb-3"
                      inline
                    />
                    {!isEmpty(supplyPrices) && (
                      <InputCheck
                        label="I acknowledge that my card on file will be charged for the above total amount listed."
                        className="pb-3"
                        nameOveride="acknowledgement"
                        required
                        inline
                      />
                    )}
                    <InputSelect
                      label="Address Type"
                      options={['Residential', 'Commercial', 'Unknown']}
                      floatingLabel={true}
                      required
                    />
                  </div>
                </div>
              ),
            });
          }}
        >
          {(formikProps: FormikProps<SuppliesForm>) => (
            <Stack gap={4}>
              {map(dataWithNewPrices, (supplyGroup) => (
                <SupplyItem
                  key={supplyGroup._id}
                  img={supplyGroup.image}
                  title={supplyGroup.name}
                  description={supplyGroup.description}
                  items={supplyGroup.supplies}
                  hasZone={!isEmpty(supplyPrices)}
                  formikProps={formikProps}
                />
              ))}
              <Container>
                <Row className="justify-content-end">
                  <Col xs={12} md={5} xl={4}>
                    <Button
                      disabled={sum(Object.values(formikProps.values)) === 0}
                      className="fw-medium fs-5 rounded-3"
                      style={{ width: '100%' }}
                      content="Review Order"
                      type="submit"
                      isLoading={createOrderOperation.loading}
                    />
                  </Col>
                </Row>
              </Container>
            </Stack>
          )}
        </Form>
        <hr className="mt-4" />
        <div className="text-center">
          <Form<{ email: string; message?: string }>
            initialValues={{ email: vendor?.address?.email || '' }}
            onSubmit={async (
              { email, message = '' },
              { setFieldValue, resetForm },
            ) => {
              await createRequest({ variables: { email, message } });
              push({
                title: 'Request Sent!',
                body: 'Your supply request has been received. A Farmshare representative will be in contact shortly.',
                bg: 'primary',
                delay: 4000,
              });
              setFieldValue('message', '', false);
              resetForm();
            }}
          >
            <Stack gap={4} className="px-md-5 py-md-3">
              <div>
                <div className="fs-2">
                  Interested in custom-branded boxes? Got a question?
                </div>
                <div className="fw-light">
                  Contact us to get a mockup and quote.
                </div>
              </div>
              <InputText type="email" label="Email" floatingLabel required />
              <InputTextArea label="Message" rows={2} floatingLabel required />
              <div>
                <Button
                  type="submit"
                  icon={faMessage}
                  content="Send Request"
                  isLoading={createRequestOperation.loading}
                />
              </div>
            </Stack>
          </Form>
        </div>
      </Container>
    </div>
  );
}
