import { useFormikContext } from 'formik';
import { camelCase } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { Stack } from 'react-bootstrap';

import { type InputComponent, InputText } from '.';

export interface InputDateRangeProps extends InputComponent {
  min?: moment.Moment;
  max?: moment.Moment;
  step?: number;
}

const DATE_FORMAT = 'YYYY-MM-DD';

export function InputDateRange({
  label,
  nameOveride,
  min,
  max,
  floatingLabel,
  showLabel = false,
  step = 7,
}: InputDateRangeProps) {
  const { setFieldValue, values } =
    useFormikContext<Record<string, { start: string; end: string }>>();

  const name = useMemo(
    () => nameOveride ?? camelCase(label),
    [label, nameOveride],
  );

  useEffect(() => {
    if (values[name] && values[name].start) {
      const momentStart = moment(values[name].start);
      const momentEnd = moment(values[name].end);
      if (momentStart.isAfter(momentEnd)) {
        setFieldValue(
          `${name}.end`,
          momentStart.add(step, 'days').format(DATE_FORMAT),
        );
      }
    }
  }, [name, setFieldValue, step, values]);

  return (
    <Stack direction="horizontal" gap={3}>
      <InputText
        type="date"
        label="Start Date"
        nameOveride={`${name}.start`}
        showLabel={showLabel}
        min={(min ?? moment()).format(DATE_FORMAT)}
        max={max?.format(DATE_FORMAT)}
        floatingLabel={floatingLabel}
        required
      />
      <div>{'-'}</div>
      <InputText
        type="date"
        label="End Date"
        nameOveride={`${name}.end`}
        showLabel={showLabel}
        min={(min ?? moment()).add(step, 'day').format('YYYY-MM-DD')}
        max={max?.add(step, 'day').format('YYYY-MM-DD')}
        floatingLabel={floatingLabel}
        required
      />
    </Stack>
  );
}
