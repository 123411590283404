import { DataDetailList } from '@farmshare/ui-components';
import { startCase } from 'lodash';
import { Card } from 'react-bootstrap';

import { User } from 'lib/graphql';

export const UserDetails = ({ user }: { user: User }) => {
  return (
    <Card className="h-100">
      <Card.Header>User</Card.Header>
      <Card.Body>
        <DataDetailList
          rows={[
            { label: 'First Name', value: user?.first_name },
            { label: 'Last Name', value: user?.last_name },
            { label: 'Email', value: user?.email },
            { label: 'Role', value: startCase(user?.role ?? '') },
          ]}
        />
      </Card.Body>
    </Card>
  );
};
