import { environment } from 'environments/environment.prod';
import { Col, Row } from 'react-bootstrap';

import { StripePricingTable } from 'components/stripe-pricing-table/stripe-pricing-table';

export default function SignupPage() {
  return (
    <div>
      <Row md={1}>
        <Col className="text-center">
          <StripePricingTable
            publishableKey={environment.stripeKey}
            pricingTableId="prctbl_1OgWMHCZAQl1OOa7lBeA45i2"
          />
        </Col>
      </Row>
    </div>
  );
}
