import { FetchResult } from '@apollo/client';
import {
  type ButtonProps,
  useModal,
  useToastr,
  ActionButtons,
} from '@farmshare/ui-components';
import {
  faBoxesPacking,
  faCancel,
  faExclamationTriangle,
  faEye,
  faPrint,
  faTrash,
  faXmarkSquare,
} from '@fortawesome/free-solid-svg-icons';
import { includes, join, map, some } from 'lodash';
import { useMemo } from 'react';

import { ShipmentPackingTips } from 'components/shipment-packing-tips/shipment-packing-tips';

import {
  EnumShipmentStatus,
  useShipmentVoidLabelMutation,
  useDeleteShipmentMutation,
  type Shipment,
  type ShipmentVoidLabelMutation,
  DeleteShipmentMutation,
  useShipmentCancelPickupMutation,
  ShipmentCancelPickupMutation,
  useShipmentGetPackingTipsMutation,
} from 'lib/graphql';

interface ShipmentButtonsProps {
  shipment: Shipment;
  disableDelete?: boolean;
  hideOrderButton?: boolean;
  onVoid?: (voidResponse: FetchResult<ShipmentVoidLabelMutation>) => void;
  onDelete?: (deleteResponse: FetchResult<DeleteShipmentMutation>) => void;
  onPickupCancel?: (
    cancelResponse: FetchResult<ShipmentCancelPickupMutation>,
  ) => void;
}

export function ShipmentButtons({
  shipment,
  disableDelete = false,
  hideOrderButton = false,
  onVoid,
  onDelete,
  onPickupCancel,
}: ShipmentButtonsProps) {
  const { ask, info } = useModal();
  const { push } = useToastr();

  const [getPackingTips, getPackingTipsOp] = useShipmentGetPackingTipsMutation({
    errorPolicy: 'all',
  });
  const [voidLabel, voidLabelOp] = useShipmentVoidLabelMutation();
  const [cancelPickup, cancelPickupOp] = useShipmentCancelPickupMutation();
  const [deleteShipment, deleteShipmentOp] = useDeleteShipmentMutation();

  const buttons = useMemo(() => {
    const _buttons: ButtonProps[] = [];

    if (shipment.label_url) {
      _buttons.push({
        content: `Print Label${shipment.packages.length > 1 ? 's' : ''}`,
        icon: faPrint,
        href: shipment.label_url,
      });
    }

    if (
      includes(
        [
          EnumShipmentStatus.New,
          EnumShipmentStatus.LabelPurchased,
          EnumShipmentStatus.PickupScheduled,
          EnumShipmentStatus.WaitingForDropoff,
        ],
        shipment.status,
      )
    ) {
      if (!hideOrderButton && shipment.order) {
        _buttons.push({
          content: `View Order`,
          icon: faEye,
          href: `/orders/${shipment.order._id}`,
        });
      }

      if (
        some(shipment.packages, 'packaging') &&
        includes(
          [
            EnumShipmentStatus.LabelPurchased,
            EnumShipmentStatus.PickupScheduled,
            EnumShipmentStatus.WaitingForDropoff,
          ],
          shipment.status,
        )
      ) {
        _buttons.push({
          content: 'Packing Tips',
          icon: faBoxesPacking,
          isLoading: getPackingTipsOp.loading,
          onClick: async () => {
            const packingTipsResponse = await getPackingTips({
              variables: { shipmentId: shipment._id },
            });

            if (packingTipsResponse.data?.shipmentGetPackingTips) {
              return info({
                type: 'info',
                title: 'Packing Tips',
                size: 'lg',
                body: (
                  <ShipmentPackingTips
                    shipment={packingTipsResponse.data?.shipmentGetPackingTips}
                  />
                ),
                icon: faBoxesPacking,
              });
            } else if (packingTipsResponse.errors) {
              return info({
                type: 'info',
                title: 'Packing Tips Error',
                icon: faExclamationTriangle,
                body: (
                  <div>
                    <h5>Please correct the following errors:</h5>
                    {map(packingTipsResponse.errors, (e, i) => (
                      <div key={i}>{e.message}</div>
                    ))}
                  </div>
                ),
              });
            }
          },
        });
      }

      if (
        shipment.status === EnumShipmentStatus.PickupScheduled &&
        !!shipment.pickup?.external_id
      ) {
        _buttons.push({
          content: 'Cancel Pickup',
          icon: faCancel,
          isLoading: cancelPickupOp.loading,
          onClick: async () => {
            ask({
              type: 'ask',
              icon: faCancel,
              title: 'Cancel Pickup',
              body: 'Are you sure you want to cancel the pickup for this shipment?',
              isLoading: cancelPickupOp.loading,
              onConfirm: async () => {
                const cancelResponse = await cancelPickup({
                  variables: { pickupId: shipment.pickup?._id },
                });
                push({
                  title: 'Success',
                  body: 'Pickup successfully cancelled.',
                  bg: 'primary',
                  delay: 5000,
                });
                if (onPickupCancel) {
                  onPickupCancel(cancelResponse);
                }
              },
            });
          },
        });
      }

      if (shipment.external_label_id) {
        _buttons.push({
          content: 'Void Label',
          icon: faXmarkSquare,
          isLoading: voidLabelOp.loading,
          onClick: async () => {
            ask({
              type: 'ask',
              title: 'Void Label',
              icon: faXmarkSquare,
              isLoading: voidLabelOp.loading,
              body: `Are you sure you want to void the label${
                shipment.packages.length > 1 ? 's' : ''
              } for ${join(
                map(shipment.packages, (p) => p.tracking_number),
                ', ',
              )}?`,
              onConfirm: async () => {
                const voidResponse = await voidLabel({
                  variables: { shipmentId: shipment._id },
                });
                push({
                  title: 'Success',
                  body: 'Label successfully voided.',
                  bg: 'primary',
                  delay: 5000,
                });
                if (onVoid) {
                  onVoid(voidResponse);
                }
              },
            });
          },
        });
      }

      if (!disableDelete && shipment.status === EnumShipmentStatus.New) {
        _buttons.push({
          content: 'Delete',
          icon: faTrash,
          isLoading: deleteShipmentOp.loading,
          onClick: async () => {
            ask({
              type: 'ask',
              title: 'Delete Shipment',
              body: 'Are you sure you want to delete this shipment?',
              icon: faTrash,
              yesText: 'Delete',
              yesIcon: faTrash,
              onConfirm: async () => {
                const deleteResponse = await deleteShipment({
                  variables: { shipmentId: shipment._id },
                });
                push({
                  title: 'Success',
                  body: 'Shipment successfully deleted.',
                  bg: 'primary',
                  delay: 5000,
                });
                if (onDelete) {
                  onDelete(deleteResponse);
                }
              },
            });
          },
        });
      }
    }

    return _buttons;
  }, [
    ask,
    cancelPickup,
    cancelPickupOp.loading,
    deleteShipment,
    deleteShipmentOp.loading,
    disableDelete,
    getPackingTips,
    getPackingTipsOp.loading,
    hideOrderButton,
    info,
    onDelete,
    onPickupCancel,
    onVoid,
    push,
    shipment,
    voidLabel,
    voidLabelOp.loading,
  ]);

  return <ActionButtons buttons={buttons} />;
}
