export type AnimalSplitType =
  | 'whole'
  | 'half'
  | 'quarters'
  | 'half_and_two_quarters';

export const ALL_ANIMAL_SPLITS = [
  'whole',
  'half',
  'quarters',
  'half_and_two_quarters',
];

export const animalSplitTypeHelper = (value: AnimalSplitType | string) => {
  switch (value.toLowerCase()) {
    case 'whole':
      return { label: 'Whole', order: 0, value };

    case 'half':
      return { label: 'Half', order: 1, value };

    case 'quarters':
      return { label: 'Quarters', order: 2, value };

    case 'half_and_two_quarters':
      return { label: 'Half and Two Quarters', order: 3, value };

    default:
      return { label: value, order: 99, value };
  }
};

export type AnimalSplitPart = 'whole' | 'half' | 'quarter';
export const animalSplitPartHelper = (value: AnimalSplitPart | string) => {
  switch (value.toLowerCase()) {
    case 'whole':
      return { label: 'Whole', order: 0, value };

    case 'half':
      return { label: 'Half', order: 1, value };

    case 'quarter':
      return { label: 'Quarter', order: 2, value };

    default:
      return { label: value, order: 99, value };
  }
};
