import { PageTitle, useModal } from '@farmshare/ui-components';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

export default function AdminAssetPage() {
  const { save } = useModal();

  return (
    <div>
      <PageTitle
        title="Vendor Assets"
        innerBreadcrumbs={[{ text: 'Admin', to: '/admin' }]}
        actionButtons={[
          {
            content: 'Add Asset',
            icon: faPlus,
            onClick: () => {
              save({
                type: 'save',
                title: 'Add Asset',
                body: <div />,
                initialValues: {},
              });
            },
          },
        ]}
      />
    </div>
  );
}
