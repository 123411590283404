import {
  type InputChipOption,
  InputChips,
  InputText,
  InfoBox,
  InputSelect,
  InputQuantity,
} from '@farmshare/ui-components';
import {
  formatToCurrency,
  inspectionLevelHelper,
  RecursivePartial,
} from '@farmshare/utils';
import { type FormikProps } from 'formik';
import {
  find,
  groupBy,
  intersection,
  map,
  orderBy,
  range,
  sortBy,
  startCase,
} from 'lodash';
import { useCallback, useMemo } from 'react';
import { Card, Col, Row, Stack } from 'react-bootstrap';

import {
  EnumPrimalCutType,
  type ProcessorCapability,
  type PrimalCut,
  ProcessorSettings,
  EnumProcessorSchedulingAnimalSpecies,
  EnumCutsheetInspectionLevels,
} from 'lib/graphql';

import {
  ExtrasModel,
  ExtrasTrimOptionsType,
  TrimModel,
} from 'pages/processor/cutsheets/_views/cutsheet-modal';

export interface SchedulingCustomCutsheetModalForm {
  name: string;
  inspectionLevel?: EnumCutsheetInspectionLevels;
  cuts: InputChipOption[];
  pricePerPound?: number;
  steaksPerPack?: string;
  steakThickness?: string;
  groundMeatSize?: string;
  roastSize?: string;
  extras?: ExtrasTrimOptionsType['extras'];
  trim?: ExtrasTrimOptionsType['trim'];
}

interface SchedulingCustomCutsheetModalProps {
  fP: FormikProps<SchedulingCustomCutsheetModalForm>;
  capabilities?: RecursivePartial<ProcessorCapability>[];
  animalSpecies?: EnumProcessorSchedulingAnimalSpecies;
  settings?: RecursivePartial<ProcessorSettings>;
}

export function SchedulingCustomCutsheetModal({
  fP,
  capabilities,
  settings,
  animalSpecies,
}: SchedulingCustomCutsheetModalProps) {
  const selectedCutsById = useMemo(
    () => map(fP.values.cuts, (c) => c.value),
    [fP.values.cuts],
  );

  const getCutOption: (cut: PrimalCut) => InputChipOption = useCallback(
    (cut: PrimalCut) => {
      // checking to see if any of the 'blockedBy' values are currently selected
      const disabled = intersection(selectedCutsById, cut.blockedBy).length > 0;
      return { label: cut.name, value: cut._id, disabled };
    },
    [selectedCutsById],
  );

  const foundCapability = useMemo(() => {
    if (animalSpecies) {
      return find(
        capabilities,
        (c) =>
          (c.animalSpecies as unknown as EnumProcessorSchedulingAnimalSpecies) ===
            animalSpecies &&
          (c.inspectionLevel as unknown as EnumCutsheetInspectionLevels) ===
            fP.values.inspectionLevel,
      );
    }
  }, [animalSpecies, fP.values.inspectionLevel, capabilities]);

  const renderSubGroup = useCallback(
    (cuts: PrimalCut[], label: string) => {
      return (
        <div key={label}>
          <div className="mb-1 fs-6">{label}</div>
          <InputChips
            nameOveride="cuts"
            options={map(orderBy(cuts, 'order'), getCutOption)}
            size="sm"
            required
          />
        </div>
      );
    },
    [getCutOption],
  );

  const renderExtraTrimSection = useCallback(
    (label: 'extras' | 'trim') => {
      if (
        animalSpecies &&
        fP.values.inspectionLevel &&
        fP.values?.[label]?.[animalSpecies]?.[fP.values.inspectionLevel]
      ) {
        return (
          <div key={label}>
            <div className="fw-bold fs-4 mb-3">{startCase(label)}</div>
            {map(
              fP.values?.[label][animalSpecies][fP.values.inspectionLevel],
              (item: ExtrasModel | TrimModel, index: number) => {
                return (
                  <div
                    key={item.name}
                    className="gx-1 mb-3 d-flex flex-wrap justify-content-between align-items-center me-3"
                  >
                    <InputChips
                      options={[
                        {
                          label: `${startCase(item.name)} / ${formatToCurrency(
                            item.pricePerPound,
                          )}/lb ${
                            (item.minLbs ?? 0) > 0
                              ? ` / ${item?.minLbs}lb min `
                              : ''
                          }`,
                          value: item.name,
                          isActive: item.isActive,
                        },
                      ]}
                      size="sm"
                      action={() => {
                        fP.setFieldValue(
                          `${label}.${animalSpecies}.${fP.values.inspectionLevel}.${index}`,
                          {
                            ...item,
                            isActive: !item.isActive,
                          },
                        );
                      }}
                    />

                    {item.minLbs ? (
                      <InputQuantity
                        nameOveride={`${label}.${animalSpecies}.${fP.values.inspectionLevel}.${index}.quantity`}
                        step={1}
                        min={item.isActive ? item.minLbs : 0}
                        required={item.isActive}
                        showEdit
                      />
                    ) : null}

                    {label === 'trim' && (
                      <div style={{ width: '75px' }}>
                        <InputText
                          label="Rank"
                          nameOveride={`${label}.${animalSpecies}.${fP.values.inspectionLevel}.${index}.rank`}
                          type="number"
                          size="sm"
                          floatingLabel
                          required={item.isActive}
                          min={1}
                        />
                      </div>
                    )}
                  </div>
                );
              },
            )}
          </div>
        );
      }
    },
    [fP, animalSpecies],
  );

  const renderGroup = useCallback(
    (cuts: PrimalCut[] = [], label: string) => {
      const subGroups = groupBy(cuts, (c) => {
        let _name = c.primal?.name;
        if (c.type !== EnumPrimalCutType.Other) {
          _name += ` ${startCase(c.type)}s`;
        }
        return _name;
      });
      return (
        <Col key={label}>
          <Card className="h-100" body>
            <Stack gap={2}>
              <div className="fw-bold fs-5">{label}</div>
              {map(subGroups, renderSubGroup)}
            </Stack>
          </Card>
        </Col>
      );
    },
    [renderSubGroup],
  );

  const groupedCapabilities = useMemo(() => {
    if (foundCapability) {
      return groupBy(foundCapability?.cuts, (c) => c?.primal?.group?.name);
    }
    return [];
  }, [foundCapability]);

  const inspectionLevelOptions = useMemo(() => {
    const animalSetting = settings?.animalSettings?.find(
      (o) => o?.species?.toString() === animalSpecies?.toString(),
    );

    return sortBy(
      map(animalSetting?.inspectionLevels, (inspectionLevel) => {
        const inspectionOption = inspectionLevelHelper(inspectionLevel ?? '');
        return { ...inspectionOption, sortValue: inspectionOption.order };
      }),
      'sortValue',
    );
  }, [settings?.animalSettings, animalSpecies]);

  return (
    <>
      <div className="fs-5 fw-bold mb-2">Details</div>
      <Row xs={1} lg={3} className="g-2">
        <Col>
          <InputText
            label="Name"
            type="text"
            hint="Make this name something that is recognizable to you."
            floatingLabel
            required
          />
        </Col>
        <Col>
          <InputSelect
            label="Inspection Level"
            nameOveride="inspectionLevel"
            options={inspectionLevelOptions}
            floatingLabel
            required
          />
        </Col>
      </Row>
      <hr />
      <div className="fs-5 fw-bold mb-2">Specifications</div>
      <Row xs={1} lg={4} className="g-2">
        <Col>
          <InputSelect
            label="Ground Meat Size (lb.)"
            options={map([1, 1.5, 2], (value) => ({
              label: `${value} lb.`,
              value,
            }))}
            nameOveride="groundMeatSize"
            floatingLabel
            required
          />
        </Col>
        <Col>
          <InputSelect
            label="Roast Size (lb.)"
            options={map(['2-3', '3-4', '4-5'], (value) => ({
              label: `${value} lb.`,
              value,
            }))}
            nameOveride="roastSize"
            floatingLabel
          />
        </Col>
        <Col>
          <InputSelect
            label="Steak Thickness (in.)"
            nameOveride="steakThickness"
            options={map([0.75, 1, 1.25, 1.5, 1.75, 2], (value) => ({
              label: `${value} in.`,
              value,
            }))}
            floatingLabel
          />
        </Col>
        <Col>
          <InputSelect
            label="Steaks/Pack"
            nameOveride="steaksPerPack"
            options={map(range(1, 5, 1), (value) => ({
              label: value.toString(),
              value,
            }))}
            floatingLabel
          />
        </Col>
      </Row>
      <hr />
      <div className="fs-5 fw-bold mb-2">Cuts</div>
      <InfoBox
        content={
          <>
            Select all cuts that you would like. Every unselected cut will
            default to ground meat.
          </>
        }
      />

      {groupedCapabilities && (
        <Row xs={1} lg={2} xl={3} className="g-2">
          {map(groupedCapabilities, renderGroup)}
          <Col>
            <Card className="h-100" body>
              <Stack gap={2}>
                {map(['extras', 'trim'], renderExtraTrimSection)}
              </Stack>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
}
