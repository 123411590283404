import { PaginationTable, useModal, useToastr } from '@farmshare/ui-components';
import { formatToShortDate } from '@farmshare/utils';
import { faPeopleGroup, faPlus } from '@fortawesome/free-solid-svg-icons';
import { keyBy, mapValues } from 'lodash';

import {
  type Segment,
  type SegmentPagination,
  SortFindManySegmentInput,
  useSegmentPaginationLazyQuery,
  type SegmentPaginationQuery,
  type SegmentPaginationQueryVariables,
  useSegmentCreateMutation,
} from 'lib/graphql';

import { SegmentModal, type SegmentModalForm } from './_views/segment-modal';

export function SegmentView() {
  const { save } = useModal();
  const { push } = useToastr();

  const [createSegment, createSegmentOp] = useSegmentCreateMutation();

  return (
    <div>
      <PaginationTable<
        Segment,
        SegmentPagination,
        SegmentPaginationQuery,
        SegmentPaginationQueryVariables,
        SortFindManySegmentInput
      >
        paginationQuery={useSegmentPaginationLazyQuery({
          notifyOnNetworkStatusChange: true,
        })}
        defaultSort={SortFindManySegmentInput.NameAsc}
        filters={[{ label: 'Enabled', value: 'enabled' }]}
        defaultFilters={['enabled']}
        columns={[
          { label: 'Name', field: 'name' },
          // { label: 'Category', field: 'category', minimumBreakpoint: 'md' },
          {
            label: '# of Customers',
            formatter: (row) => row.customers?.length,
          },
          { label: 'Filter Field', field: 'filter.field' },
          { label: 'Filter Value', field: 'filter.value' },
          { label: 'Filter Unit', field: 'filter.unit' },
          {
            label: 'Date Created',
            field: 'date_created',
            formatter: (row) => formatToShortDate(row.date_created),
          },
        ]}
        dataAccessor={(a) => a.segmentPagination as SegmentPagination}
        buildFilterQuery={(allFilters, defaultSort, page, perPage) => ({
          filter: mapValues(
            keyBy(allFilters, (k) => k.value),
            (v) => v.isActive,
          ),
          sort: defaultSort,
          page,
          perPage,
        })}
        actionButtons={(op) => [
          {
            content: (
              <span className="d-none d-md-inline">New&nbsp;Segment</span>
            ),
            icon: faPlus,
            onClick: () => {
              save<SegmentModalForm>({
                type: 'save',
                title: 'New Segment',
                icon: faPeopleGroup,
                // size: 'lg',
                body: (formikProps) => (
                  <SegmentModal formikProps={formikProps} />
                ),
                initialValues: {
                  name: '',
                  selector: 'all',
                  selectedValue: '',
                },
                isLoading: createSegmentOp.loading,
                onSubmit: async (values) => {
                  await createSegment({ variables: values });
                  push({
                    title: 'Success',
                    body: 'Segment created successfully.',
                    bg: 'primary',
                    delay: 4000,
                  });
                  op.refetch();
                },
              });
            },
          },
        ]}
      />
    </div>
  );
}
