import { join, map } from 'lodash';
import { IntercomProps } from 'react-use-intercom';

import { User } from 'lib/graphql';

export const buildIntercomProps = (user: User): Partial<IntercomProps> => {
  const props: Partial<IntercomProps> = {
    avatar: {
      type: 'avatar',
      imageUrl: user.picture_url || undefined,
    },
    userId: user._id,
    name: `${user.first_name} ${user.last_name}`,
    // TODO this might change for basic users
    phone: user.active_vendor?.address.phone || undefined,
    alignment: 'right',
    verticalPadding: 90,
  };

  if (user.tenant) {
    props.company = {
      companyId: user.tenant._id,
      name: user.tenant?.name,
      plan: join(
        map(user.tenant.modules, (m) => m.name),
        ',',
      ),
      createdAt: user.tenant?.date_created,
    };
  }

  return props;
};
