import {
  Button,
  DataDetailListMinimal,
  DataDetailListProps,
  InputCheck,
  InputSelect,
  InputText,
  MoneyDisplay,
  useModal,
  useToastr,
} from '@farmshare/ui-components';
import {
  animalSpeciesHelper,
  animalSplitTypeHelper,
  inspectionLevelHelper,
} from '@farmshare/utils';
import { faEdit, faFileEdit } from '@fortawesome/free-solid-svg-icons';
import { useFormikContext } from 'formik';
import { concat, isNil, map, sortBy } from 'lodash';
import moment, { Moment } from 'moment';
import { useCallback, useMemo } from 'react';
import { Card, Col, Row, Stack } from 'react-bootstrap';

import {
  AnimalHeadFragment,
  EnumProcessorSchedulingAnimalHeadsInspectionLevel,
} from 'lib/graphql';
import { isAnimalBeef } from 'lib/processingJobUtils';

import { type AnimalHeadDetailsSectionProps } from '../animal-head';

function buildHangingWeightRowValue(
  animalHead: AnimalHeadFragment | null | undefined,
) {
  if (animalHead?.isHangingWeightPerSide) {
    const sideAPart = animalHead.hangingWeightSideA
      ? `${animalHead.hangingWeightSideA}`
      : '-';
    const sideBPart = animalHead.hangingWeightSideB
      ? `${animalHead.hangingWeightSideB}`
      : '-';
    return `${sideAPart}/${sideBPart}`;
  }
  return animalHead?.hangingWeight ? `${animalHead.hangingWeight}` : undefined;
}

interface AnimalHeadDetailsFormData {
  inspectionLevel?: EnumProcessorSchedulingAnimalHeadsInspectionLevel;
  producerIdentifier?: string;
  tracking?: string;
  isHangingWeightPerSide?: boolean;
  hangingWeightSideA?: number;
  hangingWeightSideB?: number;
  hangingWeight?: number;
  liveWeight?: number;
  killDate?: string;
  cutDate?: string;
  isOverThirtyMonths?: boolean;
  sex?: string;
}

const EditAnimalHeadDetailsForm = ({
  currentKillDate,
  currentCutDate,
  show30MonthsOld,
  currentSpeciesInspectionLevels,
  currentInspectionLevel,
}: {
  currentKillDate: Moment;
  currentCutDate: Moment;
  show30MonthsOld: boolean;
  currentSpeciesInspectionLevels: string[];
  currentInspectionLevel?: string;
}) => {
  const { values } = useFormikContext<AnimalHeadDetailsFormData>();
  const showSideWeights = useMemo(() => {
    return values.isHangingWeightPerSide ?? false;
  }, [values.isHangingWeightPerSide]);

  const inspectionLevelOptions = useMemo(() => {
    const currentOptions = map(currentSpeciesInspectionLevels, (o) =>
      inspectionLevelHelper(o),
    );

    const currentSelectedOption = [];
    // In case someone has a head scheduled as exempt then exempt is disabled on the species in the future. It will still show up for this head.
    if (
      !currentSpeciesInspectionLevels.includes(currentInspectionLevel ?? '')
    ) {
      currentSelectedOption.push(
        inspectionLevelHelper(currentInspectionLevel ?? 'N/A'),
      );
    }

    return sortBy(concat(currentOptions, currentSelectedOption), 'order');
  }, [currentSpeciesInspectionLevels, currentInspectionLevel]);

  const displayCurrentKillDate = currentKillDate.isValid()
    ? currentKillDate.format('yyyy-MM-DD')
    : 'N/A';

  const displayCurrentCutDate = currentCutDate.isValid()
    ? currentCutDate.format('yyyy-MM-DD')
    : 'N/A';

  return (
    <Stack gap={3}>
      <Row>
        <Col>
          <InputSelect
            label="Inspection Level"
            floatingLabel
            nameOveride="inspectionLevel"
            options={inspectionLevelOptions}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputText
            label="Producer Identifier"
            floatingLabel
            type="text"
            nameOveride="producerIdentifier"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputSelect
            label="Sex"
            options={['M', 'F']}
            floatingLabel
            nameOveride="sex"
            hint="What sex is this animal?"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputText
            label="Tracking Number"
            floatingLabel
            type="text"
            nameOveride="tracking"
          />
        </Col>
      </Row>
      {show30MonthsOld && (
        <Row>
          <Col>
            <InputCheck
              label="Beef is over 30 months old?"
              floatingLabel
              type="checkbox"
              inline
              nameOveride="isOverThirtyMonths"
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <InputText
            type="number"
            min={0}
            step={0.01}
            label="Live Weight"
            floatingLabel
            nameOveride="liveWeight"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputCheck
            label="Hanging weight is entered per side."
            floatingLabel
            type="switch"
            inline
            nameOveride="isHangingWeightPerSide"
          />
        </Col>
      </Row>
      <Row>
        {showSideWeights && (
          <>
            <Col xs={6}>
              <InputText
                type="number"
                min={0}
                step={0.01}
                label={'Hanging Weight Side A'}
                floatingLabel
                nameOveride="hangingWeightSideA"
                hint={`Total hanging weight of animal side A`}
              />
            </Col>
            <Col xs={6}>
              <InputText
                type="number"
                min={0}
                step={0.01}
                label={'Hanging Weight Side B'}
                floatingLabel
                nameOveride="hangingWeightSideB"
                hint={`Total hanging weight of the animal side B`}
              />
            </Col>
          </>
        )}
        {!showSideWeights && (
          <Col>
            <InputText
              type="number"
              min={0}
              step={0.01}
              label={'Hanging Weight'}
              floatingLabel
              nameOveride={'hangingWeight'}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <InputText
            label="Kill Date"
            type="date"
            nameOveride="killDate"
            hint={`Current date this animal was killed on ${displayCurrentKillDate}`}
            floatingLabel
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputText
            label="Cut Date"
            type="date"
            nameOveride="cutDate"
            hint={`Current date this animal was cut on ${displayCurrentCutDate}`}
            floatingLabel
          />
        </Col>
      </Row>
    </Stack>
  );
};

export function AnimalHeadDetails({
  animalHead,
  processingJobId,
  updateAnimalHead,
  animalSpecies,
  currentSpeciesInspectionLevels,
}: AnimalHeadDetailsSectionProps) {
  const { save } = useModal();
  const { push } = useToastr();

  const splitTypeDisplay = useMemo(() => {
    return animalSplitTypeHelper(animalHead?.splitType ?? 'N/A');
  }, [animalHead?.splitType]);

  const animalIsTypeBeef = useMemo(() => {
    return isAnimalBeef(animalSpecies);
  }, [animalSpecies]);

  const handleEditDetails = useCallback(() => {
    const momentKillDate = moment.utc(animalHead?.killDate ?? null);
    const momentCutDate = moment.utc(animalHead?.cutDate ?? null);

    return save<AnimalHeadDetailsFormData>({
      type: 'save',
      title: 'Edit Details',
      icon: faFileEdit,
      initialValues: {
        inspectionLevel: animalHead?.inspectionLevel ?? undefined,
        producerIdentifier: animalHead?.producerIdentifier ?? undefined,
        tracking: animalHead?.tracking ?? undefined,
        isHangingWeightPerSide: animalHead?.isHangingWeightPerSide ?? false,
        hangingWeight: animalHead?.hangingWeight ?? undefined,
        hangingWeightSideA: animalHead?.hangingWeightSideA ?? undefined,
        hangingWeightSideB: animalHead?.hangingWeightSideB ?? undefined,
        liveWeight: animalHead?.liveWeight ?? undefined,
        killDate: momentKillDate.isValid()
          ? momentKillDate.format('yyyy-MM-DD')
          : undefined,
        cutDate: momentCutDate.isValid()
          ? momentCutDate.format('yyyy-MM-DD')
          : undefined,
        isOverThirtyMonths: animalHead?.isOverThirtyMonths ?? undefined,
        sex: animalHead?.sex ?? undefined,
      },
      body: (
        <EditAnimalHeadDetailsForm
          show30MonthsOld={animalIsTypeBeef}
          currentKillDate={momentKillDate}
          currentCutDate={momentCutDate}
          currentSpeciesInspectionLevels={currentSpeciesInspectionLevels ?? []}
          currentInspectionLevel={animalHead?.inspectionLevel ?? undefined}
        />
      ),
      onSubmit: async (values) => {
        const updatedKillDate = values.killDate
          ? moment.utc(values.killDate).format('MM/DD/YYYY')
          : null;
        const updatedCutDate = values.cutDate
          ? moment.utc(values.cutDate).format('MM/DD/YYYY')
          : null;

        await updateAnimalHead({
          variables: {
            processorSchedulingId: processingJobId,
            animalHeadId: animalHead?._id,
            record: {
              producerIdentifier: values.producerIdentifier,
              tracking: values.tracking,
              isHangingWeightPerSide: values.isHangingWeightPerSide,
              hangingWeightSideA: values.isHangingWeightPerSide
                ? values.hangingWeightSideA
                : undefined,
              hangingWeightSideB: values.isHangingWeightPerSide
                ? values.hangingWeightSideB
                : undefined,
              hangingWeight: values.isHangingWeightPerSide
                ? undefined
                : values.hangingWeight,
              liveWeight: values.liveWeight,
              killDate: updatedKillDate,
              cutDate: updatedCutDate,
              isOverThirtyMonths: animalIsTypeBeef
                ? values.isOverThirtyMonths
                : undefined,
              sex: values.sex,
              inspectionLevel: values.inspectionLevel,
            },
          },
        });

        push({
          title: 'Processing Job Updated',
          body: 'The animal head has been updated.',
          bg: 'success',
          delay: 4000,
        });
      },
    });
  }, [
    save,
    animalHead,
    updateAnimalHead,
    processingJobId,
    animalIsTypeBeef,
    push,
    currentSpeciesInspectionLevels,
  ]);

  const dataRows = useMemo(() => {
    const rows: DataDetailListProps['rows'] = [];

    const isSideHangingWeightLabelPart = animalHead?.isHangingWeightPerSide
      ? `(Side A/Side B)`
      : '';

    const hangingWeightRowValue = buildHangingWeightRowValue(animalHead);

    rows.push(
      { label: 'Animal Id', value: animalHead?.publicId },
      {
        label: 'Species',
        value: animalSpeciesHelper(animalSpecies ?? 'N/A').label,
      },
      {
        label: 'Inspection Level',
        value: animalHead?.inspectionLevel
          ? inspectionLevelHelper(animalHead?.inspectionLevel).label
          : undefined,
      },
      {
        label: 'Kill Slot Price',
        value: <MoneyDisplay value={animalHead?.butcherSlotPrice} />,
      },
      { label: 'Tracking Number', value: animalHead?.tracking },
      {
        label: 'Producer Identifier',
        value: animalHead?.producerIdentifier,
      },
    );

    if (animalIsTypeBeef) {
      rows.push({
        label: 'Beef is over 30 months old?',
        value: isNil(animalHead?.isOverThirtyMonths)
          ? 'Unknown'
          : animalHead?.isOverThirtyMonths
          ? 'Yes'
          : 'No',
      });
    }

    rows.push(
      { label: 'Sex', value: animalHead?.sex?.toUpperCase() || 'Unknown' },
      { label: 'Live Weight (lbs) ', value: animalHead?.liveWeight },
      { label: 'Split', value: splitTypeDisplay.label },
      {
        label: 'Is Hanging Weight Per Side',
        value: animalHead?.isHangingWeightPerSide ? 'Yes' : 'No',
      },
      {
        label: `Hanging Weight${isSideHangingWeightLabelPart} (lbs)`,
        value: hangingWeightRowValue,
      },
      {
        label: 'Kill Date',
        value: animalHead?.killDateStr,
      },
      {
        label: 'Cut Date',
        value: animalHead?.cutDateStr,
      },
    );

    return rows;
  }, [animalHead, animalIsTypeBeef, splitTypeDisplay, animalSpecies]);

  return (
    <Card className="h-100">
      <Card.Header className="d-flex align-items-center">
        <span className="fw-bold fs-5">Details</span>
        <Button
          className="ms-auto"
          content="Edit Details"
          onClick={handleEditDetails}
          icon={faEdit}
          size="sm"
        />
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <DataDetailListMinimal rows={dataRows} />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
