import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

export const usePageTracking = () => {
  const location = useLocation();
  const { update } = useIntercom();

  useEffect(() => {
    /**
     * https://developers.intercom.com/installing-intercom/web/methods/#update-throttling
     * Initiate a Ping call update wtih intercom so that the recent page views updates on the user
     * This is throttled by intercom at 20 calls every 30 minutes. Its reset when you refresh the page.
     */
    update({});
  }, [location, update]);
};
