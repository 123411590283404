import { PaginationTablePage } from '@farmshare/ui-components';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { filter, map, startCase } from 'lodash';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';

import { ShipmentButtons } from 'components/shipment-buttons/shipment-buttons';

import {
  type Shipment,
  SortFindManyShipmentInput,
  EnumShipmentStatus,
  useShipmentPaginationLazyQuery,
  type ShipmentPagination,
  type ShipmentPaginationQueryVariables,
  type ShipmentPaginationQuery,
} from 'lib/graphql';

export type PickedShipment = Pick<
  Shipment,
  | '_id'
  | 'external_id'
  | 'external_label_id'
  | 'label_layout'
  | 'label_url'
  | 'chosen_rate'
  | 'packages'
  | 'pickup'
  | 'requested_delivery_date'
  | 'status'
  | 'to_address'
  | 'order'
>;

export default function ShipmentsPage() {
  const navigate = useNavigate();

  return (
    <div className="pt-4">
      <PaginationTablePage<
        PickedShipment,
        ShipmentPagination,
        ShipmentPaginationQuery,
        ShipmentPaginationQueryVariables,
        SortFindManyShipmentInput
      >
        title="Shipments"
        paginationQuery={useShipmentPaginationLazyQuery({
          notifyOnNetworkStatusChange: true,
        })}
        buildFilterQuery={(allFilters, defaultSort, page, perPage) => {
          const activeFilters = filter(allFilters, (f) => f.isActive || false);

          if (activeFilters?.length > 0) {
            return {
              filter: {
                OR: map(activeFilters, (a) => ({
                  status: a.value as EnumShipmentStatus,
                })),
              },
              sort: defaultSort,
              page,
              perPage,
            };
          }
          return {
            filter: {},
            sort: defaultSort,
            page,
            perPage,
          };
        }}
        filters={map(EnumShipmentStatus, (e) => ({
          label: e.toString(),
          value: e,
        }))}
        defaultFilters={[
          EnumShipmentStatus.New,
          EnumShipmentStatus.LabelPurchased,
          EnumShipmentStatus.PickupScheduled,
          EnumShipmentStatus.WaitingForDropoff,
          EnumShipmentStatus.InTransit,
        ]}
        defaultSort={SortFindManyShipmentInput.DeliveryDateAsc}
        dataAccessor={(a) => a.shipmentPagination as ShipmentPagination}
        actionButtons={() => [
          {
            content: (
              <span className="d-none d-md-inline">New&nbsp;Shipment</span>
            ),
            icon: faPlus,
            onClick: () => navigate('/shipments/create'),
          },
        ]}
        columns={[
          {
            label: 'Recipient',
            formatter: (row) => (
              <Link to={`/shipments/${row._id}`}>
                {row.order?.shipping
                  ? `${row.order.shipping.first_name} ${row.order.shipping.last_name}`
                  : `${row.to_address?.first_name} ${row.to_address?.last_name}`}
              </Link>
            ),
          },
          {
            label: 'Expected Date',
            field: 'chosen_rate.estimated_delivery_date',
            formatter: (row) => (
              <div className="d-none d-md-block">
                {row.chosen_rate?.estimated_delivery_date ? (
                  moment(row.chosen_rate?.estimated_delivery_date).format('L')
                ) : (
                  <span className="fst-italic text-muted">tbd...</span>
                )}
              </div>
            ),
            minimumBreakpoint: 'md',
          },
          {
            label: 'Packages',
            formatter: (row) => row.packages.length,
            minimumBreakpoint: 'lg',
          },
          {
            label: 'Status',
            field: 'status',
            formatter: (row) => startCase(row.status),
          },
          {
            label: 'Carrier',
            field: 'chosen_rate.carrier.friendly_name',
          },
          {
            label: 'Days',
            field: 'chosen_rate.delivery_days',
            minimumBreakpoint: 'lg',
          },
          {
            formatter: (row, _, operation) => (
              <ShipmentButtons
                shipment={row as Shipment}
                onVoid={() => operation?.refetch()}
                onDelete={() => operation?.refetch()}
              />
            ),
          },
        ]}
      />
    </div>
  );
}
