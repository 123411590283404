export const environment = {
  production: true,
  authAudience: 'https://api.farmshare.co',
  authDomain: 'farmshare.us.auth0.com',
  authClientId: 'TUcEYrobjksCGyco46kkkJlofpv727kb',
  intercomKey: 'ujxh1s4b',
  stripeKey:
    'pk_live_51Kb5omCZAQl1OOa73mLM0xKBGxnRcQQ61VSW0QYqUsK7ZEsqwifGqAWuqyBU4bLtK88Rf6rJ4jvXH8dFu2gQItbH00TN1O9Nfi',
  stripePricingTable: 'prctbl_1NrkyQCZAQl1OOa7j0cuyocp',
  tinyApiKey: 'hv6ora44j5j9k7n1gkgkfyogvqykyuk52l7e12yhdl5kqqvw',
};
