import { Button, useModal } from '@farmshare/ui-components';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { map } from 'lodash';

import {
  type TenantByIdQuery,
  useTenantModulesEditMutation,
} from 'lib/graphql';

import { ModulesModal, type ModulesModalForm } from './modules-modal';

interface EditModulesButtonProps {
  tenant: TenantByIdQuery['tenantById'];
  refetch: () => Promise<unknown>;
}

export function EditModulesButton(props: EditModulesButtonProps) {
  const [editModules, { loading }] = useTenantModulesEditMutation();

  const { save } = useModal();

  return (
    <Button
      content="Edit Modules"
      size="sm"
      icon={faEdit}
      isLoading={loading}
      onClick={() =>
        save<ModulesModalForm>({
          type: 'save',
          title: 'Edit Modules',
          icon: faEdit,
          initialValues: {
            moduleIds: map(props.tenant?.modules, '_id'),
          },
          body: <ModulesModal />,
          isLoading: loading,
          onSubmit: async ({ moduleIds }) => {
            await editModules({
              variables: { tenantId: props.tenant?._id, moduleIds },
            });
            await props.refetch();
          },
        })
      }
    />
  );
}
