import { InputTextArea } from '@farmshare/ui-components';
import { FormikProps } from 'formik';
import { Col, Row } from 'react-bootstrap';

export interface NoteForm {
  note: string;
}

export interface NoteProps {
  formik: FormikProps<NoteForm>;
}

export function NoteModal({ formik }: NoteProps) {
  return (
    <Row>
      <Col>
        <InputTextArea
          label="Write your note here..."
          nameOveride="note"
          rows={10}
          required
        />
      </Col>
    </Row>
  );
}
