import {
  DataDetailList,
  Error as ErrorComponent,
  Loading,
  PageTitle,
} from '@farmshare/ui-components';
import { formatToCurrency, formatToShortDate } from '@farmshare/utils';
import { map, first, startCase } from 'lodash';
import { useEffect } from 'react';
import { Accordion, Card, Col, Container, Row } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';

import { addressFieldsArray } from 'lib/addressFieldsArray';
import { Shipment, useOrderByIdLazyQuery } from 'lib/graphql';

import ShipmentDetails from './_views/shipment-details';

export default function OrderDetailsPage() {
  const { hash } = useLocation();
  const { id } = useParams();

  const [load, { data, loading, error, called, refetch }] =
    useOrderByIdLazyQuery();

  useEffect(() => {
    if (id) {
      load({ variables: { id } });
    }
  }, [load, id]);

  if (error || (called && data && !data?.orderById)) {
    return (
      <ErrorComponent
        error={error ? error : new Error(`Order #${id} not found.`)}
      />
    );
  }

  return loading ? (
    <Loading />
  ) : (
    <div className="pt-3 pt-lg-4">
      {data?.orderById && (
        <>
          <PageTitle
            title={
              <>
                Order&nbsp;
                <span className="small fw-light text-muted">
                  {data.orderById.external_id}
                </span>
              </>
            }
            innerBreadcrumbs={[{ to: '/orders', text: 'Orders' }]}
            breadCrumbLabel={`Order ${data.orderById.external_id}`}
          />
          <Container>
            <Card className="mb-3">
              <Card.Header className="bg-primary text-white">
                <h6 className="mt-2">Order Information</h6>
              </Card.Header>
              <Card.Body>
                <Container>
                  <Row>
                    <Col md={5}>
                      <DataDetailList
                        heading="Shipping"
                        rows={addressFieldsArray(data.orderById.shipping)}
                      />
                    </Col>
                    <Col md={3}>
                      <DataDetailList
                        heading="Transaction"
                        rows={[
                          {
                            label: 'Total',
                            value: formatToCurrency(data.orderById.total),
                          },
                          {
                            label: 'Shipping Paid',
                            value: formatToCurrency(
                              data.orderById.shipping_paid,
                            ),
                          },
                        ]}
                      />
                    </Col>
                    <Col md={4}>
                      <DataDetailList
                        heading="Details"
                        rows={[
                          {
                            label: 'Status',
                            value: startCase(data.orderById.status),
                          },
                          {
                            label: 'Date Created',
                            value: formatToShortDate(
                              data.orderById.date_created,
                            ),
                          },
                          {
                            label: 'Date Completed',
                            value: formatToShortDate(
                              data.orderById.date_completed,
                            ),
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
            <Accordion
              defaultActiveKey={
                hash !== '' ? hash : `#${first(data.orderById.shipments)?._id}`
              }
              // onSelect={(_id) => {
              // @todo: replace hash in url onSelect
              // }}
            >
              {map(data.orderById.shipments, (shipment, idx, arr) => (
                <ShipmentDetails
                  key={shipment._id}
                  orderId={data.orderById?.external_id || 0}
                  shipment={shipment as Shipment}
                  shipmentNumber={idx + 1}
                  totalNumberOfShipments={arr.length}
                  refetch={refetch}
                />
              ))}
            </Accordion>
          </Container>
        </>
      )}
    </div>
  );
}
