import {
  InputChips,
  InputQuantity,
  InputSelect,
  InputText,
  type InputChipOption,
} from '@farmshare/ui-components';
import {
  animalSplitTypeHelper,
  ALL_ANIMAL_SPLITS,
  animalSpeciesHelper,
  inspectionLevelHelper,
  formatToCurrency,
} from '@farmshare/utils';
import { faInfoCircle, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikProps } from 'formik';
import {
  find,
  groupBy,
  intersection,
  map,
  orderBy,
  range,
  reduce,
  sortBy,
  startCase,
} from 'lodash';
import { useCallback, useMemo, useRef } from 'react';
import {
  Card,
  Col,
  OverlayTrigger,
  Popover,
  Row,
  Stack,
} from 'react-bootstrap';

import {
  EnumCutsheetAnimalSpecies,
  EnumCutsheetInspectionLevels,
  EnumPrimalCutType,
  type PrimalCut,
  type ViewProcessorAdminQueryResult,
} from 'lib/graphql';

export interface ExtrasModel {
  name: string;
  pricePerPound: number;
  minLbs?: number;
  isActive: boolean;
  quantity?: number;
}

export interface TrimModel extends ExtrasModel {
  rank: number;
}

export type ExtrasTrimOptionsType = {
  extras: Record<
    Partial<EnumCutsheetAnimalSpecies>,
    Record<Partial<EnumCutsheetInspectionLevels>, ExtrasModel[]>
  >;
  trim: Record<
    Partial<EnumCutsheetAnimalSpecies>,
    Record<Partial<EnumCutsheetInspectionLevels>, TrimModel[]>
  >;
};

export interface NewCutsheetForm {
  animalSpecies: EnumCutsheetAnimalSpecies;
  inspectionLevel?: EnumCutsheetInspectionLevels;
  name: string;
  cuts: InputChipOption[];
  splitTypes: InputChipOption[];
  pricePerPound?: number;
  steaksPerPack?: string;
  steakThickness?: string;
  groundMeatSize: string;
  roastSize?: string;
  extras?: ExtrasTrimOptionsType['extras'];
  trim?: ExtrasTrimOptionsType['trim'];
}

interface NewCutsheetProps {
  fP: FormikProps<NewCutsheetForm>;
  settings?: ViewProcessorAdminQueryResult['data'];
}

export function CutsheetModal({ fP, settings }: NewCutsheetProps) {
  const ref = useRef(null);

  const foundCapability = useMemo(() => {
    if (settings && fP.values.animalSpecies) {
      return find(
        settings.processorCapabilityMany,
        (processorCapability) =>
          (processorCapability as any)?.animalSpecies ===
            fP.values.animalSpecies &&
          processorCapability.inspectionLevel === fP.values.inspectionLevel,
      );
    }
  }, [fP.values.animalSpecies, fP.values.inspectionLevel, settings]);

  const groupedCapabilities = useMemo(() => {
    if (foundCapability) {
      return groupBy(foundCapability.cuts, (c) => c.primal?.group?.name);
    }
    return [];
  }, [foundCapability]);

  const selectedCutsById = useMemo(
    () => map(fP.values.cuts, (c) => c.value),
    [fP.values.cuts],
  );

  const getCutOption: (cut: PrimalCut) => InputChipOption = useCallback(
    (cut: PrimalCut) => {
      // checking to see if any of the 'blockedBy' values are currently selected
      const disabled = intersection(selectedCutsById, cut.blockedBy).length > 0;
      return { label: cut.name, value: cut._id, disabled };
    },
    [selectedCutsById],
  );

  const renderExtraTrimSection = useCallback(
    (label: 'extras' | 'trim') => {
      if (
        fP.values.inspectionLevel &&
        fP.values?.[label]?.[fP.values.animalSpecies]?.[
          fP.values.inspectionLevel
        ]
      ) {
        return (
          <div key={label}>
            <div className="fw-bold fs-4 mb-3">{startCase(label)}</div>
            {map(
              fP.values?.[label][fP.values.animalSpecies][
                fP.values.inspectionLevel
              ],
              (item: ExtrasModel | TrimModel, index: number) => {
                return (
                  <div
                    key={item.name}
                    className="gx-1 mb-3 d-flex flex-wrap justify-content-between align-items-center me-3"
                  >
                    <InputChips
                      options={[
                        {
                          label: `${startCase(item.name)} / ${formatToCurrency(
                            item.pricePerPound,
                          )}/lb ${
                            (item.minLbs ?? 0) > 0
                              ? ` / ${item?.minLbs}lb min `
                              : ''
                          }`,
                          value: item.name,
                          isActive: item.isActive,
                        },
                      ]}
                      size="sm"
                      action={() => {
                        fP.setFieldValue(
                          `${label}.${fP.values.animalSpecies}.${fP.values.inspectionLevel}.${index}`,
                          {
                            ...item,
                            isActive: !item.isActive,
                          },
                        );
                      }}
                    />

                    {item.minLbs ? (
                      <InputQuantity
                        nameOveride={`${label}.${fP.values.animalSpecies}.${fP.values.inspectionLevel}.${index}.quantity`}
                        step={1}
                        min={item.isActive ? item.minLbs : 0}
                        required={item.isActive}
                        showEdit
                      />
                    ) : null}

                    {label === 'trim' && (
                      <div style={{ width: '75px' }}>
                        <InputText
                          label="Rank"
                          nameOveride={`${label}.${fP.values.animalSpecies}.${fP.values.inspectionLevel}.${index}.rank`}
                          type="number"
                          size="sm"
                          floatingLabel
                          required={item.isActive}
                          min={1}
                        />
                      </div>
                    )}
                  </div>
                );
              },
            )}
          </div>
        );
      }
    },
    [fP],
  );

  const renderSubGroup = useCallback(
    (cuts: PrimalCut[], label: string) => {
      return (
        <div key={label}>
          <div className="mb-1 fs-5">{label}</div>
          <InputChips
            nameOveride="cuts"
            options={map(orderBy(cuts, 'order'), getCutOption)}
            size="sm"
            required
          />
        </div>
      );
    },
    [getCutOption],
  );

  const renderGroup = useCallback(
    (cuts: PrimalCut[], label: string) => {
      const subGroups = groupBy(cuts, (c) => {
        let _name = c.primal?.name;
        if (c.type !== EnumPrimalCutType.Other) {
          _name += ` ${startCase(c.type)}s`;
        }
        return _name;
      });

      return (
        <Col key={label}>
          <Card className="h-100" body>
            <Stack gap={2}>
              <div className="fw-bold fs-4">{label}</div>
              {map(subGroups, renderSubGroup)}
            </Stack>
          </Card>
        </Col>
      );
    },
    [renderSubGroup],
  );

  const animalSpeciesOptions = useMemo(() => {
    return reduce(
      settings?.processorSettingsOne?.animalSettings,
      (acc: InputChipOption[], animalSetting) => {
        if (animalSetting?.isEnabled) {
          const speciesOption = animalSpeciesHelper(animalSetting.species);

          acc.push({ ...speciesOption, sortValue: speciesOption.order });
        }
        return acc;
      },
      [],
    );
  }, [settings?.processorSettingsOne?.animalSettings]);

  const inspectionLevelOptions = useMemo(() => {
    const animalSetting = settings?.processorSettingsOne?.animalSettings?.find(
      (o) => o?.species.toString() === fP.values.animalSpecies.toString(),
    );

    return sortBy(
      map(animalSetting?.inspectionLevels, (inspectionLevel) => {
        const inspectionOption = inspectionLevelHelper(inspectionLevel ?? '');
        return { ...inspectionOption, sortValue: inspectionOption.order };
      }),
      'sortValue',
    );
  }, [settings?.processorSettingsOne?.animalSettings, fP.values.animalSpecies]);

  return (
    <>
      <div className="fs-5 fw-bold mb-2">Details</div>
      <Row xs={1} lg={3} className="g-2">
        <Col>
          <InputSelect
            label="Animal Species"
            options={animalSpeciesOptions}
            floatingLabel
            required
          />
        </Col>
        <Col>
          <InputSelect
            label="Inspection Level"
            nameOveride="inspectionLevel"
            options={inspectionLevelOptions}
            floatingLabel
            required
          />
        </Col>
        <Col>
          <InputText label="Name" type="text" floatingLabel required />
        </Col>
        <Col>
          <InputText
            label="Price/lb."
            nameOveride="pricePerPound"
            type="number"
            floatingLabel
            required
          />
        </Col>
      </Row>
      <hr />
      <div className="fs-5 fw-bold mb-2">Specifications</div>
      <Row xs={1} lg={4} className="g-2">
        <Col>
          <InputSelect
            label="Ground Meat Size (lb.)"
            options={map([1, 1.5, 2], (value) => ({
              label: `${value} lb.`,
              value,
            }))}
            nameOveride="groundMeatSize"
            floatingLabel
            required
          />
        </Col>
        <Col>
          <InputSelect
            label="Roast Size (lb.)"
            options={map(['2-3', '3-4', '4-5'], (value) => ({
              label: `${value} lb.`,
              value,
            }))}
            nameOveride="roastSize"
            floatingLabel
          />
        </Col>
        <Col>
          <InputSelect
            label="Steak Thickness (in.)"
            nameOveride="steakThickness"
            options={map([0.75, 1, 1.25, 1.5, 1.75, 2], (value) => ({
              label: `${value} in.`,
              value,
            }))}
            floatingLabel
          />
        </Col>
        <Col>
          <InputSelect
            label="Steaks/Pack"
            nameOveride="steaksPerPack"
            options={map(range(1, 5, 1), (value) => ({
              label: value.toString(),
              value,
            }))}
            floatingLabel
          />
        </Col>
      </Row>
      <hr />

      <div className="fs-5 fw-bold mb-2">Split Types</div>
      <InputChips
        nameOveride="splitTypes"
        options={map(ALL_ANIMAL_SPLITS, (split) => ({
          label: animalSplitTypeHelper(split).label,
          value: animalSplitTypeHelper(split).value,
        }))}
        size="sm"
        required
      />
      <hr />

      <div className="fs-5 fw-bold mb-2" ref={ref}>
        Cuts
        <OverlayTrigger
          container={ref}
          placement="right"
          overlay={
            <Popover className="p-3 bg-info-subtle">
              <Stack
                gap={2}
                direction="horizontal"
                className="align-items-start"
              >
                <FontAwesomeIcon
                  icon={faLightbulb}
                  size="lg"
                  className="mt-1"
                />
                <div>
                  Select all cuts that will be offered in this cutsheet. Every
                  unselected cut will default to ground meat.
                </div>
              </Stack>
            </Popover>
          }
        >
          <span className="ms-2">
            <FontAwesomeIcon icon={faInfoCircle} />
          </span>
        </OverlayTrigger>
      </div>

      {groupedCapabilities && (
        <Row xs={1} lg={2} xl={3} className="g-2">
          {map(groupedCapabilities, renderGroup)}
          <Col>
            <Card className="h-100" body>
              <Stack gap={2}>
                {map(['extras', 'trim'], renderExtraTrimSection)}
              </Stack>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
}
