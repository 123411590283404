import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  type NormalizedCacheObject,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

const allowed = [
  'processingPartners',
  'processingPartnersOne',
  'processorSchedulingMany',
  'processorSchedulingOne',
  'ProcessorSchedulingById',
  'ViewProcessingPartner',
  'CreateProcessorScheduling',
  'ViewSchedulingCreate',
  'CutsheetCreateOne',
];

export function getApolloClient(
  token: string,
): ApolloClient<NormalizedCacheObject> {
  const authMiddleware = new ApolloLink((operation, forward) => {
    if (token) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
    } else {
      if (!allowed.includes(operation.operationName)) {
        throw new Error('No access token available.');
      }
    }

    return forward(operation);
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  });

  const httpLink = new HttpLink({ uri: `/api/graphql` });

  return new ApolloClient({
    link: from([errorLink, authMiddleware, httpLink]),
    cache: new InMemoryCache(),
    credentials: 'same-origin',
  });
}
