import { Loading, PaginationTable, PageTitle } from '@farmshare/ui-components';
import { formatToCurrency } from '@farmshare/utils';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faBoxOpen,
  faClock,
  faHome,
  faTruck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map } from 'lodash';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { userState } from 'state';

import {
  SortFindManySupplyOrderInput,
  SupplyOrder,
  SupplyOrderPagination,
  SupplyOrderPaginationQuery,
  SupplyOrderPaginationQueryVariables,
  usePartnersDashboardQuery,
  useSupplyOrderPaginationLazyQuery,
} from 'lib/graphql';

interface DashboardCard {
  title: string;
  value?: string;
  icon: IconProp;
}

export default function DashboardPage() {
  const user = useRecoilValue(userState);
  const paginationQuery = useSupplyOrderPaginationLazyQuery();

  const { data, loading } = usePartnersDashboardQuery();

  const statusTextMap: {
    [key: string]: string;
  } = {
    PROCESSING: 'Ordered',
    FULFILLED: 'Fulfilled',
  };

  const cards: DashboardCard[] | undefined = useMemo(() => {
    if (data?.partnersDashboard) {
      return [
        {
          title: 'Shipments Ready',
          value: data.partnersDashboard.shipments?.ready?.toLocaleString(),
          icon: faClock,
        },
        {
          title: 'Deliveries In-Transit',
          value: data.partnersDashboard.shipments?.inTransit?.toLocaleString(),
          icon: faTruck,
        },
        {
          title: 'Completed Deliveries',
          value: data.partnersDashboard.shipments?.delivered?.toLocaleString(),
          icon: faHome,
        },
      ];
    }
  }, [data?.partnersDashboard]);

  const renderCard = useCallback((card: DashboardCard, idx: number) => {
    return (
      <Col key={idx}>
        <Card className="bg-body-secondary rounded-4" body>
          <div className="d-flex gap-3 align-items-center">
            <FontAwesomeIcon
              className="text-primary"
              style={{ minWidth: '55px' }}
              icon={card.icon}
              size="3x"
            />
            <div className="d-flex flex-column justify-content-between text-start">
              <div className="fw-bolder fs-1">{card.value}</div>
              <div className="text-secondary">{card.title}</div>
            </div>
          </div>
        </Card>
      </Col>
    );
  }, []);

  const renderSuppliesCard = () => {
    return (
      <Col className="pt-3">
        <Card className="bg-body-secondary rounded-4" body>
          <div className="d-flex gap-3 align-items-center">
            <FontAwesomeIcon
              className="text-primary"
              style={{ minWidth: '55px' }}
              icon={faBoxOpen}
              size="3x"
            />
            <div className="d-flex flex-column justify-content-between text-start">
              <div className="fw-bolder fs-1">
                {data?.partnersDashboard?.numSupplyOrders || 0}
              </div>
              <div className="text-secondary">Supplies Ordered</div>
            </div>
          </div>
          {data?.partnersDashboard?.numSupplyOrders &&
          data.partnersDashboard.numSupplyOrders > 0 ? (
            <div className="px-1 bg-body rounded-4">
              <PaginationTable<
                SupplyOrder,
                SupplyOrderPagination,
                SupplyOrderPaginationQuery,
                SupplyOrderPaginationQueryVariables,
                SortFindManySupplyOrderInput
              >
                paginationQuery={paginationQuery}
                columns={[
                  {
                    label: 'Date Created',
                    field: 'date_created',
                    formatter: (row) => moment(row.date_created).format('LL'),
                  },
                  {
                    label: 'Status',
                    field: 'status',
                    formatter: (row) => statusTextMap[row.status] || row.status,
                  },
                  {
                    label: 'Purchase Total',
                    field: 'total',
                    formatter: (row) =>
                      row.total && formatToCurrency(row.total),
                  },
                ]}
                dataAccessor={(a) =>
                  a.supplyOrderPagination as SupplyOrderPagination
                }
                defaultSort={SortFindManySupplyOrderInput.DateCreatedDesc}
                buildFilterQuery={(_, defaultSort, page, __) => {
                  return {
                    filter: {},
                    sort: defaultSort,
                    page,
                    perPage: 5,
                  };
                }}
                hideButtons
                isDashboard
              />
            </div>
          ) : null}
        </Card>
      </Col>
    );
  };

  return loading ? (
    <Loading />
  ) : (
    <Container>
      <PageTitle title="Dashboard" show={false} />
      <div className="text-center mt-3 mt-md-5">
        <div className="text-primary fs-3">
          <span className="fw-light">Welcome,</span>
          &nbsp;
          <span className="fw-bold">{user?.first_name}</span>
        </div>
        <div className="mt-4 mt-md-5">
          <Row className="g-3" xs={1} md={cards?.length}>
            {map(cards, renderCard)}
          </Row>
          <Row className="g-3 text-start" xs={1}>
            {renderSuppliesCard()}
          </Row>
        </div>
      </div>
    </Container>
  );
}
