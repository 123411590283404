import { InputText } from '@farmshare/ui-components';
import { Col, Row } from 'react-bootstrap';

export interface CustomerFormForm {
  firstName: string;
  lastName: string;
  email: string;
}

export function CustomerForm() {
  return (
    <Row className="g-3">
      <Col sm={12} lg={4}>
        <InputText label="First Name" type="text" floatingLabel required />
      </Col>
      <Col sm={12} lg={4}>
        <InputText label="Last Name" type="text" floatingLabel required />
      </Col>
      <Col sm={12} lg={4}>
        <InputText label="Email" type="email" floatingLabel required />
      </Col>
    </Row>
  );
}
