import { InputPhone, InputText } from '@farmshare/ui-components';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import { Col, Row } from 'react-bootstrap';

export interface UserInfoFormForm {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  company?: string;
}

interface UserInfoFormProps<T> {
  values: T;
  hideHeading?: boolean;
  prefix?: string;
}

export function UserInfoForm<T>({
  values,
  hideHeading = false,
  prefix = 'requesterContactInformation',
}: UserInfoFormProps<T>) {
  const { dirty } = useFormikContext();

  return (
    <>
      <div className="fw-bold fs-3">Primary Contact Information</div>
      {hideHeading ? (
        <div className="mb-2">
          Please enter a phone number and/or email address for the primary
          contact.
        </div>
      ) : (
        <div className="mb-2">
          Since you don't have an account with us, please enter your contact
          information. A phone number and/or email address is required.
        </div>
      )}
      <Row className="mb-1 g-3" xs={1} md={2}>
        <Col>
          <InputText
            type="text"
            label="First Name"
            floatingLabel
            required
            nameOveride={`${prefix}.firstName`}
          />
        </Col>
        <Col>
          <InputText
            type="text"
            label="Last Name"
            floatingLabel
            required
            nameOveride={`${prefix}.lastName`}
          />
        </Col>
        <Col>
          <InputPhone
            label="Phone"
            floatingLabel
            required={dirty && !get(values, [prefix, 'email'])}
            nameOveride={`${prefix}.phone`}
          />
        </Col>
        <Col className="px-2 pb-">
          <InputText
            type="email"
            label="Email"
            floatingLabel
            required={dirty && !get(values, [prefix, 'phone'])}
            nameOveride={`${prefix}.email`}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputText
            type="text"
            label="Company/Farm"
            floatingLabel
            nameOveride={`${prefix}.company`}
          />
        </Col>
      </Row>
    </>
  );
}
