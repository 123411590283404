import { useAuth0 } from '@auth0/auth0-react';
import { Loading } from '@farmshare/ui-components';
import { useEffect } from 'react';
import { Container, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function HomePage() {
  const { isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  return isLoading ? (
    <Loading />
  ) : (
    <div className="bg-primary pt-5 vh-100">
      <Container>
        <Stack
          gap={3}
          className="d-flex justify-content-center align-items-center text-center"
        >
          <div>
            <img
              src="/assets/images/farmshare-text-lime.svg"
              alt="Farmshare"
              className="w-100"
            />
            <h1 className="text-white">Partners</h1>
          </div>
          <div className="text-light fs-5">
            Your operational toolkit for direct-to-market farm sales.
          </div>
        </Stack>
      </Container>
    </div>
  );
}
