import styles from './calendar-legend.module.scss';
import {
  getGroupColor,
  EnumProcessorStatusGroups,
} from '../../lib/helpers/processorStatusPresentation';

export function CalendarLegend() {
  return (
    <div className={styles.legend}>
      {Object.entries(EnumProcessorStatusGroups).map(([group, label]) => {
        return (
          <div key={group} className={styles.legendItem}>
            <div
              style={{ backgroundColor: getGroupColor(label) }}
              className={styles.legendColor}
            />
            <div className={styles.legendLabel}>{label}</div>
          </div>
        );
      })}
    </div>
  );
}
