import { InputCheckGroup } from '@farmshare/ui-components';
import { map, sortBy } from 'lodash';
import { Stack } from 'react-bootstrap';

import { useModuleManyQuery } from 'lib/graphql';

export interface ModulesModalForm {
  moduleIds: string[];
}

export function ModulesModal() {
  const { data } = useModuleManyQuery({ variables: {} });

  return (
    <Stack gap={3} className="mb-md-2 px-2 px-md-3">
      {map(
        sortBy(data?.moduleMany, (m) => m.order),
        (k, i) => (
          <InputCheckGroup
            key={i}
            type="checkbox"
            nameOveride="moduleIds"
            value={k._id}
            label={k.label}
            inline
          />
        ),
      )}
    </Stack>
  );
}
