import { Col, Container, Row } from 'react-bootstrap';

export interface NoResultsOverrideType {
  mainText?: string;
  subText?: string;
}

export function NoResultsText({
  textOverride,
}: {
  textOverride?: NoResultsOverrideType;
}) {
  return (
    <Container className="pt-4">
      <Row className="justify-content-md-center">
        <Col md="auto">
          <h2>{textOverride?.mainText || 'No Results'}</h2>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md="auto">
          <p>
            {textOverride?.subText !== undefined
              ? textOverride.subText
              : 'Try adjusting your filters'}
          </p>
        </Col>
      </Row>
    </Container>
  );
}
