import { useCallback } from 'react';
import {
  Pagination as RBPagination,
  PaginationProps as RBPaginationProps,
} from 'react-bootstrap';

export interface PaginationProps {
  setPage: (newPage: number) => void;
  currentPage: number;
  pageCount: number;
  itemCount?: number;
  size?: RBPaginationProps['size'];
  className?: string;
}

export function Pagination({
  setPage,
  currentPage,
  pageCount,
  size,
  className,
}: PaginationProps) {
  const handleSetPage = useCallback(
    (pageNumber: number) => setPage(pageNumber),
    [setPage],
  );

  const getPaginationItem = useCallback(
    (pageNumber: number) => (
      <RBPagination.Item
        active={pageNumber === currentPage}
        onClick={() => handleSetPage(pageNumber)}
      >
        {pageNumber}
      </RBPagination.Item>
    ),
    [currentPage, handleSetPage],
  );

  return (
    <div>
      {pageCount > 1 && (
        <RBPagination className={className} size={size}>
          <RBPagination.First
            onClick={() => handleSetPage(1)}
            disabled={currentPage === 1}
          />
          <RBPagination.Prev
            onClick={() => handleSetPage(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {getPaginationItem(1)}
          {currentPage > 5 && <RBPagination.Ellipsis disabled />}
          {currentPage > 6 &&
            pageCount - currentPage < 2 &&
            getPaginationItem(currentPage - 5)}
          {currentPage > 5 &&
            pageCount - currentPage < 3 &&
            getPaginationItem(currentPage - 4)}
          {currentPage > 4 && getPaginationItem(currentPage - 3)}
          {currentPage > 3 && getPaginationItem(currentPage - 2)}
          {currentPage > 2 && getPaginationItem(currentPage - 1)}
          {currentPage > 1 &&
            pageCount > currentPage &&
            getPaginationItem(currentPage)}
          {pageCount - currentPage > 1 && getPaginationItem(currentPage + 1)}
          {pageCount - currentPage > 2 && getPaginationItem(currentPage + 2)}
          {pageCount - currentPage > 3 &&
            currentPage < 2 &&
            getPaginationItem(currentPage + 3)}
          {pageCount - currentPage > 4 &&
            currentPage < 3 &&
            getPaginationItem(currentPage + 4)}
          {pageCount - currentPage > 5 &&
            currentPage < 4 &&
            getPaginationItem(currentPage + 5)}
          {pageCount - currentPage > 3 && <RBPagination.Ellipsis disabled />}
          {getPaginationItem(pageCount)}
          <RBPagination.Next
            onClick={() => handleSetPage(currentPage + 1)}
            disabled={currentPage === pageCount}
          />
          <RBPagination.Last
            onClick={() => handleSetPage(pageCount)}
            disabled={currentPage === pageCount}
          />
        </RBPagination>
      )}
    </div>
  );
}
