export function dayOfWeekHelper(value: string): {
  label: string;
  value: string;
  order: number;
} {
  switch (value.toLowerCase()) {
    case 'sunday':
      return { label: 'Sunday', order: 0, value };

    case 'monday':
      return { label: 'Monday', order: 1, value };

    case 'tuesday':
      return { label: 'Tuesday', order: 2, value };

    case 'wednesday':
      return { label: 'Wednesday', order: 3, value };

    case 'thursday':
      return { label: 'Thursday', order: 4, value };

    case 'friday':
      return { label: 'Friday', order: 5, value };

    case 'saturday':
      return { label: 'Saturday', order: 6, value };

    default:
      return { label: value, order: 99, value };
  }
}

export const DAYS_OF_WEEK = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];
