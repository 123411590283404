import {
  Form as FormikForm,
  Formik,
  type FormikConfig,
  type FormikProps,
} from 'formik';
import { isFunction, noop } from 'lodash';
import { type FormEventHandler, type ReactElement, useMemo } from 'react';
import { Form as RBForm } from 'react-bootstrap';

type TField =
  | string
  | number
  | boolean
  | Date
  | ReactElement
  | undefined
  | object;

export type FormValues<TForm> = Partial<
  Record<
    keyof TForm,
    TField | TField[] | Record<string, TField> | Record<string, TField>[]
  >
>;

export interface FormProps<TForm extends FormValues<TForm>> {
  initialValues: TForm;
  children?:
    | ReactElement
    | ReactElement[]
    | ((props: FormikProps<TForm>) => ReactElement)
    | ((props: FormikProps<TForm>) => ReactElement[]);
  onChange?: FormEventHandler<HTMLFormElement>;
  onSubmit?: FormikConfig<TForm>['onSubmit'];
  validate?: FormikConfig<TForm>['validate'];
  enableReinitialize?: boolean;
}

export function Form<TForm extends FormValues<TForm>>({
  initialValues,
  children,
  onChange = noop,
  onSubmit = noop,
  validate = noop,
  enableReinitialize = false,
}: FormProps<TForm>) {
  const formikProps: FormikConfig<TForm> = useMemo(
    () => ({ initialValues, onSubmit, validate, enableReinitialize }),
    [initialValues, onSubmit, validate, enableReinitialize],
  );

  return (
    <Formik<TForm> {...formikProps}>
      {(props) => (
        <RBForm
          className="d-flex flex-column flex-grow-1"
          as={FormikForm}
          onChange={onChange}
          autoComplete="off"
        >
          {isFunction(children) ? children(props) : children}
        </RBForm>
      )}
    </Formik>
  );
}
