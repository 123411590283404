import { InputCheck, InputText } from '@farmshare/ui-components';
import { Nullable, calculateYieldHelper } from '@farmshare/utils';
import { useFormikContext } from 'formik';
import { map } from 'lodash';
import { useMemo } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';

import { ProcessorScheduling } from 'lib/graphql';
import { processingJobAnimalHeadLabel } from 'lib/processingJobUtils';

const AHS_FIELD_PREFIX = 'animalHeads';

export interface AnimalHeadKilledForm {
  _id?: string;
  tracking?: string;
  isHangingWeightPerSide?: boolean;
  hangingWeight?: Nullable<number>;
  hangingWeightSideA?: Nullable<number>;
  hangingWeightSideB?: Nullable<number>;
  killDate?: string;
  cutDate?: string;
  grade?: string;
  liveWeight?: Nullable<number>;
}

export interface KilledModalFormProps {
  animalHeads: AnimalHeadKilledForm[];
}

const HeadTracking = ({
  ahidx,
  heading,
}: {
  ahidx: number;
  animalHead: AnimalHeadKilledForm;
  heading: string;
}) => {
  const { values, setFieldValue } = useFormikContext<KilledModalFormProps>();

  const calculateYield = (
    animalHeadIndex: number,
    updatedField: string,
    value: number | string,
  ) => {
    const yieldField = [AHS_FIELD_PREFIX, animalHeadIndex, 'yield'].join('.');
    const isHangingWeightPerSide =
      values.animalHeads?.[animalHeadIndex]?.isHangingWeightPerSide;

    let liveWeight = 0;
    let hangingWeight = 0;

    switch (updatedField) {
      case 'liveWeight': {
        liveWeight = Number(value);

        if (isHangingWeightPerSide) {
          hangingWeight +=
            values.animalHeads?.[animalHeadIndex]?.hangingWeightSideA || 0;
          hangingWeight +=
            values.animalHeads?.[animalHeadIndex]?.hangingWeightSideB || 0;
        } else {
          hangingWeight =
            values.animalHeads?.[animalHeadIndex]?.hangingWeight || 0;
        }
        break;
      }
      case 'hangingWeight': {
        liveWeight = values.animalHeads?.[animalHeadIndex]?.liveWeight || 0;
        hangingWeight = Number(value);
        break;
      }
      case 'hangingWeightSideA': {
        const sideB =
          values.animalHeads?.[animalHeadIndex]?.hangingWeightSideB || 0;

        liveWeight = values.animalHeads?.[animalHeadIndex]?.liveWeight || 0;
        hangingWeight = Number(value) + sideB;
        break;
      }
      case 'hangingWeightSideB': {
        const sideA =
          values.animalHeads?.[animalHeadIndex]?.hangingWeightSideA || 0;

        liveWeight = values.animalHeads?.[animalHeadIndex]?.liveWeight || 0;
        hangingWeight = Number(value) + sideA;
        break;
      }
      default:
        break;
    }

    const yieldValue = calculateYieldHelper(liveWeight, hangingWeight);

    setFieldValue(yieldField, yieldValue);
  };

  const showSideWeights = useMemo(() => {
    const animalHead = values.animalHeads?.[ahidx];
    return animalHead.isHangingWeightPerSide ?? false;
  }, [values.animalHeads, ahidx]);
  return (
    <Stack gap={2}>
      <h5 className="fw-bold">{heading}</h5>
      <Row className="align-items-center pb-2">
        <Col>
          <InputText
            label={`Kill Date`}
            type="date"
            nameOveride={`${AHS_FIELD_PREFIX}.${ahidx}.killDate`}
            hint={`Date this animal will be killed on. Defaults to today.`}
            floatingLabel
            required
          />
        </Col>
        <Col>
          <InputText
            label={`Cut Date`}
            type="date"
            nameOveride={`${AHS_FIELD_PREFIX}.${ahidx}.cutDate`}
            hint={`Date this animal will be cut on`}
            floatingLabel
          />
        </Col>
      </Row>
      <Row className="align-items-center pb-2">
        <Col>
          <InputText
            label={`Tracking Number`}
            type="text"
            nameOveride={`${AHS_FIELD_PREFIX}.${ahidx}.tracking`}
            hint={`Tracking number for this animal head`}
            floatingLabel
            required
          />
        </Col>
      </Row>
      <Row className="align-items-center pb-2">
        <Col xs={6}>
          <InputText
            type="number"
            min={0}
            step={0.01}
            label="Live Weight"
            floatingLabel
            nameOveride={`${AHS_FIELD_PREFIX}.${ahidx}.liveWeight`}
            hint="Total live weight of the animal"
            onChange={(name, value) =>
              calculateYield(ahidx, 'liveWeight', value)
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputCheck
            label="Would you like to enter the hanging weight per each animal side?"
            floatingLabel
            type="switch"
            nameOveride={`${AHS_FIELD_PREFIX}.${ahidx}.isHangingWeightPerSide`}
          />
        </Col>
      </Row>
      <Row>
        {showSideWeights && (
          <>
            <Col xs={6}>
              <InputText
                type="number"
                min={0}
                step={0.01}
                label={'Hanging Weight Side A'}
                floatingLabel
                nameOveride={`${AHS_FIELD_PREFIX}.${ahidx}.hangingWeightSideA`}
                required
                hint={`Total hanging weight of animal side A`}
                onChange={(name, value) =>
                  calculateYield(ahidx, 'hangingWeightSideA', value)
                }
              />
            </Col>
            <Col xs={6}>
              <InputText
                type="number"
                min={0}
                step={0.01}
                label={'Hanging Weight Side B'}
                floatingLabel
                nameOveride={`${AHS_FIELD_PREFIX}.${ahidx}.hangingWeightSideB`}
                required
                hint={`Total hanging weight of the animal side B`}
                onChange={(name, value) =>
                  calculateYield(ahidx, 'hangingWeightSideB', value)
                }
              />
            </Col>
          </>
        )}
        {!showSideWeights && (
          <Col xs={6}>
            <InputText
              type="number"
              min={0}
              step={0.01}
              label={'Hanging Weight'}
              floatingLabel
              nameOveride={`${AHS_FIELD_PREFIX}.${ahidx}.hangingWeight`}
              required
              hint={`Total hanging weight of the entire animal`}
              onChange={(name, value) =>
                calculateYield(ahidx, 'hangingWeight', value)
              }
            />
          </Col>
        )}
      </Row>
      <Row className="align-items-center pb-2">
        <Col xs={4}>
          <InputText
            type="text"
            label="Yield"
            floatingLabel
            disabled
            nameOveride={`${AHS_FIELD_PREFIX}.${ahidx}.yield`}
            hint="The ratio of carcass weight to live weight"
          />
        </Col>
        <Col xs={8}>
          <InputText
            type="text"
            label="Grade"
            floatingLabel
            nameOveride={`${AHS_FIELD_PREFIX}.${ahidx}.grade`}
            hint="The quality grade assigned to the carcass (e.g. Prime, or other grading system)"
          />
        </Col>
      </Row>
    </Stack>
  );
};

export const KilledModal = ({
  scheduledJob,
}: {
  scheduledJob: ProcessorScheduling;
}) => {
  const { values } = useFormikContext<KilledModalFormProps>();

  return (
    <Stack gap={2}>
      {map(values.animalHeads, (animalHead, idx) => {
        return (
          <HeadTracking
            key={idx}
            ahidx={idx}
            animalHead={animalHead}
            heading={processingJobAnimalHeadLabel({
              producerIdentifier:
                scheduledJob?.animalHeads?.[idx]?.producerIdentifier,
              animalSpecies: scheduledJob.animalSpecies,
              inspectionLevel:
                scheduledJob?.animalHeads?.[idx]?.inspectionLevel ?? undefined,
              requestedBy: {
                first_name: scheduledJob.requestedBy?.first_name,
                last_name: scheduledJob.requestedBy?.last_name,
              },
              animalNumber: idx + 1,
            })}
          />
        );
      })}
    </Stack>
  );
};
