import { Button, IModalProperties, useToastr } from '@farmshare/ui-components';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Stack } from 'react-bootstrap';

import { downloadFromBase64Encoded } from 'lib/downloadFromBase64Encoded';
import { downlaodFromPresignedUrl } from 'lib/downloadFromPresignedUrl';
import { useProcessorSchedulingGenerateCarcassTagsMutation } from 'lib/graphql';

export const DownloadCarcassTagsModal = ({
  scheduledJobId,
  modalProps,
}: {
  scheduledJobId: string;
  modalProps: IModalProperties;
}) => {
  const [generateCarcassTags, generateCarcassTagsOp] =
    useProcessorSchedulingGenerateCarcassTagsMutation();

  const { push } = useToastr();
  return (
    <div>
      <Stack gap={2}>
        <Row>
          <Col>
            Click the button below to download the carcass tags. If you are not
            ready to download the carcass tags then you can download them later
            from Job details page.
          </Col>
        </Row>
        <Row className="justify-content-center align-items-center text-center">
          <Col>
            <Button
              content="Download Carcass Tags"
              icon={faFileDownload}
              disabled={generateCarcassTagsOp.loading}
              isLoading={generateCarcassTagsOp.loading}
              size="lg"
              onClick={async () => {
                try {
                  const response = await generateCarcassTags({
                    variables: {
                      processorSchedulingId: scheduledJobId,
                    },
                  });

                  if (
                    response.data?.processorSchedulingGenerateCarcassTags
                      ?.fileUrl
                  ) {
                    await downlaodFromPresignedUrl(
                      response.data?.processorSchedulingGenerateCarcassTags
                        ?.fileUrl,
                    );
                  } else if (
                    response.data?.processorSchedulingGenerateCarcassTags?.file
                  ) {
                    await downloadFromBase64Encoded(
                      response.data.processorSchedulingGenerateCarcassTags.file,
                      response.data.processorSchedulingGenerateCarcassTags
                        ?.filename ?? 'Carcass_Tags.pdf',
                    );
                  }
                  if (modalProps.onClose) {
                    modalProps?.onClose();
                  }
                } catch (error) {
                  push({
                    title: 'Error',
                    body: (error as string).toString(),
                    bg: 'danger',
                    delay: 4000,
                  });
                }
              }}
            />
          </Col>
        </Row>
      </Stack>
    </div>
  );
};
