import { PaginationTablePage } from '@farmshare/ui-components';
import { formatToCurrency } from '@farmshare/utils';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import {
  Invoice,
  InvoicePagination,
  SortFindManyInvoiceInput,
  useInvoicePaginationLazyQuery,
  InvoicePaginationQuery,
  InvoicePaginationQueryVariables,
  EnumInvoiceType,
} from 'lib/graphql';

export default function AdminLogisticsInvoicePage() {
  const navigate = useNavigate();

  return (
    <div>
      <PaginationTablePage<
        Invoice,
        InvoicePagination,
        InvoicePaginationQuery,
        InvoicePaginationQueryVariables,
        SortFindManyInvoiceInput
      >
        title="Logistics Invoices"
        paginationQuery={useInvoicePaginationLazyQuery()}
        innerBreadcrumbs={[{ text: 'Admin', to: '/admin' }]}
        defaultSort={SortFindManyInvoiceInput.IdAsc}
        actionButtons={() => [
          {
            content: 'Create Invoice',
            icon: faPlus,
            onClick: () => navigate('/admin/invoices/create'),
          },
        ]}
        dataAccessor={(i) => i.invoicePagination as InvoicePagination}
        buildFilterQuery={(_, defaultSort, page, perPage) => {
          return {
            filter: { type: EnumInvoiceType.PartnersShipmentsMonthly },
            sort: defaultSort,
            page,
            perPage,
          };
        }}
        columns={[
          { label: 'Vendor', field: 'vendor.shop_name' },
          {
            label: 'Month',
            field: 'billing_month',
            formatter: (row) =>
              moment.utc({ month: row.billing_month - 1 }).format('MMMM'),
          },
          { label: 'Year', field: 'billing_year' },
          {
            label: 'Amount Due',
            field: 'amount_due',
            formatter: (row) => formatToCurrency(row.amount_due),
          },
          {
            label: 'Amount Paid',
            field: 'amount_paid',
            formatter: (row) => formatToCurrency(row.amount_paid),
          },
          { label: 'Status', field: 'status' },
          { label: 'Number', field: 'number' },
        ]}
      />
    </div>
  );
}
