import { Button, InputText, InputChips } from '@farmshare/ui-components';
import { formatToCurrency } from '@farmshare/utils';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useFormikContext } from 'formik';
import { startCase, map } from 'lodash';
import { useCallback, Fragment } from 'react';
import { Card, Col, Row, Stack } from 'react-bootstrap';

export interface ExtrasTrimModel {
  name: string;
  pricePerPound: number;
  minLbs?: number;
  isActive?: boolean;
}

export interface ExtrasTrimSectionForm {
  [key: string]: any;
  extras?: ExtrasTrimModel[];
  trim?: ExtrasTrimModel[];
}

export function ExtrasTrimSection() {
  const { values, resetForm, errors } =
    useFormikContext<ExtrasTrimSectionForm>();

  const addItem = useCallback(
    (type: 'extras' | 'trim') => {
      const existingItem = values[`${type}`]?.find(
        (item) => item.name === values[`${type}Name`],
      );

      if (existingItem) {
        resetForm({
          values: {
            ...values,
          },
          errors: {
            ...errors,
            [`${type}Name`]: `${startCase(type)} already added`,
          },
        });

        return;
      }

      // check if name is filled out
      if (!values[`${type}Name`]) {
        resetForm({
          values: {
            ...values,
          },
          errors: {
            ...errors,
            [`${type}Name`]: 'Name is required',
          },
        });

        return;
      }

      const item = {
        name: values[`${type}Name`] ?? '',
        pricePerPound: Number(values[`${type}PriceLbs`]) || 0,
        minLbs: Number(values[`${type}MinLbs`]) || 0,
        isActive: true,
      };
      if (values[`${type}`]) {
        values[`${type}`]?.push(item);
      } else {
        values[`${type}`] = [item];
      }

      resetForm({
        values: {
          ...values,
          [`${type}Name`]: '',
          [`${type}PriceLbs`]: '',
          [`${type}MinLbs`]: '',
        },
        errors: {},
      });
    },
    [errors, resetForm, values],
  );

  const removeItem = useCallback(
    (type: 'extras' | 'trim', itemName: string) => {
      const filteredItems = values[`${type}`]?.filter(
        (item) => item.name !== itemName,
      );

      resetForm({
        values: {
          ...values,
          [`${type}`]: filteredItems,
        },
        errors: {},
      });
    },
    [values, resetForm],
  );

  const renderSection = useCallback(() => {
    return ['extras', 'trim'].map((label) => (
      <Fragment key={label}>
        <div className="fw-bold fs-4">{startCase(label)}</div>
        <Row className="gx-1 mb-1">
          <Col md={4}>
            <InputText
              label="Name"
              nameOveride={`${label}Name`}
              type="text"
              size="sm"
              floatingLabel
            />
          </Col>
          <Col md={3}>
            <InputText
              label="Price/lb."
              nameOveride={`${label}PriceLbs`}
              type="number"
              size="sm"
              floatingLabel
            />
          </Col>
          <Col md={3}>
            <InputText
              label="Min. lbs."
              nameOveride={`${label}MinLbs`}
              type="number"
              size="sm"
              floatingLabel
            />
          </Col>
          <Col md={2}>
            <Button
              icon={faPlus}
              content="Add"
              size="sm"
              style={{ height: '54.4px', width: '100%' }}
              onClick={() => addItem(label as 'extras' | 'trim')}
            />
          </Col>
        </Row>
        {errors && (
          <div className="text-danger">
            {errors[`${label}Name`] && <p>{String(errors[`${label}Name`])}</p>}
            {errors[`${label}PriceLbs`] && (
              <p>{String(errors[`${label}PriceLbs`])}</p>
            )}
          </div>
        )}
        {values[`${label}`] && values[`${label}`].length > 0 && (
          <div>
            {map(values[`${label}`], (item) => (
              <div
                key={`${label}-${item.name}`}
                className="d-flex justify-content-between mb-2"
              >
                <InputChips
                  nameOveride={item.name}
                  options={[
                    {
                      label: `${startCase(item.name)} / ${formatToCurrency(
                        item.pricePerPound,
                      )}/lb ${
                        item.minLbs > 0 ? ` / ${item.minLbs}lb min ` : ''
                      }`,
                      value: item.name,
                      isActive: item.isActive,
                    },
                  ]}
                  size="sm"
                  action={() => {
                    // toggle active
                    item.isActive = !item.isActive;
                    resetForm({
                      values: {
                        ...values,
                      },
                    });
                  }}
                />
                <Button
                  icon={faTrash}
                  variant="ghost"
                  size="sm"
                  className="text-danger"
                  onClick={() => {
                    removeItem(label as 'extras' | 'trim', item.name);
                  }}
                />
              </div>
            ))}
          </div>
        )}
      </Fragment>
    ));
  }, [errors, values, addItem, resetForm, removeItem]);

  return (
    <Col>
      <Card className="h-100" body>
        <Stack gap={3}>{renderSection()}</Stack>
      </Card>
    </Col>
  );
}
