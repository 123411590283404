import {
  Nullable,
  formatFullName,
  animalSpeciesHelper,
  inspectionLevelHelper,
} from '@farmshare/utils';
import { find, includes, reduce } from 'lodash';

import {
  ComplexAnimalCapacity,
  EnumProcessorSchedulingAnimalSpecies,
  EnumProcessorSettingsAnimalSettingsInspectionLevels,
  EnumProcessorSettingsAnimalSettingsSpecies,
  EnumProcessorSettingsCapacitySettingsDailyCapacitiesType,
  ProcessorSettingsAnimalSettings,
  SpeciesInspectionComplexAnimalCapacitySchema,
} from './graphql';

type ProcessingJobAnimalHeadLabelParams = {
  producerIdentifier?: Nullable<string>;
  animalSpecies?: string;
  animalNumber: number;
  inspectionLevel?: string;
  requestedBy: {
    first_name?: Nullable<string>;
    last_name?: Nullable<string>;
  };
};

export function processingJobAnimalHeadLabel({
  producerIdentifier,
  requestedBy,
  animalNumber,
  animalSpecies = '',
  inspectionLevel = '',
}: ProcessingJobAnimalHeadLabelParams) {
  const animalSpeciesOption = animalSpeciesHelper(animalSpecies);
  const inspectionLevelPart = inspectionLevel
    ? inspectionLevelHelper(inspectionLevel).label
    : '';
  const requestedByName = formatFullName(
    requestedBy?.first_name ?? '',
    requestedBy?.last_name,
  );
  const producerIdentifierPart = producerIdentifier
    ? ` ${producerIdentifier}`
    : '';

  return `${inspectionLevelPart} ${animalSpeciesOption.label} #${animalNumber} - ${requestedByName}${producerIdentifierPart}`;
}

export function isAnimalBeef(
  animalSpecies?: EnumProcessorSchedulingAnimalSpecies,
) {
  return animalSpecies === EnumProcessorSchedulingAnimalSpecies.Beef;
}

export function getCapacity(
  capacityConfigurationType?: EnumProcessorSettingsCapacitySettingsDailyCapacitiesType | null,
  species?: EnumProcessorSettingsAnimalSettingsSpecies,
  capacities?: Array<ComplexAnimalCapacity | null> | null,
  animalSetting?: ProcessorSettingsAnimalSettings,
) {
  // if animal species is not enabled then return 0 for capacity.
  if (!animalSetting?.isEnabled) {
    return { total: 0, inspectionLevels: {} };
  }

  switch (capacityConfigurationType) {
    case EnumProcessorSettingsCapacitySettingsDailyCapacitiesType.Species:
      // This is easy there should just be one for the species so we can return the first one
      const capacity = find(capacities, { species });
      return { total: capacity?.value ?? 0, inspectionLevels: {} };

    case EnumProcessorSettingsCapacitySettingsDailyCapacitiesType.SpeciesInspection:
      // This is more difficult there will be many for the species so we sum them all for the inspection levels that are enabled.
      const totalSpeciesCount = reduce(
        capacities as SpeciesInspectionComplexAnimalCapacitySchema[],
        (
          acc: {
            total: number;
            inspectionLevels: Partial<
              Record<
                EnumProcessorSettingsAnimalSettingsInspectionLevels,
                number
              >
            >;
          },
          c: SpeciesInspectionComplexAnimalCapacitySchema,
        ) => {
          if (
            c?.species === species &&
            includes(
              animalSetting?.inspectionLevels,
              c?.inspectionLevel as unknown as EnumProcessorSettingsAnimalSettingsInspectionLevels,
            )
          ) {
            acc.total = acc.total + (c?.value ?? 0);
            acc.inspectionLevels[c.inspectionLevel] = c?.value ?? 0;
          }

          return acc;
        },
        { total: 0, inspectionLevels: {} },
      );
      return totalSpeciesCount;

    case EnumProcessorSettingsCapacitySettingsDailyCapacitiesType.WeightedAnimalUnit:
      // There is only one capacity object in the array so we just grab the first item from the array.
      const totalWeightedAnimalCapacity = capacities?.[0]?.value ?? 0;
      const weightedAnimalUnitCost = animalSetting.weightedAnimalUnit ?? 0;

      // Don't divide by zero
      if (weightedAnimalUnitCost === 0) {
        return { total: 0, inspectionLevels: {} };
      }

      // To compute capacity for the species just divide the totalWeightedAnimalCapacity  by weighted animal unit cost.
      // So if the species has a weighted animal cost of 1 and the totalWeightedCapacity is 10 the capacity of the animal is 10 / 1 = 10.
      // If the weighted animal cost is 0.5 and the total capacity is 10 the capacity of the animal is 10 / 0.5 = 20
      // Capacity is always measured in integers since these are animals you cannot have a half capacity left.
      // Therefore we always floor or round the number down. So 20.7 is 20.
      return {
        total: Math.floor(totalWeightedAnimalCapacity / weightedAnimalUnitCost),
        inspectionLevels: {},
      };

    default:
      return { total: 0, inspectionLevels: {} };
  }
}
