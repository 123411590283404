import { InputSelect, InputText } from '@farmshare/ui-components';
import { filter, map } from 'lodash';
import { Stack } from 'react-bootstrap';

import { EnumIntegrationName, EnumIntegrationType, Vendor } from 'lib/graphql';

export interface IntegrationModalForm {
  vendor: string;
  name: EnumIntegrationName | undefined;
  type: EnumIntegrationType;
  key: string;
  secret?: string;
  endpoint: string;
}

interface IntegrationModalProps {
  vendors: Pick<Vendor, 'shop_name' | '_id'>[];
}

export function IntegrationModal(props: IntegrationModalProps) {
  return (
    <Stack gap={3} className="mb-md-2">
      <InputSelect
        label="Vendor"
        options={map(props.vendors, (v) => ({
          label: v.shop_name,
          value: v._id,
        }))}
        floatingLabel
        required
      />
      <InputSelect
        label="Service"
        nameOveride="name"
        options={filter(
          map(EnumIntegrationName, (k) => ({ label: k, value: k })),
          // excluding Shopify as we don't support it
          (option) => option.value !== EnumIntegrationName.Shopify,
        )}
        floatingLabel
        required
      />
      <InputSelect
        label="Type"
        options={map(EnumIntegrationType, (k) => ({ label: k, value: k }))}
        floatingLabel
        required
      />
      <InputText label="Endpoint" type="url" floatingLabel required />
      <InputText label="Key" type="text" floatingLabel required />
      <InputText label="Secret" type="password" floatingLabel />
    </Stack>
  );
}
