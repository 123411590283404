import {
  Button,
  Form,
  Loading,
  PageTitle,
  useToastr,
} from '@farmshare/ui-components';
import { faSave, faUndo } from '@fortawesome/free-solid-svg-icons';
import { map, isFinite } from 'lodash';
import { useEffect } from 'react';
import { Card, Container, Stack } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import {
  EnumProductStock_Status,
  useProductByIdQuery,
  useProductUpdateByIdMutation,
} from 'lib/graphql';

import { ProductForm, type ProductFormForm } from './_views/product-form';
import { ProductVariantForm } from './_views/product-variant-form';

function valueIsNumber(value: number | null | undefined): value is number {
  return isFinite(value);
}

const getNumberValue = (
  value: number | null | undefined,
  defaultValue = NaN,
) => {
  return valueIsNumber(value) ? value : defaultValue;
};

export default function ProductDetailsPage() {
  const { id } = useParams();
  const { push } = useToastr();
  const navigate = useNavigate();

  const { data, loading } = useProductByIdQuery({
    variables: { productId: id },
    // Super important we don't use a cache so we make sure we have the correct product data always for editing.
    fetchPolicy: 'no-cache',
  });

  const [updateProduct, updateProductOp] = useProductUpdateByIdMutation();

  // Don't support details page for child products navigate to the parent
  useEffect(() => {
    if (data?.productById?.parent) {
      navigate(`/products/${data.productById.parent?._id}`);
    }
  }, [data, navigate]);

  if (loading || !data?.productById) {
    return <Loading />;
  }

  return (
    <div className="py-4">
      <PageTitle
        title={
          <>
            <span className="small fw-light text-muted">Product:&nbsp;</span>
            {data?.productById?.name}
          </>
        }
        innerBreadcrumbs={[{ to: '/products', text: 'Products' }]}
        breadCrumbLabel={data.productById.name || ''}
      />
      <Form<ProductFormForm & { variants: any[] }>
        initialValues={{
          name: data.productById.name || '',
          enabled: data.productById.enabled,
          description: data.productById.description || '',
          shortDescription: data.productById.short_description || '',
          price: getNumberValue(data.productById.price),
          weight: getNumberValue(data.productById.weight),
          stockStatus:
            data.productById.stock_status || EnumProductStock_Status.Instock,
          stockQuantity: getNumberValue(data.productById.stock_quantity, 1),
          dimensions: {
            length: getNumberValue(data.productById.dimensions?.length),
            width: getNumberValue(data.productById.dimensions?.width),
            height: getNumberValue(data.productById.dimensions?.height),
          },
          variants: data.productById.children ?? [],
        }}
        onSubmit={async (formData) => {
          const {
            name,
            enabled,
            description,
            price,
            weight,
            dimensions,
            shortDescription,
            stockQuantity,
            stockStatus,
          } = formData;

          await updateProduct({
            variables: {
              id: data.productById?._id,
              record: {
                product: {
                  name,
                  enabled,
                  description,
                  price,
                  weight,
                  dimensions,
                  short_description: shortDescription,
                  stock_quantity: stockQuantity,
                  stock_status: stockStatus,
                },
                children: map(formData.variants ?? [], (variant) => {
                  return {
                    _id: variant._id,
                    record: {
                      name: variant.name,
                      price: variant.price,
                      weight: variant.weight,
                      stock_quantity: variant.stock_quantity,
                      stock_status: variant.stock_status,
                    },
                  };
                }),
              },
            },
          });
          push({
            title: 'Success',
            body: `${name} updated successfully.`,
            bg: 'primary',
            delay: 4000,
          });
        }}
      >
        {({ dirty }) => (
          <Container>
            <Card>
              <Card.Header>Details</Card.Header>
              <Card.Body>
                <ProductForm
                  integrationName={data.productById?.integration?.name}
                />
              </Card.Body>
            </Card>
            <ProductVariantForm />
            <Stack
              direction="horizontal"
              gap={2}
              className="mt-3 justify-content-end"
            >
              <Button content="Reset" icon={faUndo} variant="ghost" />
              <Button
                content="Save"
                type="submit"
                icon={faSave}
                disabled={!dirty}
                isLoading={updateProductOp.loading}
              />
            </Stack>
          </Container>
        )}
      </Form>
    </div>
  );
}
