import { animalSpeciesHelper } from '@farmshare/utils';
import { EventContentArg } from '@fullcalendar/core';

import { ProcessorScheduling } from 'lib/graphql';

import styles from './calendar-event-content.module.scss';

export function CalendarEventContent(eventContent: EventContentArg) {
  const { requestedBy, headCount, animalSpecies } = eventContent.event
    .extendedProps as ProcessorScheduling;

  return (
    <div className={styles.eventWrapper}>
      <div className={styles.eventLastName}>{requestedBy?.last_name}:</div>
      <div>
        {headCount} {animalSpeciesHelper(animalSpecies).label}
      </div>
    </div>
  );
}
