import { debounce } from 'lodash';
import { useState, useEffect, useCallback } from 'react';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export const useBreakpoint = () => {
  const resolveBreakpoint = useCallback((width: number): Breakpoint => {
    if (width < 576) return 'xs';
    if (width < 768) return 'sm';
    if (width < 992) return 'md';
    if (width < 1200) return 'lg';
    if (width < 1440) return 'xl';
    return 'xxl';
  }, []);

  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));

  const update = debounce(() => {
    setSize(resolveBreakpoint(window.innerWidth));
  }, 200);

  useEffect(() => {
    window.addEventListener('resize', update);
    return () => window.removeEventListener('resize', update);
  }, [update]);

  return size;
};
