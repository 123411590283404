import { Loading } from '@farmshare/ui-components';
import { useMemo } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';

import { ProcessingJobByIdQuery } from 'lib/graphql';

import { AnimalHeadsList } from '../_views/animal-heads-list';
import { DepositDetails } from '../_views/deposit-details';
import { InvoiceDetails } from '../_views/invoice-details';
import { JobDetails } from '../_views/job-details';
import { RequestedByDetails } from '../_views/requested-by-details';
import { type ProcessingJobPage } from '../useProcessingJobPage';

interface Props {
  processingJob: ProcessingJobPage['processingJobQueryResult'];
  updateProcessingJob: ProcessingJobPage['updateProcessingJob'];
  updateProcessingJobOp: ProcessingJobPage['updateProcessingJobOp'];
}

export interface ProcessingJobDetailsSectionProps {
  processingJob: ProcessingJobByIdQuery['findProcessorSchedulingById'];
  updateProcessingJob: ProcessingJobPage['updateProcessingJob'];
  updateProcessingJobOp: ProcessingJobPage['updateProcessingJobOp'];
}

export function JobDetailsTab({
  processingJob,
  updateProcessingJob,
  updateProcessingJobOp,
}: Props) {
  const isLoading = useMemo(() => processingJob.loading, [processingJob]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Stack gap={3}>
      <Row className="g-4">
        <Col md={6}>
          <JobDetails
            processingJob={processingJob.data?.findProcessorSchedulingById}
            updateProcessingJob={updateProcessingJob}
            updateProcessingJobOp={updateProcessingJobOp}
          />
        </Col>

        <Col md={6}>
          <RequestedByDetails
            processingJob={processingJob.data?.findProcessorSchedulingById}
            updateProcessingJob={updateProcessingJob}
            updateProcessingJobOp={updateProcessingJobOp}
          />
        </Col>

        <Col md={6}>
          <DepositDetails
            processingJob={processingJob.data?.findProcessorSchedulingById}
          />
        </Col>

        <Col md={6}>
          <InvoiceDetails
            processingJob={processingJob.data?.findProcessorSchedulingById}
            updateProcessingJob={updateProcessingJob}
            updateProcessingJobOp={updateProcessingJobOp}
          />
        </Col>
      </Row>

      <AnimalHeadsList
        processingJob={processingJob.data?.findProcessorSchedulingById}
        isLoading={isLoading}
      />
    </Stack>
  );
}
