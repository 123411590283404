import { InputText } from '@farmshare/ui-components';
import { useFormikContext } from 'formik';
import { flatMap, map } from 'lodash';
import { Col, Row } from 'react-bootstrap';

import { EnumProcessorSettingsAnimalSettingsSpecies } from 'lib/graphql';

import { type SettingsFormData } from './settings-form';

export function AnimalSettingsPricing() {
  const { values } = useFormikContext<SettingsFormData>();
  return flatMap(values.animalSpecies, (speciesOffered) => {
    const inspectionsOffered =
      values.inspectionLevels[
        speciesOffered.value as EnumProcessorSettingsAnimalSettingsSpecies
      ] ?? [];
    return map(inspectionsOffered, (inspectionOffered) => {
      return (
        <Row
          className="g-3 mb-3 align-items-end"
          key={`${inspectionOffered.value}_${speciesOffered.value}`}
        >
          <Col xs={12} md={3}>
            <InputText
              nameOveride={`butcherSlotPricing.${speciesOffered.value}.${inspectionOffered.value}`}
              label={`Kill slot price ${inspectionOffered.label} ${speciesOffered.label} ($)`}
              type="number"
            />
          </Col>

          <Col xs={12} md={3}>
            <InputText
              nameOveride={`customCutsheetPricing.${speciesOffered.value}.${inspectionOffered.value}`}
              label={`Custom cutsheet price ${inspectionOffered.label} ${speciesOffered.label} ($)`}
              type="number"
            />
          </Col>
        </Row>
      );
    });
  });
}
