import {
  AddressForm,
  AddressFormForm,
  InputText,
} from '@farmshare/ui-components';
import { Stack } from 'react-bootstrap';

import { useStateManyQuery } from 'lib/graphql';

export interface VendorModalForm {
  shopName: string;
  shopUrl: string;
  tagline?: string;
  address: AddressFormForm;
}

export function VendorModal() {
  const { data } = useStateManyQuery();

  return (
    <Stack gap={3} className="mb-md-2">
      <InputText label="Shop Name" type="text" floatingLabel required />
      <InputText label="Tagline" type="text" floatingLabel />
      <InputText label="Shop URL" type="url" floatingLabel />
      <AddressForm
        prefixes={['address']}
        states={data?.stateMany}
        floatingLabel
      />
    </Stack>
  );
}
