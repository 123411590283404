import { InputCheckGroup, InputText } from '@farmshare/ui-components';
import { map, sortBy } from 'lodash';
import { Card, Stack } from 'react-bootstrap';

import { useModuleManyQuery } from 'lib/graphql';

export interface TenantModalForm {
  name: string;
  email: string;
  domain?: string;
  pictureUrl?: string;
  primaryColor?: string;
  modules?: string[];
}

export function TenantModal() {
  const { data } = useModuleManyQuery({ variables: {} });

  return (
    <Stack gap={3} className="mb-md-2">
      <InputText label="Name" type="text" floatingLabel required />
      <InputText label="Email" type="text" floatingLabel required />
      <InputText label="Domain" type="text" floatingLabel />
      <InputText label="Picture URL" type="text" floatingLabel />
      <InputText label="Primary Color" type="color" />

      <Card body>
        <h6>Modules:</h6>
        {map(
          sortBy(data?.moduleMany, (m) => m.order),
          (k, i) => (
            <InputCheckGroup
              key={i}
              type="checkbox"
              nameOveride="modules"
              value={k._id}
              label={k.label}
              inline
            />
          ),
        )}
      </Card>
    </Stack>
  );
}
