import type {
  LazyQueryResultTuple,
  OperationVariables,
  QueryResult,
} from '@apollo/client';
import { type Row } from '@tanstack/react-table';
import { type ReactNode } from 'react';

import { type ButtonProps } from '../button/button';
import { PageTitle } from '../page-title/page-title';
import {
  PaginationTable,
  type PaginationFilter,
  type PaginationResponse,
} from '../pagination-table/pagination-table';
import { type ColumnProps } from '../table/table';

export interface PaginationTablePageProps<
  TData,
  TAccessor,
  TQuery,
  TVars extends OperationVariables,
  TSort,
> {
  actionButtons?: (t: QueryResult<TQuery, TVars>) => ButtonProps[];
  buildFilterQuery: (
    allFilters: PaginationFilter[],
    sort: TSort,
    page: number,
    perPage: number,
  ) => TVars | undefined;
  dataAccessor: (r: TQuery) => TAccessor | undefined | null;
  columns: ColumnProps<TData, TQuery, TVars>[];
  defaultFilters?: string[];
  defaultSort: TSort;
  filters?: PaginationFilter[];
  innerBreadcrumbs?: { text: string; to: string }[];
  paginationQuery: LazyQueryResultTuple<TQuery, TVars>;
  renderSubComponent?: (
    row: Row<TData>,
    operation?: QueryResult<TQuery, TVars>,
  ) => ReactNode;
  title: string;
}

export function PaginationTablePage<
  TData,
  TAccessor extends PaginationResponse<TData>,
  TQuery,
  TVars extends OperationVariables,
  TSort,
>({
  actionButtons,
  buildFilterQuery,
  columns,
  dataAccessor,
  defaultSort,
  filters,
  innerBreadcrumbs,
  paginationQuery,
  renderSubComponent,
  title,
  defaultFilters = [],
}: PaginationTablePageProps<TData, TAccessor, TQuery, TVars, TSort>) {
  return (
    <>
      <PageTitle
        title={
          <span>
            {title}
            <span className="text-muted small ms-2 fs-4">
              {/* ({accessor?.count}) */}
            </span>
          </span>
        }
        breadCrumbLabel={title}
        innerBreadcrumbs={innerBreadcrumbs}
      />
      <PaginationTable<TData, TAccessor, TQuery, TVars, TSort>
        {...{
          actionButtons,
          buildFilterQuery,
          columns,
          dataAccessor,
          defaultSort,
          defaultFilters,
          paginationQuery,
          filters,
          renderSubComponent,
        }}
      />
    </>
  );
}
