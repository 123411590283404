import { ApolloProvider } from '@apollo/client';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode, useEffect, useMemo, useState } from 'react';

import { getApolloClient } from 'lib/graphql/client';
interface AuthApolloProviderProps {
  children: ReactNode;
}

export function AuthApolloProvider(props: AuthApolloProviderProps) {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState<string>();

  if (process.env.NODE_ENV !== 'production') {
    loadDevMessages();
    loadErrorMessages();
  }

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then(setAccessToken);
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  const client = useMemo(
    () => getApolloClient(accessToken || ''),
    [accessToken],
  );

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}
