import type { AddressFormForm } from '@farmshare/ui-components';

import { type AddressInput, EnumAddressType } from './graphql';

export function addressFormToCamelCase(address: AddressFormForm): AddressInput {
  return {
    type: address.type as EnumAddressType,
    first_name: address.firstName,
    last_name: address.lastName,
    company: address.company || undefined,
    address_1: address.address1,
    address_2: address.address2 || undefined,
    city: address.city,
    state: address.state,
    postcode: address.postcode,
    email: address.email,
    phone: address.phone,
    country: 'US',
  };
}
