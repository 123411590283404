import { InputSelect, InputText } from '@farmshare/ui-components';
import type { FormikProps } from 'formik';
import { map } from 'lodash';
import { Col, Row, Stack } from 'react-bootstrap';

import { EnumPackagingInsulation_Type } from 'lib/graphql';

export interface PackagingModalForm {
  name: string;
  boxLength: number;
  boxWidth: number;
  boxHeight: number;
  insulationType?: EnumPackagingInsulation_Type;
  insulationThickness?: number;
  insulationRValue?: number;
}

interface PackagingModalProps {
  formikProps: FormikProps<PackagingModalForm>;
}

export function PackagingModal(props: PackagingModalProps) {
  return (
    <Stack gap={3}>
      <InputText type="text" label="Name" required />
      <Row md={3}>
        <Col>
          <InputText
            type="number"
            label="Length"
            nameOveride="boxLength"
            min={0}
            required
          />
        </Col>
        <Col>
          <InputText
            type="number"
            label="Width"
            nameOveride="boxWidth"
            min={0}
            required
          />
        </Col>
        <Col>
          <InputText
            type="number"
            label="Height"
            nameOveride="boxHeight"
            min={0}
            required
          />
        </Col>
      </Row>
      <InputSelect
        label="Insulation Type"
        options={map(EnumPackagingInsulation_Type, (value, label) => ({
          label,
          value,
        }))}
      />
      <Row md={2}>
        <Col>
          <InputText type="number" label="Insulation Thickness" min={0} />
        </Col>
        <Col>
          <InputText
            type="number"
            label="Insulation R-Value"
            min={0}
            step={0.1}
          />
        </Col>
      </Row>
    </Stack>
  );
}
