import { MutationResult } from '@apollo/client';
import { InputSelectOption } from '@farmshare/ui-components';
import { Nullable } from '@farmshare/utils';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldArray, useFormikContext } from 'formik';
import { concat, filter, get, map } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { Stack, Form as BSForm, Card, Button } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { userState } from 'state';

import {
  CreateProcessorSchedulingMutation,
  EnumProcessorSchedulingAnimalHeadsInspectionLevel,
  EnumProcessorSettingsAnimalSettingsInspectionLevels,
  EnumProcessorSettingsCapacitySettingsDailyCapacitiesType,
} from 'lib/graphql';

import { AddAnimalHeadForm, type AnimalHead } from './add-animal-head-form';
import { PayDepositModal } from './pay-deposit-modal';
import { UserInfoForm, type UserInfoFormForm } from './user-info-form';

const ANIMAL_HEADS_FIELD_ARRAY_NAME = 'scheduledHeads';

const getCurrentCount = (
  scheduledHeads: Array<AnimalHead>,
  inspectionLevel: EnumProcessorSchedulingAnimalHeadsInspectionLevel,
) => {
  const filtered = filter(
    scheduledHeads,
    (o) => o.inspectionLevel === inspectionLevel,
  );

  return filtered?.length;
};

export interface SchedulingCreateForm {
  requesterContactInformation: UserInfoFormForm;
  scheduledHeads: Array<AnimalHead>;
}

interface Props {
  animalSpecies?: string;
  createSchedulingOp: MutationResult<CreateProcessorSchedulingMutation>;
  isOnBehalfOf: boolean;
  totalDayCapacity: {
    total: number;
    inspectionLevels?: Partial<
      Record<EnumProcessorSettingsAnimalSettingsInspectionLevels, number>
    >;
  };
  enabledAnimalSplitOptions: InputSelectOption[];
  enabledAnimalInspectionLevelOptions: InputSelectOption[];
  capacityConfigurationType:
    | EnumProcessorSettingsCapacitySettingsDailyCapacitiesType
    | null
    | undefined;
}

export function CreateSchedulingForm({
  animalSpecies,
  createSchedulingOp,
  isOnBehalfOf,
  totalDayCapacity,
  enabledAnimalSplitOptions,
  enabledAnimalInspectionLevelOptions,
  capacityConfigurationType,
}: Props) {
  const [activeAnimalCard, setActiveAnimalCard] =
    useState<Nullable<number>>(null);
  const user = useRecoilValue(userState);
  const { setFieldValue, values, errors, submitCount } =
    useFormikContext<SchedulingCreateForm>();

  const animalHeadsFieldError = useMemo(() => {
    return get(errors, `${ANIMAL_HEADS_FIELD_ARRAY_NAME}`);
  }, [errors]);

  // Example of a useless useCallback
  const handleAddAnimal = useCallback(() => {
    // Only add if we still have capacity
    if (values.scheduledHeads?.length < totalDayCapacity.total) {
      const updated = concat(values.scheduledHeads, [
        {
          contacts: [],
        },
      ]);
      // The scheduledHeads array must be treated as immutable
      setFieldValue(ANIMAL_HEADS_FIELD_ARRAY_NAME, updated, false);
      setActiveAnimalCard(updated.length - 1);
    }
  }, [
    setFieldValue,
    setActiveAnimalCard,
    values.scheduledHeads,
    totalDayCapacity,
  ]);

  return (
    <Stack gap={3}>
      {!isOnBehalfOf &&
        createSchedulingOp.data &&
        createSchedulingOp.data.processorSchedulingCreate?.checkoutSession && (
          <PayDepositModal
            checkoutSession={
              createSchedulingOp.data.processorSchedulingCreate?.checkoutSession
            }
            schedulingId={
              createSchedulingOp.data.processorSchedulingCreate?.scheduling._id
            }
          />
        )}
      {(!user || isOnBehalfOf) && (
        <>
          <UserInfoForm<SchedulingCreateForm>
            hideHeading={isOnBehalfOf}
            values={values}
          />
          <hr />
        </>
      )}
      {submitCount > 0 && (
        <BSForm.Control.Feedback type="invalid" style={{ display: 'block' }}>
          {typeof animalHeadsFieldError === 'string'
            ? animalHeadsFieldError
            : null}
        </BSForm.Control.Feedback>
      )}
      <Stack gap={3} className="mb-3">
        <div className="fw-bold fs-3">
          Scheduled Animals{' '}
          <span>
            total {values.scheduledHeads.length}/{totalDayCapacity.total}
          </span>
          {capacityConfigurationType ===
            EnumProcessorSettingsCapacitySettingsDailyCapacitiesType.SpeciesInspection &&
            map(totalDayCapacity.inspectionLevels, (val, key) => {
              return (
                <>
                  {' '}
                  <span>
                    {key}{' '}
                    {getCurrentCount(
                      values.scheduledHeads,
                      key as EnumProcessorSchedulingAnimalHeadsInspectionLevel,
                    )}
                    /{val}
                  </span>
                </>
              );
            })}
        </div>

        <FieldArray
          name={ANIMAL_HEADS_FIELD_ARRAY_NAME}
          render={({ remove }) =>
            map(values.scheduledHeads, (_, idx) => (
              <AddAnimalHeadForm
                key={idx}
                name={ANIMAL_HEADS_FIELD_ARRAY_NAME}
                isActive={idx === activeAnimalCard}
                onEdit={setActiveAnimalCard}
                animalSpecies={animalSpecies}
                scheduledHeadsIdx={idx}
                onRemove={remove}
                isOnBehalfOf={isOnBehalfOf}
                enabledAnimalSplitOptions={enabledAnimalSplitOptions}
                enabledAnimalInspectionLevelOptions={
                  enabledAnimalInspectionLevelOptions
                }
              />
            ))
          }
        />

        <Card style={{ borderStyle: 'dashed', opacity: 0.65 }}>
          <Card.Header>
            <div>Add Animal</div>
          </Card.Header>
          <Card.Body className="text-center">
            <Button variant="link">
              <FontAwesomeIcon
                icon={faPlus}
                size="5x"
                onClick={handleAddAnimal}
              />
            </Button>
          </Card.Body>
        </Card>
      </Stack>
    </Stack>
  );
}
