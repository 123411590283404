import {
  InputChips,
  InputText,
  type InputChipOption,
} from '@farmshare/ui-components';
import { animalSplitTypeHelper, ALL_ANIMAL_SPLITS } from '@farmshare/utils';
import { FormikProps } from 'formik';
import { map } from 'lodash';
import { Col, Row } from 'react-bootstrap';

export interface EditCutsheetForm {
  name: string;
  splitTypes: InputChipOption[];
}

export interface EditCutsheetProps {
  formik: FormikProps<EditCutsheetForm>;
}

export function EditCutsheetModal({ formik }: EditCutsheetProps) {
  return (
    <>
      <Row>
        <Col>
          <InputText label="Name" type="text" floatingLabel required />
        </Col>
      </Row>
      <hr />
      <div className="fs-5 fw-bold mb-2">Split Types</div>
      <InputChips
        nameOveride="splitTypes"
        options={map(ALL_ANIMAL_SPLITS, (split) => ({
          label: animalSplitTypeHelper(split).label,
          value: animalSplitTypeHelper(split).value,
        }))}
        size="sm"
        required
      />
    </>
  );
}
