import { PageTitle } from '@farmshare/ui-components';

export default function AdminShipmentPage() {
  return (
    <div>
      <PageTitle
        title="Shipments"
        innerBreadcrumbs={[{ text: 'Admin', to: '/admin' }]}
      />
    </div>
  );
}
