import { Button, IModalFooterProps, useModal } from '@farmshare/ui-components';
import { faCalendarPlus } from '@fortawesome/free-solid-svg-icons';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { StripeEmbeddedCheckoutOptions, loadStripe } from '@stripe/stripe-js';
import { environment } from 'environments/environment';
import { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface Props {
  checkoutSession?: { clientSecret: string };
  schedulingId: string;
}
const STRIPE_KEY = environment.stripeKey;

const stripePromise = loadStripe(STRIPE_KEY);

const PayCompleteModalFooter = ({
  modalProps,
  schedulingId,
}: {
  modalProps: any;
  schedulingId: string;
}) => {
  const navigate = useNavigate();
  const handleClose = useCallback(() => {
    if (modalProps.onClose) {
      modalProps.onClose();
      navigate(`/scheduling/${schedulingId}/details`);
    }
  }, [modalProps, schedulingId, navigate]);

  return (
    <Modal.Footer>
      <div>
        <Button content="Close" onClick={handleClose} variant="primary" />
      </div>
    </Modal.Footer>
  );
};

export const PayDepositModal = ({ checkoutSession, schedulingId }: Props) => {
  const { payment } = useModal();
  const [isPaymentComplete, setIsPaymentComplete] = useState(false);
  const handleOnComplete = () => setIsPaymentComplete(true);

  useEffect(() => {
    payment({
      backdrop: 'static',
      type: 'save',
      size: 'xl',
      icon: faCalendarPlus,
      isLoading: false,
      title: 'Scheduling Deposit',
      initialValues: {},
      body: (
        <PayDepositModalInner
          checkoutSession={checkoutSession}
          onComplete={handleOnComplete}
        />
      ),
      footer: isPaymentComplete
        ? ({ modalProps }: IModalFooterProps) => (
            <PayCompleteModalFooter
              modalProps={modalProps}
              schedulingId={schedulingId}
            />
          )
        : null,
      onCancel: () => {
        // Possibly do something because the modal was closed
        // Or we wait for the expiration on the checkout session
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentComplete]);

  return null;
};

const PayDepositModalInner = (props: any) => {
  const { checkoutSession, onComplete } = props;

  const options: StripeEmbeddedCheckoutOptions = {
    clientSecret: checkoutSession?.clientSecret,
    onComplete,
  };

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};
