import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck,
  faCircleNotch,
  faRotateLeft,
} from '@fortawesome/free-solid-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { assign } from 'lodash';
import { CSSProperties, ReactNode, useMemo } from 'react';
import {
  Button as RBButton,
  ButtonProps as RBButtonProps,
} from 'react-bootstrap';

export interface ButtonProps
  extends Pick<
    RBButtonProps,
    | 'active'
    | 'as'
    | 'disabled'
    | 'id'
    | 'onClick'
    | 'size'
    | 'type'
    | 'variant'
  > {
  content?: ReactNode;
  isLoading?: boolean;
  icon?: FontAwesomeIconProps['icon'];
  className?: string;
  href?: string;
  target?: '_blank';
  block?: boolean;
  inlineMarginTop?: boolean;
  style?: CSSProperties;
}

export function Button(props: ButtonProps) {
  const getIcon: IconProp | undefined = useMemo(() => {
    if (props.icon) {
      return props.icon;
    }

    switch (props.type) {
      case 'submit':
        return faCheck;
      case 'reset':
        return faRotateLeft;
    }
  }, [props]);

  const iconMargin = useMemo(() => {
    if (!props.content) {
      return;
    }

    switch (props.size) {
      case 'lg':
      default:
        return 'mx-2 ms-md-0';
      case 'sm':
        return 'mx-1 ms-md-0';
    }
  }, [props.content, props.size]);

  return (
    <RBButton
      active={props.active}
      disabled={props.disabled || props.isLoading}
      variant={props.variant || 'primary'}
      type={props.type || 'button'}
      size={props.size}
      onClick={props.onClick}
      className={
        props.block ? 'd-block w-100' : 'text-nowrap ' + props.className
      }
      href={props.href}
      target={props.target}
      as={props.as}
      style={
        props.inlineMarginTop
          ? assign({ marginTop: '30px' }, props.style)
          : props.style
      }
    >
      {props.isLoading ? (
        <FontAwesomeIcon icon={faCircleNotch} className={iconMargin} spin />
      ) : (
        getIcon && <FontAwesomeIcon icon={getIcon} className={iconMargin} />
      )}
      {props.content}
    </RBButton>
  );
}
