import {
  Button,
  PaginationTablePage,
  useModal,
  useToastr,
} from '@farmshare/ui-components';
import { faBox, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useCallback } from 'react';

import {
  type Packaging,
  type PackagingPagination,
  SortFindManyPackagingInput,
  usePackagingPaginationLazyQuery,
  type PackagingPaginationQuery,
  type PackagingPaginationQueryVariables,
  usePackagingCreateMutation,
  usePackagingEditMutation,
} from 'lib/graphql';

import { PackagingModal, PackagingModalForm } from './_views/packaging-modal';

export default function AdminPackagingPage() {
  const { save } = useModal();
  const { push } = useToastr();

  const [createPackaging, createPackagingOp] = usePackagingCreateMutation();
  const [updatePackaging, updatePackagingOp] = usePackagingEditMutation();

  const renderDimensionalUnit = useCallback(
    (value?: number | null) => (
      <>
        {value || '-'}&nbsp;
        <span className="small text-muted">in.</span>
      </>
    ),
    [],
  );

  return (
    <div>
      <PaginationTablePage<
        Packaging,
        PackagingPagination,
        PackagingPaginationQuery,
        PackagingPaginationQueryVariables,
        SortFindManyPackagingInput
      >
        title="Packagings"
        paginationQuery={usePackagingPaginationLazyQuery()}
        innerBreadcrumbs={[{ text: 'Admin', to: '/admin' }]}
        defaultSort={SortFindManyPackagingInput.IdAsc}
        actionButtons={(t) => [
          {
            content: 'Create Packaging',
            icon: faPlus,
            isLoading: createPackagingOp.loading,
            onClick: () =>
              save<PackagingModalForm>({
                type: 'save',
                title: 'Create New Packaging',
                icon: faBox,
                isLoading: createPackagingOp.loading,
                initialValues: {
                  name: '',
                  boxLength: 0,
                  boxWidth: 0,
                  boxHeight: 0,
                },
                body: (formikProps) => (
                  <PackagingModal formikProps={formikProps} />
                ),
                onSubmit: async (newPackaging) => {
                  await createPackaging({ variables: { newPackaging } });
                  push({
                    title: 'Success',
                    bg: 'primary',
                    delay: 4000,
                    body: 'New packaging created successfully.',
                  });
                  t.refetch();
                },
              }),
          },
        ]}
        dataAccessor={(i) => i.packagingPagination as PackagingPagination}
        buildFilterQuery={(_, defaultSort, page, perPage) => {
          return {
            filter: {},
            sort: defaultSort,
            page,
            perPage,
          };
        }}
        columns={[
          { label: 'Name', field: 'name' },
          {
            label: 'Length',
            field: 'box_length_in',
            formatter: (row) => renderDimensionalUnit(row.box_length_in),
          },
          {
            label: 'Width',
            field: 'box_width_in',
            formatter: (row) => renderDimensionalUnit(row.box_width_in),
          },
          {
            label: 'Height',
            field: 'box_height_in',
            formatter: (row) => renderDimensionalUnit(row.box_height_in),
          },
          { label: 'Insulation Type', field: 'insulation_type' },
          {
            label: 'Insulation Thickness',
            field: 'insulation_thickness_in',
            formatter: (row) =>
              renderDimensionalUnit(row.insulation_thickness_in),
          },
          { label: 'Insulation R-Value', field: 'insulation_r_value' },
          {
            formatter: (row, _, t) => (
              <Button
                content="Edit"
                icon={faEdit}
                size="sm"
                isLoading={updatePackagingOp.loading}
                onClick={() =>
                  save<PackagingModalForm>({
                    type: 'save',
                    title: 'Edit Packaging',
                    icon: faBox,
                    isLoading: updatePackagingOp.loading,
                    initialValues: {
                      name: row.name,
                      boxLength: row.box_length_in,
                      boxWidth: row.box_width_in,
                      boxHeight: row.box_height_in,
                      insulationType: row.insulation_type || undefined,
                      insulationThickness:
                        row.insulation_thickness_in || undefined,
                      insulationRValue: row.insulation_r_value || undefined,
                    },
                    body: (formikProps) => (
                      <PackagingModal formikProps={formikProps} />
                    ),
                    onSubmit: async (editPackaging) => {
                      await updatePackaging({
                        variables: { _id: row._id, editPackaging },
                      });
                      push({
                        title: 'Success',
                        bg: 'primary',
                        delay: 4000,
                        body: 'Packaging successfully edited.',
                      });
                      if (t) {
                        t.refetch();
                      }
                    },
                  })
                }
              />
            ),
          },
        ]}
      />
    </div>
  );
}
