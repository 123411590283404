import {
  Button,
  ExternalLink,
  Form,
  InputSelect,
  InputSelectProps,
  PageTitle,
  Table,
  useToastr,
} from '@farmshare/ui-components';
import { formatToCurrency, formatToShortDate } from '@farmshare/utils';
import { faFileInvoice, faSearch } from '@fortawesome/free-solid-svg-icons';
import { first, groupBy, join, map } from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import { Card, Col, Container, Row, Stack } from 'react-bootstrap';

import {
  useInvoiceCreateMutation,
  useShipmentsByDateLazyQuery,
} from 'lib/graphql';

export default function AdminInvoiceCreatePage() {
  const { push } = useToastr();

  const [getShipments, getShipmentsOp] = useShipmentsByDateLazyQuery();
  const [createInvoice, createInvoiceOp] = useInvoiceCreateMutation();

  const months = useMemo(() => {
    const arr: InputSelectProps['options'] = [];
    for (let i = 1; i <= 4; i++) {
      const m = moment.utc().subtract(i, 'months');
      arr.push({ label: m.format('MMMM'), value: m.format('MMM YYYY') });
    }
    return arr;
  }, []);

  return (
    <div>
      <PageTitle
        title="Create Invoice"
        innerBreadcrumbs={[
          { text: 'Admin', to: '/admin' },
          { text: 'Invoices', to: '/admin/invoices' },
        ]}
      />
      <Form<{ month: number }>
        initialValues={{ month: 0 }}
        onSubmit={(values) => {
          const startDate = moment.utc(values.month).startOf('month');
          const endDate = moment.utc(values.month).endOf('month');

          getShipments({ variables: { startDate, endDate } });
        }}
      >
        {(props) => (
          <Container>
            <Row md={3}>
              <Col>
                <InputSelect
                  label="Month"
                  options={months}
                  disabled={getShipmentsOp.loading}
                  required
                />
              </Col>
              <Col>
                <Button
                  type="submit"
                  content="Get Shipments"
                  icon={faSearch}
                  isLoading={getShipmentsOp.loading}
                  inlineMarginTop
                />
              </Col>
            </Row>
            {getShipmentsOp.data?.shipmentsByDate && (
              <>
                <hr />
                <Stack gap={3}>
                  {map(
                    groupBy(
                      getShipmentsOp.data.shipmentsByDate,
                      (s) => s?.vendor?.shop_name,
                    ),
                    (shipmentGroup, vendor) => (
                      <Card key={vendor}>
                        <Card.Header>
                          <Stack
                            direction="horizontal"
                            className="justify-content-between"
                          >
                            <div>{vendor}</div>
                            <Button
                              content="Generate Invoice"
                              icon={faFileInvoice}
                              size="sm"
                              isLoading={createInvoiceOp.loading}
                              onClick={async () => {
                                const billing_period = moment.utc(
                                  props.values.month,
                                );
                                await createInvoice({
                                  variables: {
                                    newInvoice: {
                                      vendor: first(shipmentGroup)?.vendor?._id,
                                      description: `Farmshare Partners Shipments for ${billing_period.format(
                                        'MMMM YYYY',
                                      )}`,
                                      billing_month: billing_period.month() + 1,
                                      billing_year: billing_period.year(),
                                    },
                                    newInvoiceItems: map(
                                      shipmentGroup,
                                      (s) => ({
                                        shipment: s?._id,
                                        description: join(
                                          [
                                            moment(s?.date_created).format(
                                              'MM/DD',
                                            ),
                                            s?.chosen_rate?.carrier
                                              ?.friendly_name,
                                            // s?.reference,
                                            join(
                                              map(
                                                s?.packages,
                                                (p) => p.tracking_number,
                                              ),
                                              ',',
                                            ),
                                          ],
                                          ' | ',
                                        ),
                                        amount:
                                          s?.chosen_rate?.total_amount || 0,
                                        quantity: 1,
                                      }),
                                    ),
                                  },
                                });
                                push({
                                  title: 'Success',
                                  body: 'Invoice created successfully.',
                                  bg: 'primary',
                                  delay: 4000,
                                });
                                await getShipmentsOp.refetch();
                              }}
                            />
                          </Stack>
                        </Card.Header>
                        <Card.Body>
                          <Table
                            rows={shipmentGroup}
                            columns={[
                              {
                                label: 'Id',
                                field: 'external_ids',
                                formatter: (row) => (
                                  <ExternalLink
                                    href={`/shipments/${row?._id}`}
                                    text={
                                      row?.external_ids['ship-engine'] || ''
                                    }
                                  />
                                ),
                              },
                              {
                                label: 'Reference',
                                field: 'reference',
                              },
                              {
                                label: 'Date Created',
                                field: 'date_created',
                                formatter: (row) =>
                                  formatToShortDate(row?.date_created),
                              },
                              {
                                label: 'Date Completed',
                                field: 'date_completed',
                                formatter: (row) =>
                                  formatToShortDate(row?.date_completed),
                              },
                              {
                                label: 'Carrier',
                                field: 'chosen_rate.carrier.friendly_name',
                              },
                              {
                                label: 'Cost',
                                field: 'chosen_rate.total_amount',
                                formatter: (row) =>
                                  formatToCurrency(
                                    row?.chosen_rate?.total_amount,
                                  ),
                              },
                              {
                                label: 'Invoiced?',
                                field: 'invoice_line_item',
                                formatter: (row) =>
                                  row?.invoice_line_item ? 'Yes' : 'No',
                              },
                            ]}
                          />
                        </Card.Body>
                      </Card>
                    ),
                  )}
                </Stack>
              </>
            )}
          </Container>
        )}
      </Form>
    </div>
  );
}
