import { PageTitle } from '@farmshare/ui-components';
import { join } from 'lodash';
import { Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';

import { useViewProcessorSchedulingQuery } from 'lib/graphql';

import {
  occupiedStatuses,
  SchedulingSelectForm,
} from './_views/scheduling-select-form';

export default function ProcessingPartnerHome() {
  const navigate = useNavigate();
  const { vendorId } = useParams();

  const { data } = useViewProcessorSchedulingQuery({
    variables: { statuses: occupiedStatuses, vendorId },
  });

  const vendorName = data?.processingPartnersOne?.vendor?.shop_name ?? '';

  return (
    <div className="py-3 py-lg-4">
      <PageTitle
        title={`${vendorName} Scheduling`}
        breadCrumbLabel={vendorName}
        innerBreadcrumbs={[{ text: 'Scheduling', to: '/scheduling' }]}
      />
      <Container>
        {data?.processingPartnersOne?.about && (
          <>
            <p className="fs-5 fw-bold">About:</p>
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(data.processingPartnersOne.about),
              }}
            ></div>
            <hr />
          </>
        )}
        <SchedulingSelectForm
          onSelect={(animalType, date) =>
            navigate(
              join(
                [
                  '/scheduling',
                  vendorId,
                  animalType,
                  date.format('YYYY-MM-DD'),
                ],
                '/',
              ),
            )
          }
          vendorId={vendorId}
        />
      </Container>
    </div>
  );
}
