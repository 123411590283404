import { InputText } from '@farmshare/ui-components';
import { Nullable } from '@farmshare/utils';
import { useFormikContext } from 'formik';
import { map } from 'lodash';
import { Col, Row, Stack } from 'react-bootstrap';

import { ProcessorScheduling } from 'lib/graphql';
import { processingJobAnimalHeadLabel } from 'lib/processingJobUtils';

export interface AnimalHeadDroppedOffForm {
  _id?: string;
  liveWeight?: Nullable<number>;
}

export interface DroppedOffModalProps {
  animalHeads: AnimalHeadDroppedOffForm[];
}

const HeadTracking = ({
  ahidx,
  heading,
}: {
  ahidx: number;
  animalHead: AnimalHeadDroppedOffForm;
  heading: string;
}) => {
  return (
    <Stack gap={2}>
      <h5 className="fw-bold">{heading}</h5>
      <Row className="align-items-center pb-2">
        <Col>
          <InputText
            type="number"
            min={0}
            step={0.01}
            label="Live Weight"
            floatingLabel
            nameOveride={`animalHeads.${ahidx}.liveWeight`}
            hint="Live weight of the animal"
          />
        </Col>
      </Row>
    </Stack>
  );
};

export const DroppedOffModal = ({
  scheduledJob,
}: {
  scheduledJob: ProcessorScheduling;
}) => {
  const { values } = useFormikContext<DroppedOffModalProps>();

  return (
    <Stack gap={2}>
      {map(values.animalHeads, (animalHead, idx) => {
        return (
          <HeadTracking
            key={idx}
            ahidx={idx}
            animalHead={animalHead}
            heading={processingJobAnimalHeadLabel({
              producerIdentifier:
                scheduledJob?.animalHeads?.[idx]?.producerIdentifier,
              animalSpecies: scheduledJob.animalSpecies,
              inspectionLevel:
                scheduledJob?.animalHeads?.[idx]?.inspectionLevel ?? undefined,
              requestedBy: {
                first_name: scheduledJob.requestedBy?.first_name,
                last_name: scheduledJob.requestedBy?.last_name,
              },
              animalNumber: idx + 1,
            })}
          />
        );
      })}
    </Stack>
  );
};
