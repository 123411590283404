import { DataDetailListMinimal, MoneyDisplay } from '@farmshare/ui-components';
import { isNil } from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import { type ProcessingJobDetailsSectionProps } from './job-details-tab';

type Props = Pick<ProcessingJobDetailsSectionProps, 'processingJob'>;

export function DepositDetails({ processingJob }: Props) {
  const refundDeadlineDisplay = useMemo(() => {
    const refundDeadlineDays = processingJob?.refundDeadlineDays;

    if (isNil(refundDeadlineDays)) {
      return '-';
    }

    const refundDeadlineDate = moment
      .utc(processingJob?.dropoffDateStr)
      .subtract(refundDeadlineDays, 'days')
      .format('l');

    return refundDeadlineDate;
  }, [processingJob?.refundDeadlineDays, processingJob?.dropoffDateStr]);
  return (
    <Card className="h-100">
      <Card.Header className="d-flex align-items-center">
        <span className="fw-bold fs-5">Deposit</span>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <DataDetailListMinimal
              rows={[
                {
                  label: 'Amount',
                  value: (
                    <MoneyDisplay value={processingJob?.deposit?.amount} />
                  ),
                },
                {
                  label: 'Status',
                  value:
                    processingJob?.deposit?.checkoutSession?.payment_status,
                },
                {
                  label: 'Refund Deadline',
                  value: refundDeadlineDisplay,
                },
                {
                  label: 'Refund Deadline Days',
                  value: processingJob?.refundDeadlineDays,
                },
              ]}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
