import { PageTitle } from '@farmshare/ui-components';
import { map, startCase } from 'lodash';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link, Outlet } from 'react-router-dom';

const links = ['invoices', 'tenants'];

export default function AdminPage() {
  return (
    <Container className="pt-4">
      <PageTitle title="Admin" show={false} />
      <Row>
        <Col md={2} className="mb-3 mb-md-0">
          <Card className="h-100" body>
            <h4>Admin Panel</h4>
            <hr />
            <ul className="list-unstyled">
              {map(links, (link) => (
                <li key={link}>
                  <Link to={`/admin/${link}`}>{startCase(link)}</Link>
                </li>
              ))}
            </ul>
          </Card>
        </Col>
        <Col md={10}>
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
}
