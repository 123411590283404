import moment from 'moment';

const PREFIX_FORMAT = 'YYYYMMDD';

function getAnimalIdentifier(species?: string) {
  if (!species) {
    throw new Error(
      `Unable to generate tracking number missing animal species: ${species}.`,
    );
  }

  // Assume we never have species that start with same letter.
  return species.substring(0, 1).toUpperCase();
}

function getInspectionLevelIdentifier(inspectionLevel?: string) {
  if (!inspectionLevel) {
    return '';
  }
  return inspectionLevel.substring(0, 1).toUpperCase();
}

/**
 * Tracking number has the following structure.
 *
 * 1. The first letter determines the species.
 *  ex: B would be equal to 'Beef', H is hog etc
 *
 * 2. The second letter determines the inspection level
 *   ex: E is `Exempt` U is `USDA` S is `State`
 *
 * 2. The kill date of the animal in MMDDYYYY format
 *
 * 3. The publicId on the animal head to guaranty uniqueness
 *
 * These are meant to be unique on a per processor basis.
 * So two separate processors could have the same tracking numbers
 */
export function generateTrackingNumber({
  animalSpecies,
  inspectionLevel,
  killDate,
  uniqueId = '',
}: {
  animalSpecies: string;
  inspectionLevel: string;
  killDate: Date;
  uniqueId?: string;
}) {
  const formattedDate = moment.utc(killDate).format(PREFIX_FORMAT);

  const animalIdentifier = getAnimalIdentifier(animalSpecies);
  const inspectionLevelIdentifier =
    getInspectionLevelIdentifier(inspectionLevel);

  return `${animalIdentifier}${inspectionLevelIdentifier}${formattedDate}${uniqueId}`;
}
