import {
  // InputText,
  PaginationTablePage,
  // useModal,
} from '@farmshare/ui-components';
import { formatToCurrency } from '@farmshare/utils';
// import {
//   faBasketShopping,
//   faFileImport,
//   faPlus,
// } from '@fortawesome/free-solid-svg-icons';
import { filter, map, startCase } from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';

import {
  EnumOrderStatus,
  Order,
  OrderPagination,
  OrderPaginationQuery,
  OrderPaginationQueryVariables,
  SortFindManyOrderInput,
  // useOrderImportMutation,
  useOrderPaginationLazyQuery,
} from 'lib/graphql';

// import { OrderModal, type OrderForm } from './_views/order-modal';

// interface OrderImportModal {
//   orderId?: number;
// }

export default function OrdersPage() {
  // const { save } = useModal();

  // const [importOrder, importOrderMutation] = useOrderImportMutation();

  return (
    <div className="pt-4">
      <PaginationTablePage<
        Order,
        OrderPagination,
        OrderPaginationQuery,
        OrderPaginationQueryVariables,
        SortFindManyOrderInput
      >
        actionButtons={({ loading, refetch }) => [
          // {
          //   content: 'New Order',
          //   icon: faPlus,
          //   onClick: () =>
          //     save<OrderForm>({
          //       type: 'save',
          //       title: 'New Order',
          //       icon: faBasketShopping,
          //       size: 'lg',
          //       initialValues: {
          //         customer: undefined,
          //         shipping: undefined,
          //         lineItems: [{ id: '', quantity: 0, price: 0, subtotal: 0 }],
          //         total: 0,
          //       },
          //       body: (formikProps) => <OrderModal formikProps={formikProps} />,
          //       validate: (values) => {
          //         const errors = {};
          //         return errors;
          //       },
          //       onSubmit: (values, formikHelpers) => {
          //         //
          //       },
          //     }),
          // },
          //   {
          //     content: 'Import Order',
          //     icon: faFileImport,
          //     isLoading: importOrderMutation.loading,
          //     onClick: () =>
          //       save<OrderImportModal>({
          //         type: 'save',
          //         title: 'Order Import',
          //         saveText: 'Import',
          //         saveIcon: faFileImport,
          //         icon: faFileImport,
          //         isLoading: loading,
          //         body: () => <InputText type="number" label="Order Id" required />,
          //         async onSubmit({ orderId }: OrderImportModal) {
          //           if (orderId) {
          //             await importOrder({
          //               variables: { orderId },
          //             });
          //             await refetch();
          //           }
          //         },
          //         initialValues: { orderId: undefined },
          //       }),
          //   },
        ]}
        title="Orders"
        filters={map(EnumOrderStatus, (e) => ({
          label: e.toString(),
          value: e,
        }))}
        buildFilterQuery={(allFilters, defaultSort, page, perPage) => {
          const activeFilters = filter(allFilters, (f) => f.isActive || false);

          if (activeFilters?.length > 0) {
            return {
              filter: {
                OR: map(activeFilters, (a) => ({
                  status: a.value as EnumOrderStatus,
                })),
              },
              sort: defaultSort,
              page,
              perPage,
            };
          }
          return { filter: {}, sort: defaultSort, page, perPage };
        }}
        defaultFilters={[EnumOrderStatus.Pending, EnumOrderStatus.Processing]}
        defaultSort={SortFindManyOrderInput.OrderNumberDesc}
        dataAccessor={(a) => a.orderPagination as OrderPagination}
        paginationQuery={useOrderPaginationLazyQuery({
          notifyOnNetworkStatusChange: true,
        })}
        columns={[
          {
            label: 'Id',
            field: 'external_id',
            formatter: (row) => (
              <Link to={`/orders/${row._id}`}>{row.external_id}</Link>
            ),
          },
          {
            label: 'Date Created',
            field: 'date_created',
            formatter: (row) => moment(row.date_created).format('LLL'),
          },
          {
            label: 'Status',
            field: 'status',
            formatter: (row) => startCase(row.status),
          },
          {
            label: 'Customer',
            field: 'customer.full_name',
            formatter: (row) =>
              row.customer?.full_name ?? (
                <span className="fst-italic text-muted">guest</span>
              ),
          },
          {
            label: 'Items',
            field: 'line_items',
            formatter: (row) => row.line_items?.length,
          },
          {
            label: 'Total',
            field: 'total',
            formatter: (row) => formatToCurrency(row.total),
          },
        ]}
      />
    </div>
  );
}
