// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
  production: false,
  authAudience: 'https://api.farmshare.co',
  authDomain: 'dev-hw2ja6fxufte1kp3.us.auth0.com',
  authClientId: 'cNrsxVQ5K77ZxPv8WlA5E9HVG8dVlPAJ',
  intercomKey: 'vwjyd9p9',
  stripeKey:
    'pk_test_51Kb5omCZAQl1OOa7Opo0wjJT5fUIMzcWTJ5HkK7lRLAazD20rTLsnFaVU4Bq2cFYHIlVZTMuvy3mqGT63TqDgDmW00Yzji4MD0',
  stripePricingTable: 'prctbl_1NtFYiCZAQl1OOa7rusK7jHQ',
  tinyApiKey: 'hv6ora44j5j9k7n1gkgkfyogvqykyuk52l7e12yhdl5kqqvw',
};
