import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isBoolean } from 'lodash';
import type { ReactNode } from 'react';

export interface ExternalLinkProps {
  href: string;
  text?: ReactNode;
  icon?: boolean | IconProp;
}

export function ExternalLink({ href, text, icon = true }: ExternalLinkProps) {
  return (
    <a
      href={href}
      className="text-decoration-none"
      target="_blank"
      rel="noreferrer"
    >
      {icon && (
        <FontAwesomeIcon
          icon={isBoolean(icon) ? faExternalLink : icon}
          className="me-2"
        />
      )}
      {text && <span>{text}</span>}
    </a>
  );
}
