import { PaginationTablePage, useModal } from '@farmshare/ui-components';
import { formatToShortDate } from '@farmshare/utils';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { join, map } from 'lodash';
import { Link } from 'react-router-dom';

import {
  Tenant,
  TenantPagination,
  SortFindManyTenantInput,
  useTenantPaginationLazyQuery,
  TenantPaginationQuery,
  TenantPaginationQueryVariables,
  useTenantCreateMutation,
} from 'lib/graphql';

import { TenantModal, type TenantModalForm } from './_views/tenant-modal';

export default function AdminTenantPage() {
  const [addTenant, addTenantOps] = useTenantCreateMutation();

  const { save } = useModal();

  return (
    <div>
      <PaginationTablePage<
        Tenant,
        TenantPagination,
        TenantPaginationQuery,
        TenantPaginationQueryVariables,
        SortFindManyTenantInput
      >
        title="Tenants"
        paginationQuery={useTenantPaginationLazyQuery()}
        defaultSort={SortFindManyTenantInput.IdAsc}
        innerBreadcrumbs={[{ text: 'Admin', to: '/admin' }]}
        dataAccessor={(t) => t.tenantPagination as TenantPagination}
        actionButtons={(t) => [
          {
            content: 'Add Tenant',
            icon: faPlus,
            isLoading: addTenantOps.loading,
            onClick: () =>
              save<TenantModalForm>({
                type: 'save',
                title: 'New Tenant',
                icon: faPlus,
                initialValues: {
                  name: '',
                  email: '',
                  primaryColor: '#000000',
                },
                body: <TenantModal />,
                onSubmit: async ({
                  name,
                  email,
                  domain,
                  modules,
                  pictureUrl,
                  primaryColor,
                }) => {
                  await addTenant({
                    variables: {
                      newTenant: {
                        name,
                        email,
                        domain,
                        modules,
                        enabled: true,
                        date_created: new Date(),
                        picture_url: pictureUrl,
                        primary_color: primaryColor,
                      },
                    },
                  });
                  await t.refetch();
                },
              }),
          },
        ]}
        buildFilterQuery={(_, defaultSort, page, perPage) => {
          return {
            filter: {},
            sort: defaultSort,
            page,
            perPage,
          };
        }}
        columns={[
          {
            label: 'Name',
            field: 'name',
            formatter: (row) => (
              <Link to={`/admin/tenants/${row._id}`}>{row.name}</Link>
            ),
          },
          {
            label: 'Date Created',
            field: 'date_created',
            formatter: (row) => formatToShortDate(row.date_created),
          },
          {
            label: 'Modules',
            formatter: (row) =>
              join(
                map(row.modules, (m) => m.label),
                ', ',
              ),
            minimumBreakpoint: 'md',
          },
          {
            label: '# Users',
            formatter: (row) => row.users.length,
            minimumBreakpoint: 'md',
          },
          {
            label: 'Enabled?',
            field: 'enabled',
            formatter: (row) => (row.enabled ? 'Yes' : ''),
          },
        ]}
      />
    </div>
  );
}
