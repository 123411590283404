import { faLightbulb, faMoon } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { includes } from 'lodash';
import { ChangeEventHandler, useCallback, useEffect } from 'react';
import { Form, Stack } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

export type Theme = 'light' | 'dark';

interface ThemeSelectorProps {
  theme: Theme;
  setTheme: (theme: Theme) => void;
}

const excludedPaths: string[] = ['/'];

export function ThemeSelector({ theme, setTheme }: ThemeSelectorProps) {
  const { pathname } = useLocation();

  useEffect(() => {
    const _theme = localStorage.getItem('theme');

    if (_theme === 'light' || includes(excludedPaths, pathname)) {
      setTheme('light');
    } else {
      setTheme('dark');
    }
  }, [pathname, setTheme]);

  useEffect(() => {
    document.getElementById('navbar')?.setAttribute('data-bs-theme', theme);
    document.documentElement.setAttribute('data-bs-theme', theme);
  }, [theme]);

  const handleThemeChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const theme: Theme = e.target.checked ? 'dark' : 'light';
      setTheme(theme);
      localStorage.setItem('theme', theme);
    },
    [setTheme],
  );

  return (
    !includes(excludedPaths, pathname) && (
      <div className="bg-body px-2 py-1 ms-3 my-2 rounded-3">
        <Stack
          direction="horizontal"
          gap={2}
          className="fw-light small text-muted d-print-none"
        >
          <div>
            Light&nbsp;
            <FontAwesomeIcon icon={faLightbulb} size="sm" />
            &nbsp;
          </div>
          <Form.Switch
            name="theme"
            onChange={handleThemeChange}
            checked={theme === 'dark'}
          />
          <div>
            <FontAwesomeIcon icon={faMoon} size="sm" />
            &nbsp;Dark
          </div>
        </Stack>
      </div>
    )
  );
}
