import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Container, Row, Stack } from 'react-bootstrap';

// const tiers = [
//   { upTo: 10, price: 49 },
//   { upTo: 50, price: 99 },
//   { upTo: 150, price: 229 },
//   { price: 999 },
// ];

export default function PromosPage() {
  return (
    <Container className="mt-md-5 my-3 px-3">
      <Row className="mx-1 mx-md-5 my-4 justify-content-center">
        <Col>
          <img
            src="/assets/images/logo-roca-green.svg"
            alt="Farmshare Logo"
            className="img-fluid"
          />
        </Col>
      </Row>
      <div className="bg-primary w-100 lh-1 mb-4 rounded">&nbsp;</div>
      <Row className="align-items-center g-3">
        <Col md={{ span: 8, offset: 2 }}>
          <Stack gap={4} className="text-center align-items-center">
            <div className="fs-5">
              As a special offer,{' '}
              <span className="text-primary fw-bolder fs-4">Farmshare</span> is
              delighted to offer 1 month free of our industry-leading{' '}
              <b>Partners</b> platform.
            </div>
            {/* <div>
              Our pricing is based on the number of shipment you send during a
              month:
            </div>
            <div>
              <Table>
                <thead>
                  <tr>
                    <th>Number of Shipments</th>
                    <th>Monthly Price</th>
                  </tr>
                </thead>
                <tbody>
                  {map(tiers, (tier, i) => (
                    <tr key={i}>
                      <td>
                        {i > 0 ? (tiers[i - 1]?.upTo ?? 0) + 1 : 0}
                        {tier.upTo ? ` - ${tier.upTo}` : '+'}
                      </td>
                      <td className="fw-bold">
                        <FontAwesomeIcon icon={faDollarSign} size="xs" />
                        &nbsp;
                        {tier.price}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div> */}
            <div className="fs-5">
              Schedule a demo today to start your{' '}
              <b>30-day, no committment trial</b> of{' '}
              <span className="text-primary fw-bolder fs-4">
                Farmshare&nbsp;Partners
              </span>
              !
              <div className="d-block mt-3">
                <Button
                  href="https://meetings.hubspot.com/henry-arrowood/schedule-demo"
                  size="lg"
                >
                  Book My Demo!
                </Button>
              </div>
            </div>
            <div className="text-muted fs-5">OR</div>
            <Stack gap={2} className="fs-5">
              <div>
                Contact <b>Henry Arrowood</b> at:
              </div>
              <div>
                <FontAwesomeIcon icon={faEnvelope} className="text-primary" />
                &nbsp;<a href="mailto:henry@farmshare.co">henry@farmshare.co</a>
              </div>
              <div>
                <FontAwesomeIcon icon={faPhone} className="text-primary" />
                &nbsp;<a href="tel:+1 (301) 448-0543">(301) 448-0543</a>
              </div>
            </Stack>
            <div className="fs-5 fst-italic text-muted pt-2">
              *Ask about our ready-to-go website solutions too.
            </div>
          </Stack>
        </Col>
      </Row>
    </Container>
  );
}
