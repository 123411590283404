import { Button, useModal, useToastr } from '@farmshare/ui-components';
import { faFileDownload, faXmark } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useRecoilValue } from 'recoil';
import { vendorState } from 'state';

import { downloadFromBase64Encoded } from 'lib/downloadFromBase64Encoded';
import { downlaodFromPresignedUrl } from 'lib/downloadFromPresignedUrl';
import { useProcessorSchedulingGenerateKillSheetMutation } from 'lib/graphql';

import { GenerateKillsheetModal } from './modals/generate-killsheet-modal';

export function GenerateKillsheetButton() {
  const { save } = useModal();
  const { push } = useToastr();

  const vendor = useRecoilValue(vendorState);

  const [generateKillSheet] = useProcessorSchedulingGenerateKillSheetMutation();

  return (
    <Button
      className="me-3"
      content="Generate Kill Sheet"
      icon={faFileDownload}
      onClick={() => {
        save({
          backdrop: 'static',
          type: 'save',
          title: 'Generate Kill Sheet',
          cancelText: 'Close',
          saveText: 'Generate Kill Sheet',
          saveIcon: faFileDownload,
          cancelIcon: faXmark,
          body: <GenerateKillsheetModal />,
          initialValues: {
            killDate: moment().format('YYYY-MM-DD'),
          },
          async onSubmit(values) {
            try {
              const response = await generateKillSheet({
                variables: {
                  date: values.killDate,
                  vendorId: vendor._id,
                },
              });

              if (
                response.data?.processorSchedulingGenerateKillSheet?.fileUrl
              ) {
                downlaodFromPresignedUrl(
                  response.data?.processorSchedulingGenerateKillSheet?.fileUrl,
                );
              } else if (
                response.data?.processorSchedulingGenerateKillSheet?.file
              ) {
                downloadFromBase64Encoded(
                  response.data.processorSchedulingGenerateKillSheet.file,
                  response.data.processorSchedulingGenerateKillSheet
                    ?.filename ?? 'Kill_Sheet.pdf',
                );
              }
            } catch (error) {
              push({
                title: 'Error',
                body: (error as string).toString(),
                bg: 'danger',
                delay: 4000,
              });
            }
          },
        });
      }}
    />
  );
}
