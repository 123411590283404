import { Editor } from '@tinymce/tinymce-react';
import { ErrorMessage, useFormikContext } from 'formik';
import { join } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { Editor as TinyMCEEditor } from 'tinymce';

export interface InputEditorProps {
  name: string;
  apiKey: string;
  theme: 'light' | 'dark';
  initialContent?: string;
}

export function InputEditor({
  apiKey,
  initialContent,
  name,
  theme,
}: InputEditorProps) {
  const { setFieldValue } = useFormikContext<Record<string, string>>();

  const editorRef = useRef<TinyMCEEditor | null>(null);

  useEffect(() => {
    if (initialContent) {
      setFieldValue(name, initialContent);
    }
  }, [initialContent, name, setFieldValue]);

  const handleEditorChange = useCallback(() => {
    if (editorRef.current) {
      setFieldValue(name, editorRef.current.getContent({ format: 'html' }));
    }
  }, [name, setFieldValue]);

  return (
    <>
      <Editor
        onInit={(_, editor) => (editorRef.current = editor)}
        initialValue={initialContent}
        init={{
          menubar: false,
          plugins: ['autolink', 'emoticons'],
          skin: theme === 'dark' ? 'oxide-dark' : 'oxide',
          content_css: theme === 'dark' ? 'dark' : '',
          protect: [/[\n\f\r\t\v]/g],
          toolbar: join(
            [
              'undo redo',
              'emoticons',
              'bold italic',
              ' alignleft aligncenter alignright alignjustify',
              'outdent indent',
            ],
            ' | ',
          ),
        }}
        apiKey={apiKey}
        onFocusOut={() => handleEditorChange()}
      />
      <ErrorMessage
        name={name}
        render={(msg: string) => (
          <Form.Control.Feedback
            type="invalid"
            // Since we are not using bootstrap Form elements but formik we should force this to display the error message
            style={{ display: 'block' }}
          >
            {msg}
          </Form.Control.Feedback>
        )}
      />
    </>
  );
}
