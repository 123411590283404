import { useModal, useToastr } from '@farmshare/ui-components';
import {
  faTrash,
  faEdit,
  faClone,
  faPlus,
  faEllipsis,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map } from 'lodash';
import { Dropdown } from 'react-bootstrap';

import {
  useCutsheetSoftDeleteMutation,
  useViewProcessorAdminQuery,
  type Cutsheet,
  useCutsheetUpsertMutation,
  CutsheetUpsertMutationVariables,
  EnumCutsheetType,
} from 'lib/graphql';

import { CutsheetModal, NewCutsheetForm } from './cutsheet-modal';
import { EditCutsheetModal, EditCutsheetForm } from './edit-cutsheet-modal';

interface CutsheetsButtonsProps {
  cutsheet: Cutsheet;
  refetch: () => Promise<unknown>;
}

export function CutsheetButtons({ cutsheet, refetch }: CutsheetsButtonsProps) {
  const [deleteCutsheet, deleteCutsheetOp] = useCutsheetSoftDeleteMutation();
  const [upsertCutSheet, upsertCutSheetOp] = useCutsheetUpsertMutation();
  const settings = useViewProcessorAdminQuery();

  const { ask, save } = useModal();
  const { push } = useToastr();

  if (cutsheet.isDeleted) {
    return null;
  }

  const duplicateCutSheet = async () => {
    return save<NewCutsheetForm>({
      type: 'save',
      title: 'Add a Cutsheet',
      icon: faPlus,
      size: 'xl',
      initialValues: {
        animalSpecies: cutsheet.animalSpecies,
        inspectionLevel: cutsheet.inspectionLevels?.[0] ?? undefined,
        name: `${cutsheet.name} - COPY`,
        pricePerPound: cutsheet.pricePerPound ?? 0,
        groundMeatSize: '' + cutsheet.groundMeatSize || '1',
        cuts: cutsheet.primalCuts.map((c) => ({
          label: c.name,
          value: c._id,
        })),
        splitTypes: cutsheet.splitTypes!.map((st) => ({
          label: st as string,
          value: st as string,
        })),
        roastSize: cutsheet.roastSize ?? '1',
        steaksPerPack: '' + cutsheet.steaksPerPack || '1',
        steakThickness: '' + cutsheet.steakThickness || '1',
      },
      body: (formik) => <CutsheetModal fP={formik} settings={settings?.data} />,

      validate: async (values) => {
        const errors: Record<string, string> = {};

        if (!values.name) {
          errors.name = 'Name is required';
        }
        if (values.splitTypes?.length === 0) {
          errors.splitTypes = 'At least one Split type is required';
        }

        if (!values.inspectionLevel) {
          errors.inspectionLevel =
            'Please select at least one inspection level.';
        }

        return errors;
      },
      onSubmit: async ({
        animalSpecies,
        inspectionLevel,
        cuts,
        splitTypes,
        name,
        roastSize,
        steaksPerPack,
        groundMeatSize,
        steakThickness,
        pricePerPound = 0,
      }) => {
        const variables: CutsheetUpsertMutationVariables = {
          primalCuts: map(cuts, (c) => c.value),
          splitTypes: map(splitTypes, (st) => st.value),
          type: EnumCutsheetType.Vendor,
          groundMeatSize: parseFloat(groundMeatSize),
          animalSpecies,
          inspectionLevels: inspectionLevel ? [inspectionLevel] : [],
          name,
          pricePerPound,
          roastSize,
        };

        if (steaksPerPack) {
          variables.steaksPerPack = parseInt(steaksPerPack);
        }
        if (steakThickness) {
          variables.steakThickness = parseFloat(steakThickness);
        }

        await upsertCutSheet({ variables });
        push({
          title: 'Save Successful',
          bg: 'primary',
          body: 'Cutsheet successfully saved.',
          delay: 4000,
        });
        refetch();
      },
    });
  };

  const handleCutSheetEdit = async () => {
    return save<EditCutsheetForm>({
      type: 'save',
      title: 'Edit Cutsheet',
      icon: faEdit,
      initialValues: {
        name: cutsheet.name,
        splitTypes: cutsheet.splitTypes!.map((st) => ({
          label: st as string,
          value: st as string,
        })),
      },
      body: (formik) => <EditCutsheetModal formik={formik} />,

      validate: async (values) => {
        const errors: Record<string, string> = {};

        if (!values.name) {
          errors.name = 'Name is required';
        }
        if (values.splitTypes?.length === 0) {
          errors.splitTypes = 'At least one Split type is required';
        }

        return errors;
      },
      onSubmit: async (values) => {
        await upsertCutSheet({
          variables: {
            cutsheetId: cutsheet._id,
            name: values.name,
            splitTypes: values.splitTypes?.map((st) => st.value),
          },
        });

        push({
          title: 'Success',
          body: `Cutsheet ${values.name} successfully updated.`,
          bg: 'primary',
          delay: 4000,
        });

        await refetch();
      },
    });
  };

  return (
    <Dropdown align="end" className="my-auto">
      <Dropdown.Toggle
        icon={faEllipsis}
        variant="primary"
        size="lg"
        as={FontAwesomeIcon}
        disabled={deleteCutsheetOp.loading || upsertCutSheetOp.loading}
        className="bg-primary text-white border border-primary rounded px-2"
        style={{ cursor: 'pointer', marginTop: '1px' }}
      >
        <span className="d-none d-md-inline">Actions</span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
          onClick={duplicateCutSheet}
          className="w-100 fw-bold me-2"
        >
          <FontAwesomeIcon icon={faClone} style={{ minWidth: '25px' }} />
          Duplicate
        </Dropdown.Item>
        <Dropdown.Item
          onClick={handleCutSheetEdit}
          className="w-100 fw-bold me-2"
        >
          <FontAwesomeIcon icon={faEdit} style={{ minWidth: '25px' }} />
          Edit
        </Dropdown.Item>
        <Dropdown.Item
          onClick={async () =>
            ask({
              type: 'ask',
              title: 'Confirm Deletion',
              body: `Are you sure you want to delete ${cutsheet.name}?`,
              onConfirm: async () => {
                await deleteCutsheet({
                  variables: { cutsheetId: cutsheet._id },
                });
                push({
                  title: 'Success',
                  body: `Cutsheet ${cutsheet.name} successfully deleted.`,
                  bg: 'primary',
                  delay: 5000,
                });

                await refetch();
              },
            })
          }
          className="w-100 fw-bold"
        >
          <FontAwesomeIcon icon={faTrash} style={{ minWidth: '25px' }} />
          Delete
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
