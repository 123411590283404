import { PaginationTable } from '@farmshare/ui-components';
// import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { keyBy, mapValues } from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';

import {
  type Customer,
  type CustomerPagination,
  SortFindManyCustomerInput,
  useCustomerPaginationLazyQuery,
  CustomerPaginationQuery,
  CustomerPaginationQueryVariables,
} from 'lib/graphql';

export function CustomersView() {
  return (
    <div>
      <PaginationTable<
        Customer,
        CustomerPagination,
        CustomerPaginationQuery,
        CustomerPaginationQueryVariables,
        SortFindManyCustomerInput
      >
        paginationQuery={useCustomerPaginationLazyQuery({
          notifyOnNetworkStatusChange: true,
        })}
        defaultSort={SortFindManyCustomerInput.NameAsc}
        // filters={[{ label: 'Enabled', value: 'enabled' }]}
        // defaultFilters={['enabled']}
        columns={[
          {
            label: 'Name',
            field: 'full_name',
            formatter: (row) => (
              <Link to={`/marketing/customers/${row._id}`}>
                {row.full_name}
              </Link>
            ),
          },
          { label: 'Email', field: 'email', minimumBreakpoint: 'md' },
          {
            label: 'Last Active',
            field: 'last_active',
            formatter: (row) =>
              row.last_active ? moment.unix(row.last_active).format('L') : '-',
          },
        ]}
        dataAccessor={(a) => a.customerPagination as CustomerPagination}
        buildFilterQuery={(allFilters, defaultSort, page, perPage) => ({
          filter: mapValues(
            keyBy(allFilters, (k) => k.value),
            (v) => v.isActive,
          ),
          sort: defaultSort,
          page,
          perPage,
        })}
        // actionButtons={() => [
        //   {
        //     content: (
        //       <span className="d-none d-md-inline">New&nbsp;Customer</span>
        //     ),
        //     icon: faPlus,
        //     onClick: () => {
        //       save<CustomerForm>({
        //         type: 'save',
        //         title: 'New Customer',
        //         body: (formikProps) => <div />,
        //         initialValues: { name: '', customers: [] },
        //       });
        //     },
        //   },
        // ]}
      />
    </div>
  );
}
