import { PageTitle } from '@farmshare/ui-components';
import { kebabCase, map, some } from 'lodash';
import { ReactNode, useEffect, useMemo } from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { vendorState } from 'state';

import { EnumIntegrationName } from 'lib/graphql';

// import { AutomationsView } from './automations';
import { CampaignsView } from './campaigns';
import { CustomersView } from './customers';
import { SegmentView } from './segments';

interface Tab {
  key: string;
  title: string;
  element: ReactNode;
}

export default function MarketingPage() {
  const navigate = useNavigate();
  const { tab } = useParams();

  const vendor = useRecoilValue(vendorState);

  const tabs: Tab[] = useMemo(() => {
    const _tabs: Pick<Tab, 'title' | 'element'>[] = [
      { title: 'Customers', element: <CustomersView /> },
    ];

    // only enable marketing tabs if a sendgrid integration is set up
    if (
      some(vendor?.integrations, (i) => i.name === EnumIntegrationName.Sendgrid)
    ) {
      _tabs.push({ title: 'Segments', element: <SegmentView /> });
      _tabs.push({ title: 'Campaigns', element: <CampaignsView /> });
      // { title: 'Automations', element: <AutomationsView /> },
    }

    return map(_tabs, (t): Tab => ({ ...t, key: kebabCase(t.title) }));
  }, [vendor?.integrations]);

  useEffect(() => {
    if (!tab || !some(tabs, (t) => t.key === tab)) {
      navigate('/marketing/customers', { replace: true });
    }
  }, [navigate, tab, tabs]);

  return (
    <div className="pt-4">
      <PageTitle title="Marketing" showHr={false} />
      <Container>
        <Tabs
          defaultActiveKey={tab}
          onSelect={(tabName) => {
            if (tabName) {
              navigate(`/marketing/${tabName}`);
            }
          }}
        >
          {map(tabs, (tab) => (
            <Tab key={tab.key} title={tab.title} eventKey={tab.key}>
              <Container className="mt-3">{tab.element}</Container>
            </Tab>
          ))}
        </Tabs>
      </Container>
    </div>
  );
}
