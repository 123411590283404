import { Button, useModal } from '@farmshare/ui-components';
import { faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import { Stack } from 'react-bootstrap';

import {
  useUserActivateMutation,
  useUserDeactivateMutation,
  type User,
} from 'lib/graphql';

interface UserButtonsProps {
  user: User;
}
export function UserButtons({ user }: UserButtonsProps) {
  const [activateUser, activateUserOp] = useUserActivateMutation();
  const [deactivateUser, deactivateUserOp] = useUserDeactivateMutation();

  const { ask } = useModal();

  return (
    <Stack direction="horizontal">
      {user.enabled ? (
        <Button
          content="Deactivate"
          icon={faStop}
          size="sm"
          variant="danger"
          isLoading={deactivateUserOp.loading}
          onClick={async () =>
            ask({
              type: 'ask',
              title: 'Confirm Deactivation',
              body: `Are you sure you want to deactivate ${user.first_name}?`,
              onConfirm: async () => {
                const response = await deactivateUser({
                  variables: { userId: user._id },
                });
                if (response.data?.userDeactivate) {
                  user = response.data.userDeactivate;
                }
              },
            })
          }
        />
      ) : (
        <Button
          content="Activate"
          icon={faPlay}
          size="sm"
          isLoading={activateUserOp.loading}
          onClick={async () => {
            const response = await activateUser({
              variables: { userId: user._id },
            });
            if (response.data?.userActivate) {
              user = response.data.userActivate;
            }
          }}
        />
      )}
    </Stack>
  );
}
