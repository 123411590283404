import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import FullCalendar from '@fullcalendar/react';
import moment, { type Moment } from 'moment';
import { RefObject, useMemo, useRef } from 'react';
import Form from 'react-bootstrap/Form';

import styles from './big-calendar-header.module.scss';
import { Button } from '../button/button';

type BigCalendarHeader = {
  calendarRef: RefObject<FullCalendar>;
  dateRangeHeader: string;
  selectedDate: Moment | null;
  setSelectedDate: (newDate: Moment) => void;
  currentDate: Date | undefined;
  setCurrentDate: (newDate: Date) => void;
};

export function BigCalendarHeader({
  calendarRef,
  dateRangeHeader,
  selectedDate,
  setSelectedDate,
  currentDate,
  setCurrentDate,
}: BigCalendarHeader) {
  const monthPickerRef = useRef<HTMLInputElement>(null);

  const handleDateChange = (direction: 'prev' | 'today' | 'next'): void => {
    const calendarApi = calendarRef.current?.getApi();

    if (calendarApi) {
      if (direction === 'prev') {
        calendarApi.prev();
      } else if (direction === 'next') {
        calendarApi.next();
      } else {
        calendarApi.today();
      }

      setCurrentDate(calendarApi.getDate());
      setSelectedDate(moment(calendarApi.getDate()));
    }
  };

  const week: Moment[] = useMemo(() => {
    const dates = Array.from({ length: 7 }, (_, i) => {
      return moment(currentDate).startOf('week').add(i, 'day');
    });

    return dates;
  }, [currentDate]);

  const openDay = (day: Moment) => {
    const calendarApi = calendarRef.current?.getApi();
    calendarApi?.gotoDate(day.format('YYYY-MM-DD'));

    setSelectedDate(day);
  };

  return (
    <div>
      <div className={styles.bigCalendarHeaderNavigation}>
        <Button icon={faChevronLeft} onClick={() => handleDateChange('prev')} />
        <Form.Group controlId="month">
          <Form.Label
            className="mb-0"
            onClick={() => {
              monthPickerRef.current?.showPicker();
            }}
          >
            {dateRangeHeader}
          </Form.Label>
          <Form.Control
            className="visually-hidden"
            type="month"
            plaintext
            value={moment(currentDate)?.format('YYYY-MM')}
            ref={monthPickerRef}
            onChange={(e) => {
              const value = e.target.value;
              let newDate: Moment;

              if (!value) {
                newDate = moment();
              } else {
                newDate = moment(value, 'YYYY-MM');
              }
              setCurrentDate(newDate.toDate());
              calendarRef.current?.getApi().gotoDate(newDate.toDate());
            }}
          />
        </Form.Group>
        <Button
          icon={faChevronRight}
          onClick={() => handleDateChange('next')}
        />
      </div>
      <div className={styles.bigCalendarHeaderWeek}>
        {week.map((day, i) => {
          return (
            <div
              key={i}
              className={`${styles.bigCalendarHeaderDate} ${
                selectedDate?.isSame(day)
                  ? styles.bigCalendarHeaderDateSelected
                  : ''
              }`}
              onClick={() => openDay(day)}
            >
              <div>{day.format('ddd')}</div>
              <div>{day.format('DD')}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
