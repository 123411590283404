import { Loading, PageTitle } from '@farmshare/ui-components';
import { map } from 'lodash';
import { Container, Col, Row } from 'react-bootstrap';

import { useProcessingPartnersQuery } from 'lib/graphql';

import ProcessorCard from './_views/processor-card';

export default function SchedulingPage() {
  const { data, loading } = useProcessingPartnersQuery();

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="pt-4">
      <PageTitle title="Processor Scheduling" breadCrumbLabel="Scheduling" />
      <Container className="d-flex justify-content-center">
        <Row xs={1} sm={2} lg={3} xl={4} className="w-100 g-2">
          {map(data?.processingPartners, (p, idx) => (
            <Col key={idx}>
              <ProcessorCard processingPartner={p} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}
