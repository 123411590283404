import {
  Button,
  Loading,
  PaginationTable,
  useModal,
  useToastr,
} from '@farmshare/ui-components';
import { faPlus, faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { keyBy, mapValues } from 'lodash';
import moment from 'moment';
import { useCallback } from 'react';
import { Stack, Form } from 'react-bootstrap';

import {
  EnumNoteType,
  SortFindManyNoteInput,
  useNotePaginationLazyQuery,
  useNoteUpsertMutation,
  type Note,
  type NotePagination,
  type NotePaginationQuery,
  type NotePaginationQueryVariables,
  type NoteUpsertMutationVariables,
} from 'lib/graphql';

import { NoteForm, NoteModal } from './note-modal';
import { type ProcessingJobPage } from '../useProcessingJobPage';

interface Props {
  processingJob: ProcessingJobPage['processingJobQueryResult'];
}

export function NotesTab({ processingJob }: Props) {
  const { save, info } = useModal();
  const { push } = useToastr();

  const notePaginationLazyQuery = useNotePaginationLazyQuery({
    notifyOnNetworkStatusChange: true,
  });
  const [noteUpsert, noteUpsertOp] = useNoteUpsertMutation();

  const validate = useCallback(async (values: NoteForm) => {
    const errors: Record<string, string> = {};

    if (!values.note) {
      errors.note = 'Note content is required';
    }

    return errors;
  }, []);

  if (processingJob.loading || noteUpsertOp.loading) {
    return <Loading />;
  }

  return (
    <>
      <Stack direction="horizontal" className="justify-content-between">
        <div>
          <h2 className="fw-bold">Notes</h2>
        </div>
      </Stack>
      <PaginationTable<
        Note,
        NotePagination,
        NotePaginationQuery,
        NotePaginationQueryVariables,
        SortFindManyNoteInput
      >
        enableSearchParams={false}
        paginationQuery={notePaginationLazyQuery}
        defaultSort={SortFindManyNoteInput.UpdatedatDesc}
        columns={[
          {
            label: 'Note',
            field: 'note',
            formatter: (row) => (
              <div>
                {row.note.slice(0, 60)}
                {row.note.length > 60 && '...'}
              </div>
            ),
          },
          {
            label: 'Created by',
            field: 'createdBy',
            formatter: (row) => (
              <span>
                {row.createdBy?.first_name} {row.createdBy?.last_name}
              </span>
            ),
          },
          {
            label: 'Created at',
            field: 'createdAt',
            formatter: (row) => (
              <span>{moment(row.createdAt).format('MM/DD/YYYY hh:mm A')}</span>
            ),
          },
          {
            label: 'Updated at',
            field: 'updatedAt',
            formatter: (row) => (
              <span>{moment(row.updatedAt).format('MM/DD/YYYY hh:mm A')}</span>
            ),
          },
          {
            formatter: (row) => (
              <Stack direction="horizontal" className="justify-content-end">
                <Button
                  content="View"
                  icon={faEye}
                  size="sm"
                  isLoading={noteUpsertOp.loading}
                  disabled={noteUpsertOp.loading}
                  className="w-100 fw-bold me-2"
                  onClick={() => {
                    info({
                      type: 'info',
                      icon: faEye,
                      title: 'View Note',
                      size: 'xl',
                      body: (
                        <Form.Control
                          as="textarea"
                          value={row.note}
                          rows={10}
                          readOnly
                        />
                      ),
                    });
                  }}
                />
                <Button
                  content="Edit"
                  icon={faEdit}
                  size="sm"
                  isLoading={noteUpsertOp.loading}
                  disabled={noteUpsertOp.loading}
                  className="w-100 fw-bold me-2"
                  onClick={() => {
                    save<NoteForm>({
                      type: 'save',
                      title: 'Edit Note',
                      icon: faEdit,
                      size: 'xl',
                      body: (formik) => <NoteModal formik={formik} />,
                      initialValues: {
                        note: row.note,
                      },
                      onSubmit: async ({ note }) => {
                        const variables: NoteUpsertMutationVariables = {
                          noteId: row._id,
                          note,
                        };

                        await noteUpsert({ variables });
                        push({
                          title: 'Save Successful',
                          bg: 'primary',
                          body: 'Note successfully saved.',
                          delay: 4000,
                        });
                        notePaginationLazyQuery[1].refetch();
                      },
                      validate,
                    });
                  }}
                />
              </Stack>
            ),
          },
        ]}
        dataAccessor={(a) => a.notePagination as NotePagination}
        buildFilterQuery={(allFilters, defaultSort, page, perPage) => {
          return {
            filter: {
              ...mapValues(keyBy(allFilters, (k) => k.value)),
              processorScheduling:
                processingJob.data?.findProcessorSchedulingById?._id,
            },
            sort: defaultSort,
            page,
            perPage,
          };
        }}
        actionButtons={(queryOp) => [
          {
            content: 'Add a Note',
            icon: faPlus,
            onClick: () =>
              save<NoteForm>({
                type: 'save',
                title: 'New Note',
                icon: faPlus,
                size: 'xl',
                body: (formik) => <NoteModal formik={formik} />,
                initialValues: {
                  note: '',
                },
                onSubmit: async ({ note }) => {
                  const variables: NoteUpsertMutationVariables = {
                    note,
                    type: EnumNoteType.ProcessorScheduling,
                    processorScheduling:
                      processingJob.data?.findProcessorSchedulingById?._id,
                  };

                  await noteUpsert({ variables });
                  push({
                    title: 'Save Successful',
                    bg: 'primary',
                    body: 'Note successfully saved.',
                    delay: 4000,
                  });
                  queryOp.refetch();
                },
                validate,
              }),
          },
        ]}
      />
    </>
  );
}
