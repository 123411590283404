import { PageTitle } from '@farmshare/ui-components';
import { useCallback, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { CreateScheduling } from './_views/create-scheduling';

export default function SchedulingCreatePage() {
  const navigate = useNavigate();
  const { vendorId, animalType, date } = useParams();
  const [vendorName, setVendorName] = useState('');
  const handleCancel = useCallback(() => navigate(-1), [navigate]);

  return (
    <div className="pt-3 pt-lg-4">
      <PageTitle
        title="Schedule New Processing"
        innerBreadcrumbs={[
          { text: 'Scheduling', to: '/scheduling' },
          {
            text: vendorName,
            to: `/scheduling/${vendorId}`,
          },
        ]}
      />
      <Container>
        <CreateScheduling
          animalSpecies={animalType}
          date={date}
          vendorId={vendorId}
          setVendorName={setVendorName}
          onCancel={handleCancel}
        />
      </Container>
    </div>
  );
}
