import {
  // Button,
  InputCheck,
  InputSelect,
  InputText,
  InputTextArea,
} from '@farmshare/ui-components';
import { map } from 'lodash';
import { Col, Row } from 'react-bootstrap';

import { EnumIntegrationName, EnumProductStock_Status } from 'lib/graphql';

export interface ProductFormForm {
  name: string;
  enabled: boolean;
  shortDescription: string;
  description: string;
  price: number;
  weight: number;
  dimensions?: {
    length?: number;
    width?: number;
    height?: number;
  };
  stockStatus: EnumProductStock_Status;
  stockQuantity: number;
  // images?: string[]; // { src?: string; name?: string }[];
}

// Currently we only differentiate for a shopify type product vs all others. Thank you shopify
const ShopifyProductForm = () => {
  return (
    <Row className="g-3">
      <Col md={12} lg={5}>
        <InputText label="Name" type="text" floatingLabel required />
      </Col>
      <Col xs={9} lg={5}>
        <InputText label="Short Description" type="text" floatingLabel />
      </Col>
      <Col xs={3} lg={2}>
        <InputCheck label="Enabled" />
      </Col>
      <Col xs={12}>
        <InputTextArea
          label="Description"
          rows={2}
          floatingLabel
          showLabel
          required
        />
      </Col>
      <Col xs={4}>
        <InputText
          label="Length"
          nameOveride="dimensions.length"
          type="number"
          floatingLabel
        />
      </Col>
      <Col xs={4}>
        <InputText
          label="Width"
          nameOveride="dimensions.width"
          type="number"
          floatingLabel
        />
      </Col>
      <Col xs={4}>
        <InputText
          label="Height"
          nameOveride="dimensions.height"
          type="number"
          floatingLabel
        />
      </Col>
    </Row>
  );
};

const DefaultProductForm = () => {
  return (
    <Row className="g-3">
      <Col md={12} lg={5}>
        <InputText label="Name" type="text" floatingLabel required />
      </Col>
      <Col xs={9} lg={5}>
        <InputText label="Short Description" type="text" floatingLabel />
      </Col>
      <Col xs={3} lg={2}>
        <InputCheck label="Enabled" />
      </Col>
      <Col xs={12}>
        <InputTextArea
          label="Description"
          rows={2}
          floatingLabel
          showLabel
          required
        />
      </Col>
      <Col xs={6}>
        <InputText label="Price" type="number" floatingLabel required />
      </Col>
      <Col xs={6}>
        <InputText label="Weight" type="number" floatingLabel />
      </Col>
      <Col xs={4}>
        <InputText
          label="Length"
          nameOveride="dimensions.length"
          type="number"
          floatingLabel
        />
      </Col>
      <Col xs={4}>
        <InputText
          label="Width"
          nameOveride="dimensions.width"
          type="number"
          floatingLabel
        />
      </Col>
      <Col xs={4}>
        <InputText
          label="Height"
          nameOveride="dimensions.height"
          type="number"
          floatingLabel
        />
      </Col>
      <Col xs={6}>
        <InputSelect
          label="Stock Status"
          options={map(EnumProductStock_Status, (value, label) => ({
            label,
            value,
          }))}
          floatingLabel
          required
        />
      </Col>
      <Col xs={6}>
        <InputText
          label="Stock Quantity"
          type="number"
          floatingLabel
          required
        />
      </Col>
    </Row>
  );
};

export function ProductForm({
  integrationName,
}: {
  integrationName: EnumIntegrationName | undefined;
}) {
  if (integrationName === EnumIntegrationName.Shopify) {
    return <ShopifyProductForm />;
  }

  return <DefaultProductForm />;
}
