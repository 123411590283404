import {
  Button,
  InputChipOption,
  Loading,
  PaginationTable,
  useModal,
  useToastr,
} from '@farmshare/ui-components';
import {
  formatToCurrency,
  animalSplitTypeHelper,
  ALL_ANIMAL_SPLITS,
  animalSpeciesHelper,
  inspectionLevelHelper,
} from '@farmshare/utils';
import { faGear, faPlus, faStore } from '@fortawesome/free-solid-svg-icons';
import {
  camelCase,
  every,
  filter,
  find,
  flatMap,
  forEach,
  get,
  has,
  keyBy,
  map,
  mapValues,
  omit,
  partialRight,
  reduce,
  set,
  startCase,
} from 'lodash';
import { useMemo } from 'react';
import { Dropdown, Stack } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { vendorState } from 'state';

import { CutsheetTooltip } from 'components/cutsheet-tooltip/cutsheet-tooltip';

import {
  EnumCutsheetAnimalSpecies,
  EnumCutsheetInspectionLevels,
  EnumCutsheetType,
  EnumPrimalCutType,
  EnumProcessorCapabilityAnimalSpecies,
  EnumProcessorCapabilityInspectionLevel,
  ProcessorCapability,
  ProcessorCapabilityExtras,
  SortFindManyCutsheetInput,
  useCutsheetPaginationLazyQuery,
  useCutsheetUpsertMutation,
  useProcessorCapabilityUpsertMutation,
  ViewProcessorAdminDocument,
  type Cutsheet,
  type CutsheetPagination,
  type CutsheetPaginationQuery,
  type CutsheetPaginationQueryVariables,
  type CutsheetUpsertMutationVariables,
  type Maybe,
  type ViewProcessorAdminQueryResult,
} from 'lib/graphql';

import {
  CapabilitiesModal,
  type CapabilitesModalForm,
} from './_views/capabilites-modal';
import { CutsheetButtons } from './_views/cutsheet-buttons';
import {
  CutsheetModal,
  ExtrasModel,
  ExtrasTrimOptionsType,
  TrimModel,
  type NewCutsheetForm,
} from './_views/cutsheet-modal';
import { ExtrasTrimModel } from './_views/extras-trim-section';

interface ProcessorCutsheetsProps {
  settings?: Maybe<ViewProcessorAdminQueryResult>;
}

export default function ProcessorCutsheets({
  settings,
}: ProcessorCutsheetsProps) {
  const { save } = useModal();
  const { push } = useToastr();

  const vendor = useRecoilValue(vendorState)?._id;

  const cutsheetPaginationLazyQuery = useCutsheetPaginationLazyQuery({
    notifyOnNetworkStatusChange: true,
  });
  const [upsertCapability, upsertCapabilityOp] =
    useProcessorCapabilityUpsertMutation({
      refetchQueries: [{ query: ViewProcessorAdminDocument }],
    });
  const [upsertCutSheet, upsertCutSheetOp] = useCutsheetUpsertMutation({
    refetchQueries: [{ query: ViewProcessorAdminDocument }],
  });

  const primalGroupOptions = useMemo(() => {
    const enabledAnimals = filter(
      settings?.data?.processorSettingsOne?.animalSettings,
      { isEnabled: true },
    );

    return flatMap(enabledAnimals, (animalSetting) =>
      map(animalSetting?.inspectionLevels, (inspectionLevel) => {
        return {
          species: animalSetting?.species,
          inspectionLevel: inspectionLevel ?? undefined,
        };
      }),
    );
  }, [settings?.data?.processorSettingsOne?.animalSettings]);

  const extrasTrimOptions = useMemo(() => {
    return reduce(
      settings?.data?.processorSettingsOne?.animalSettings,
      (acc, animalSetting) => {
        const species = animalSetting?.species;
        forEach(animalSetting?.inspectionLevels, (inspectionLevel) => {
          const capabilities = find(settings?.data?.processorCapabilityMany, {
            animalSpecies: animalSetting?.species,
            inspectionLevel,
          });
          const extras = map(
            filter(capabilities?.extras, (extra) => extra?.isActive),
            // Something with lodash lately has been causing it to incorrectly infer types it keeps thinkng things are <TheActualType> | number.
            // I can't figure out why it thinks things can be numbers.
            (extra: ProcessorCapabilityExtras) => ({
              name: extra?.name,
              pricePerPound: extra.pricePerPound,
              minLbs: extra.minLbs,
              isActive: false,
              quantity: 0,
            }),
          );
          const trim = map(
            filter(capabilities?.trim, (trim) => trim?.isActive),
            // Something with lodash lately has been causing it to incorrectly infer types it keeps thinkng things are <TheActualType> | number.
            // I can't figure out why it thinks things can be numbers.
            (trim: ProcessorCapabilityExtras) => ({
              name: trim?.name,
              pricePerPound: trim.pricePerPound,
              minLbs: trim.minLbs,
              isActive: false,
              quantity: 0,
              rank: 1,
            }),
          );
          if (species) {
            set(acc, `extras[${species}][${inspectionLevel}]`, extras);
            set(acc, `trim[${species}][${inspectionLevel}]`, trim);
          }
        });

        return acc;
      },
      { extras: {}, trim: {} } as ExtrasTrimOptionsType,
    );
  }, [
    settings?.data?.processorCapabilityMany,
    settings?.data?.processorSettingsOne?.animalSettings,
  ]);

  if (
    settings?.loading ||
    upsertCapabilityOp.loading ||
    upsertCutSheetOp.loading
  ) {
    return <Loading />;
  }

  return (
    <>
      <Stack direction="horizontal" className="justify-content-between">
        <div>
          <h2 className="fw-bold">Manage Cutsheets</h2>
          <p className="mb-0">
            Create and edit all your cutsheets here that will be available for
            your customers.
          </p>
        </div>
        <Dropdown className="mb-auto">
          <Dropdown.Toggle
            as={Button}
            icon={faGear}
            content="Set Capabilities"
          />
          <Dropdown.Menu>
            <Dropdown.Header>Animals</Dropdown.Header>
            {map(primalGroupOptions, (option) => {
              if (!option.species || !option.inspectionLevel) {
                return;
              }

              if (option.species && option.inspectionLevel) {
                const speciesOption = animalSpeciesHelper(option.species);
                const inspectionLevelOption = inspectionLevelHelper(
                  option.inspectionLevel,
                );
                const capability = find(
                  settings?.data?.processorCapabilityMany,
                  (pc) =>
                    pc.animalSpecies === option.species &&
                    pc.inspectionLevel === option.inspectionLevel,
                );

                const initialValues: CapabilitesModalForm = {
                  ...reduce(
                    capability?.cuts,
                    (acc, primalCut) => {
                      let _name = primalCut.primal?.name;
                      if (primalCut.type !== EnumPrimalCutType.Other) {
                        _name += ` ${startCase(primalCut.type)}s`;
                      }
                      _name = camelCase(_name);

                      const option: InputChipOption = {
                        label: primalCut.name,
                        value: primalCut._id,
                      };
                      if (has(acc, _name)) {
                        const group = get(acc, _name);
                        group.push(option);
                      } else {
                        set(acc, _name, [option]);
                      }

                      return acc;
                    },
                    {},
                  ),
                  // remove __typename from extras and trim
                  extras:
                    (map(
                      capability?.extras,
                      partialRight(omit, ['__typename']),
                    ) as ExtrasTrimModel[]) || [],
                  trim:
                    (map(
                      capability?.trim,
                      partialRight(omit, ['__typename']),
                    ) as ExtrasTrimModel[]) || [],
                };

                return (
                  <Dropdown.Item
                    key={`${option.inspectionLevel}_${option.species}`}
                    onClick={() =>
                      save<CapabilitesModalForm>({
                        type: 'save',
                        title: `${inspectionLevelOption.label} ${speciesOption.label} Capabilities`,
                        icon: faStore,
                        size: 'xl',
                        body: (
                          <CapabilitiesModal
                            animalSpecies={option.species}
                            inspectionLevel={option.inspectionLevel}
                          />
                        ),
                        initialValues,
                        onSubmit: async (values) => {
                          await upsertCapability({
                            variables: {
                              primalCuts: map(
                                flatMap(
                                  filter(
                                    values,
                                    (value) =>
                                      Array.isArray(value) &&
                                      every(value, 'value'),
                                  ),
                                ),
                                (v) => v?.value,
                              ),
                              animalSpecies:
                                option.species as unknown as EnumProcessorCapabilityAnimalSpecies,
                              inspectionLevel:
                                option.inspectionLevel as unknown as EnumProcessorCapabilityInspectionLevel,
                              extras: values.extras,
                              trim: values.trim,
                            },
                          });
                          push({
                            title: 'Save Successful',
                            bg: 'primary',
                            body: 'Capabilities successfully saved.',
                            delay: 4000,
                          });
                        },
                      })
                    }
                  >
                    {inspectionLevelOption.label} {speciesOption.label}
                  </Dropdown.Item>
                );
              }
            })}
          </Dropdown.Menu>
        </Dropdown>
      </Stack>
      <hr />
      <PaginationTable<
        Cutsheet,
        CutsheetPagination,
        CutsheetPaginationQuery,
        CutsheetPaginationQueryVariables,
        SortFindManyCutsheetInput
      >
        enableSearchParams={false}
        paginationQuery={cutsheetPaginationLazyQuery}
        defaultSort={SortFindManyCutsheetInput.IdAsc}
        filters={[{ label: 'Archived', value: 'isDeleted' }]}
        defaultFilters={[]}
        columns={[
          {
            label: 'Name',
            field: 'name',
            formatter: (row) => (
              <div>
                {row.name}
                <CutsheetTooltip cutsheet={row} />
              </div>
            ),
          },
          {
            label: 'Type',
            field: 'animalType',
            formatter: (row) => animalSpeciesHelper(row.animalSpecies).label,
          },
          {
            label: 'Inspection',
            field: 'inspectionLevels',
            formatter: (row) => (
              <span>
                {map(
                  row.inspectionLevels,
                  (o) => inspectionLevelHelper(o ?? 'N/A').label,
                ).join(', ')}
              </span>
            ),
          },
          {
            label: 'Price/lb.',
            field: 'pricePerPound',
            formatter: (row) => formatToCurrency(row.pricePerPound || 0),
          },
          {
            label: 'Steaks/Pack',
            field: 'steaksPerPack',
            minimumBreakpoint: 'lg',
          },
          {
            label: 'Steak Thickness',
            field: 'steakThickness',
            formatter: (row) =>
              row.steakThickness ? (
                <span>
                  {row.steakThickness}&nbsp;
                  <span className="text-muted small">(in.)</span>
                </span>
              ) : null,
            minimumBreakpoint: 'lg',
          },
          {
            label: 'Ground Meat Size',
            field: 'groundMeatSize',
            formatter: (row) => (
              <span>
                {row.groundMeatSize}&nbsp;
                <span className="text-muted small">(lb.)</span>
              </span>
            ),
            minimumBreakpoint: 'md',
          },
          {
            label: 'Split Types',
            field: 'splitTypes',
            formatter: (row) => (
              <span>
                {row.splitTypes
                  ?.map((split) => animalSplitTypeHelper(split as string).label)
                  .join(', ') ?? 'None'}
              </span>
            ),
          },
          {
            formatter: (row) => (
              <CutsheetButtons
                cutsheet={row}
                refetch={cutsheetPaginationLazyQuery[1].refetch}
              />
            ),
          },
        ]}
        dataAccessor={(a) => a.cutsheetPagination as CutsheetPagination}
        buildFilterQuery={(allFilters, defaultSort, page, perPage) => {
          return {
            filter: {
              ...mapValues(
                keyBy(allFilters, (k) => k.value),
                (v) => v.isActive,
              ),
              vendor,
            },
            sort: defaultSort,
            page,
            perPage,
          };
        }}
        actionButtons={(queryOp) => [
          {
            content: 'Add a Cutsheet',
            icon: faPlus,
            onClick: () =>
              save<NewCutsheetForm>({
                type: 'save',
                title: 'New Cutsheet',
                icon: faPlus,
                fullscreen: true,
                body: (fP) => (
                  <CutsheetModal fP={fP} settings={settings?.data} />
                ),
                initialValues: {
                  animalSpecies: EnumCutsheetAnimalSpecies.Beef,
                  inspectionLevel: EnumCutsheetInspectionLevels.State,
                  name: '',
                  pricePerPound: 0,
                  groundMeatSize: '1',
                  cuts: [],
                  splitTypes: map(ALL_ANIMAL_SPLITS, (split) => ({
                    label: split,
                    value: split,
                  })),
                  extras: extrasTrimOptions.extras,
                  trim: extrasTrimOptions.trim,
                },
                onSubmit: async ({
                  animalSpecies,
                  inspectionLevel,
                  cuts,
                  splitTypes,
                  name,
                  roastSize,
                  steaksPerPack,
                  groundMeatSize,
                  steakThickness,
                  pricePerPound = 0,
                  extras,
                  trim,
                }) => {
                  const formattedExtras = inspectionLevel
                    ? (map(
                        filter(
                          extras?.[animalSpecies]?.[inspectionLevel],
                          (extra) => extra.isActive,
                        ) || [],
                        partialRight(omit, ['__typename']),
                      ) as ExtrasModel[])
                    : [];

                  const formattedTrim = inspectionLevel
                    ? (map(
                        filter(
                          trim?.[animalSpecies]?.[inspectionLevel],
                          (trim) => trim.isActive,
                        ) || [],
                        partialRight(omit, ['__typename']),
                      ) as TrimModel[])
                    : [];
                  const variables: CutsheetUpsertMutationVariables = {
                    primalCuts: map(cuts, (c) => c.value),
                    splitTypes: map(splitTypes, (st) => st.value),
                    type: EnumCutsheetType.Vendor,
                    groundMeatSize: parseFloat(groundMeatSize),
                    animalSpecies,
                    inspectionLevels: inspectionLevel ? [inspectionLevel] : [],
                    name,
                    pricePerPound,
                    roastSize,
                    extras: formattedExtras,
                    trim: formattedTrim,
                  };

                  if (steaksPerPack) {
                    variables.steaksPerPack = parseInt(steaksPerPack);
                  }
                  if (steakThickness) {
                    variables.steakThickness = parseFloat(steakThickness);
                  }
                  await upsertCutSheet({ variables });
                  push({
                    title: 'Save Successful',
                    bg: 'primary',
                    body: 'Cutsheet successfully saved.',
                    delay: 4000,
                  });
                  queryOp.refetch();
                },
                validate: async (values) => {
                  const errors: Record<string, any> = {};

                  if (values.splitTypes.length === 0) {
                    errors.splitTypes = 'At least one Split type is required';
                  }

                  if (!values.inspectionLevel) {
                    set(
                      errors,
                      'inspectionLevel',
                      'Please select at least one inspection level.',
                    );
                  }
                  // check if rank is unique for active trims
                  const currentTrim = values.inspectionLevel
                    ? values.trim?.[values.animalSpecies]?.[
                        values.inspectionLevel
                      ] ?? []
                    : [];
                  if (currentTrim.length > 0) {
                    const animalType = values.animalSpecies;

                    for (const trim of currentTrim) {
                      const ranks = map(
                        filter(currentTrim, (t) => t.isActive),
                        (t) => t.rank,
                      );

                      if (
                        ranks.length !== new Set(ranks).size &&
                        values.inspectionLevel
                      ) {
                        errors.trim = {
                          ...errors?.trim,
                          [animalType]: {
                            ...errors?.trim?.[animalType],
                            [values.inspectionLevel]: map(currentTrim, (t) => ({
                              rank: 'Rank must be unique',
                            })),
                          },
                        };
                      }
                    }
                  }

                  // check if rank is greater than 0 for active trims
                  if (currentTrim.length > 0 && values.inspectionLevel) {
                    const animalType = values.animalSpecies;

                    for (let i = 0; i < currentTrim.length; i++) {
                      if (currentTrim[i].isActive && currentTrim[i].rank < 1) {
                        const trimErrors =
                          errors.trim?.[animalType][values.inspectionLevel] ||
                          [];

                        trimErrors[i] = { rank: 'Rank must be greater than 0' };
                        errors.trim = {
                          ...errors?.trim,
                          [animalType]: {
                            [values.inspectionLevel]: trimErrors,
                          },
                        };
                      }
                    }
                  }

                  return errors;
                },
              }),
          },
        ]}
      />
    </>
  );
}
