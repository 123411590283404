import { InfoBox, InputChips, Loading } from '@farmshare/ui-components';
import {
  assign,
  camelCase,
  groupBy,
  map,
  orderBy,
  reduce,
  startCase,
} from 'lodash';
import { useCallback } from 'react';
import { Card, Col, Row, Stack } from 'react-bootstrap';

import {
  EnumPrimalCutType,
  EnumPrimalGroupAnimalSpecies,
  EnumPrimalGroupInspectionLevel,
  EnumProcessorSettingsAnimalSettingsInspectionLevels,
  EnumProcessorSettingsAnimalSettingsSpecies,
  type Primal,
  type PrimalCut,
  usePrimalGroupManyQuery,
} from 'lib/graphql';

import {
  ExtrasTrimSection,
  ExtrasTrimSectionForm,
} from './extras-trim-section';

export type CapabilitesModalForm = {
  [x: string]: any;
} & ExtrasTrimSectionForm;

interface CapabilitiesModalProps {
  animalSpecies?: EnumProcessorSettingsAnimalSettingsSpecies;
  inspectionLevel?: EnumProcessorSettingsAnimalSettingsInspectionLevels;
}

export function CapabilitiesModal({
  animalSpecies,
  inspectionLevel,
}: CapabilitiesModalProps) {
  const { data, loading } = usePrimalGroupManyQuery({
    variables: {
      filter: {
        animalSpecies: animalSpecies as unknown as EnumPrimalGroupAnimalSpecies,
        inspectionLevel:
          inspectionLevel as unknown as EnumPrimalGroupInspectionLevel,
      },
    },
  });

  const renderCuts = useCallback(
    (cuts: PrimalCut[], name: string) => (
      <div key={name}>
        <div className="mb-1">
          Do you offer <span className="fw-bold fs-5">{name}</span>?
        </div>
        <InputChips
          nameOveride={camelCase(name)}
          options={map(orderBy(cuts, 'order'), (cut) => ({
            label: cut.name,
            value: cut._id,
          }))}
          size="sm"
        />
      </div>
    ),
    [],
  );

  const renderSection = useCallback(
    (label: string, primals: Pick<Primal, 'name' | 'cuts'>[]) => {
      const primalCutTypes: Record<string, PrimalCut[]> = reduce(
        orderBy(primals, 'order'),
        (acc, primal) => {
          const groups = groupBy(primal.cuts, (c) => {
            let _name = primal.name;
            if (c.type !== EnumPrimalCutType.Other) {
              _name += ` ${startCase(c.type)}s`;
            }
            return _name;
          });
          return assign(acc, groups);
        },
        {},
      );

      return (
        <Col key={label}>
          <Card className="h-100" body>
            <Stack gap={2}>
              <div className="fw-bold fs-4">{label}</div>
              {map(primalCutTypes, renderCuts)}
            </Stack>
          </Card>
        </Col>
      );
    },
    [renderCuts],
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <InfoBox
        content={
          <>
            Please select all cuts that you are able to process. Any unselected
            cuts will default to ground meat.
          </>
        }
      />
      <Row xs={1} lg={2} xl={3} className="g-2">
        {map(orderBy(data?.primalGroupMany, 'order'), (primalGroup) =>
          renderSection(primalGroup.name, primalGroup.primals),
        )}
        <ExtrasTrimSection />
      </Row>
    </>
  );
}
