import {
  Button,
  DataDetailListMinimal,
  InputCheck,
  InputPhone,
  InputText,
  MoneyDisplay,
  PhoneDisplay,
  useModal,
  useToastr,
} from '@farmshare/ui-components';
import { animalSplitPartHelper, formatFullName } from '@farmshare/utils';
import {
  faEdit,
  faEnvelope,
  faFileDownload,
  faFileEdit,
} from '@fortawesome/free-solid-svg-icons';
import { useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';
import { Alert, AlertHeading, Card, Col, Row, Stack } from 'react-bootstrap';

import { downloadFromBase64Encoded } from 'lib/downloadFromBase64Encoded';
import { downlaodFromPresignedUrl } from 'lib/downloadFromPresignedUrl';
import {
  CutsheetInformationFragment,
  EnumUserRole,
  ProcessingJobByIdDocument,
  useProcessorSchedulingGenerateCutsheetRequestMutation,
  useProcessorSchedulingNotificationSendMutation,
} from 'lib/graphql';

import { AnimalHeadDetailsSectionProps } from '../animal-head';

interface EditContactDetailsFormData {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  changeUser?: boolean;
}

const EditContactDetailsForm = ({ canEdit }: { canEdit: boolean }) => {
  const { values } = useFormikContext<EditContactDetailsFormData>();

  return (
    <Stack gap={3}>
      {!canEdit && (
        <>
          <Row>
            <Col>
              <Alert variant="warning">
                <AlertHeading>
                  <div>Unable to edit user information</div>
                </AlertHeading>
                <p>
                  The contact user doesn't allow updating their information. You
                  may only change the user to a new one.
                </p>
              </Alert>
            </Col>
          </Row>

          <Row>
            <Col>
              <InputCheck
                type="switch"
                inline
                label="Would you like to change this user?"
                nameOveride="changeUser"
              />
            </Col>
          </Row>
        </>
      )}

      <Row>
        <Col className="px-2 pb-3">
          <InputText
            type="text"
            label="First Name"
            floatingLabel
            nameOveride="firstName"
            disabled={!canEdit && !values.changeUser}
          />
        </Col>
      </Row>
      <Row>
        <Col className="px-2 pb-3">
          <InputText
            type="text"
            label="Last Name"
            floatingLabel
            nameOveride="lastName"
            disabled={!canEdit && !values.changeUser}
          />
        </Col>
      </Row>
      <Row>
        <Col className="px-2 pb-3">
          <InputPhone
            label="Phone"
            floatingLabel
            nameOveride="phone"
            disabled={!canEdit && !values.changeUser}
            required={!values.email}
          />
        </Col>
      </Row>
      <Row>
        <Col className="px-2 pb-3">
          <InputText
            type="email"
            label="Email"
            floatingLabel
            nameOveride="email"
            disabled={!canEdit && !values.changeUser}
            required={!values.phone}
          />
        </Col>
      </Row>
    </Stack>
  );
};

interface Props extends AnimalHeadDetailsSectionProps {
  cutsheetInformation?: CutsheetInformationFragment | null;
  cutsheetInformationIdx: number;
}

export function AnimalHeadCutsheetInformation({
  processingJobId,
  animalHead,
  cutsheetInformation,
  cutsheetInformationIdx,
  updateAnimalHead,
}: Props) {
  const { save, ask } = useModal();
  const { push } = useToastr();

  const [sendNotification, sendNotificationOp] =
    useProcessorSchedulingNotificationSendMutation({
      refetchQueries: [
        {
          query: ProcessingJobByIdDocument,
          variables: {
            jobId: processingJobId,
            includeNotificationHistory: true,
            includeProcessorSettings: true,
          },
        },
      ],
    });

  const [generateCutsheetPdf, generateCutsheetPdfOp] =
    useProcessorSchedulingGenerateCutsheetRequestMutation();

  const heading = useMemo(() => {
    const splitPart = animalSplitPartHelper(
      cutsheetInformation?.splitPart ?? 'N/A',
    );

    if (animalHead?.splitType === 'half') {
      if (splitPart.value === 'half') {
        return `${splitPart.label} #${cutsheetInformationIdx + 1}`;
      }
    }
    if (animalHead?.splitType === 'quarters') {
      if (splitPart.value === 'quarter') {
        return `${splitPart.label} #${cutsheetInformationIdx + 1}`;
      }
    }

    if (animalHead?.splitType === 'half_and_two_quarters') {
      if (splitPart.value === 'half') {
        return `${splitPart.label} #1`;
      }
      if (splitPart.value === 'quarter') {
        return `${splitPart.label} #${cutsheetInformationIdx}`;
      }
    }

    return splitPart.label;
  }, [
    cutsheetInformation?.splitPart,
    cutsheetInformationIdx,
    animalHead?.splitType,
  ]);

  const handleViewCutsheet = useCallback(async () => {
    if (processingJobId && cutsheetInformation?._id) {
      try {
        const response = await generateCutsheetPdf({
          variables: {
            processorSchedulingId: processingJobId,
            cutsheetRequestId: cutsheetInformation._id,
          },
        });

        if (
          response.data?.processorSchedulingGenerateCutsheetRequest?.fileUrl
        ) {
          downlaodFromPresignedUrl(
            response.data?.processorSchedulingGenerateCutsheetRequest?.fileUrl,
          );
        } else if (
          response.data?.processorSchedulingGenerateCutsheetRequest?.file
        ) {
          downloadFromBase64Encoded(
            response.data?.processorSchedulingGenerateCutsheetRequest.file,
            response.data?.processorSchedulingGenerateCutsheetRequest
              ?.filename ?? 'Cutsheet.pdf',
          );
        }
      } catch (error) {
        push({
          title: 'Error',
          body: (error as string).toString(),
          bg: 'danger',
          delay: 4000,
        });
      }
    }
  }, [processingJobId, cutsheetInformation?._id, generateCutsheetPdf, push]);

  const handleEditCutsheet = useCallback(() => {
    if (processingJobId) {
      window.open(`/scheduling/${processingJobId}/cutsheets`, '_blank');
    }
  }, [processingJobId]);

  const handleEditContact = useCallback(() => {
    const initialValues = {
      firstName: cutsheetInformation?.contactUser?.first_name,
      lastName: cutsheetInformation?.contactUser?.last_name ?? undefined,
      phone: cutsheetInformation?.contactUser?.phone ?? undefined,
      email: cutsheetInformation?.contactUser?.email ?? undefined,
    };

    return save<EditContactDetailsFormData>({
      type: 'save',
      title: 'Edit Contact Details',
      icon: faFileEdit,
      initialValues: { ...initialValues },
      body: (
        <EditContactDetailsForm
          canEdit={
            cutsheetInformation?.contactUser?.role === EnumUserRole.Basic
          }
        />
      ),
      onSubmit: async (values) => {
        await updateAnimalHead({
          variables: {
            processorSchedulingId: processingJobId,
            animalHeadId: animalHead?._id,
            record: {
              cutsheetInformation: {
                _id: cutsheetInformation?._id,
                contactUser: {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  phone: values.phone,
                  email: values.email,
                },
              },
            },
          },
        });

        push({
          title: 'Processing Job Updated',
          body: `The contact user has been updated on the processing job.`,
          bg: 'success',
          delay: 4000,
        });
      },
    });
  }, [
    save,
    cutsheetInformation?.contactUser,
    cutsheetInformation?._id,
    processingJobId,
    animalHead?._id,
    updateAnimalHead,
    push,
  ]);

  const handleSendCutsheetEmail = useCallback(() => {
    const fullName = formatFullName(
      cutsheetInformation?.contactUser?.first_name ?? '',
      cutsheetInformation?.contactUser?.last_name,
    );

    return ask({
      type: 'ask',
      title: cutsheetInformation?.contactUser?.email
        ? 'Confirm Email Send'
        : 'Confirm SMS Send',
      body: `Are you sure you want to send the add cutsheet notification to ${fullName} at ${
        cutsheetInformation?.contactUser?.email
          ? cutsheetInformation.contactUser.email
          : cutsheetInformation?.contactUser?.phone
      }`,
      onConfirm: async () => {
        await sendNotification({
          variables: {
            type: 'addCutsheets',
            notificationRequest: {
              scheduledJobId: processingJobId,
              contactUserId: cutsheetInformation?.contactUser?._id,
            },
          },
        });
        push({
          title: 'Notification Sent',
          body: `The add cutsheet notification has been sent.`,
          bg: 'success',
          delay: 4000,
        });
      },
    });
  }, [
    ask,
    cutsheetInformation?.contactUser,
    sendNotification,
    processingJobId,
    push,
  ]);

  return (
    <Card className="h-100">
      <Card.Header className="d-flex align-items-center">
        <span className="fw-bold fs-5">{heading}</span>
      </Card.Header>
      <Card.Body>
        <div className="d-block text-break">
          <DataDetailListMinimal
            heading={
              <Stack direction="horizontal" className="d-flex">
                <div>Contact Information</div>
                <Stack gap={1} direction="horizontal" className="ms-auto">
                  {(cutsheetInformation?.contactUser?.email ||
                    cutsheetInformation?.contactUser?.phone) && (
                    <Button
                      size="sm"
                      content="Send Cutsheet Reminder"
                      onClick={handleSendCutsheetEmail}
                      icon={faEnvelope}
                    />
                  )}

                  <Button
                    size="sm"
                    content="Edit Contact"
                    className="ms-auto"
                    onClick={handleEditContact}
                    icon={faEdit}
                  />
                </Stack>
              </Stack>
            }
            rows={[
              {
                label: 'Name',
                value: formatFullName(
                  cutsheetInformation?.contactUser?.first_name ?? '',
                  cutsheetInformation?.contactUser?.last_name,
                ),
              },
              {
                label: 'Phone',
                value: cutsheetInformation?.contactUser?.phone ? (
                  <PhoneDisplay phone={cutsheetInformation.contactUser.phone} />
                ) : undefined,
              },
              {
                label: 'Email',
                value: cutsheetInformation?.contactUser?.email,
              },
            ]}
          />
        </div>
      </Card.Body>
      <Card.Body>
        <div className="d-block text-break">
          <DataDetailListMinimal
            heading={
              <Stack direction="horizontal" className="d-flex">
                <div>Cutsheet Information</div>
                <Stack gap={1} direction="horizontal" className="ms-auto">
                  <Button
                    size="sm"
                    content="Download Cutsheet"
                    disabled={
                      !cutsheetInformation?.cutsheet?._id ||
                      generateCutsheetPdfOp.loading
                    }
                    isLoading={generateCutsheetPdfOp.loading}
                    onClick={handleViewCutsheet}
                    icon={faFileDownload}
                  />
                  <Button
                    size="sm"
                    content="Edit Cutsheet"
                    onClick={handleEditCutsheet}
                    icon={faEdit}
                  />
                </Stack>
              </Stack>
            }
            rows={[
              {
                label: 'Cutsheet',
                value: cutsheetInformation?.cutsheet?.name,
              },
              {
                label: 'Type',
                value: cutsheetInformation?.cutsheet?.type,
              },
              {
                label: 'Price/lb',
                value: cutsheetInformation?.cutsheet?.pricePerPound ? (
                  <MoneyDisplay
                    value={cutsheetInformation?.cutsheet?.pricePerPound}
                  />
                ) : undefined,
              },
              {
                label: 'Notes',
                value: cutsheetInformation?.notes,
              },
            ]}
          />
        </div>
      </Card.Body>
    </Card>
  );
}
