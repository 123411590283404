import {
  Button,
  InputSelect,
  InputText,
  type InputTextProps,
  Loading,
  InputSelectProps,
  InputComponent,
  InputEditor,
} from '@farmshare/ui-components';
import { faRocket } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'environments/environment';
import type { FormikProps } from 'formik';
import {
  assign,
  camelCase,
  every,
  forEach,
  isUndefined,
  map,
  replace,
  startCase,
} from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Container, Row, Stack } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { themeState } from 'state';

import {
  EnumCampaignType,
  // Product,
  useSegmentManyQuery,
  useProductManyLazyQuery,
  type Vendor,
  useCampaignGenerateCopyLazyQuery,
  EnumCampaignMedium,
} from 'lib/graphql';

export interface CampaignCreateModalForm
  extends Record<string, string | undefined> {
  medium?: EnumCampaignMedium;
  type?: EnumCampaignType;
  product?: string;
  segment?: string;
  subject?: string;
  body?: string;
}

export interface CampaignCreateModalProps {
  formikProps: FormikProps<CampaignCreateModalForm>;
  vendor: Vendor;
}

interface CampaignModalPrompt {
  name: string;
  text: string;
  inputs: (
    | { type: 'text'; props: InputTextProps }
    | { type: 'select'; props: InputSelectProps }
  )[];
}

export function CampaignCreateModal(props: CampaignCreateModalProps) {
  const [selectedCard, setSelectedCard] = useState<number>(0);

  const theme = useRecoilValue(themeState);

  const segmentOp = useSegmentManyQuery({});

  const [getProducts, getProductsOp] = useProductManyLazyQuery();
  const [generateCopy, generateCopyOp] = useCampaignGenerateCopyLazyQuery();

  // useEffect(() => {
  //   if (
  //     props.formikProps.values.type === EnumCampaignType.ProductSpotlight &&
  //     !getProductsOp.called
  //   ) {
  //     getProducts();
  //   }
  // }, [getProducts, getProductsOp.called, props.formikProps.values.type]);

  const prompts: CampaignModalPrompt[] = useMemo(() => {
    switch (props.formikProps.values.type) {
      case EnumCampaignType.Newsletter:
        return [
          {
            name: 'Educational',
            text: 'Write a newsletter about some common misconceptions about {{topic}}.',
            inputs: [
              {
                type: 'text',
                props: { label: 'Topic', type: 'text', max: 45 },
              },
            ],
          },
          {
            name: 'Informative',
            text: 'Write a newsletter that is informative about {{topic}}.',
            inputs: [
              {
                type: 'text',
                props: { label: 'Topic', type: 'text', max: 45 },
              },
            ],
          },
          // {
          //   name: 'Promotional',
          //   text: 'Write a promotional email about {{topic}}.',
          //   inputs: [
          //     {
          //       type: 'text',
          //       props: { label: 'Topic', type: 'text', max: 35 },
          //     },
          //     {
          //       type: 'text',
          //       props: { label: 'Coupon', type: 'text', max: 35 },
          //     },
          //   ],
          // },
        ];
      // case EnumCampaignType.ProductSpotlight:
      //   return [
      //     {
      //       name: 'Seasonal Recipe',
      //       text: 'Write a seasonal recipe for my {{product}}.',
      //       inputs: [
      //         {
      //           type: 'select',
      //           props: {
      //             label: 'Product',
      //             options: map(getProductsOp.data?.productMany, (p) => ({
      //               label: p.name || '',
      //               value: p.name || '',
      //             })),
      //           },
      //         },
      //       ],
      //     },
      //     {
      //       name: 'Health Benefits',
      //       text: 'Talk about the health benefits of my {{product}}.',
      //       inputs: [
      //         {
      //           type: 'select',
      //           props: {
      //             label: 'Product',
      //             options: map(getProductsOp.data?.productMany, (p) => ({
      //               label: p.name || '',
      //               value: p.name || '',
      //             })),
      //           },
      //         },
      //       ],
      //     },
      //   ];
      default:
        return [];
    }
  }, [props.formikProps.values.type]);

  const renderPrompt = useCallback(
    (prompt: CampaignModalPrompt, index: number) => {
      const isActive = index === selectedCard;
      return (
        <Col key={index}>
          <Card
            className={
              index === selectedCard
                ? 'border border-2 border-primary h-100'
                : 'h-100'
            }
            style={{ cursor: 'pointer' }}
            onClick={() => setSelectedCard(index)}
          >
            <Card.Body
              className={`d-flex flex-column justify-content-between ${
                isActive ? '' : 'text-muted'
              }`}
            >
              <Card.Title>{prompt.name}</Card.Title>
              <Card.Text className="flex-grow-1">
                {replace(prompt.text, /{{\w+}}/i, '..')}
              </Card.Text>
              <Stack gap={2} className="flex-grow-0">
                {map(prompt.inputs, (input, idx) => {
                  const props: InputComponent = {
                    floatingLabel: true,
                    disabled: !isActive,
                    required: isActive,
                    nameOveride: `${camelCase(input.props.label)}-${index}`,
                  };

                  switch (input.type) {
                    case 'text':
                      return (
                        <InputText {...assign(props, input.props)} key={idx} />
                      );
                    case 'select':
                      return (
                        <InputSelect
                          {...assign(props, input.props)}
                          key={idx}
                        />
                      );
                  }
                })}
              </Stack>
            </Card.Body>
          </Card>
        </Col>
      );
    },
    [selectedCard],
  );

  const command = useMemo(() => {
    if (!isUndefined(selectedCard)) {
      let _command = prompts[selectedCard].text;

      forEach(prompts[selectedCard].inputs, (i) => {
        _command = replace(
          _command,
          new RegExp(`{{${camelCase(i.props.label)}}}`, 'ig'),
          props.formikProps.values[
            `${camelCase(i.props.label)}-${selectedCard}`
          ] || '',
        );
      });

      return _command;
    }
  }, [prompts, props.formikProps.values, selectedCard]);

  const content = useMemo(
    () =>
      generateCopyOp.data?.campaignGenerateCopy?.commands
        ? generateCopyOp.data.campaignGenerateCopy.commands[0]?.text
        : '',
    [generateCopyOp.data?.campaignGenerateCopy?.commands],
  );

  if (segmentOp.loading || getProductsOp.loading) {
    return <Loading />;
  }

  return (
    <Container className="">
      <Stack gap={3} className="mt-md-3">
        <div className="fs-6">
          What kind of campaign would you like to create?
        </div>
        <Row className="g-3">
          <Col md={4}>
            <InputText
              label="Campaign Title"
              type="text"
              floatingLabel
              required
            />
          </Col>
          <Col md={4}>
            <InputSelect
              label="Type"
              options={map(EnumCampaignType, (value) => ({
                label: startCase(value),
                value,
              }))}
              floatingLabel
              required
            />
          </Col>
          <Col md={4}>
            <InputSelect
              label="Segment"
              options={map(segmentOp.data?.segmentMany, (segment) => ({
                label: segment.name,
                value: segment._id,
              }))}
              floatingLabel
              required
            />
          </Col>
        </Row>
        <hr />
        {props.formikProps.values.type && (
          <>
            <div>
              Prompts:&nbsp;
              <span className="small text-muted">(select one)</span>
            </div>
            <Row xs={1} md={3} className="g-3">
              {map(prompts, renderPrompt)}
            </Row>
            <div className="text-end">
              <Button
                content="Generate"
                icon={faRocket}
                isLoading={generateCopyOp.loading}
                onClick={() =>
                  command &&
                  every(props.formikProps.errors, (_, e) => e === 'body') &&
                  generateCopy({ variables: { command } })
                }
              />
            </div>
          </>
        )}
        {content && (
          <>
            <hr />
            {/* <Row xs={1} md={2}>
              <Col> */}
            <Stack gap={3}>
              <InputText label="Subject" type="text" floatingLabel required />
              <InputEditor
                name="body"
                apiKey={environment.tinyApiKey}
                initialContent={content}
                theme={theme}
              />
            </Stack>
            {/* </Col>
              <Col></Col>
            </Row> */}
          </>
        )}
      </Stack>
    </Container>
  );
}
