import { InputSelect, InputText } from '@farmshare/ui-components';
import { FormikProps } from 'formik';
import { find, map } from 'lodash';
import { useEffect, useMemo } from 'react';
import { Card, Col, Row, Stack } from 'react-bootstrap';

import { usePackagingManyLazyQuery } from 'lib/graphql';

export interface ShipmentPackageFormForm
  extends Record<string, string | number | undefined> {
  packageType?: 'customPackaging' | 'farmsharePackaging';
  packaging?: string;
  externalId?: string;
  reference?: string;
  declaredValue?: number;
  weight?: number;
  length?: number;
  width?: number;
  height?: number;
  insuredValue?: number;
}

interface ShipmentPackageFormProps<T> {
  formikProps: FormikProps<T>;
  idx: number;
}

export function ShipmentPackageForm<
  T extends { packages: ShipmentPackageFormForm[] },
>({
  formikProps: { values, setFieldValue },
  idx,
}: ShipmentPackageFormProps<T>) {
  const [loadPackaging, loadPackagingOp] = usePackagingManyLazyQuery({
    notifyOnNetworkStatusChange: true,
  });

  const packageType = useMemo(
    () => values.packages[idx].packageType,
    [values.packages, idx],
  );

  const packaging = useMemo(() => {
    if (loadPackagingOp.data && packageType === 'farmsharePackaging') {
      return find(
        loadPackagingOp.data.packagingMany,
        (p) => p.code === values.packages[idx].packaging,
      );
    }
  }, [values.packages, idx, loadPackagingOp.data, packageType]);

  // only loading custom packaging should the user request it
  useEffect(() => {
    if (
      packageType === 'farmsharePackaging' &&
      !loadPackagingOp.data &&
      !loadPackagingOp.called
    ) {
      loadPackaging();
    }
  }, [
    loadPackaging,
    loadPackagingOp.called,
    loadPackagingOp.data,
    packageType,
  ]);

  useEffect(() => {
    if (packaging) {
      setFieldValue(`packages.${idx}.length`, packaging.box_length_in);
      setFieldValue(`packages.${idx}.width`, packaging.box_width_in);
      setFieldValue(`packages.${idx}.height`, packaging.box_height_in);
    }
  }, [setFieldValue, values.packages, idx, packageType, packaging]);

  return (
    <>
      <InputText
        label="External Id"
        nameOveride={`packages.${idx}.external_id`}
        type="text"
        hidden
      />
      <Row>
        <Col md={4}>
          <Card className="h-100">
            <Card.Header>Packaging</Card.Header>
            <Card.Body>
              <Stack gap={3}>
                <InputSelect
                  label="Package Type"
                  options={['Custom Packaging', 'Farmshare Packaging']}
                  nameOveride={`packages.${idx}.packageType`}
                  floatingLabel
                  required
                />
                <InputSelect
                  label="Packaging"
                  options={map(loadPackagingOp.data?.packagingMany, (p) => ({
                    label: p.name,
                    value: p.code,
                  }))}
                  nameOveride={`packages.${idx}.packaging`}
                  disabled={packageType !== 'farmsharePackaging'}
                  required={packageType === 'farmsharePackaging'}
                  floatingLabel
                />
              </Stack>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="h-100">
            <Card.Header>Dimensions</Card.Header>
            <Card.Body>
              <Stack gap={3}>
                <InputText
                  label="Length (in.)"
                  type="number"
                  nameOveride={`packages.${idx}.length`}
                  disabled={packageType === 'farmsharePackaging'}
                  floatingLabel
                  required
                />
                <InputText
                  label="Width (in.)"
                  type="number"
                  nameOveride={`packages.${idx}.width`}
                  disabled={packageType === 'farmsharePackaging'}
                  floatingLabel
                  required
                />
                <InputText
                  label="Height (in.)"
                  type="number"
                  nameOveride={`packages.${idx}.height`}
                  disabled={packageType === 'farmsharePackaging'}
                  floatingLabel
                  required
                />
              </Stack>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="h-100">
            <Card.Header>Details</Card.Header>
            <Card.Body>
              <Stack gap={3}>
                <InputText
                  label="Weight (lbs.)"
                  type="number"
                  nameOveride={`packages.${idx}.weight`}
                  floatingLabel
                  required
                />
                <InputText
                  label="Declared Value ($)"
                  type="number"
                  nameOveride={`packages.${idx}.declaredValue`}
                  floatingLabel
                />
                <InputText
                  label="Reference"
                  type="text"
                  nameOveride={`packages.${idx}.reference`}
                  floatingLabel
                />
              </Stack>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
