import { environment } from 'environments/environment';
import { createElement, useEffect } from 'react';

export interface StripePricingTableProps {
  pricingTableId?: string;
  publishableKey?: string;
}

export function StripePricingTable({
  pricingTableId = environment.stripePricingTable,
  publishableKey = environment.stripeKey,
}: StripePricingTableProps) {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return createElement('stripe-pricing-table', {
    'pricing-table-id': pricingTableId,
    'publishable-key': publishableKey,
  });
}
