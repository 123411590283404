import { concat, noop } from 'lodash';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { ToastContainer } from 'react-bootstrap';

import ToastRenderer, { Toast } from './toastr-renderer';

interface IToastrProviderProps {
  children: ReactNode;
}

interface IToastrContext {
  push: (t: Toast) => void;
}

const ToastrContext = createContext<IToastrContext>({ push: noop });

const useToastr = () => useContext(ToastrContext);

function ToastrProvider({ children }: IToastrProviderProps) {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const updateToasts = useCallback((toast: Toast) => {
    setToasts((arr) => concat(arr, toast));
  }, []);

  return (
    <ToastrContext.Provider value={{ push: updateToasts }}>
      <ToastContainer
        style={{
          position: 'fixed',
          top: '80px',
          right: '15px',
        }}
      >
        {toasts.map((toast, idx) => (
          <ToastRenderer key={idx} toast={toast} />
        ))}
      </ToastContainer>
      {children}
    </ToastrContext.Provider>
  );
}

export { ToastrContext, ToastrProvider, useToastr };
export type { IToastrContext, IToastrProviderProps };
