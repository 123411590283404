import { Loading, PageTitle, useScrollToTop } from '@farmshare/ui-components';
import { animalSpeciesHelper } from '@farmshare/utils';
import { filter, find, findIndex, isNil, map } from 'lodash';
import { useMemo } from 'react';
import { Col, Container, Row, Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import {
  AnimalHeadFragment,
  EnumProcessorSchedulingAnimalSpecies,
  ProcessingJobByIdDocument,
  ProcessorSchedulingAnimalHeadPartialUpdateByIdMutationHookResult,
  useProcessorSchedulingAnimalHeadPartialUpdateByIdMutation,
} from 'lib/graphql';
import { processingJobAnimalHeadLabel } from 'lib/processingJobUtils';

import { AnimalHeadCutsheetInformation } from './_views/animal-head-cutsheet-information';
import { AnimalHeadDetails } from './_views/animal-head-details';
import { useProcessingJobPage } from './useProcessingJobPage';

export interface AnimalHeadDetailsSectionProps {
  animalHead: AnimalHeadFragment | undefined | null;
  currentSpeciesInspectionLevels?: string[];
  animalSpecies?: EnumProcessorSchedulingAnimalSpecies;
  processingJobId: string | undefined;
  updateAnimalHead: ProcessorSchedulingAnimalHeadPartialUpdateByIdMutationHookResult['0'];
  updateAnimalHeadOp: ProcessorSchedulingAnimalHeadPartialUpdateByIdMutationHookResult['1'];
}

export function AnimalHead() {
  useScrollToTop();
  const { animalHeadId } = useParams();
  const { processingJobQueryResult, processingJobDisplayName, jobId } =
    useProcessingJobPage();

  const [updateAnimalHead, updateAnimalHeadOp] =
    useProcessorSchedulingAnimalHeadPartialUpdateByIdMutation({
      refetchQueries: [
        {
          query: ProcessingJobByIdDocument,
          variables: {
            jobId,
            includeNotificationHistory: true,
            includeProcessorSettings: true,
          },
        },
      ],
    });

  const animalHead = useMemo(() => {
    return find(
      processingJobQueryResult.data?.findProcessorSchedulingById?.animalHeads,
      (o) => o?._id === animalHeadId,
    );
  }, [
    animalHeadId,
    processingJobQueryResult.data?.findProcessorSchedulingById?.animalHeads,
  ]);

  const animalSpeciesHelped = useMemo(
    () =>
      animalSpeciesHelper(
        processingJobQueryResult.data?.findProcessorSchedulingById
          ?.animalSpecies ?? '',
      ),
    [processingJobQueryResult.data?.findProcessorSchedulingById?.animalSpecies],
  );

  const animalHeadDisplayName = useMemo(() => {
    const foundIdx = findIndex(
      processingJobQueryResult.data?.findProcessorSchedulingById?.animalHeads,
      (o) => o?._id === animalHeadId,
    );

    return processingJobAnimalHeadLabel({
      producerIdentifier: animalHead?.producerIdentifier,
      requestedBy: {
        first_name: '',
        last_name: '',
      },
      animalNumber: foundIdx + 1,
      animalSpecies:
        processingJobQueryResult.data?.findProcessorSchedulingById
          ?.animalSpecies,
      inspectionLevel: animalHead?.inspectionLevel ?? undefined,
    });
  }, [
    processingJobQueryResult.data?.findProcessorSchedulingById?.animalSpecies,
    processingJobQueryResult.data?.findProcessorSchedulingById?.animalHeads,
    animalHead?.producerIdentifier,
    animalHeadId,
    animalHead?.inspectionLevel,
  ]);

  const currentSpeciesInspectionLevels = useMemo(() => {
    const found = find(
      processingJobQueryResult.data?.findProcessorSchedulingById
        ?.processorSettings?.animalSettings,
      (o) => o?.species === animalSpeciesHelped.value,
    );

    // All this work to make sure I get back an array of only the enums.
    // Not null or undefined or an array with null or undefined in it.
    // NOTE: It is not possible for this to ever have null or undefined mixed into the array.
    return filter(found?.inspectionLevels, (o) => !isNil(o)) ?? [];
  }, [
    processingJobQueryResult.data?.findProcessorSchedulingById
      ?.processorSettings?.animalSettings,
    animalSpeciesHelped,
  ]);

  if (processingJobQueryResult.loading) {
    return <Loading />;
  }

  return (
    <Container className="pt-3">
      <PageTitle
        title={animalHeadDisplayName}
        showHr={false}
        // TODO only show this if they are the processor.
        // Other users will need to go elsewhere.
        innerBreadcrumbs={[
          { text: 'Bookings', to: '/processor/bookings' },
          { text: processingJobDisplayName, to: `/processing-job/${jobId}` },
        ]}
      />
      <Container>
        <Stack gap={3}>
          <Row className="g-4">
            <Col>
              <AnimalHeadDetails
                animalHead={animalHead}
                animalSpecies={
                  processingJobQueryResult.data?.findProcessorSchedulingById
                    ?.animalSpecies
                }
                currentSpeciesInspectionLevels={currentSpeciesInspectionLevels}
                processingJobId={jobId}
                updateAnimalHead={updateAnimalHead}
                updateAnimalHeadOp={updateAnimalHeadOp}
              />
            </Col>
          </Row>
          <Row className="g-4">
            {map(animalHead?.cutsheetInformation, (ci, idx) => {
              return (
                <Col md={6} key={idx}>
                  <AnimalHeadCutsheetInformation
                    cutsheetInformationIdx={idx}
                    animalHead={animalHead}
                    cutsheetInformation={ci}
                    processingJobId={jobId}
                    updateAnimalHead={updateAnimalHead}
                    updateAnimalHeadOp={updateAnimalHeadOp}
                  />
                </Col>
              );
            })}
          </Row>
        </Stack>
      </Container>
    </Container>
  );
}
