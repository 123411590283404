export type AnimalSexType = 'm' | 'f';

export function calculateYieldHelper(
  liveWeight: number,
  hangingWeight: number,
) {
  const yieldValue = liveWeight === 0 ? 0 : hangingWeight / liveWeight;

  return `${(yieldValue * 100).toFixed(2)}%`;
}
