import { Button, Form, Loading, useToastr } from '@farmshare/ui-components';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCreditCard, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { filter, findLastIndex, forEach, map, set } from 'lodash';
import { useEffect } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { userState } from 'state';

import {
  AnimalHeadInput,
  CutsheetInformationInput,
  EnumProcessorSchedulingAnimalSpecies,
  EnumProcessorSettingsAnimalSettingsSpecies,
  EnumProcessorSettingsCapacitySettingsDailyCapacitiesType,
} from 'lib/graphql';
import { isAnimalBeef } from 'lib/processingJobUtils';

import {
  CreateSchedulingForm,
  SchedulingCreateForm,
} from './create-scheduling-form';
import { ProcessingDetails } from './processing-details';
import { useSchedulingCreatePage } from '../hooks/useSchedulingCreatePage';

interface CreateSchedulingProps {
  animalSpecies?: string;
  date?: string;
  vendorId?: string;
  isOnBehalfOf?: boolean;
  setVendorName?: React.Dispatch<React.SetStateAction<string>>;
  onCancel: () => void;
  onCreateSuccess?: () => void;
  submitButtonLabel?: string;
  submitButtonIcon?: IconProp;
}

export function CreateScheduling({
  animalSpecies,
  date,
  vendorId,
  setVendorName,
  onCancel,
  onCreateSuccess,
  isOnBehalfOf = false,
  submitButtonLabel = 'Continue to Payment',
  submitButtonIcon = faCreditCard,
}: CreateSchedulingProps) {
  const user = useRecoilValue(userState);
  const { push } = useToastr();
  const schedulingCreatePage = useSchedulingCreatePage({
    animalSpecies,
    date,
    vendorId,
  });

  const {
    data,
    loading,
    createScheduling,
    createSchedulingOp,
    butcherSlotPricing,
    momentDate,
    spotsRemaining,
    vendorName,
    enabledAnimalSplitOptions,
    enabledAnimalInspectionLevelOptions,
    capacityConfigurationType,
  } = schedulingCreatePage;

  useEffect(() => {
    if (setVendorName) {
      setVendorName(vendorName);
    }
  }, [vendorName, setVendorName]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Form<SchedulingCreateForm>
      initialValues={{
        requesterContactInformation: {
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          company: '',
        },
        scheduledHeads: [],
      }}
      validate={(values) => {
        const errors: Partial<
          Record<keyof SchedulingCreateForm, string | string[]>
        > = {};

        const totalScheduledHeads = values?.scheduledHeads?.length ?? 0;
        if (totalScheduledHeads < 1) {
          errors.scheduledHeads =
            'You must schedule at least one animal for processing.';
        }

        // Validate if processor capacity is configured for species_inspection level we don't exceed individual inspection level maximums
        if (
          capacityConfigurationType ===
          EnumProcessorSettingsCapacitySettingsDailyCapacitiesType.SpeciesInspection
        ) {
          forEach(spotsRemaining.inspectionLevels, (spotRemainingVal, key) => {
            // get the current scheduled heads with that inspection level
            const currentScheduledWithInpsectionLevel = filter(
              values.scheduledHeads,
              (o) => o.inspectionLevel === key,
            );

            if (
              currentScheduledWithInpsectionLevel?.length >
              (spotRemainingVal ?? 0)
            ) {
              // find last index of animal head with this inspection value error it and open the animal head
              const lastIdx = findLastIndex(
                values.scheduledHeads,
                (o) => o.inspectionLevel === key,
              );

              set(
                errors,
                `scheduledHeads.[${lastIdx}].inspectionLevel`,
                `The processor has exceeded the amount of ${key} inspection level they can handle for this day. Please select a different inspection level.`,
              );
            }
          });
        }

        // Validate we have all the information for every scheduled head and set errors accordingly.
        forEach(values.scheduledHeads, (sh, idx) => {
          forEach(sh.contacts, (c, cidx) => {
            // check each contact we have has the information needed for the contact
            if (!c.firstName) {
              set(
                errors,
                `scheduledHeads.[${idx}].contacts.[${cidx}].firstName`,
                'You must provide a first name for this contact.',
              );
            }

            if (!c.email && !c.phone) {
              set(
                errors,
                `scheduledHeads.[${idx}].contacts.[${cidx}].email`,
                'You must provide either an email or phone for this contact.',
              );
            }
          });
        });

        if (!isOnBehalfOf && user) {
          if (!user.first_name && !user.phone) {
            set(
              errors,
              'requesterContactInformation.email',
              'Either a phone number or email must be provided.',
            );
          }
        } else {
          if (
            !values.requesterContactInformation.email &&
            !values.requesterContactInformation.phone
          ) {
            set(
              errors,
              'requesterContactInformation.email',
              'Either a phone number or email must be provided.',
            );
            set(
              errors,
              'requesterContactInformation.phone',
              'Either a phone number or email must be provided.',
            );
          }
        }

        return errors;
      }}
      onSubmit={async (form: SchedulingCreateForm) => {
        const requesterContactInformation =
          !isOnBehalfOf && user
            ? {
                firstName: user.first_name,
                lastName: user.last_name,
                email: user.email,
                phone: user.phone,
              }
            : form.requesterContactInformation;

        const animalIsTypeBeef = isAnimalBeef(
          animalSpecies as EnumProcessorSchedulingAnimalSpecies,
        );

        const animalHeads: AnimalHeadInput[] = map(
          form.scheduledHeads,
          (scheduledHead) => {
            const cutsheetInformation: CutsheetInformationInput[] = map(
              scheduledHead.contacts,
              (contact) => {
                return {
                  splitPart: contact.splitPart,
                  quantity: 1,
                  cutsheet: contact.cutsheet?.id,
                  contactInformation: {
                    firstName: contact.firstName,
                    lastName: contact.lastName,
                    email: contact.email,
                    phone: contact.phone,
                  },
                };
              },
            );
            return {
              splitType: scheduledHead.splitType,
              producerIdentifier: scheduledHead.producerIdentifier,
              inspectionLevel: scheduledHead.inspectionLevel,
              cutsheetInformation,
              isOverThirtyMonths: animalIsTypeBeef
                ? scheduledHead.isOverThirtyMonths ?? false
                : undefined,
              sex: scheduledHead.sex,
            };
          },
        );

        if (momentDate) {
          try {
            await createScheduling({
              variables: {
                newSchedule: {
                  animalSpecies:
                    animalSpecies as EnumProcessorSettingsAnimalSettingsSpecies,
                  dropoffDate: momentDate.format('M/DD/YYYY'),
                  // TODO we shouldn't allow a submit with zero heads that is silly
                  headCount: form.scheduledHeads?.length ?? 0,
                  processorSettings: data?.processingPartnersOne?._id,
                  vendor: vendorId,
                  animalHeads,
                  requesterContactInformation,
                  refundDeadlineDays:
                    data?.processingPartnersOne?.refundDeadlineDays || 0,
                  isOnBehalfOf,
                },
              },
            });

            if (onCreateSuccess) {
              onCreateSuccess();
            }
          } catch (error) {
            push({
              title: 'Error',
              body: (error as string).toString(),
              bg: 'danger',
              delay: 4000,
            });
          }
        }
      }}
    >
      <Row className="g-3 g-lg-4">
        <Col lg={3} className="pb-3">
          <ProcessingDetails
            animalSpecies={animalSpecies}
            butcherSlotPricing={butcherSlotPricing}
            dropOffDate={date}
            processor={{
              email: data?.processingPartnersOne?.vendor?.address?.email,
              name: data?.processingPartnersOne?.vendor?.shop_name,
              phone: data?.processingPartnersOne?.vendor?.address?.phone,
            }}
            refundDeadlineDays={
              data?.processingPartnersOne?.refundDeadlineDays || 0
            }
            schedulingDepositPrice={
              data?.processingPartnersOne?.schedulingDeposit?.price || 0
            }
            spotsRemaining={spotsRemaining}
            isOnBehalfOf={isOnBehalfOf}
            capacityConfigurationType={capacityConfigurationType}
          />
        </Col>
        <Col lg={9}>
          <CreateSchedulingForm
            animalSpecies={animalSpecies}
            createSchedulingOp={createSchedulingOp}
            totalDayCapacity={spotsRemaining}
            isOnBehalfOf={isOnBehalfOf}
            enabledAnimalSplitOptions={enabledAnimalSplitOptions}
            enabledAnimalInspectionLevelOptions={
              enabledAnimalInspectionLevelOptions
            }
            capacityConfigurationType={capacityConfigurationType}
          />
        </Col>
      </Row>
      <Stack
        direction="horizontal"
        gap={1}
        className="justify-content-end mb-3"
      >
        <Button
          disabled={createSchedulingOp.loading}
          variant="ghost"
          icon={faXmarkCircle}
          onClick={onCancel}
          content="Cancel"
        />
        <Button
          isLoading={createSchedulingOp.loading}
          disabled={createSchedulingOp.loading}
          type="submit"
          content={submitButtonLabel}
          icon={submitButtonIcon}
          variant="primary"
        />
      </Stack>
    </Form>
  );
}
