import { isNil } from 'lodash';
import { useMemo } from 'react';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const MoneyDisplay = ({
  value,
  className,
}: {
  value: number | undefined | null;
  className?: string;
}) => {
  const formattedValue = useMemo(() => {
    if (isNil(value)) {
      return '-';
    }
    return currencyFormatter.format(value);
  }, [value]);

  return <span className={className}>{formattedValue}</span>;
};
