import {
  Button,
  DataDetailListMinimal,
  InputText,
  MoneyDisplay,
  useModal,
  useToastr,
} from '@farmshare/ui-components';
import { animalSpeciesHelper } from '@farmshare/utils';
import { faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import moment, { Moment } from 'moment';
import { useCallback, useMemo } from 'react';
import { Card, Col, Row, Stack } from 'react-bootstrap';

import { type ProcessingJobDetailsSectionProps } from './job-details-tab';

const EditDropoffDateForm = ({
  currentDropoffDate,
}: {
  currentDropoffDate: Moment;
}) => {
  const displayCurrentDropoffDate = currentDropoffDate.isValid()
    ? currentDropoffDate.format('yyyy-MM-DD')
    : 'N/A';
  return (
    <Stack gap={3}>
      <Row>
        <Col>
          <InputText
            label="Dropoff Date"
            type="date"
            nameOveride="dropoffDate"
            hint={`Current requested dropoff date on ${displayCurrentDropoffDate}`}
            floatingLabel
            required
          />
        </Col>
      </Row>
    </Stack>
  );
};

export function JobDetails({
  processingJob,
  updateProcessingJob,
}: ProcessingJobDetailsSectionProps) {
  const { save } = useModal();
  const { push } = useToastr();

  // TODO  do i need inspection level
  const animalSpeciesOption = useMemo(() => {
    if (processingJob?.animalSpecies) {
      return animalSpeciesHelper(processingJob.animalSpecies);
    }
    return null;
  }, [processingJob?.animalSpecies]);

  const handleEditDropoffDate = useCallback(() => {
    const momentDropoffDate = moment.utc(processingJob?.dropoffDate ?? null);
    return save<{ dropoffDate?: string }>({
      type: 'save',
      title: 'Edit Dropoff Date',
      icon: faCalendarDays,
      initialValues: {
        dropoffDate: momentDropoffDate.isValid()
          ? momentDropoffDate.format('yyyy-MM-DD')
          : undefined,
      },
      body: <EditDropoffDateForm currentDropoffDate={momentDropoffDate} />,
      validate: (values) => {
        // moment(undefined) returns current date moment(null) creates an invalid date so we coalesce possible undefined to null
        const isValidDate = moment.utc(values.dropoffDate ?? null).isValid();
        if (!isValidDate) {
          return { dropoffDate: 'Please select a valid date.' };
        }
      },
      onSubmit: async (values) => {
        const updatedDropOffDate = moment
          .utc(values.dropoffDate)
          .format('M/DD/YYYY');

        await updateProcessingJob({
          variables: {
            processorSchedulingId: processingJob?._id,
            record: {
              dropoffDate: updatedDropOffDate,
            },
          },
        });

        push({
          title: 'Processing Job Updated',
          body: `The dropoff date has been updated.`,
          bg: 'success',
          delay: 4000,
        });
      },
    });
  }, [
    save,
    processingJob?.dropoffDate,
    updateProcessingJob,
    processingJob?._id,
    push,
  ]);

  return (
    <Card className="h-100">
      <Card.Header className="d-flex align-items-center">
        <span className="fw-bold fs-5">Details</span>
        <Button
          className="ms-auto"
          content="Edit Dropoff Date"
          onClick={handleEditDropoffDate}
          icon={faEdit}
          size="sm"
        />
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <DataDetailListMinimal
              rows={[
                {
                  label: 'Job Id',
                  value: processingJob?.jobId,
                },
                // TODO  do i need inspection level
                {
                  label: 'Item',
                  value: animalSpeciesOption?.label ?? '-',
                },
                {
                  label: 'Status',
                  value: processingJob?.status,
                },
                {
                  label: 'Dropoff Date',
                  value: processingJob?.dropoffDateStr,
                },
                {
                  label: 'Head Count',
                  value: processingJob?.headCount,
                },
              ]}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
