import { Button, IModalProperties } from '@farmshare/ui-components';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';

export const ReviewModalFooter = ({
  modalProps,
  onDenyBooking,
}: {
  modalProps: IModalProperties;
  onDenyBooking: () => void;
}) => {
  // The way the modals render and handle state makes this necessary for now... Don't do this in the future unless we have no other choice
  const [isDenying, setIsDenying] = useState(false);

  const handleDenyClick = () => {
    setIsDenying(true);
    onDenyBooking();
    setIsDenying(false);
    if (modalProps.onClose) {
      modalProps.onClose();
    }
  };
  return (
    <Modal.Footer>
      <Button
        type="button"
        content="Deny"
        isLoading={isDenying}
        disabled={modalProps.isLoading}
        icon={faXmark}
        variant="danger"
        onClick={handleDenyClick}
      />
      <Button
        type="submit"
        content="Accept"
        icon={faCheck}
        variant="success"
        disabled={isDenying}
        isLoading={modalProps.isLoading}
      />
    </Modal.Footer>
  );
};
