import { InputCheckGroup, InputText } from '@farmshare/ui-components';
import { FormikProps } from 'formik';
import { Card, Col, Row, Stack } from 'react-bootstrap';

export interface CampaignScheduleForm {
  sendAt: string;
  sendDate: string;
}

interface CampaignScheduleModalProps {
  formikProps: FormikProps<CampaignScheduleForm>;
}

export function CampaignScheduleModal(props: CampaignScheduleModalProps) {
  return (
    <div>
      <Stack gap={3}>
        <Card body>
          <InputCheckGroup
            type="radio"
            label="Now"
            nameOveride="sendAt"
            value="now"
            inline
          />
        </Card>
        <Card body>
          <Row>
            <Col sm={2}>
              <InputCheckGroup
                type="radio"
                label="Later"
                nameOveride="sendAt"
                value="later"
                inline
              />
            </Col>
            <Col sm={1} />
            <Col sm={7}>
              <InputText
                type="datetime-local"
                label="Send Date"
                disabled={props.formikProps.values.sendAt !== 'later'}
                required={props.formikProps.values.sendAt === 'later'}
              />
            </Col>
          </Row>
        </Card>
      </Stack>
    </div>
  );
}
