function base64ToArrayBuffer(base64: string): ArrayBufferLike {
  const binary = window.atob(base64);
  const bytes = new Uint8Array(binary.length);

  for (let i = 0; i < binary.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }

  return bytes.buffer;
}

export function downloadFromBase64Encoded(base64: string, filename: string) {
  const url = URL.createObjectURL(new Blob([base64ToArrayBuffer(base64)]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(url);
  link.remove();
}
