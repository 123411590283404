import {
  Form,
  InputChipOption,
  Loading,
  useToastr,
} from '@farmshare/ui-components';
import {
  animalSpeciesHelper,
  animalSplitTypeHelper,
  dayOfWeekHelper,
  DAYS_OF_WEEK,
  inspectionLevelHelper,
} from '@farmshare/utils';
import { find, forEach, map, reduce, unionWith } from 'lodash';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { vendorState } from 'state';

import {
  CreateProcessorSettingsCustomCapacityInput,
  CreateProcessorSettingsWithCustomCapacityInput,
  EnumProcessorSettingsAnimalSettingsInspectionLevels,
  EnumProcessorSettingsAnimalSettingsSpecies,
  EnumProcessorSettingsCapacityConfigurationType,
  Maybe,
  ViewProcessorAdminDocument,
  ViewProcessorAdminQuery,
  ViewProcessorAdminQueryResult,
  useProcessorSettingsCreateMutation,
  useProcessorSettingsUpdateMutation,
} from 'lib/graphql';

import SettingsForm, {
  SettingsFormData,
  daysOfWeekOptions,
  animalSpeciesOptions,
} from './_views/settings-form';

const defaultSettings: SettingsFormData = {
  daysOfWeek: daysOfWeekOptions.filter((o) =>
    ['monday', 'tuesday', 'wednesday'].includes(o.value),
  ),
  animalSpecies: animalSpeciesOptions.filter(
    (o) => o.value === EnumProcessorSettingsAnimalSettingsSpecies.Beef,
  ),

  capacityConfigurationType:
    EnumProcessorSettingsCapacityConfigurationType.SpeciesInspection,
  animalUnitCapacitySettings: {
    [EnumProcessorSettingsAnimalSettingsSpecies.Beef]: 0,
  },
  inspectionLevels: {
    [EnumProcessorSettingsAnimalSettingsSpecies.Beef]: [
      inspectionLevelHelper(
        EnumProcessorSettingsAnimalSettingsInspectionLevels.Exempt,
      ),
    ],
  },
  splitTypes: {
    [EnumProcessorSettingsAnimalSettingsSpecies.Beef]: [
      animalSplitTypeHelper('whole'),
    ],
  },
  capacitySettings: [
    { dayOfWeek: 'sunday', dailyCapacities: [] },
    { dayOfWeek: 'monday', dailyCapacities: [] },
    { dayOfWeek: 'tuesday', dailyCapacities: [] },
    { dayOfWeek: 'wednesday', dailyCapacities: [] },
    { dayOfWeek: 'thursday', dailyCapacities: [] },
    { dayOfWeek: 'friday', dailyCapacities: [] },
    { dayOfWeek: 'saturday', dailyCapacities: [] },
  ],
  customCapacityDays: {},
  schedulingDeposit: 0,
  butcherSlotPricing: {},
  customCutsheetPricing: {},
  refundDeadlineDays: 3,
  isNotificationDisabled: false,
  isPastDatesEnabled: false,
};

const formatSettingsDataFromDB = (
  processorSettings: ViewProcessorAdminQuery['processorSettingsOne'],
) => {
  const daysOfWeek: InputChipOption[] = [];

  // Format the capacity Settings
  const capacitySettings: any[] =
    map(processorSettings?.capacitySettings, (capacitySetting) => ({
      dayOfWeek: capacitySetting?.dayOfWeek,
      isEnabled: capacitySetting?.isEnabled,
      dailyCapacities: capacitySetting?.dailyCapacities,
    })) ?? [];

  // daysOfWeek
  forEach(processorSettings?.capacitySettings, (capacitySetting) => {
    if (capacitySetting?.isEnabled) {
      const dayOfWeekOption = dayOfWeekHelper(capacitySetting.dayOfWeek);
      daysOfWeek.push({ ...dayOfWeekOption, sortValue: dayOfWeekOption.order });
    }
  });

  // Format custom capacities
  const customCapacities = reduce(
    processorSettings?.customCapacities,
    (acc: SettingsFormData['customCapacityDays'], customCapacity) => {
      if (customCapacity && customCapacity.dateStr) {
        acc[customCapacity.dateStr] = {
          _id: customCapacity._id,
          date: customCapacity.date,
          isDeleted: customCapacity.isDeleted,
          dailyCapacities: map(customCapacity.dailyCapacities, (dc) => ({
            type: dc?.type,
            capacities: map(
              dc?.capacities as any[],
              ({ species, value, inspectionLevel }) => ({
                species,
                value,
                inspectionLevel,
              }),
            ),
          })),
        };
      }

      return acc;
    },
    {},
  );

  // Format animal settings
  const animalSpecies: InputChipOption[] = [];
  const speciesInspectionLevels: Partial<
    Record<EnumProcessorSettingsAnimalSettingsSpecies, Array<InputChipOption>>
  > = {};
  const speciesSplitTypes: Partial<
    Record<EnumProcessorSettingsAnimalSettingsSpecies, Array<InputChipOption>>
  > = {};
  const butcherSlotPricing: Partial<
    Record<
      EnumProcessorSettingsAnimalSettingsSpecies,
      Partial<
        Record<EnumProcessorSettingsAnimalSettingsInspectionLevels, number>
      >
    >
  > = {};
  const customCutsheetPricing: Partial<
    Record<
      EnumProcessorSettingsAnimalSettingsSpecies,
      Partial<
        Record<EnumProcessorSettingsAnimalSettingsInspectionLevels, number>
      >
    >
  > = {};

  const animalUnitCapacitySettings: Partial<
    Record<EnumProcessorSettingsAnimalSettingsSpecies, number>
  > = {};

  forEach(processorSettings?.animalSettings, (animalSetting) => {
    // TODO the isEnabled is not active I am just changing the entire array and not just flipping the flag
    if (animalSetting?.isEnabled) {
      const speciesOption = animalSpeciesHelper(animalSetting.species);
      animalSpecies.push({ ...speciesOption, sortValue: speciesOption.order });

      // setup weightedanimalUnits
      animalUnitCapacitySettings[animalSetting.species] =
        animalSetting.weightedAnimalUnit ?? 0;

      // set default inpsection levels Graphql says this array could have null values
      // so we have to use a forEach loop instead of a map function which is silly.
      const currentInspectionLevels: InputChipOption[] = [];
      forEach(animalSetting.inspectionLevels, (inspectionLevel) => {
        if (inspectionLevel) {
          currentInspectionLevels.push(inspectionLevelHelper(inspectionLevel));
        }
      });
      speciesInspectionLevels[animalSetting.species] = currentInspectionLevels;

      // set default splits
      const currentSplitTypes: InputChipOption[] = [];
      forEach(animalSetting.splitTypes, (splitType) => {
        if (splitType) {
          currentSplitTypes.push(animalSplitTypeHelper(splitType));
        }
      });

      speciesSplitTypes[animalSetting.species] = currentSplitTypes;

      const speciesButcherSlotPricing = reduce(
        animalSetting.butcherSlotPricing,
        (acc: Record<string, number>, butcherSlotPrice) => {
          if (butcherSlotPrice?.inspectionLevel) {
            acc[butcherSlotPrice.inspectionLevel] = butcherSlotPrice.price;
          }

          return acc;
        },
        {},
      );

      butcherSlotPricing[animalSetting.species] = speciesButcherSlotPricing;

      const speciesCustomCutsheetPricing = reduce(
        animalSetting.customCutsheetPricing,
        (acc: Record<string, number>, customCutsheetPrice) => {
          if (customCutsheetPrice?.inspectionLevel) {
            acc[customCutsheetPrice.inspectionLevel] =
              customCutsheetPrice.price;
          }

          return acc;
        },
        {},
      );

      customCutsheetPricing[animalSetting.species] =
        speciesCustomCutsheetPricing;
    }
  });

  const form: SettingsFormData = {
    daysOfWeek,
    animalSpecies,
    inspectionLevels: speciesInspectionLevels,
    splitTypes: speciesSplitTypes,
    capacityConfigurationType:
      processorSettings?.capacityConfigurationType ??
      EnumProcessorSettingsCapacityConfigurationType.SpeciesInspection,
    animalUnitCapacitySettings,
    capacitySettings,
    schedulingDeposit: processorSettings?.schedulingDeposit?.price ?? 0,
    butcherSlotPricing,
    customCutsheetPricing,
    customCapacityDays: customCapacities,
    refundDeadlineDays:
      processorSettings?.refundDeadlineDays ||
      defaultSettings.refundDeadlineDays,
    about: processorSettings?.about as string,
    isNotificationDisabled: !!processorSettings?.isNotificationDisabled,
    isPastDatesEnabled: false,
  };
  return form;
};

interface SchedulingSettingsProps {
  settings?: Maybe<ViewProcessorAdminQueryResult>;
}

export default function SchedulingSettings({
  settings,
}: SchedulingSettingsProps) {
  const vendor = useRecoilValue(vendorState);
  const [createProcessorSettings, createProcessorSettingsOperation] =
    useProcessorSettingsCreateMutation({
      refetchQueries: [{ query: ViewProcessorAdminDocument }],
    });

  const [updateProcessorSettings, updateProcessorSettingsOperation] =
    useProcessorSettingsUpdateMutation({
      refetchQueries: [{ query: ViewProcessorAdminDocument }],
    });

  const { push } = useToastr();

  const initialFormValues = useMemo(() => {
    if (settings?.data?.processorSettingsOne) {
      return formatSettingsDataFromDB(settings.data.processorSettingsOne);
    }
    return defaultSettings;
  }, [settings?.data?.processorSettingsOne]);

  const formatAndCreateSettings = (values: SettingsFormData) => {
    const customCapacities: CreateProcessorSettingsCustomCapacityInput[] =
      reduce(
        Object.keys(values.customCapacityDays),
        (acc: any, key: string) => {
          const capacityObj = values.customCapacityDays[key];
          acc.push({
            date: key,
            _id: capacityObj._id,
            isDeleted: capacityObj.isDeleted,
            // We have to map out the __typename that graphql puts into all objects
            dailyCapacities: map(
              capacityObj.dailyCapacities,
              ({ type, capacities = [] }) => {
                const cleanedCapacities = map(
                  capacities,
                  ({ species, value, inspectionLevel }) => ({
                    species,
                    value,
                    inspectionLevel,
                  }),
                );

                return { type, capacities: cleanedCapacities };
              },
            ),
          });
          return acc;
        },
        [],
      );

    // Currently we only save the enabled ones
    // TODO add in the logic to handle having new animals and disabling old ones
    const animalSettings = map(values.animalSpecies, (animalSpeciesOption) => {
      const splitTypesChips =
        values.splitTypes[
          animalSpeciesOption.value as EnumProcessorSettingsAnimalSettingsSpecies
        ] ?? [];

      const inspectionLevelChips =
        values.inspectionLevels[
          animalSpeciesOption.value as EnumProcessorSettingsAnimalSettingsSpecies
        ] ?? [];

      const speciesButcherSlotPricing =
        values.butcherSlotPricing[
          animalSpeciesOption.value as EnumProcessorSettingsAnimalSettingsSpecies
        ] ?? {};

      const speciesCustomCutsheetPricing =
        values.customCutsheetPricing[
          animalSpeciesOption.value as EnumProcessorSettingsAnimalSettingsSpecies
        ] ?? {};

      return {
        isEnabled: true,
        species:
          animalSpeciesOption.value as EnumProcessorSettingsAnimalSettingsSpecies,
        splitTypes: map(splitTypesChips, (chip) => chip.value),
        weightedAnimalUnit:
          values.animalUnitCapacitySettings[
            animalSpeciesOption.value as EnumProcessorSettingsAnimalSettingsSpecies
          ],
        inspectionLevels: map(
          inspectionLevelChips,
          (chip) =>
            chip.value as EnumProcessorSettingsAnimalSettingsInspectionLevels,
        ),
        butcherSlotPricing: map(
          speciesButcherSlotPricing,
          (price, inspectionLevel) => ({ inspectionLevel, price: price ?? 0 }),
        ),
        customCutsheetPricing: map(
          speciesCustomCutsheetPricing,
          (price, inspectionLevel) => ({ inspectionLevel, price: price ?? 0 }),
        ),
      };
    });

    // Capacity settings we loop over the DAYS_OF_WEEK array and we build a capacity object for each day of week.
    // This is easy to make sure we can keep day capacity configuration even after its disabled
    const capacitySettings = map(DAYS_OF_WEEK, (value) => {
      const currentDailyCapacities: any =
        find(settings?.data?.processorSettingsOne?.capacitySettings, {
          dayOfWeek: value,
        })?.dailyCapacities ?? [];

      // TODO its simpler to just take all the daily capacities and use them.
      const updatedDailyCapcities =
        find(values.capacitySettings, { dayOfWeek: value })?.dailyCapacities ??
        [];

      const unionedDailyCapacities = unionWith(
        updatedDailyCapcities,
        currentDailyCapacities,
        (left, right) => left?.type === right?.type,
      );

      return {
        dayOfWeek: value,
        isEnabled: Boolean(find(values.daysOfWeek, { value })),
        // We have to map out the __typename that graphql puts into all the object
        dailyCapacities: map(
          unionedDailyCapacities,
          ({ type, capacities = [] }) => {
            const cleanedCapacities = map(
              capacities,
              ({ species, value, inspectionLevel }) => ({
                species,
                value,
                inspectionLevel,
              }),
            );
            return { type, capacities: cleanedCapacities };
          },
        ),
      } as any;
    });

    const formattedSettings: CreateProcessorSettingsWithCustomCapacityInput = {
      vendor: vendor._id,
      animalSettings: animalSettings,
      schedulingDeposit: values.schedulingDeposit,
      refundDeadlineDays: values.refundDeadlineDays,
      about: values.about,
      isNotificationDisabled: values.isNotificationDisabled,
      capacityConfigurationType: values.capacityConfigurationType,
      capacitySettings: capacitySettings,

      customCapacities,
    };
    return formattedSettings;
  };

  if (!vendor || settings?.loading) {
    return <Loading />;
  }

  return (
    <div className="mb-5">
      <Form<SettingsFormData>
        initialValues={initialFormValues}
        enableReinitialize={true}
        onSubmit={async (values) => {
          const formattedFormData = formatAndCreateSettings(values);
          let toast = {
            title: 'Settings Updated!',
            body: 'Your settings have been sucessfully updated!',
            bg: 'primary',
            delay: 4000,
          };
          try {
            // If user has processing settings already, update, otherwise create new doc
            if (settings?.data?.processorSettingsOne?._id) {
              await updateProcessorSettings({
                variables: {
                  updateProcessorSettings: {
                    processorSettingsId: settings.data.processorSettingsOne._id,
                    ...formattedFormData,
                  },
                } as any,
              });
            } else {
              await createProcessorSettings({
                variables: {
                  newProcessorSettings: {
                    ...formattedFormData,
                  },
                },
              });
            }
          } catch (err) {
            toast = {
              ...toast,
              title: 'Error',
              body: (err as string).toString(),
              bg: 'danger',
            };
          }

          push(toast);
        }}
      >
        <SettingsForm
          isSaving={
            createProcessorSettingsOperation.loading ||
            updateProcessorSettingsOperation.loading
          }
        />
      </Form>
    </div>
  );
}
