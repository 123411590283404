import { Field, ErrorMessage, useFormikContext } from 'formik';
import { camelCase } from 'lodash';
import { useCallback, useMemo } from 'react';
import { Form } from 'react-bootstrap';

import { InputComponent } from '.';

export interface InputCheckGroupProps extends InputComponent {
  type?: 'checkbox' | 'radio';
  value?: string | number;
  inline?: boolean;
}

export function InputCheckGroup({
  label,
  disabled,
  hint,
  required,
  nameOveride,
  value,
  type = 'radio',
  inline = false,
  className,
}: InputCheckGroupProps) {
  const { errors } = useFormikContext<Record<string, boolean>>();

  const validate = useCallback(
    (value: string | number | boolean | undefined) => {
      if (required && (!value || value === undefined)) {
        return `${label} is required.`;
      }
    },
    [label, required],
  );

  const camelLabel = useMemo(() => camelCase(label), [label]);

  const name = useMemo(
    () => nameOveride ?? camelLabel,
    [camelLabel, nameOveride],
  );

  return (
    <Form.Group className={className}>
      {label !== '' && !inline && (
        <Form.Label htmlFor={`form.${name}.${camelLabel}`} title={camelLabel}>
          {label}
          {required && <sup className="text-danger fw-bold">&nbsp;*</sup>}
        </Form.Label>
      )}
      <Field
        id={`form.${name}.${camelLabel}`}
        as={Form.Check}
        name={name}
        type={type}
        placeholder={label}
        disabled={disabled}
        validate={validate}
        required={required}
        isInvalid={errors[name]}
        label={inline ? label : null}
        value={value}
      />
      {hint && <Form.Text>{hint}</Form.Text>}
      <ErrorMessage
        name={name}
        render={(msg: string) => (
          <Form.Control.Feedback
            type="invalid"
            // Since we are not using bootstrap Form elements but formik we should force this to display the error message
            style={{ display: 'block' }}
          >
            {msg}
          </Form.Control.Feedback>
        )}
      />
    </Form.Group>
  );
}
