import { animalSpeciesHelper } from './animalSpeciesHelper';

export function inspectionLevelHelper(value: string) {
  switch (value?.toLowerCase()) {
    case 'exempt':
      return { label: 'Exempt', order: 0, value };

    case 'state':
      return { label: 'State', order: 1, value };

    case 'usda':
      return { label: 'USDA', order: 2, value };

    default:
      return { label: value, order: 99, value };
  }
}

export function inspectionLevelSpeciesDisplay(
  inspectionLevel: string,
  animalSpecies: string,
) {
  const inspection = inspectionLevelHelper(inspectionLevel);
  const species = animalSpeciesHelper(animalSpecies);

  return `${inspection.label} ${species.label}`;
}
