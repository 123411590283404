import {
  DataDetailList,
  Error,
  Loading,
  PageTitle,
  Table,
} from '@farmshare/ui-components';
import { formatToShortDate } from '@farmshare/utils';
import { flatMap, join, map, truncate } from 'lodash';
import moment from 'moment';
import { Card, Container, Image, Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { Integration, User, Vendor, useTenantByIdQuery } from 'lib/graphql';

import { AddIntegrationButton } from './_views/add-integration-button';
import { AddUserButton } from './_views/add-user-button';
import { AddVendorButton } from './_views/add-vendor-button';
import { EditModulesButton } from './_views/edit-modules-button';
import { EditSubscriptionButton } from './_views/edit-subscription-button';
import { IntegrationButtons } from './_views/integration-buttons';
import { UserButtons } from './_views/user-buttons';

export default function AdminTenantDetailsPage() {
  const { id } = useParams();

  const { data, loading, error, refetch } = useTenantByIdQuery({
    variables: { tenantId: id },
  });

  if (error) {
    return <Error error={error} />;
  }

  return loading ? (
    <Loading />
  ) : (
    <div>
      <PageTitle
        title={data?.tenantById?.name}
        innerBreadcrumbs={[
          { text: 'Admin', to: '/admin' },
          { text: 'Tenants', to: '/admin/tenants' },
        ]}
      />
      <Container>
        <Stack direction="vertical" gap={3}>
          <Card>
            <Card.Header>
              <Stack direction="horizontal" className="justify-content-between">
                <div>Details</div>
                {data?.tenantById && (
                  <Stack direction="horizontal" gap={2}>
                    <EditSubscriptionButton
                      tenantId={data.tenantById._id}
                      subscription={
                        data?.tenantById?.external_ids[
                          'stripe-subscription-item'
                        ]
                      }
                      refetch={refetch}
                    />
                    <EditModulesButton
                      tenant={data.tenantById}
                      refetch={refetch}
                    />
                  </Stack>
                )}
              </Stack>
            </Card.Header>
            <Card.Body>
              <DataDetailList
                rows={[
                  { label: 'Name', value: data?.tenantById?.name },
                  // { label: 'Domain', value: data?.tenantById?.domain },
                  {
                    label: 'Modules',
                    value: join(map(data?.tenantById?.modules, 'label'), ', '),
                  },
                  {
                    label: 'Created',
                    value: formatToShortDate(data?.tenantById?.date_created),
                  },
                  { label: 'Auth0 ID', value: data?.tenantById?.external_id },
                  {
                    label: 'Subscription Item',
                    value:
                      data?.tenantById?.external_ids[
                        'stripe-subscription-item'
                      ],
                  },
                  {
                    label: 'Enabled',
                    value: data?.tenantById?.enabled ? 'Yes' : 'No',
                  },
                ]}
              />
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>
              <Stack direction="horizontal" className="justify-content-between">
                <div>Vendors</div>
                {data?.tenantById && (
                  <AddVendorButton tenant={data.tenantById} refetch={refetch} />
                )}
              </Stack>
            </Card.Header>
            <Card.Body>
              {data?.tenantById?.vendors && (
                <Table<Partial<Vendor>>
                  rows={data.tenantById.vendors}
                  columns={[
                    { label: 'Name', field: 'shop_name' },
                    {
                      label: 'Enabled?',
                      field: 'enabled',
                      formatter: (row) => (row.enabled ? 'Yes' : 'No'),
                    },
                    { label: 'Email', field: 'address.email' },
                    { label: 'Shop', field: 'shop_url' },
                  ]}
                />
              )}
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>
              <Stack direction="horizontal" className="justify-content-between">
                <div>Integrations</div>
                {data?.tenantById && (
                  <AddIntegrationButton
                    tenant={data.tenantById}
                    refetch={refetch}
                  />
                )}
              </Stack>
            </Card.Header>
            <Card.Body>
              {data?.tenantById?.vendors && (
                <Table<
                  Pick<
                    Integration,
                    '_id' | 'name' | 'type' | 'endpoint' | 'key'
                  > & { vendor_id: string; vendor_name: string }
                >
                  rows={flatMap(data.tenantById.vendors, (v) =>
                    map(v.integrations, (i) => ({
                      ...i,
                      vendor_id: v._id,
                      vendor_name: v.shop_name,
                    })),
                  )}
                  columns={[
                    { label: 'Vendor', field: 'vendor_name' },
                    { label: 'Name', field: 'name' },
                    { label: 'Type', field: 'type' },
                    {
                      label: 'Endpoint',
                      field: 'endpoint',
                      minimumBreakpoint: 'md',
                    },
                    {
                      label: 'Key',
                      field: 'key',
                      formatter: (row) => truncate(row.key, { length: 22 }),
                      minimumBreakpoint: 'md',
                    },
                    {
                      formatter: (row) => (
                        <IntegrationButtons
                          integration={row}
                          refetch={refetch}
                        />
                      ),
                    },
                  ]}
                />
              )}
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>
              <Stack direction="horizontal" className="justify-content-between">
                <div>Users</div>
                {data?.tenantById?.external_id && (
                  <AddUserButton tenant={data.tenantById} refetch={refetch} />
                )}
              </Stack>
            </Card.Header>
            <Card.Body>
              {data?.tenantById?.users && (
                <Table<User>
                  rows={data.tenantById.users}
                  columns={[
                    {
                      formatter: (row) =>
                        row.picture_url ? (
                          <Image
                            src={row.picture_url}
                            alt={`${row.first_name}'s picture`}
                            width={22}
                            roundedCircle
                            fluid
                          />
                        ) : null,
                      minimumBreakpoint: 'md',
                    },
                    { label: 'First Name', field: 'first_name' },
                    { label: 'Last Name', field: 'last_name' },
                    { label: 'Email', field: 'email', minimumBreakpoint: 'md' },
                    {
                      label: 'Enabled?',
                      field: 'enabled',
                      formatter: (row) => (row.enabled ? 'Yes' : 'No'),
                    },
                    {
                      label: 'Last Login',
                      field: 'last_login',
                      formatter: (row) =>
                        row.last_login
                          ? moment.unix(row.last_login).format('L LT')
                          : '-',
                      minimumBreakpoint: 'md',
                    },
                    { formatter: (row) => <UserButtons user={row} /> },
                  ]}
                />
              )}
            </Card.Body>
          </Card>
        </Stack>
      </Container>
    </div>
  );
}
