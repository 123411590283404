import { faCheck, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clone, map } from 'lodash';
import { useReducer, useState } from 'react';
import { Dropdown, Stack } from 'react-bootstrap';

import { Button, type ButtonProps } from '../button/button';

interface Option {
  label: string;
  value: string;
  isActive?: boolean;
}

export interface DropdownMultiSelectProps
  extends Pick<ButtonProps, 'content' | 'icon'> {
  title: string;
  options: Option[];
  onApply: (options: Option[]) => void;
  resetSelections?: boolean;
}

function filterReducer(
  allFilters: Option[],
  action:
    | { type: 'add_option' | 'remove_option'; idx: number }
    | { type: 'reset'; initialState: Option[] },
): Option[] {
  switch (action.type) {
    case 'add_option':
      allFilters[action.idx].isActive = true;
      break;
    case 'remove_option':
      allFilters[action.idx].isActive = false;
      break;
    case 'reset':
      allFilters = action.initialState;
      break;

    default:
      throw Error('Unknown action.');
  }

  // cloning to trigger state change
  return clone(allFilters);
}

export function DropdownMultiSelect({
  content,
  icon,
  onApply,
  options,
  resetSelections,
  title,
}: DropdownMultiSelectProps) {
  const [shown, setShown] = useState<boolean>(false);
  const [allOptions, dispatchOptionAction] = useReducer(filterReducer, options);

  return (
    <Dropdown className="d-inline" show={shown}>
      <Dropdown.Toggle onClick={() => setShown(!shown)}>
        {icon && <FontAwesomeIcon icon={icon} className="me-1 me-md-2" />}
        <span className="d-none d-md-inline">{title}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {map(allOptions, (option, idx) => (
          <Dropdown.Item
            key={idx}
            onClick={() => {
              dispatchOptionAction({
                type: option.isActive ? 'remove_option' : 'add_option',
                idx,
              });
            }}
          >
            <div className="d-flex align-items-center">
              <div style={{ minWidth: '25px' }}>
                {option.isActive && (
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="ms-1 text-primary"
                  />
                )}
              </div>
              {option.label}
            </div>
          </Dropdown.Item>
        ))}
        <Dropdown.Divider />
        <Stack
          direction="horizontal"
          gap={1}
          className="justify-content-center"
        >
          {resetSelections && (
            <Button
              content="Reset"
              variant="ghost"
              icon={faUndo}
              size="sm"
              onClick={() => {
                dispatchOptionAction({
                  type: 'reset',
                  initialState: map(options, (o) => ({ ...o, isActive: true })),
                });
              }}
            />
          )}
          <Button
            content={content}
            icon={icon}
            size="sm"
            onClick={() => {
              onApply(allOptions);
              setShown(false);
            }}
          />
        </Stack>
      </Dropdown.Menu>
    </Dropdown>
  );
}
