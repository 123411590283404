import { InputSelect, InputText } from '@farmshare/ui-components';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import { map } from 'lodash';
import { Card, Col, Row } from 'react-bootstrap';

import { EnumProductStock_Status } from 'lib/graphql';

export function ProductVariantForm() {
  return (
    <FieldArray
      name="variants"
      render={({ form }: FieldArrayRenderProps) => {
        return (
          <div>
            {map(form.values?.variants, (variant, idx) => {
              return (
                <Card key={idx} className="my-4">
                  <Card.Header>Variant {variant?.name}</Card.Header>
                  <Card.Body>
                    <Row className="g-3">
                      <Col xs={6}>
                        <InputText
                          label="Price"
                          nameOveride={`variants.${idx}.price`}
                          type="number"
                          floatingLabel
                          required
                        />
                      </Col>
                      <Col xs={6}>
                        <InputText
                          label="Weight"
                          nameOveride={`variants.${idx}.weight`}
                          type="number"
                          floatingLabel
                        />
                      </Col>
                      <Col xs={6}>
                        <InputSelect
                          label="Stock Status"
                          nameOveride={`variants.${idx}.stock_status`}
                          options={map(
                            EnumProductStock_Status,
                            (value, label) => ({
                              label,
                              value,
                            }),
                          )}
                          floatingLabel
                          required
                        />
                      </Col>
                      <Col xs={6}>
                        <InputText
                          label="Stock Quantity"
                          type="number"
                          nameOveride={`variants.${idx}.stock_quantity`}
                          floatingLabel
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              );
            })}
          </div>
        );
      }}
    />
  );
}
