import { Button, InputText } from '@farmshare/ui-components';
import { formatPhoneNumber } from '@farmshare/utils';
import { faLightbulb } from '@fortawesome/free-regular-svg-icons';
import { faSearch, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikProps } from 'formik';
import { map } from 'lodash';
import { useCallback, useState } from 'react';
import { Alert, Card, Col, Row } from 'react-bootstrap';

import { useCustomerManyLazyQuery } from 'lib/graphql';

export interface CustomerLookupModalForm {
  email?: string;
  firstName?: string;
  lastName?: string;
  company?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postcode?: string;
  country?: string;
  phone?: string;
  type?: string;
}

interface CustomerLookupModalProps {
  formikProps: FormikProps<CustomerLookupModalForm>;
}

export function CustomerLookupModal({ formikProps }: CustomerLookupModalProps) {
  const [selectedAddress, setSelectedAddress] = useState<string>();

  const [getCustomers, getCustomersOperation] = useCustomerManyLazyQuery();

  const className = useCallback(
    (idx: string) => (idx === selectedAddress ? 'border-primary' : ''),

    [selectedAddress],
  );

  return (
    <div>
      <div className="d-flex flex-column gap-3">
        <InputText type="email" label="Email" floatingLabel />
        <Row>
          <Col>
            <InputText type="text" label="First Name" floatingLabel />
          </Col>
          <Col>
            <InputText type="text" label="Last Name" floatingLabel />
          </Col>
        </Row>
        <div className="d-flex justify-content-end gap-2">
          <Button
            type="reset"
            content="Reset"
            icon={faUndo}
            size="sm"
            variant="ghost"
            onClick={() => {
              formikProps.resetForm();
              setSelectedAddress('');
            }}
          />
          <Button
            content="Search"
            icon={faSearch}
            size="sm"
            isLoading={getCustomersOperation.loading}
            onClick={() =>
              getCustomers({
                variables: {
                  filter: {
                    OR: [
                      { email: formikProps.values.email },
                      { first_name: formikProps.values.firstName },
                      { last_name: formikProps.values.lastName },
                    ],
                  },
                },
              })
            }
          />
        </div>
      </div>
      {getCustomersOperation.data?.customerMany && (
        <>
          <hr />
          {getCustomersOperation.data.customerMany.length === 0 ? (
            <Alert variant="danger">No customers found...</Alert>
          ) : (
            <>
              <div className="mb-3 text-primary">
                <FontAwesomeIcon icon={faLightbulb} />
                &nbsp;Select an address below:
              </div>
              <Row md={2} className="g-3">
                {map(getCustomersOperation.data.customerMany, (c, idx) =>
                  map(c.addresses, (a, idx2) => (
                    <Col key={`${idx}-${idx2}`}>
                      <Card
                        onClick={() => {
                          formikProps.setValues({
                            email: c.email,
                            firstName: a.first_name || '',
                            lastName: a.last_name || '',
                            company: a.company || '',
                            address1: a.address_1,
                            address2: a.address_2 || '',
                            city: a.city,
                            state: a.state,
                            postcode: a.postcode,
                            country: a.country,
                            phone: a.phone || '',
                            type: a.type,
                          });
                          setSelectedAddress(`${idx}-${idx2}`);
                        }}
                        className={className(`${idx}-${idx2}`)}
                        style={{ cursor: 'pointer' }}
                        body
                      >
                        <div className="fw-bold">
                          {c.first_name}&nbsp;{c.last_name}
                        </div>
                        <div className="small">
                          {a.address_1}
                          {a.address_2 ? `, ${a.address_2}` : ''}
                        </div>
                        <div className="small">
                          {a.city},&nbsp;{a.state}&nbsp;
                          {a.postcode}
                        </div>
                        <div className="small">
                          {a.phone ? formatPhoneNumber(a.phone) : ''}
                        </div>
                      </Card>
                    </Col>
                  )),
                )}
              </Row>
            </>
          )}
        </>
      )}
    </div>
  );
}
