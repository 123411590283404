import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faLightbulb } from '@fortawesome/free-regular-svg-icons';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, type ReactNode } from 'react';
import { Alert } from 'react-bootstrap';

export interface InfoBoxProps {
  content: ReactNode;
  type?: 'info' | 'danger';
}

export function InfoBox({ content, type = 'info' }: InfoBoxProps) {
  const icon: IconProp | null = useMemo(() => {
    switch (type) {
      case 'info':
        return faLightbulb;
      case 'danger':
        return faXmarkCircle;
    }
  }, [type]);

  return (
    <Alert variant={type} className="d-flex">
      <div className="me-3">
        <FontAwesomeIcon icon={icon} className="" />
      </div>
      <div className="flex-grow-1"> {content}</div>
    </Alert>
  );
}
