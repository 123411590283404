import { InputText, InputTextArea, InputCheck } from '@farmshare/ui-components';
import { Stack } from 'react-bootstrap';

export const GenerateInvoiceModal = () => {
  return (
    <Stack gap={2}>
      <InputCheck
        label="Will the user pay in cash at pickup?"
        nameOveride="isPayAtPickup"
        type="checkbox"
      />
      <InputText
        type="number"
        min={0}
        nameOveride="additionalCost"
        label="Additional Cost"
        step={0.01}
        placeholder="$0.00"
      />
      <InputTextArea
        label="Reason"
        nameOveride="additionalCostReason"
        showLabel
        className="mt-3"
        rows={3}
      />
      <hr />
      <InputTextArea
        label="Add any additional details you would like the customer to know"
        nameOveride="additionalPickupDetails"
        showLabel
        rows={3}
      />
    </Stack>
  );
};
