import { Loading } from '@farmshare/ui-components';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { filter, map } from 'lodash';
import { Stack } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { userState, vendorState } from 'state';

import {
  EnumProcessorSchedulingStatus,
  FilterFindManyProcessorSchedulingInput,
  SortFindManyProcessorSchedulingInput,
  useProcessorSchedulingManyQuery,
  useProcessorSchedulingPaginationLazyQuery,
} from 'lib/graphql';

import TableContainer, { ExtraColumnsEnum } from './_views/table-container';
import { GenerateKillsheetButton } from '../lib/_views/generate-killsheet-button';

export default function ProcessorDashboard() {
  const user = useRecoilValue(userState);
  const vendor = useRecoilValue(vendorState);

  const shownStatuses = [
    EnumProcessorSchedulingStatus.Scheduled,
    EnumProcessorSchedulingStatus.DroppedOff,
    EnumProcessorSchedulingStatus.Killed,
    EnumProcessorSchedulingStatus.Aging,
    EnumProcessorSchedulingStatus.Curing,
    EnumProcessorSchedulingStatus.Invoicing,
    EnumProcessorSchedulingStatus.ReadyPayAtPickup,
    EnumProcessorSchedulingStatus.InvoicePaid,
  ];

  const completedStatuses = [
    EnumProcessorSchedulingStatus.CancelledByProcessor,
    EnumProcessorSchedulingStatus.CancelledByProducer,
    EnumProcessorSchedulingStatus.Completed,
    EnumProcessorSchedulingStatus.DeniedByProcessor,
  ];

  // Get all pending bookings that have a drop off date in the future
  const pendingBookingsQueryFilter: FilterFindManyProcessorSchedulingInput = {
    vendor: vendor?._id,
    status: EnumProcessorSchedulingStatus.Requested,
    _operators: {},
  };

  // Get all bookings that are in an in progress status
  const bookingsInProgressQueryFilter: FilterFindManyProcessorSchedulingInput =
    {
      vendor: vendor?._id,
      OR: map(
        filter(
          shownStatuses,
          (s) =>
            (s as EnumProcessorSchedulingStatus) !==
            EnumProcessorSchedulingStatus.Completed,
        ),
        (s) => ({
          status: s as EnumProcessorSchedulingStatus,
        }),
      ),
    };

  // Get all jobs in a final state
  const completedBookingsQueryFilter: FilterFindManyProcessorSchedulingInput = {
    vendor: vendor?._id,
    OR: map(completedStatuses, (s) => ({
      status: s as EnumProcessorSchedulingStatus,
    })),
  };

  // Pagination Queries
  const pendingBookingsPaginationQuery =
    useProcessorSchedulingPaginationLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        sort: SortFindManyProcessorSchedulingInput.DropoffdateAsc,
        filter: pendingBookingsQueryFilter,
        page: 1,
        perPage: 5,
      },
    });
  const bookingsInProgressPaginationQuery =
    useProcessorSchedulingPaginationLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        sort: SortFindManyProcessorSchedulingInput.DropoffdateAsc,
        filter: bookingsInProgressQueryFilter,
        page: 1,
        perPage: 5,
      },
    });
  const completedBookingsPaginationQuery =
    useProcessorSchedulingPaginationLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        sort: SortFindManyProcessorSchedulingInput.DropoffdateAsc,
        filter: completedBookingsQueryFilter,
        page: 1,
        perPage: 10,
      },
    });

  const {
    data: processorSchedulingsData,
    loading: processorSchedulingsLoading,
  } = useProcessorSchedulingManyQuery({
    fetchPolicy: 'network-only',
    variables: { filter: { vendor: vendor?._id } },
  });

  return processorSchedulingsLoading ? (
    <Loading />
  ) : (
    <div className="pb-5">
      <Stack direction="horizontal" className="justify-content-between pb-2">
        <div>
          <h2 className="fw-bold">
            {user?.first_name ? `Welcome, ${user.first_name}!` : 'Welcome!'}
          </h2>
          <p className="m-0">
            Check out your current bookings and update the status of ongoing
            processes here.
          </p>
        </div>
        <GenerateKillsheetButton />
      </Stack>
      <TableContainer
        processorSchedulingsData={processorSchedulingsData}
        titleText="Pending Bookings"
        subText=""
        noResultsText="No Pending Bookings"
        icon={faHourglassHalf}
        buildFilter={pendingBookingsQueryFilter}
        query={pendingBookingsPaginationQuery}
        hideButtons
        rowActionButtons={['Review']}
        onChangeCallback={() => {
          pendingBookingsPaginationQuery[1].refetch();
          bookingsInProgressPaginationQuery[1].refetch();
          completedBookingsPaginationQuery[1].refetch();
        }}
      />
      <TableContainer
        processorSchedulingsData={processorSchedulingsData}
        titleText="Bookings In Progress"
        subText="Update Statuses to notify your customers!"
        noResultsText="No Bookings in Progress"
        icon={faCalendarCheck}
        buildFilter={bookingsInProgressQueryFilter}
        query={bookingsInProgressPaginationQuery}
        hideButtons
        rowActionButtons={['Details', 'Cancel']}
        onChangeCallback={() => {
          bookingsInProgressPaginationQuery[1].refetch();
          completedBookingsPaginationQuery[1].refetch();
        }}
        extraColumns={[ExtraColumnsEnum.StatusSelect]}
      />
      <TableContainer
        processorSchedulingsData={processorSchedulingsData}
        titleText="Completed Bookings"
        subText=""
        noResultsText="No Completed Bookings To Show"
        icon={faCalendarCheck}
        buildFilter={completedBookingsQueryFilter}
        query={completedBookingsPaginationQuery}
        rowActionButtons={['Details']}
        hideButtons
        onChangeCallback={() => {
          completedBookingsPaginationQuery[1].refetch();
        }}
        extraColumns={[
          ExtraColumnsEnum.StatusText,
          ExtraColumnsEnum.StatusLastUpdated,
        ]}
        perPageOverride={10}
      />
    </div>
  );
}
