import { ApolloQueryResult } from '@apollo/client';
import { Table } from '@farmshare/ui-components';
import { formatToCurrency } from '@farmshare/utils';
import { ceil, some, sumBy } from 'lodash';
import { useCallback } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userState } from 'state';

import {
  // useShipmentSplitMutation,
  type OrderLineItem,
  OrderByIdQuery,
} from 'lib/graphql';

interface ShipmentItemsProps {
  line_items: OrderLineItem[];
  refetch?: () => Promise<ApolloQueryResult<OrderByIdQuery>>;
}

export function ShipmentItems(props: ShipmentItemsProps) {
  const user = useRecoilValue(userState);

  // const { save } = useModal();

  // const [splitShipment, splitShipmentOperations] = useShipmentSplitMutation();

  const renderProductLink = useCallback(
    (row: OrderLineItem) => {
      if (some(user.tenant?.modules, (m) => m.name === 'products')) {
        return (
          <Link to={`/products/${row.product?._id}`}>{row.product?.name}</Link>
        );
      }
      return row.product?.name;
    },
    [user.tenant?.modules],
  );

  const renderWeight = useCallback(
    (val?: number | null) => (
      <>
        {val}&nbsp;
        <span className="small text-muted">
          {val && val > 1 ? '(lbs.)' : '(lb.)'}
        </span>
      </>
    ),
    [],
  );

  return (
    <>
      <Card.Header>
        {/* only show split button if refetch() is supplied */}
        <div className="d-flex">
          <div className="flex-grow-1">Items</div>
          {/* {props.refetch && props.line_items.length > 1 && (
            <Button
              type="button"
              content="Split Shipment"
              icon={faArrowsSplitUpAndLeft}
              size="sm"
              onClick={() =>
                save({
                  type: 'save',
                  title: 'Split Shipment',
                  icon: faArrowsSplitUpAndLeft,
                  isLoading: splitShipmentOperations.loading,
                  body: () => (
                    <div>
                      <div className="mb-3 fw-bold">
                        Which items would you like to split off?
                      </div>
                      {map(props.line_items, (li, idx) => (
                        <div key={idx}>
                          <InputCheck
                            label={li.product?.name || ''}
                            nameOveride={li._id}
                            inline
                          />
                        </div>
                      ))}
                    </div>
                  ),
                  initialValues: {},
                  onSubmit: async (values) => {
                    await splitShipment({ variables: { ids: keys(values) } });
                    if (props.refetch) {
                      props.refetch();
                    }
                  },
                })
              }
            />
          )} */}
        </div>
      </Card.Header>
      <Card.Body>
        <Table<OrderLineItem>
          rows={props.line_items}
          columns={[
            {
              label: '',
              field: '_id',
              formatter(_, rowNumber) {
                return `${rowNumber}.`;
              },
            },
            {
              label: 'Product',
              field: 'product',
              formatter: renderProductLink,
            },
            {
              label: 'Dimensions',
              field: 'product.dimensions',
              formatter(row) {
                if (row.product?.dimensions) {
                  const { length, width, height } = row.product.dimensions;
                  return (
                    <span>
                      {length}x{width}x{height}&nbsp;
                      <span className="text-muted small">(in.)</span>
                    </span>
                  );
                }
              },
              minimumBreakpoint: 'lg',
            },
            {
              label: 'Weight',
              field: 'product.weight',
              formatter(row) {
                return renderWeight(row.product?.weight);
              },
              minimumBreakpoint: 'lg',
            },
            {
              label: 'Price',
              field: 'product.price',
              formatter(row) {
                return formatToCurrency(row.product?.price ?? undefined);
              },
            },
            {
              label: 'Qty.',
              field: 'quantity',
              footer: { aggregation: sumBy },
            },
            {
              label: 'Total Weight',
              field: 'product.weight',
              formatter(row) {
                return renderWeight(
                  ceil(row?.quantity ?? 0 * (row.product?.weight || 1)),
                );
              },
              footer: {
                aggregation(collection) {
                  return sumBy(collection, (c) =>
                    ceil(c?.quantity ?? 0 * (c.product?.weight || 1)),
                  );
                },
                formatter: renderWeight,
              },
              minimumBreakpoint: 'lg',
            },
            {
              label: 'Total Price',
              field: 'total',
              formatter(row) {
                return formatToCurrency(row.total);
              },
              footer: {
                aggregation: sumBy,
                formatter: formatToCurrency,
              },
            },
          ]}
        />
      </Card.Body>
    </>
  );
}
