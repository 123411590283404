import {
  InputCheckGroup,
  InputSelect,
  InputText,
} from '@farmshare/ui-components';
import { FormikProps } from 'formik';
import { map, range } from 'lodash';
import { useMemo } from 'react';
import { Card, Stack } from 'react-bootstrap';

import { useProductManyQuery } from 'lib/graphql';

export interface SegmentModalForm {
  name: string;
  selector: 'all' | 'byPurchaseDate' | 'byProduct';
  selectedValue: string;
}

interface SegmentModalProps {
  formikProps: FormikProps<SegmentModalForm>;
}

export function SegmentModal(props: SegmentModalProps) {
  const { data } = useProductManyQuery();

  const selectors: {
    key: SegmentModalForm['selector'];
    heading: string;
    description?: string;
    options?: {
      label: string;
      value: number;
    }[];
  }[] = useMemo(() => {
    return [
      { heading: 'All Customers', key: 'all' },
      {
        key: 'byPurchaseDate',
        heading: 'Customers',
        description: 'who purchased in the last',
        options: map(range(30, 121, 30), (value) => ({
          label: `${value} days`,
          value,
        })),
      },
      {
        key: 'byProduct',
        heading: 'Customers',
        description: 'who purchased a specific product',
        options: map(data?.productMany, (product) => ({
          label: product.name || '',
          value: product._id,
        })),
      },
    ];
  }, [data?.productMany]);

  return (
    <Stack>
      <div>
        <InputText
          type="text"
          label="Name"
          hint="Give your segment a name."
          className="me-5"
          floatingLabel
          required
        />
        <hr />
        <div className="mb-md-3 fs-5">Choose filters below:</div>
        <Stack gap={3}>
          {map(selectors, (selector) => (
            <Card
              key={selector.key}
              className={
                props.formikProps.values.selector === selector.key
                  ? ''
                  : 'text-muted'
              }
              body
            >
              <Stack
                direction="horizontal"
                className="justify-content-between align-items-center"
                gap={3}
              >
                <InputCheckGroup
                  nameOveride="selector"
                  value={selector.key}
                  inline
                />
                <div
                  className={`fw-bold ${
                    !selector.description && !selector.options
                      ? 'flex-grow-1'
                      : ''
                  }`}
                >
                  {selector.heading}
                </div>
                {selector.description && <div>{selector.description}</div>}
                {selector.options && (
                  <InputSelect
                    nameOveride="selectedValue"
                    className="flex-grow-1"
                    options={selector.options}
                    disabled={
                      props.formikProps.values.selector !== selector.key
                    }
                    required={
                      props.formikProps.values.selector === selector.key
                    }
                  />
                )}
              </Stack>
            </Card>
          ))}
        </Stack>
      </div>
    </Stack>
  );
}
