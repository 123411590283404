import { FieldArray, useFormikContext } from 'formik';
import { find, findIndex, map, sortBy } from 'lodash';
import { Row, Stack } from 'react-bootstrap';

import { CapacityBlock } from './capacity-block';
import { type SettingsFormData } from './settings-form';

export function WeeklyCapacitySettings() {
  const { values } = useFormikContext<SettingsFormData>();

  return (
    <Stack className="justify-content-between">
      <div className="fw-bold fs-5">Set your standard weekly capacity</div>
      <p>This can be overidden for specific days below.</p>
      <Row className="gx-2 gy-3">
        <FieldArray
          name="capacitySettings"
          render={() => {
            return map(sortBy(values['daysOfWeek'], 'sortValue'), (day) => {
              const capacitySetting = find(values['capacitySettings'], {
                dayOfWeek: day.value,
              });
              const capacitySettingIdx = findIndex(values['capacitySettings'], {
                dayOfWeek: day.value,
              });

              return (
                <CapacityBlock
                  key={day.value}
                  parentBaseName={`capacitySettings.[${capacitySettingIdx}]`}
                  title={day.label}
                  dailyCapacitySetting={capacitySetting}
                  currentCapacityConfigurationType={
                    values.capacityConfigurationType
                  }
                />
              );
            });
          }}
        />
      </Row>
    </Stack>
  );
}
