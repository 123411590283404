export interface InputComponent {
  className?: string;
  disabled?: boolean;
  floatingLabel?: boolean;
  hidden?: boolean;
  hint?: string;
  label?: string;
  nameOveride?: string;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  showLabel?: boolean;
  size?: 'sm' | 'lg';
}

export { InputCheck, type InputCheckProps } from './input-check';
export {
  InputCheckGroup,
  type InputCheckGroupProps,
} from './input-check-group';
export { InputDateRange, type InputDateRangeProps } from './input-date-range';
export { InputEditor, type InputEditorProps } from './input-editor';
export { InputQuantity, type InputQuantityProps } from './input-quantity';
export {
  InputSelect,
  type InputSelectProps,
  type InputSelectOption,
} from './input-select';
export { InputSelectBar, type InputSelectBarProps } from './input-select-bar';
export { InputText, type InputTextProps } from './input-text';
export { InputTextArea, type InputTextAreaProps } from './input-textarea';
export { InputTypeahead, type InputTypeaheadProps } from './input-typeahead';
export {
  InputChips,
  type InputChipsProps,
  type InputChipOption,
} from './input-chips';

export { InputPhone } from './input-phone';
