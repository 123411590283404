import { PaginationTablePage } from '@farmshare/ui-components';
import { Nullable, formatToCurrency } from '@farmshare/utils';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { filter, map, startCase, isFinite } from 'lodash';
import { Link } from 'react-router-dom';

import {
  type Product,
  SortFindManyProductInput,
  EnumProductStock_Status,
  useProductPaginationLazyQuery,
  type ProductPagination,
  type ProductPaginationQueryVariables,
  type ProductPaginationQuery,
  FilterFindManyProductInput,
  PackageDimensions,
} from 'lib/graphql';

const formatDimensionCell = (dimensions?: Nullable<PackageDimensions>) => {
  let dimensionStr = '';
  const lengthPart = dimensions?.length ? `${dimensions.length}x` : '-x';
  const widthPart = dimensions?.width ? `${dimensions.width}x` : '-x';
  const heightPart = dimensions?.height ? `${dimensions.height}` : '-';

  if (dimensions?.length || dimensions?.width || dimensions?.height) {
    dimensionStr = `${lengthPart}${widthPart}${heightPart}`;
  }

  if (dimensionStr === '') {
    return <span>-</span>;
  }

  return (
    <>
      {dimensionStr} <small className="text-muted">&nbsp;(in.)</small>
    </>
  );
};

export default function ProductsPage() {
  return (
    <div className="pt-4">
      <PaginationTablePage<
        Product,
        ProductPagination,
        ProductPaginationQuery,
        ProductPaginationQueryVariables,
        SortFindManyProductInput
      >
        paginationQuery={useProductPaginationLazyQuery({
          notifyOnNetworkStatusChange: true,
        })}
        buildFilterQuery={(allFilters, defaultSort, page, perPage) => {
          // Only show top level products in this list variants are accessible from the parent edit form
          let queryFilter: FilterFindManyProductInput = {
            AND: [
              { parent: null },
              {
                OR: [
                  { is_internal: false },
                  { _operators: { is_internal: { exists: false } } },
                ],
              },
            ],
          };
          const activeFilters = filter(allFilters, (f) => f.isActive || false);
          if (activeFilters?.length > 0) {
            queryFilter = {
              ...queryFilter,
              OR: map(activeFilters, (a) => ({
                stock_status: a.value as EnumProductStock_Status,
              })),
            };
          }
          return {
            filter: queryFilter,
            sort: defaultSort,
            page,
            perPage,
          };
        }}
        filters={map(EnumProductStock_Status, (e) => ({
          label: e.toString(),
          value: e,
        }))}
        defaultFilters={[EnumProductStock_Status.Instock]}
        defaultSort={SortFindManyProductInput.DateCreatedAsc}
        dataAccessor={(a) => a.productPagination as ProductPagination}
        title="My Products"
        columns={[
          {
            label: 'Name',
            field: 'name',
            formatter(row) {
              return <Link to={`/products/${row._id}`}>{row.name}</Link>;
            },
          },
          {
            label: 'Enabled',
            field: 'enabled',
            formatter: (row) => (
              <FontAwesomeIcon icon={row.enabled ? faCheck : faXmark} />
            ),
          },
          {
            label: 'Status',
            formatter: (row) => startCase(row.stock_status?.toString()),
            field: 'stock_status',
          },
          {
            label: 'Quantity',
            field: 'stock_quantity',
            formatter: ({ stock_quantity }) =>
              isFinite(stock_quantity) ? stock_quantity : '-',
          },
          {
            label: 'Weight',
            formatter: (row) => {
              if (isFinite(row.weight)) {
                return (
                  <span>
                    {row.weight}&nbsp;
                    <span className="small text-muted">
                      {row.weight && row.weight > 1 ? '(lbs.)' : '(lb.)'}
                    </span>
                  </span>
                );
              }
              return '-';
            },
            minimumBreakpoint: 'lg',
          },
          {
            label: 'Dimensions',
            field: 'dimensions',
            formatter: ({ dimensions }) => {
              return formatDimensionCell(dimensions);
            },
            minimumBreakpoint: 'lg',
          },
          {
            label: 'Price',
            field: 'price',
            formatter: (row) => formatToCurrency(row.price ?? undefined),
          },
        ]}
      />
    </div>
  );
}
