import { Button, useModal, useToastr } from '@farmshare/ui-components';
import { faPlay, faRefresh, faWrench } from '@fortawesome/free-solid-svg-icons';
import { mapKeys, snakeCase } from 'lodash';
import { useCallback } from 'react';
import { Stack } from 'react-bootstrap';

import {
  EnumIntegrationName,
  type Integration,
  useIntegrationInitAdminMutation,
  useIntegrationSyncAdminMutation,
  useIntegrationUpdateConfigMutation,
} from 'lib/graphql';

import {
  ConfigSendgridModal,
  type ConfigSendgridModalForm,
} from './config-sendgrid-modal';

interface SyncButtonsProps {
  integration: Integration;
  refetch?: () => Promise<unknown>;
}

export function IntegrationButtons({ integration, refetch }: SyncButtonsProps) {
  const [init, initOp] = useIntegrationInitAdminMutation();
  const [sync, syncOp] = useIntegrationSyncAdminMutation();

  const [updateConfig, updateConfigOp] = useIntegrationUpdateConfigMutation();

  const { save } = useModal();
  const { push } = useToastr();

  const renderInitButton = useCallback(
    (integration: Integration) => (
      <Button
        content="Init"
        icon={faPlay}
        size="sm"
        isLoading={initOp.loading}
        onClick={async () => {
          try {
            await init({ variables: { integrationId: integration._id } });
            push({
              title: 'Success',
              body: 'Integration init successful.',
              bg: 'primary',
              delay: 4000,
            });
          } catch (e) {
            push({
              title: 'Error',
              body: (e as string).toString(),
              bg: 'danger',
              delay: 4000,
            });
          }
        }}
      />
    ),
    [push, init, initOp.loading],
  );

  const renderSyncButton = useCallback(
    (integration: Integration) => (
      <Button
        content="Sync"
        icon={faRefresh}
        size="sm"
        isLoading={syncOp.loading}
        onClick={async () => {
          try {
            await sync({ variables: { integrationId: integration._id } });
            push({
              title: 'Success',
              body: 'Integration sync successful.',
              bg: 'primary',
              delay: 4000,
            });
          } catch (e) {
            push({
              title: 'Error',
              body: (e as string).toString(),
              bg: 'danger',
              delay: 4000,
            });
          }
        }}
      />
    ),
    [push, sync, syncOp],
  );

  switch (integration.name) {
    case EnumIntegrationName.WooCommerce:
      return (
        <Stack direction="horizontal" gap={2} className="justify-content-end">
          {renderInitButton(integration)}
          {renderSyncButton(integration)}
        </Stack>
      );

    case EnumIntegrationName.GrazeCart:
      return (
        <Stack direction="horizontal" gap={2} className="justify-content-end">
          {renderSyncButton(integration)}
        </Stack>
      );

    case EnumIntegrationName.Sendgrid:
      return (
        <Stack direction="horizontal" gap={2} className="justify-content-end">
          {renderInitButton(integration)}
          {renderSyncButton(integration)}
          <Button
            content="Config"
            icon={faWrench}
            size="sm"
            onClick={() => {
              save<ConfigSendgridModalForm>({
                type: 'save',
                title: 'Sengrid Config',
                icon: faWrench,
                initialValues: {
                  senderId: integration.config?.sender_id,
                  suppressionGroupId: integration.config?.suppression_group_id,
                  // newsletterDesignId: integration.config?.newsletter_design_id,
                  // newsletterTemplateId:
                  //   integration.config?.newsletter_template_id,
                  newsletterHeaderUrl:
                    integration.config?.newsletter_header_url,
                },
                isLoading: updateConfigOp.loading,
                body: <ConfigSendgridModal />,
                onSubmit: async (values) => {
                  await updateConfig({
                    variables: {
                      integrationId: integration._id,
                      config: mapKeys(values, (_, key) => snakeCase(key)),
                    },
                  });
                  push({
                    title: 'Success',
                    body: 'Integration config saved.',
                    bg: 'primary',
                    delay: 4000,
                  });
                  if (refetch) {
                    refetch();
                  }
                },
              });
            }}
          />
        </Stack>
      );

    case EnumIntegrationName.Shopify:
      return (
        <Stack direction="horizontal" gap={2} className="justify-content-end">
          {renderSyncButton(integration)}
        </Stack>
      );

    case EnumIntegrationName.ShopifyPrivate:
      return (
        <Stack direction="horizontal" gap={2} className="justify-content-end">
          {renderInitButton(integration)}
          {renderSyncButton(integration)}
        </Stack>
      );

    case EnumIntegrationName.Stripe:
      return (
        <Stack direction="horizontal" gap={2} className="justify-content-end">
          {renderInitButton(integration)}
        </Stack>
      );

    default:
      return null;
  }
}
