import { Button, InputText, useModal } from '@farmshare/ui-components';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { startsWith } from 'lodash';

import { useTenantSubscriptionEditMutation } from 'lib/graphql';

interface EditSubscriptionButtonForm {
  subscription: string;
}

interface EditSubscriptionButtonProps extends EditSubscriptionButtonForm {
  tenantId: string;
  refetch: () => Promise<unknown>;
}

export function EditSubscriptionButton({
  tenantId,
  subscription,
  refetch,
}: EditSubscriptionButtonProps) {
  const { save } = useModal();

  const [editSubscription, editSubscriptionOp] =
    useTenantSubscriptionEditMutation();

  return (
    <Button
      content="Edit Subscription"
      size="sm"
      icon={faEdit}
      isLoading={editSubscriptionOp.loading}
      onClick={() =>
        save<EditSubscriptionButtonForm>({
          type: 'save',
          title: 'Edit Subscription',
          icon: faEdit,
          isLoading: editSubscriptionOp.loading,
          initialValues: { subscription },
          body: (
            <InputText
              type="text"
              label="Subscription"
              floatingLabel
              required
            />
          ),
          validate: (values) => {
            const errors: Partial<
              Record<keyof EditSubscriptionButtonForm, string>
            > = {};

            if (!startsWith(values.subscription, 'si_')) {
              errors.subscription = 'Subscription Item must start with si_';
            }

            return errors;
          },
          onSubmit: async ({ subscription }) => {
            await editSubscription({
              variables: { tenantId, subscription },
            });
            await refetch();
          },
        })
      }
    />
  );
}
