import { DayCellContentArg } from '@fullcalendar/core';

import styles from './calendar-date-cell.module.scss';

export const CalendarDateCell = (test: DayCellContentArg) => {
  return (
    <div>
      <div>{test.dayNumberText}</div>
      <div className={`${styles.calendarDateCellLink} cell-agenda-link`}>
        View Day's Agenda
      </div>
    </div>
  );
};
