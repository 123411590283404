import {
  Button,
  // InfoBox,
  InputCheck,
  InputText,
} from '@farmshare/ui-components';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ArrayHelpers, FieldArray, FormikProps } from 'formik';
import { map } from 'lodash';
import { Alert, Card, Col, Row } from 'react-bootstrap';

import { RatesTables } from 'components/rates-tables/rates-tables';

import type { ShipmentRate } from 'lib/graphql';

export interface RatesModalForm {
  schedulePickup?: boolean;
  pickupDate?: string;
  sendNotifications?: boolean;
  notificationEmails?: string[];
  chosenRate?: string;
}

export interface RatesModalProps {
  formikProps: FormikProps<RatesModalForm>;
  hasFarmsharePackaging: boolean;
  rates?: ShipmentRate[];
}

export function RatesModal({
  formikProps,
  // hasFarmsharePackaging,
  rates,
}: RatesModalProps) {
  return (
    <div>
      {formikProps.submitCount > 0 && !formikProps.isValid && (
        <Alert variant="danger">
          <p>Please correct the following errors:</p>
          <ul>
            {map(formikProps.errors, (e, idx) => (
              <li key={idx}>{e}</li>
            ))}
          </ul>
        </Alert>
      )}
      <div className="mb-3">
        <Row className="g-3">
          <Col xs={12} md={6}>
            <Card className="h-100" body>
              <InputCheck type="switch" label="Schedule Pickup?" />
              <InputText
                type="date"
                label="Pickup Date"
                hint="Pickups will occur between 9AM and 5PM local time."
                required={formikProps.values.schedulePickup}
                disabled={!formikProps.values.schedulePickup}
                className="mt-3"
              />
            </Card>
          </Col>
          {/* <Col xs={12} md={4}>
            <Card className="h-100" body>
              {!hasFarmsharePackaging && (
                <InfoBox
                  content={
                    <span className="small">
                      Packing recommendations are only available if using
                      Farmshare provided packaging.
                    </span>
                  }
                />
              )}
              <InputCheck
                type="switch"
                label="Packing Recommendations?"
                disabled={!hasFarmsharePackaging}
                hint="Get personalized coolant recommendations for this shipment."
              />
            </Card>
          </Col> */}
          <Col xs={12} md={6}>
            <Card className="h-100" body>
              <InputCheck type="switch" label="Send Notifications?" />
              <FieldArray
                name="notificationEmails"
                render={(arrayHelpers: ArrayHelpers) => (
                  <div>
                    {map(
                      formikProps.values.notificationEmails,
                      (_, idx, arr) => (
                        <Row key={idx} className="mt-3 align-items-end">
                          <Col xs={8}>
                            <InputText
                              label={`Email ${idx + 1}`}
                              nameOveride={`notificationEmails.${idx}`}
                              required={formikProps.values.sendNotifications}
                              disabled={
                                idx === 0 ||
                                !formikProps.values.sendNotifications
                              }
                              type="email"
                            />
                          </Col>
                          <Col xs={2}>
                            {idx > 0 && (
                              <Button
                                icon={faTrash}
                                onClick={() => arrayHelpers.remove(idx)}
                                disabled={!formikProps.values.sendNotifications}
                              />
                            )}
                          </Col>
                          <Col xs={2}>
                            {idx + 1 === arr.length && (
                              <Button
                                icon={faPlus}
                                onClick={() => arrayHelpers.push('')}
                                disabled={!formikProps.values.sendNotifications}
                              />
                            )}
                          </Col>
                        </Row>
                      ),
                    )}
                  </div>
                )}
              />
            </Card>
          </Col>
        </Row>
      </div>
      <RatesTables<RatesModalForm> rates={rates} formikProps={formikProps} />
    </div>
  );
}
