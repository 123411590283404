import { useToastr } from '@farmshare/ui-components';
import { faArrowLeft, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Button, Stack } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Maybe, ViewProcessorAdminQueryResult } from 'lib/graphql';

import { CreateScheduling } from 'pages/scheduling/_views/create-scheduling';
import {
  SchedulingSelectForm,
  dateFormat,
} from 'pages/scheduling/_views/scheduling-select-form';

interface ProcessorAddJobTabProps {
  settings?: Maybe<ViewProcessorAdminQueryResult>;
}

export function ProcessorAddJobTab({ settings }: ProcessorAddJobTabProps) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { push } = useToastr();

  const [selection, setSelection] = useState<{
    animalSpecies: string;
    date: moment.Moment;
  }>();

  const handleCancel = useCallback(() => {
    setSelection(undefined);
  }, [setSelection]);

  const handleCreateSuccess = useCallback(() => {
    push({
      title: 'Booking Success',
      body: 'New booking created successfully.',
      bg: 'primary',
      delay: 4000,
    });
    setSelection(undefined);
    navigate('/processor/bookings');
  }, [push, setSelection, navigate]);

  useEffect(() => {
    const animalSpecies = searchParams.get('animalSpecies');
    const date = searchParams.get('date');
    if (animalSpecies && date) {
      setSelection({ animalSpecies, date: moment(date) });
    }
  }, [searchParams, setSelection]);

  useEffect(() => {
    if (selection) {
      setSearchParams({
        animalSpecies: selection.animalSpecies,
        date: selection.date.format('YYYY-MM-DD'),
      });
    }
  }, [selection, setSearchParams]);

  return (
    <Stack>
      <div>
        <Stack direction="horizontal" gap={2} className="align-items-start">
          {selection && (
            <Button
              variant="ghost"
              onClick={() => {
                setSearchParams(undefined);
                setSelection(undefined);
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="lg" />
            </Button>
          )}
          <h2 className="fw-bold">Add Job</h2>
        </Stack>
        <p className="mb-0">
          Use this form to add a job on behalf of your customers.
        </p>
        <hr />
      </div>
      {selection ? (
        <CreateScheduling
          animalSpecies={selection.animalSpecies}
          date={selection.date.format(dateFormat)}
          vendorId={settings?.data?.processorSettingsOne?.vendor?._id}
          isOnBehalfOf={true}
          onCancel={handleCancel}
          onCreateSuccess={handleCreateSuccess}
          submitButtonLabel="Create Scheduled Job"
          submitButtonIcon={faCheck}
        />
      ) : (
        <SchedulingSelectForm
          onSelect={(animalSpecies, date) =>
            setSelection({ animalSpecies, date })
          }
          vendorId={settings?.data?.processorSettingsOne?.vendor?._id}
        />
      )}
    </Stack>
  );
}
