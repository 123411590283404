import { OperationVariables, QueryResult } from '@apollo/client';
import {
  ActionButtons,
  type ButtonProps,
  useToastr,
  useModal,
} from '@farmshare/ui-components';
import {
  faCalendar,
  faChartLine,
  faEye,
  faStop,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useMemo } from 'react';

import {
  useCampaignScheduleMutation,
  type Campaign,
  EnumCampaignStatus,
  useCampaignPreviewMutation,
  useCampaignUnscheduleMutation,
} from 'lib/graphql';

import {
  CampaignPreviewForm,
  CampaignPreviewModal,
} from './campaign-preview-modal';
import {
  CampaignScheduleForm,
  CampaignScheduleModal,
} from './campaign-schedule-modal';
import { CampaignStatsModal } from './campaign-stats-modal';

export interface CampaignButtonsProps<
  TQuery,
  TVars extends OperationVariables,
> {
  campaign: Campaign;
  operation: QueryResult<TQuery, TVars>;
}

export function CampaignButtons<TQuery, TVars extends OperationVariables>({
  campaign,
  operation,
}: CampaignButtonsProps<TQuery, TVars>) {
  const [schedule, scheduleOp] = useCampaignScheduleMutation();
  const [unschedule, unscheduleOp] = useCampaignUnscheduleMutation();
  const [preview, previewOp] = useCampaignPreviewMutation();

  const { ask, info, save } = useModal();
  const { push } = useToastr();

  const buttons = useMemo(() => {
    const _buttons: ButtonProps[] = [];

    switch (campaign.status) {
      case EnumCampaignStatus.Draft:
        _buttons.push({
          content: 'Preview',
          icon: faEye,
          isLoading: previewOp.loading,
          onClick: async () => {
            save<CampaignPreviewForm>({
              type: 'save',
              title: 'Preview Campaign',
              initialValues: { emails: [''] },
              // size: 'xl',
              icon: faEye,
              body: (fP) => <CampaignPreviewModal formikProps={fP} />,
              onSubmit: async ({ emails }) => {
                await preview({
                  variables: { campaignId: campaign._id, emails },
                });
                push({
                  title: 'Success',
                  body: 'Campaign preview sent.',
                  bg: 'info',
                  delay: 4000,
                });
                operation.refetch();
              },
            });
          },
        });
        _buttons.push({
          content: 'Schedule',
          icon: faCalendar,
          isLoading: scheduleOp.loading,
          onClick: async () => {
            save<CampaignScheduleForm>({
              type: 'save',
              title: 'Schedule Campaign',
              initialValues: {
                sendAt: 'now',
                sendDate: moment()
                  .add(1, 'day')
                  .startOf('hour')
                  .format('yyyy-MM-DD kk:mm:ss'),
              },
              icon: faCalendar,
              body: (fP) => <CampaignScheduleModal formikProps={fP} />,
              onSubmit: async ({ sendAt, sendDate }) => {
                await schedule({
                  variables: {
                    campaignId: campaign._id,
                    sendAt:
                      sendAt === 'now' ? 'now' : moment(sendDate).toISOString(),
                  },
                });
                push({
                  title: 'Success',
                  body: 'Campaign successfully scheduled.',
                  bg: 'info',
                  delay: 4000,
                });
                operation.refetch();
              },
            });
          },
        });
        break;
      case EnumCampaignStatus.Scheduled:
        _buttons.push({
          content: 'Deactivate',
          icon: faStop,
          onClick: () =>
            ask({
              type: 'ask',
              title: 'Unschedule Campaign?',
              body: <p>Are you sure you want to unschedule this campaign?</p>,
              onConfirm: async () => {
                await unschedule({
                  variables: { campaignId: campaign._id },
                });
                push({
                  title: 'Success',
                  body: 'Campaign successfully scheduled.',
                  bg: 'info',
                  delay: 4000,
                });
                operation.refetch();
              },
            }),
        });
        break;
      case EnumCampaignStatus.Triggered:
        _buttons.push({
          content: 'Stats',
          icon: faChartLine,
          isLoading: unscheduleOp.loading,
          onClick: () =>
            info({
              type: 'info',
              title: 'Campaign Stats',
              icon: faChartLine,
              body: <CampaignStatsModal campaign={campaign} />,
              isLoading: unscheduleOp.loading,
            }),
        });
        break;
    }

    return _buttons;
  }, [
    campaign,
    previewOp.loading,
    scheduleOp.loading,
    unscheduleOp.loading,
    save,
    preview,
    push,
    operation,
    schedule,
    ask,
    unschedule,
    info,
  ]);

  return <ActionButtons buttons={buttons} />;
}
