import { animalSpeciesHelper } from '@farmshare/utils';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { concat, map, reduce } from 'lodash';
import { Fragment, useMemo, useState } from 'react';
import { Alert } from 'react-bootstrap';

import {
  Maybe,
  ProcessorSettingsValidationErrors,
  ViewProcessorAdminQueryResult,
} from 'lib/graphql';

interface ConfigurationErrorsProps {
  settings?: Maybe<ViewProcessorAdminQueryResult>;
}

type acc = { [key: string]: string[] };

const groupErrorMessages = (
  validationErrors: Maybe<ProcessorSettingsValidationErrors>[] = [],
) => {
  const grouped = reduce<Maybe<ProcessorSettingsValidationErrors>, acc>(
    validationErrors,
    (acc, curr) => {
      const messages = (curr?.messages ?? []) as string[];
      if (curr?.type === 'processor' || curr?.type === 'splitType') {
        acc.Processor
          ? acc.Processor.push(...messages)
          : (acc.Processor = concat([], messages));
      }

      if (curr?.type === 'animal' && curr?.group) {
        acc[`${curr.group}`]
          ? acc[`${curr.group}`].push(...messages)
          : (acc[`${curr.group}`] = concat([], messages));
      }

      return acc;
    },
    {},
  );

  return grouped;
};

export const ConfigurationErrors = ({ settings }: ConfigurationErrorsProps) => {
  const [isCollapsed, setCollapseAlert] = useState(false);

  const validationErrors = useMemo(() => {
    return settings?.data?.processorSettingsOne?.validationErrors ?? [];
  }, [settings]);

  const groupedErrors = useMemo(() => {
    return groupErrorMessages(validationErrors);
  }, [validationErrors]);

  if (Object.keys(groupedErrors).length === 0) {
    return null;
  }
  const handleClick = () => setCollapseAlert(!isCollapsed);

  return (
    <Alert variant="danger">
      <Alert.Heading>
        <div className="d-flex">
          <div>Processor Configuration Errors</div>
          <div className="ms-auto" onClick={handleClick}>
            <FontAwesomeIcon icon={isCollapsed ? faAngleDown : faAngleUp} />
          </div>
        </div>
      </Alert.Heading>
      {!isCollapsed && (
        <div>
          {map(groupedErrors, (group, key) => (
            <Fragment key={key}>
              {key !== 'Processor' && <h5>{animalSpeciesHelper(key).label}</h5>}
              <ul>
                {map(group, (err, innerKey) => (
                  <li key={innerKey}>{err}</li>
                ))}
              </ul>
            </Fragment>
          ))}
        </div>
      )}
    </Alert>
  );
};
