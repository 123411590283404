import { LazyQueryResultTuple, OperationVariables } from '@apollo/client';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import { CalendarOptions, EventSourceInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import moment, { Moment } from 'moment';
import { ReactNode, createRef, useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { BigCalendarHeader } from './big-calendar-header';
import styles from './big-calendar.module.scss';
import { PluginOptions, mobilePlugin } from './plugins/mobile-plugin';
import { useBreakpoint } from '../../hooks/use-breakpoint/use-breakpoint';

export type BigCalendarProps<
  TData,
  TQuery,
  TVars extends OperationVariables,
> = Omit<CalendarOptions, 'dateClick'> & {
  dateClick: (date: Moment, events: TData[]) => void;
  query: LazyQueryResultTuple<TQuery, TVars>;
  buildQueryVariables: (startDate: string, endDate: string) => TVars;
  eventsAccessor: (r: TQuery) => EventSourceInput | undefined;
  mobileOptions: PluginOptions;
  headerLeftPanel?: () => ReactNode;
  headerRightPanel?: () => ReactNode;
};

export function BigCalendar<TData, TQuery, TVars extends OperationVariables>({
  dateClick,
  query,
  buildQueryVariables,
  eventsAccessor,
  mobileOptions,
  headerLeftPanel,
  headerRightPanel,
  ...nativeProps
}: BigCalendarProps<TData, TQuery, TVars>) {
  const calendarRef = createRef<FullCalendar>();
  const breakpoint = useBreakpoint();

  const [currentDate, setCurrentDate] = useState<Date | undefined>(
    calendarRef.current?.getApi().getDate(),
  );

  const [selectedDate, setSelectedDate] = useState<Moment>(
    moment(calendarRef.current?.getApi().getDate()).startOf('day'),
  );

  const [startOfPeriod, endOfPeriod] = useMemo(() => {
    return [
      moment(currentDate)
        .startOf('months')
        .subtract({ day: 1 })
        .format('YYYY-MM-DD'),
      moment(currentDate).endOf('months').add({ day: 1 }).format('YYYY-MM-DD'),
    ];
  }, [currentDate]);

  const [loadData, { data }] = query;

  useEffect(() => {
    loadData({
      variables: buildQueryVariables(startOfPeriod, endOfPeriod),
    });
  }, [startOfPeriod, endOfPeriod, buildQueryVariables]);

  // queueMicrotask is nessesary here because Fullcalendar uses flushSync under the hood.
  useEffect(() => {
    if (['sm', 'xs'].includes(breakpoint)) {
      queueMicrotask(() => {
        calendarRef.current?.getApi().changeView('mobileView');
      });
    } else {
      queueMicrotask(() => {
        calendarRef.current?.getApi().changeView('dayGridMonth');
      });
    }
  }, [breakpoint, calendarRef]);

  const currentView: string = useMemo(() => {
    if (['sm', 'xs'].includes(breakpoint)) {
      return 'mobileView';
    }

    return 'dayGridMonth';
  }, [breakpoint]);

  const dateRangeHeader = useMemo(() => {
    const date = moment(currentDate);

    if (currentView === 'mobileView') {
      return (
        date.startOf('week').format('MMM DD') +
        ' - ' +
        date.endOf('week').format('MMM DD') +
        ', ' +
        date.endOf('week').format('YYYY')
      );
    }

    return date.format('MMMM, YYYY');
  }, [currentView, currentDate]);

  const onWindowResize = () => {
    const currentDate = new Date();

    calendarRef.current?.getApi().changeView(currentView, currentDate);
    setCurrentDate(currentDate);
    setSelectedDate(moment(currentDate));
  };

  const events = useMemo(
    () => data && eventsAccessor(data),
    [data, eventsAccessor],
  );

  const onDateClick = (e: DateClickArg) => {
    const allEvents = (events as Array<TData & { start: string }>).filter(
      (event) => moment(event.start).format('YYYY-MM-DD') === e.dateStr,
    );

    dateClick(moment(e.date), allEvents as Array<TData>);
    calendarRef.current?.getApi().select(e.date);

    setSelectedDate(moment(e.date));
  };

  return (
    <div className={styles.bigCalendar}>
      <Container>
        <Row>
          <Col sm={12} md className={styles.bigCalendarHeaderPanel}>
            {headerLeftPanel && headerLeftPanel()}
          </Col>
          <Col sm={12} md={3} className={styles.bigCalendarHeader}>
            <BigCalendarHeader
              dateRangeHeader={dateRangeHeader}
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              calendarRef={calendarRef}
            />
          </Col>
          <Col sm={12} md className={styles.bigCalendarHeaderPanel}>
            {headerRightPanel && headerRightPanel()}
          </Col>
        </Row>
      </Container>
      <FullCalendar
        dayCellClassNames={styles.bigCalendarDay}
        ref={calendarRef}
        plugins={[
          dayGridPlugin,
          interactionPlugin,
          bootstrap5Plugin,
          mobilePlugin(mobileOptions),
        ]}
        initialView={currentView}
        windowResize={onWindowResize}
        fixedWeekCount={false}
        height="auto"
        headerToolbar={{
          left: '',
          center: '',
          right: '',
        }}
        events={events}
        dateClick={onDateClick}
        {...nativeProps}
      />
    </div>
  );
}
