import { Button, InfoBox, InputText } from '@farmshare/ui-components';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FieldArray, FormikProps } from 'formik';
import { map } from 'lodash';
import { Col, Row, Stack } from 'react-bootstrap';

import { CampaignPreviewFrame } from './campaign-preview-frame';

export interface CampaignPreviewForm {
  emails: string[];
}

interface CampaignPreviewModalProps {
  formikProps: FormikProps<CampaignPreviewForm>;
}

export function CampaignPreviewModal(props: CampaignPreviewModalProps) {
  return (
    <div>
      <FieldArray
        name="emails"
        render={(a) => (
          // <Row md={2}>
          //   <Col>
          <Stack gap={3}>
            <InfoBox
              content={
                <span>
                  Enter up to <b>10 emails</b>&nbsp;to send a test to.
                </span>
              }
            />
            {map(props.formikProps.values.emails, (_, idx) => (
              <Row key={idx} className="align-items-center">
                <Col xs={10}>
                  <InputText
                    label={`Email ${idx + 1}`}
                    nameOveride={`emails.${idx}`}
                    type="email"
                    floatingLabel
                    required
                  />
                </Col>
                <Col xs={2}>
                  {idx > 0 && (
                    <Button
                      content="Remove"
                      size="sm"
                      variant="secondary"
                      icon={faMinus}
                      className="me-2"
                      onClick={() => a.remove(idx)}
                    />
                  )}
                </Col>
              </Row>
            ))}
            <div className="d-flex justify-content-start">
              <Button
                content="Add Email"
                variant="secondary"
                icon={faPlus}
                className="me-2"
                onClick={() => a.push('')}
                disabled={props.formikProps.values.emails.length >= 10}
                size="sm"
              />
            </div>
          </Stack>
          // </Col>
          // <Col>
          // <CampaignPreviewFrame />
          // </Col>
          // </Row>
        )}
      />
    </div>
  );
}
