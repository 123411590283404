import { IToastrContext } from '@farmshare/ui-components';
import moment from 'moment';

import {
  EnumProcessorSchedulingStatus,
  ProcessorScheduling,
} from 'lib/graphql';

import { StatusChanges } from '../hooks/useStatusChanges';

export const handleAcceptBooking = async (
  row: ProcessorScheduling,
  updateScheduling: StatusChanges['updateScheduling'],
  pushSuccessModal: IToastrContext['push'],
  callback?: (id: string, newStatus: EnumProcessorSchedulingStatus) => void,
) => {
  // Update status
  await updateScheduling({
    variables: {
      id: row._id,
      record: {
        status: EnumProcessorSchedulingStatus.Scheduled,
      },
    },
  });

  // Show success
  pushSuccessModal({
    title: 'Request Accepted!',
    body: row.requestedBy?.first_name
      ? `${
          row.requestedBy?.first_name || 'Customer'
        } will be coming in on ${moment(row.dropoffDate).format(
          'LL',
        )} for processing!`
      : '',
    bg: 'primary',
    delay: 4000,
  });

  // Calling a callback
  callback?.(row._id, EnumProcessorSchedulingStatus.Scheduled);
};

export const handleDenyBooking = async (
  row: ProcessorScheduling,
  updateScheduling: StatusChanges['updateScheduling'],
  pushSuccessModal: IToastrContext['push'],
  callback?: (id: string, newStatus: EnumProcessorSchedulingStatus) => void,
) => {
  await updateScheduling({
    variables: {
      id: row._id,
      record: {
        status: EnumProcessorSchedulingStatus.DeniedByProcessor,
      },
    },
  });

  // Show success
  pushSuccessModal({
    title: 'Request Denied!',
    body: `Job for ${moment(row.dropoffDate).format('LL')} was denied.`,
    bg: 'primary',
    delay: 4000,
  });

  // Calling a callback
  callback?.(row._id, EnumProcessorSchedulingStatus.DeniedByProcessor);
};

export const handleCancelBooking = async (
  row: ProcessorScheduling,
  values: any,
  updateScheduling: StatusChanges['updateScheduling'],
  callback?: (id: string, newStatus: EnumProcessorSchedulingStatus) => void,
  cancellationReasonId?: string,
) => {
  if (!cancellationReasonId) {
    console.error('Unable to find cancellation reason ID for: ', values.reason);
    return;
  }

  // Update status and cancel details. This should send us an email through GQL too.
  await updateScheduling({
    variables: {
      id: row._id,
      record: {
        cancelledAt: moment().utc().format(),
        cancellationReason: cancellationReasonId,
        cancellationCustomText: values.details,
        status: EnumProcessorSchedulingStatus.CancelledByProcessor,
      },
    },
  });

  // Calling a callback
  callback?.(row._id, EnumProcessorSchedulingStatus.CancelledByProcessor);
};
