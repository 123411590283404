export function animalTypeHelper(value: string): {
  label: string;
  value: string;
  order: number;
} {
  switch (value.toLowerCase()) {
    case 'exempt_beef':
      return { label: 'Exempt Beef', order: 0, value };
    case 'state_beef':
      return { label: 'State Beef', order: 1, value };
    case 'usda_beef':
      return { label: 'USDA Beef', order: 2, value };

    case 'exempt_hog':
      return { label: 'Exempt Hog', order: 3, value };
    case 'state_hog':
      return { label: 'State Hog', order: 4, value };
    case 'usda_hog':
      return { label: 'USDA Hog', order: 5, value };

    default:
      return { label: value, order: 99, value };
  }
}
