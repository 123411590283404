export function capacityConfigurationHelper(value: string): {
  label: string;
  value: string;
  order: number;
} {
  switch (value.toLowerCase()) {
    case 'species':
      return { label: 'By Species', order: 0, value };

    case 'species_inspection':
      return { label: 'By Species and Inspection', order: 1, value };

    case 'weighted_animal_unit':
      return { label: 'By Weighted Animal Unit', order: 2, value };

    default:
      return { label: value, order: 99, value };
  }
}
