import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map } from 'lodash';
import { useContext } from 'react';
import {
  Container,
  Accordion,
  CardHeader,
  AccordionContext,
  useAccordionButton,
  Card,
  AccordionCollapse,
  CardBody,
} from 'react-bootstrap';

import styles from './faq.module.scss';

interface faq {
  title: string;
  mainText: string;
}

export default function FAQPage() {
  const farmshareFAQs: faq[] = [
    {
      title: 'What is Farmshare?',
      mainText: `Farmshare is your one stop shop for selling direct to market. On top of helping meat processors manage their businesses, we provide a suite of tools for farmers to sell directly to consumers. From packaging, shipping, and marketing your products, we can help you run your business and farm easier than ever before.`,
    },
    {
      title: 'Why is a deposit required?',
      mainText: `Our processors have tight schedules and we want to ensure everyone shows up to their scheduled days. This deposit is simply used to reserve your spot and will be returned to you as soon as your processing is completed! If a processor cancels on you due to unforeseen circumstances, you'll be fully refunded as well.`,
    },
    {
      title: 'How do I cancel or change my booking?',
      mainText: `If you need to cancel or change your booking, you should reach out to the processor you scheduled with. You can view their contact information in your confirmation email and on their scheduling page.`,
    },
    {
      title: 'How do refunds work?',
      mainText: `If you cancel your booking within the designated deadline window, you will receive a refund within a few days. If you cancel outside of the deadline window, your booking will still be cancelled but you will forfeit your deposit.`,
    },
    {
      title: 'I need more help!',
      mainText: `Feel free to use the chat bubble in the bottom right corner of your screen to chat with us, we're happy to help!`,
    },
  ];

  const AccordionHeader = ({
    children,
    eventKey,
  }: {
    children: any;
    eventKey: string;
  }) => {
    const { activeEventKey } = useContext(AccordionContext);
    const accordionClick = useAccordionButton(eventKey);
    const isCurrentEventKey = activeEventKey === eventKey;

    return (
      <CardHeader
        className={`${styles.cardHeader} py-4`}
        onClick={accordionClick}
      >
        <div className="d-flex justify-content-between align-items-center">
          {children}
          <FontAwesomeIcon
            icon={isCurrentEventKey ? faChevronUp : faChevronDown}
          />
        </div>
      </CardHeader>
    );
  };

  return (
    <div className="pt-4">
      <Container>
        <h1 className="fw-bold">FAQs</h1>
        <p className="mb-4">
          Find the most commonly asked questions using Farmshare here!
        </p>
        <Accordion defaultActiveKey="0">
          {map(farmshareFAQs, (faq, key) => {
            return (
              <Card
                className={`bg-body-secondary mb-3 rounded-top-4 rounded-bottom-0`}
              >
                <AccordionHeader eventKey={key.toString()}>
                  <h3 className="fw-bold mb-0">{faq.title}</h3>
                </AccordionHeader>
                <AccordionCollapse eventKey={key.toString()}>
                  <CardBody>{faq.mainText}</CardBody>
                </AccordionCollapse>
              </Card>
            );
          })}
        </Accordion>
      </Container>
    </div>
  );
}
