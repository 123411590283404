import { DataDetailList } from '@farmshare/ui-components';
import { countBy, map, startCase } from 'lodash';
import { useMemo } from 'react';

import type { Campaign } from 'lib/graphql';

interface CampaignStatsModalProps {
  campaign: Campaign;
}

export function CampaignStatsModal(props: CampaignStatsModalProps) {
  const stats = useMemo(
    () => countBy(props.campaign.events, (e) => e.event),
    [props.campaign.events],
  );

  return (
    <div className="px-3 px-lg-5">
      <DataDetailList
        rows={map(stats, (value, label) => ({
          label: startCase(label),
          value,
        }))}
      />
    </div>
  );
}
