import { InputText } from '@farmshare/ui-components';
import { Stack } from 'react-bootstrap';

export interface ConfigSendgridModalForm {
  senderId: number;
  suppressionGroupId: number;
  // newsletterDesignId: string;
  // newsletterTemplateId: string;
  newsletterHeaderUrl: string;
}

export function ConfigSendgridModal() {
  return (
    <Stack gap={3} className="px-md-3">
      <InputText
        label="Sender Id"
        type="number"
        hint="The primary sender used for email communication."
        floatingLabel
        required
      />
      <InputText
        label="Suppression Group Id"
        type="number"
        hint="The group that all unsubscribes will be sent to."
        floatingLabel
        required
      />
      {/* <InputText
        label="Newsletter Design Id"
        type="text"
        hint="The design library id for newsletters."
        floatingLabel
        required
      /> */}
      <InputText
        label="Newsletter Header Url"
        type="url"
        hint="The url for the newsletter header image."
        floatingLabel
        required
      />
    </Stack>
  );
}
