import { Duration, sliceEvents } from '@fullcalendar/core';
import { DateProfile, ViewProps } from '@fullcalendar/core/internal';

import { ProcessorScheduling } from 'lib/graphql';

import {
  ProcessorSchedulingDetails,
  ProcessorSchedulingDetailsProps,
} from './processor-scheduling-details';

export function MobileCustomView(
  detailsProps: Omit<ProcessorSchedulingDetailsProps, 'events'>,
) {
  return (
    props: ViewProps & { dateProfile: DateProfile; nextDayThreshold: Duration },
  ) => {
    const segs = sliceEvents(props);
    const events = segs.map((seg) => {
      return seg.def.extendedProps as ProcessorScheduling;
    });

    return <ProcessorSchedulingDetails {...detailsProps} events={events} />;
  };
}
