import { InputSelect, InputText } from '@farmshare/ui-components';
import { map, startCase } from 'lodash';
import { Stack } from 'react-bootstrap';

import { EnumUserRole } from 'lib/graphql';

export interface UserModalForm {
  firstName: string;
  lastName: string;
  email: string;
  role: EnumUserRole;
}

export function UserModal() {
  return (
    <Stack gap={3} className="mb-md-2">
      <InputText label="First Name" type="text" floatingLabel required />
      <InputText label="Last Name" type="text" floatingLabel required />
      <InputText label="Email" type="email" floatingLabel required />
      <InputSelect
        label="Role"
        options={map(EnumUserRole, (k) => ({ label: startCase(k), value: k }))}
        floatingLabel
        required
      />
    </Stack>
  );
}
