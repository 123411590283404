import { PageTitle } from '@farmshare/ui-components';
import { kebabCase, map, some } from 'lodash';
import { ReactNode, useEffect, useMemo } from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { JobDetailsTab } from './_views/job-details-tab';
import { JobNotificationsTab } from './_views/job-notifications-tab';
import { NotesTab } from './_views/notes-tab';
import { useProcessingJobPage } from './useProcessingJobPage';

interface Tab {
  key: string;
  title: string;
  element: ReactNode;
}

export function ProcessingJob() {
  const navigate = useNavigate();
  const { tab } = useParams();
  const [searchParams] = useSearchParams();

  const parentPage = useMemo(() => {
    return searchParams.get('parent') || 'bookings';
  }, [searchParams]);

  const {
    processingJobQueryResult,
    processingJobDisplayName,
    updateProcessingJob,
    updateProcessingJobOp,
    jobId,
  } = useProcessingJobPage();

  const tabs: Tab[] = useMemo(() => {
    const _tabs = [
      {
        title: 'Details',
        element: (
          <JobDetailsTab
            processingJob={processingJobQueryResult}
            updateProcessingJob={updateProcessingJob}
            updateProcessingJobOp={updateProcessingJobOp}
          />
        ),
      },
      {
        title: 'Notifications',
        element: (
          <JobNotificationsTab processingJob={processingJobQueryResult} />
        ),
      },
      {
        title: 'Notes',
        element: <NotesTab processingJob={processingJobQueryResult} />,
      },
    ];

    return map(_tabs, (t) => ({ ...t, key: kebabCase(t.title) }));
  }, [processingJobQueryResult, updateProcessingJob, updateProcessingJobOp]);

  useEffect(() => {
    if (!tab || !some(tabs, (t) => t.key === tab)) {
      navigate(`/processing-job/${jobId}/details?parent=${parentPage}`, {
        replace: true,
      });
    }
  }, [navigate, tab, tabs, jobId, parentPage]);

  return (
    <Container className="pt-3">
      <PageTitle
        title={processingJobDisplayName}
        showHr={false}
        innerBreadcrumbs={[
          { text: 'Bookings', to: `/processor/${parentPage}` },
        ]}
      />
      <Container>
        <Tabs
          activeKey={tab}
          onSelect={(tabName) => {
            if (tabName) {
              navigate(
                `/processing-job/${jobId}/${tabName}?parent=${parentPage}`,
              );
            }
          }}
        >
          {map(tabs, (tab, idx) => (
            <Tab key={idx} title={tab.title} eventKey={tab.key}>
              <Container className="mt-4 ps-0">{tab.element}</Container>
            </Tab>
          ))}
        </Tabs>
      </Container>
    </Container>
  );
}
