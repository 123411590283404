import moment from 'moment';
import { Offcanvas, Stack } from 'react-bootstrap';

import styles from './calendar-date-details.module.scss';
import {
  ProcessorSchedulingDetails,
  ProcessorSchedulingDetailsProps,
} from './processor-scheduling-details';

type CalendarDateDetailsProps = ProcessorSchedulingDetailsProps & {
  hideSideOver: () => void;
  isOpen: boolean;
  date?: Date;
};

export function CalendarDateDetails({
  hideSideOver,
  isOpen,
  date,
  ...detailsProps
}: CalendarDateDetailsProps) {
  return (
    <Offcanvas
      show={isOpen}
      onHide={() => hideSideOver()}
      placement="end"
      scroll={false}
      className={styles.dateDetails}
      restoreFocus={false}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <Stack direction="horizontal" gap={3}>
            <div>{moment(date).format('LL')}</div>
          </Stack>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <ProcessorSchedulingDetails {...detailsProps} />
      </Offcanvas.Body>
    </Offcanvas>
  );
}
