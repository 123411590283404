import {
  IModalFooterProps,
  useModal,
  useToastr,
} from '@farmshare/ui-components';
import {
  faCalendarCheck,
  faCheck,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

import {
  EnumProcessorSchedulingStatus,
  ProcessorScheduling,
  SortFindManyCancellationReasonInput,
  useCancellationReasonManyQuery,
} from 'lib/graphql';

import { StatusChanges } from './useStatusChanges';
import CancelBookingModalContents from '../_views/modals/cancel-booking-modal-contents';
import { ReviewModalFooter } from '../_views/modals/review-modal-footer';
import ViewRequestModalContents from '../_views/modals/view-request-modal-contents';
import {
  handleAcceptBooking,
  handleCancelBooking,
  handleDenyBooking,
} from '../helpers/processorStatusHandlers';

export const useProcessorSchedulingModals = (
  updateScheduling: StatusChanges['updateScheduling'],
  processorChangeCallback: (
    processorId: string,
    newStatus: EnumProcessorSchedulingStatus,
  ) => void,
) => {
  const { save, info } = useModal();
  const { push } = useToastr();

  const { data: cancellationReasonData } = useCancellationReasonManyQuery({
    variables: {
      filter: { isEnabled: true },
      sort: SortFindManyCancellationReasonInput.IndexAsc,
    },
  });

  const openDetailsModal = (processorScheduling: ProcessorScheduling) => {
    if (
      processorScheduling.status === EnumProcessorSchedulingStatus.Requested
    ) {
      save({
        type: 'save',
        size: 'lg',
        title: processorScheduling.requestedBy?.first_name
          ? `Processing Request by ${processorScheduling.requestedBy.first_name}`
          : 'Processing Request',
        initialValues: {},
        body: () => <ViewRequestModalContents row={processorScheduling} />,
        cancelText: 'Deny',
        saveText: 'Accept',
        saveIcon: faCheck,
        cancelIcon: faXmark,
        footer: ({ modalProps }: IModalFooterProps) => (
          <ReviewModalFooter
            modalProps={modalProps}
            onDenyBooking={() =>
              handleDenyBooking(
                processorScheduling,
                updateScheduling,
                push,
                processorChangeCallback,
              )
            }
          />
        ),
        onCancel: () => {},
        onSubmit: () =>
          handleAcceptBooking(
            processorScheduling,
            updateScheduling,
            push,
            processorChangeCallback,
          ),
      });
    } else {
      info({
        type: 'info',
        size: 'lg',
        icon: faCalendarCheck,
        title: processorScheduling.requestedBy?.first_name
          ? `Processing Order by ${processorScheduling.requestedBy.first_name}`
          : 'View Processing Order',
        body: <ViewRequestModalContents row={processorScheduling} />,
      });
    }
  };

  const openCancelModal = (processorScheduling: ProcessorScheduling) => {
    save({
      type: 'save',
      title: 'Are you sure you wish to cancel this booking?',
      initialValues: {
        reason: '',
        details: '',
      },
      body: () => (
        <CancelBookingModalContents
          row={processorScheduling}
          cancellationReasonData={cancellationReasonData}
        />
      ),
      cancelText: 'Back',
      saveText: 'Cancel Order',
      saveIcon: faCheck,
      cancelIcon: faXmark,
      onSubmit: (values: any) => {
        handleCancelBooking(
          processorScheduling,
          values,
          updateScheduling,
          processorChangeCallback,
          cancellationReasonData?.cancellationReasonMany.find(
            (c) => c.reason === values.reason,
          )?._id,
        );
      },
      isLoading: false,
    });
  };

  return { openDetailsModal, openCancelModal };
};
