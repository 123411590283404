import {
  DataDetailList,
  Error,
  Loading,
  Table,
} from '@farmshare/ui-components';
import { formatToShortDate } from '@farmshare/utils';
import { first, lowerCase, map, startCase } from 'lodash';
import { Card, Col, Container, Row, Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { addressFieldsArray } from 'lib/addressFieldsArray';
import { type Package, useOrderByIdQuery } from 'lib/graphql';

import { ShipmentItems } from './_views/shipment-items';

type TPackage = Pick<
  Package,
  | '_id'
  | 'tracking_number'
  | 'tracking_page'
  | 'status'
  | 'events'
  | 'dimensions'
  | 'weight'
  | 'packaging'
  | 'package_code'
  | 'packing_tip'
>;

export default function OrderPackListPage() {
  const { id } = useParams();

  const { data, loading, error } = useOrderByIdQuery({ variables: { id } });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <div style={{ marginTop: '-1rem' }}>
      {map(data?.orderById?.shipments, (shipment, i, arr) => (
        <Container key={i}>
          {data?.orderById && (
            <div className="d-flex align-items-baseline">
              <h1>
                Order&nbsp;
                <span className="small fw-light text-muted">
                  {data.orderById.external_id}
                </span>
              </h1>
              <div className="ms-3">
                (Shipment {i + 1} of {arr.length})
              </div>
            </div>
          )}
          <Stack gap={3} className="mt-1">
            <Card>
              <Card.Header>Shipping</Card.Header>
              <Card.Body>
                <Row lg={2}>
                  <Col>
                    {data?.orderById?.shipping && (
                      <DataDetailList
                        heading="Recipient"
                        rows={addressFieldsArray(data?.orderById?.shipping)}
                      />
                    )}
                  </Col>
                  <Col>
                    <DataDetailList
                      heading="Details"
                      rows={[
                        {
                          label: 'Status',
                          value: startCase(shipment.status),
                        },
                        {
                          label: 'Ship Date',
                          value: formatToShortDate(shipment.ship_date),
                        },
                        {
                          label: 'Service',
                          value: shipment.chosen_rate?.service_type,
                        },
                        {
                          label: 'Delivery Days',
                          value: shipment.chosen_rate?.delivery_days,
                        },
                        {
                          label: 'Requested Date',
                          value: formatToShortDate(
                            shipment.requested_delivery_date,
                          ),
                        },
                        {
                          label: 'Estimated Delivery',
                          value: formatToShortDate(
                            shipment.chosen_rate?.estimated_delivery_date,
                          ),
                        },
                        {
                          label: 'Pickup',
                          value: first(shipment.pickup?.pickup_windows)
                            ?.start_at,
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card>
              <ShipmentItems line_items={shipment.line_items} />
            </Card>
            <Card>
              <Card.Header>Packages</Card.Header>
              <Card.Body>
                <Table<TPackage>
                  rows={shipment.packages as Package[]}
                  columns={[
                    {
                      formatter(_, rowNumber) {
                        return `${rowNumber}.`;
                      },
                      minimumBreakpoint: 'lg',
                    },
                    {
                      label: 'Tracking Number',
                      field: 'tracking_number',
                      minimumBreakpoint: 'lg',
                    },
                    { label: 'Status', field: 'status' },
                    {
                      label: 'Packaging',
                      field: 'packaging.name',
                      formatter: (row) => row.packaging?.name ?? 'Custom',
                    },
                    {
                      label: 'Weight',
                      field: 'weight',
                      formatter(row) {
                        return (
                          <>
                            {row.weight}
                            <small className="text-muted">&nbsp;(lbs.)</small>
                          </>
                        );
                      },
                    },
                    {
                      label: 'Dimensions',
                      field: 'dimensions',
                      formatter({ dimensions }) {
                        return (
                          <>
                            {`${dimensions.length}x${dimensions.width}x${dimensions.height}`}
                            <small className="text-muted">&nbsp;(in.)</small>
                          </>
                        );
                      },
                      minimumBreakpoint: 'md',
                    },
                    {
                      label: 'Coolant',
                      field: 'packing_tip.coolant_lbs_by_type',
                      formatter: (row) =>
                        map(
                          row.packing_tip?.coolant_lbs_by_type,
                          (value, key) =>
                            `${startCase(lowerCase(key))}: ${value}`,
                        ),
                    },
                  ]}
                />
              </Card.Body>
            </Card>
          </Stack>
        </Container>
      ))}
    </div>
  );
}
