import { InputQuantity } from '@farmshare/ui-components';
import { formatToCurrency } from '@farmshare/utils';
import { FormikProps } from 'formik';
import { map, reduce } from 'lodash';
import { Card, Col, Container, Row } from 'react-bootstrap';

import { Supply } from 'lib/graphql';

import { SuppliesForm } from '.';
import styles from './supplies.module.scss';

interface SupplyViewProps {
  img: string;
  title: string;
  description: (string | null)[];
  items: Supply[];
  hasZone: boolean;
  formikProps: FormikProps<SuppliesForm>;
}

export default function SupplyItem(props: SupplyViewProps) {
  const calculatePrice = () => {
    return reduce(
      props.items,
      (res, item, key) => {
        const itemQuantity = props.formikProps.values[item._id] || 0;
        return res + itemQuantity * item.price;
      },
      0,
    );
  };

  return (
    <Card body className="bg-body-secondary rounded-3">
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col xs="auto">
            <img
              src={`/assets/images/supplies/${props.img}`}
              alt={props.title}
              className={`img-fluid p-3 bg-body-tertiary rounded-3 ${styles.imgContainer}`}
              height={150}
              width={150}
            />
          </Col>
          <Col md>
            <div className="fs-2 fw-bold mb-1">{props.title}</div>
            <ul className={styles.descriptionList}>
              {props.description &&
                props.description.map((line) => (
                  <li key={line}>
                    <p className="mb-0 lh-2``">{line}</p>
                  </li>
                ))}
            </ul>
          </Col>
          <Col xs={12} md={5} xl={4}>
            <Container>
              {/* Size Row */}
              <Row className="align-items-center mb-2">
                <Col className="fw-bold p-0">
                  <p className="mb-0">Size</p>
                </Col>
                {map(props.items, (p, i) => (
                  <Col className="p-0 text-center" key={i}>
                    <p className="mb-0">{p.name}</p>
                  </Col>
                ))}
              </Row>
              {/* Quantity Row */}
              <Row className="align-items-center mb-2">
                <Col className="fw-bold p-0">
                  <p className="mb-0">Quantity</p>
                </Col>
                {map(props.items, (p, i) => (
                  <Col className="text-center p-0" key={i}>
                    <InputQuantity
                      nameOveride={p._id}
                      min={p.min}
                      max={p.max}
                      step={p.step}
                      className="my-1 border-none"
                    />
                  </Col>
                ))}
              </Row>
              {props.hasZone && (
                <>
                  {/* Price Row */}
                  <Row className="align-items-center">
                    <Col className="fw-bold p-0">
                      <p className="mb-0">Price</p>
                    </Col>
                    {map(props.items, (p, i) => (
                      <Col className="text-center p-0" key={i}>
                        <p className="mb-0">{formatToCurrency(p.price)}</p>
                      </Col>
                    ))}
                  </Row>
                  <Row>
                    <hr className={styles.customHR} />
                  </Row>
                  {/* Totals Row */}
                  <Row className="fs-5 fw-bold">
                    <Col className="p-0">
                      <p className="mb-0">Total</p>
                    </Col>
                    <Col className="text-end p-0">
                      <p className="mb-0">
                        {formatToCurrency(calculatePrice())}
                      </p>
                    </Col>
                  </Row>
                </>
              )}
            </Container>
          </Col>
        </Row>
      </Container>
    </Card>
  );
}
