import {
  faCheckCircle,
  faCreditCard,
  faInfoCircle,
  faQuestionCircle,
  faSave,
  faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { FormikHelpers, FormikProps } from 'formik';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';

import ModalInner from './modal-inner';
import type { ButtonProps } from '../button/button';
import { Form, type FormProps, type FormValues } from '../form/form';

export type TField = string | number | boolean | Date | undefined | object;

interface IModalBase {
  type: 'info' | 'ask' | 'save' | 'payment';
  title: string;
  icon?: FontAwesomeIconProps['icon'];
  iconClassName?: string;
  fullscreen?: string | true;
  isLoading?: boolean;
  size?: ModalProps['size'];
  error?: string;
  /**
   * Include a backdrop component. A `static`backdrop
   * will not trigger a Modal onHide when clicked.
   */
  backdrop?: ModalProps['backdrop'];
  onCancel?: () => void;
}

interface IModalInfo {
  type: 'info';
  okText?: string;
  okIcon?: FontAwesomeIconProps['icon'];
  body: ReactNode | ((ModalProps: IModalProperties) => ReactNode);
  footer?: ReactNode;
}

interface IModalAsk {
  type: 'ask';
  variant?: string;
  yesDisabled?: boolean;
  yesText?: string;
  yesIcon?: FontAwesomeIconProps['icon'];
  noText?: string;
  noIcon?: FontAwesomeIconProps['icon'];
  body: ReactNode;
  footer?: ReactNode;
  onConfirm?: (() => void) | (() => Promise<void>);
}

interface IModalSave<TForm extends FormValues<TForm>> {
  type: 'save';
  saveText?: string;
  saveIcon?: FontAwesomeIconProps['icon'];
  cancelText?: string;
  cancelIcon?: FontAwesomeIconProps['icon'];
  initialValues: FormProps<TForm>['initialValues'];
  body: ReactNode | ((formikProps: FormikProps<TForm>) => ReactNode);
  footer?:
    | ReactNode
    | (({ formikProps, modalProps }: IModalFooterProps<TForm>) => ReactNode);
  validate?: FormProps<TForm>['validate'];
  onSubmit?:
    | ((values: TForm, formikHelpers: FormikHelpers<TForm>) => void)
    | ((values: TForm, formikHelpers: FormikHelpers<TForm>) => Promise<void>);
}

interface IModalPayment {
  type: 'payment';
  body: ReactNode;
  footer: null;
}

export type IModal<
  TForm extends FormValues<TForm> = Partial<Record<string, TField>>,
> = IModalBase & (IModalInfo | IModalAsk | IModalSave<TForm> | IModalPayment);

export interface IModalProperties {
  headerClassNames: string[];
  icon?: FontAwesomeIconProps['icon'];
  isLoading: boolean;
  buttons: ButtonProps[];
  onClose?: () => void;
}

export interface IModalRendererProps<TForm extends FormValues<TForm>> {
  modal: IModal<TForm>;
}

export interface IModalFooterProps<TForm = any> {
  formikProps?: FormikProps<TForm>;
  modalProps: IModalProperties;
}

export default function ModalRenderer<TForm extends FormValues<TForm>>({
  modal,
}: IModalRendererProps<TForm>) {
  const [show, setShow] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setShow(!!modal);
  }, [modal]);

  const handleConfirm = useCallback(async () => {
    setIsLoading(true);
    try {
      if (modal?.type === 'ask' && modal?.onConfirm) {
        await modal.onConfirm();
      }
      setShow(false);
    } catch (error) {
      modal.error = (error as Error).message;
    } finally {
      setIsLoading(false);
    }
  }, [modal]);

  const handleSubmit = useCallback(
    async (values: TForm, formikHelpers: FormikHelpers<TForm>) => {
      setIsLoading(true);
      if (modal?.type === 'save' && modal?.onSubmit) {
        try {
          await modal.onSubmit(values, formikHelpers);
          setShow(false);
        } catch (error) {
          modal.error = (error as Error).message;
        } finally {
          setIsLoading(false);
        }
      }
    },
    [modal],
  );

  const handleClose = useCallback(() => {
    setShow(false);
    if (modal?.onCancel) {
      modal.onCancel();
    }
  }, [modal]);

  const modalProps: IModalProperties = useMemo(() => {
    switch (modal.type) {
      case 'info':
        return {
          headerClassNames: ['bg-body-secondary'],
          icon: faInfoCircle,
          onClose: handleClose,
          isLoading,
          buttons: [
            {
              content: modal.okText || 'Ok',
              icon: modal.okIcon || faCheckCircle,
              variant: 'primary',
              onClick: handleClose,
            },
          ],
        };
      case 'ask':
        return {
          headerClassNames: [`bg-${modal.variant || 'body-secondary'}`],
          icon: faQuestionCircle,
          iconClassName: modal.iconClassName || 'text-warning',
          onClose: handleClose,
          isLoading,
          buttons: [
            {
              content: modal.noText || 'Cancel',
              icon: modal.noIcon || faXmarkCircle,
              variant: 'ghost',
              onClick: handleClose,
            },
            {
              type: 'submit',
              content: modal.yesText || 'Yes',
              icon: modal.yesIcon || faCheckCircle,
              variant: 'primary',
              onClick: handleConfirm,
              disabled: modal.yesDisabled,
              isLoading,
            },
          ],
        };
      case 'payment':
        return {
          headerClassNames: ['bg-body-secondary'],
          icon: faCreditCard,
          onClose: handleClose,
          isLoading,
          buttons: [],
        };
      case 'save':
        return {
          headerClassNames: ['bg-body-secondary'],
          icon: faSave,
          onClose: handleClose,
          isLoading,
          buttons: [
            {
              content: modal.cancelText || 'Cancel',
              icon: modal.cancelIcon || faXmarkCircle,
              variant: 'ghost',
              onClick: handleClose,
              disabled: isLoading,
            },
            {
              type: 'submit',
              content: modal.saveText || 'Save',
              icon: modal.saveIcon || faSave,
              variant: 'primary',
              isLoading,
            },
          ],
        };
      default:
        return {
          headerClassNames: [],
          icon: faInfoCircle,
          isLoading,
          buttons: [],
        };
    }
  }, [handleClose, handleConfirm, isLoading, modal]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size={modal.size}
      fullscreen={modal.fullscreen || 'sm-down'}
      backdrop={modal.backdrop}
    >
      {modal.type === 'save' ? (
        <Form<TForm>
          onSubmit={handleSubmit}
          initialValues={modal.initialValues}
          validate={modal.validate}
        >
          {(formikProps) => (
            <ModalInner
              modal={modal}
              modalProps={modalProps}
              formikProps={formikProps}
            />
          )}
        </Form>
      ) : (
        <ModalInner modal={modal} modalProps={modalProps} />
      )}
    </Modal>
  );
}
