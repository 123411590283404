import {
  Button,
  DataDetailList,
  InputText,
  useModal,
  type ProgressDisplayProps,
  useToastr,
  ProgressDisplay,
} from '@farmshare/ui-components';
import { isArrayNullOrEmpty } from '@farmshare/utils';
import {
  faDollar,
  faTruck,
  faTruckFast,
} from '@fortawesome/free-solid-svg-icons';
import { find, map, omit } from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';

import {
  type Shipment,
  useCreateShipmentGetRatesMutation,
  useSaveShipmentPurchaseLabelMutation,
  EnumShipmentStatus,
} from 'lib/graphql';

import {
  RatesModal,
  type RatesModalForm,
} from 'pages/shipments/_views/rates-modal';

interface ShipmentProgressProps {
  shipment: Shipment;
}

export function ShipmentProgress({ shipment }: ShipmentProgressProps) {
  const { save } = useModal();
  const { push } = useToastr();

  const [getRates, getRatesOperation] = useCreateShipmentGetRatesMutation();

  const [purchaseLabel, purchaseLabelOperations] =
    useSaveShipmentPurchaseLabelMutation();

  const progressDisplaySteps = useMemo(() => {
    const steps: ProgressDisplayProps['steps'] = [
      { key: 'new', label: 'New Shipment' },
      {
        key: 'label_purchased',
        label: 'Purchase Label',
        // nextAction: (
        //   <Button
        //     content="Purchase Label"
        //     size="sm"
        //     variant="primary"
        //     icon={faDollar}
        //     isLoading={purchaseLabelOperations.loading}
        //     onClick={async () => {
        //       const getRatesResult = await getRates({
        //         variables: {
        //           referenceNumber: shipment.external_id || '',
        //           shipDate: shipment.ship_date,
        //           shipFrom: addressFormToCamelCase(values.shipFrom),
        //           shipTo: addressFormToCamelCase(values.shipTo),
        //           packages: shipment.packages,
        //         },
        //       });
        //       save<RatesModalForm>({
        //         type: 'save',
        //         title: 'Select Rate',
        //         size: 'lg',
        //         icon: faTruckFast,
        //         saveIcon: faTruckFast,
        //         saveText: 'Purchase Label',
        //         isLoading: purchaseLabelOperations.loading,
        //         body: (formikProps) => (
        //           <RatesModal formikProps={formikProps} rates={rates} />
        //         ),
        //         validate: (values) => {
        //           const errors: Partial<Record<keyof RatesModalForm, string>> =
        //             {};

        //           if (!values.chosenRate) {
        //             errors.chosenRate = 'A shipping rate needs to be chosen.';
        //           }

        //           return errors;
        //         },
        //         initialValues: {
        //           pickupDate: moment
        //             .utc(shipment.ship_date)
        //             .format('yyyy-MM-DD'),
        //           notificationEmails: [''],
        //         },
        //         onSubmit: async ({
        //           chosenRate,
        //           notificationEmails,
        //           pickupDate,
        //           sendNotifications = false,
        //         }) => {
        //           const _chosenRate = find(
        //             rates,
        //             (r) => r.external_id === chosenRate,
        //           );

        //           if (_chosenRate) {
        //             const p = await purchaseLabel({
        //               variables: {
        //                 chosenRate: omit(_chosenRate, '__typename'),
        //                 shipDate: values.shipDate,
        //                 notificationEmails,
        //                 packages,
        //                 pickupDate,
        //                 referenceNumber,
        //                 sendNotifications,
        //               },
        //             });
        //             if (p.data?.saveShipmentPurchaseLabel?._id) {
        //               push({
        //                 title: 'Success!',
        //                 bg: 'primary',
        //                 body: 'Your label has been scheduled.',
        //               });
        //             }
        //             return true;
        //           }

        //           return false;
        //         },
        //       });
        //     }}
        //   />
        // ),
      },
    ];

    // if (shipment.vendor?.prefers_dropoff) {
    if (shipment.status === EnumShipmentStatus.WaitingForDropoff) {
      steps.push({ key: 'waiting_for_dropoff', label: 'Waiting For Dropoff' });
    } else {
      steps.push({
        key: 'pickup_scheduled',
        label: 'Schedule Pickup',
        // nextAction: (
        //   <Button
        //     content="Schedule Pickup"
        //     size="sm"
        //     variant="primary"
        //     icon={faTruckFast}
        //     isLoading={pickupMutation.loading}
        //     onClick={() => {
        //       // pickup window 9AM-5PM
        //       const pickupDate = moment
        //         .utc(shipment.ship_date)
        //         .startOf('day')
        //         .format('yyyy-MM-DD');
        //       const startTime = moment
        //         .utc()
        //         .startOf('day')
        //         .hour(9)
        //         .format('kk:mm:ss');
        //       const endTime = moment
        //         .utc()
        //         .startOf('day')
        //         .hour(17)
        //         .format('kk:mm:ss');

        //       save<{ pickupDate: string; startTime: string; endTime: string }>({
        //         type: 'save',
        //         title: 'Confirm Shipment Pickup',
        //         icon: faTruck,
        //         saveIcon: faTruck,
        //         saveText: 'Schedule Pickup',
        //         isLoading: pickupMutation.loading,
        //         initialValues: { pickupDate, startTime, endTime },
        //         body: () => (
        //           <div>
        //             <DataDetailList
        //               rows={[
        //                 {
        //                   label: 'Service',
        //                   value: shipment.chosen_rate?.service_type,
        //                 },
        //                 {
        //                   label: 'Requested Date',
        //                   value: moment
        //                     .utc(shipment.requested_delivery_date)
        //                     .format('L'),
        //                 },
        //                 {
        //                   label: 'Transit Days',
        //                   value: shipment.chosen_rate?.delivery_days,
        //                 },

        //                 {
        //                   label: 'Ship Date',
        //                   value: moment.utc(shipment.ship_date).format('L'),
        //                 },
        //               ]}
        //             />
        //             <hr />
        //             <Row className="m-xs-3 g-3">
        //               <Col md={6}>
        //                 <InputText type="date" label="Pickup Date" required />
        //               </Col>
        //               <Col md={6} />
        //               <Col md={6}>
        //                 <InputText type="time" label="Start Time" required />
        //               </Col>
        //               <Col md={6}>
        //                 <InputText type="time" label="End Time" required />
        //               </Col>
        //             </Row>
        //           </div>
        //         ),
        //         onSubmit: async (values) => {
        //           console.log(values);
        //           await schedulePickup({
        //             variables: {
        //               id: shipment._id,
        //               start_at: `${values.pickupDate}T${values.startTime}Z`,
        //               end_at: `${values.pickupDate}T${values.endTime}Z`,
        //             },
        //           });
        //         },
        //       });
        //     }}
        //   />
        // ),
      });
    }

    steps.push(
      { key: 'in_transit', label: 'In Transit' },
      { key: 'completed', label: 'Shipment Delivered' },
    );

    return steps;
  }, [shipment]);

  return (
    <div>
      {shipment.status && (
        <ProgressDisplay
          steps={progressDisplaySteps}
          currentStep={shipment.status}
        />
      )}
    </div>
  );
}
