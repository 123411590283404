import moment, { Moment } from 'moment';

import styles from './date-select.module.scss';

export interface DateSelectProps {
  value: Moment;
  onChange: (e: Moment) => void;
  min?: Moment;
  max?: Moment;
}

export function DateSelect({ value, onChange, min, max }: DateSelectProps) {
  const nativeDateFormat = 'YYYY-MM-DD';
  return (
    <input
      type="date"
      className={`${styles.dateSelect} fs-5`}
      min={min?.format(nativeDateFormat)}
      max={max?.format(nativeDateFormat)}
      value={value.format(nativeDateFormat)}
      onChange={(e) => onChange(moment(e.target.value))}
    />
  );
}
