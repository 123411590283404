import { DeepKeys } from '@tanstack/react-table';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { camelCase, get, has } from 'lodash';
import { useCallback, useMemo } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import { Hint, Typeahead } from 'react-bootstrap-typeahead';
import type { TypeaheadComponentProps } from 'react-bootstrap-typeahead/types/components/Typeahead';
import type {
  Option,
  TypeaheadState,
} from 'react-bootstrap-typeahead/types/types';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';

type OptionType<T> = { _id: string | number } & T;

export interface InputTypeaheadProps<T> {
  label: string;
  options: OptionType<T>[];
  idKey?: string;
  labelKey?: DeepKeys<T>;
  disabled?: boolean;
  required?: boolean;
  floatingLabel?: boolean;
  showLabel?: boolean;
  nameOveride?: string;
  multiple?: boolean;
  allowNew?:
    | ((results: Array<Option>, props: TypeaheadState) => boolean)
    | boolean;
}

export function InputTypeahead<T extends string | Record<string, unknown>>({
  label,
  disabled,
  idKey = 'id',
  labelKey,
  required,
  options,
  floatingLabel,
  nameOveride,
  multiple = false,
  showLabel = true,
  allowNew = false,
}: InputTypeaheadProps<T>) {
  const { values, errors, handleChange, handleBlur } =
    useFormikContext<Record<string, T[]>>();

  const validate = useCallback(
    (value: string | number | undefined) =>
      required && (!value || value === undefined)
        ? `${label} is required.`
        : '',
    [label, required],
  );

  const name = useMemo(
    () => nameOveride ?? camelCase(label),
    [label, nameOveride],
  );

  const isInvalid = useMemo(
    () => has(errors, name) && get(errors, name) !== '',
    [errors, name],
  );

  const fieldProps: TypeaheadComponentProps = useMemo(
    () => ({
      allowNew,
      disabled,
      id: name,
      isInvalid,
      labelKey,
      multiple,
      name,
      onBlur: (e) => {
        e.target.name = name;
        return handleBlur(e);
      },
      onChange: (selected) => {
        const [value] = selected;
        const e = {
          target: {
            name,
            value: get(value, idKey),
          },
        };
        handleChange(e);
      },
      options,
      placeholder: label,
      renderInput: floatingLabel
        ? ({ inputRef, referenceElementRef, value, ...inputProps }) => (
            <Hint>
              <FloatingLabel controlId="floatingLabel" label={label}>
                <Form.Control
                  {...inputProps}
                  ref={(node: HTMLInputElement) => {
                    inputRef(node);
                    referenceElementRef(node);
                  }}
                />
              </FloatingLabel>
            </Hint>
          )
        : undefined,
      required,
      selected: values.name,
      validate,
    }),
    [
      allowNew,
      disabled,
      floatingLabel,
      handleBlur,
      handleChange,
      idKey,
      isInvalid,
      label,
      labelKey,
      multiple,
      name,
      options,
      required,
      validate,
      values.name,
    ],
  );

  return (
    <Form.Group controlId={`form.${name}`}>
      {showLabel && !floatingLabel && (
        <Form.Label>
          {label}
          {required && <sup className="text-danger fw-bold">&nbsp;*</sup>}
        </Form.Label>
      )}
      <Field as={Typeahead} {...fieldProps} />
      <ErrorMessage
        name={name}
        render={(msg: string) => (
          <Form.Control.Feedback type="invalid">{msg}</Form.Control.Feedback>
        )}
      />
    </Form.Group>
  );
}
