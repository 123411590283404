import { ApolloError } from '@apollo/client';
import { Button, InputText } from '@farmshare/ui-components';
import { isArrayNullOrEmpty } from '@farmshare/utils';
import {
  faPlus,
  faTrash,
  faTruckFast,
} from '@fortawesome/free-solid-svg-icons';
import { ArrayHelpers, FieldArray, type FormikProps } from 'formik';
import { map, sumBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Accordion, Alert, Card, Col, Row } from 'react-bootstrap';
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';

import { RatesTables } from 'components/rates-tables/rates-tables';
import {
  ShipmentPackageForm,
  type ShipmentPackageFormForm,
} from 'components/shipment-package-form/shipment-package-form';

import { type Shipment, useShipmentGetRatesMutation } from 'lib/graphql';

export interface LabelModalForm {
  shipDate?: string;
  chosenRate?: string;
  carrier?: string;
  packages: ShipmentPackageFormForm[];
}

interface LabelModalProps {
  shipment: Shipment;
  orderId: number | string;
  formProps: FormikProps<LabelModalForm>;
  requestedDate?: string;
}

export function LabelModal({
  shipment,
  orderId,
  formProps,
  requestedDate,
}: LabelModalProps) {
  const [activeKey, setActiveKey] = useState<AccordionEventKey>('0');

  const [getRates, rateMutation] = useShipmentGetRatesMutation();

  const _shipment = useMemo(
    () => rateMutation.data?.shipmentGetRates || shipment,
    [shipment, rateMutation.data?.shipmentGetRates],
  );

  const totalWeight = useMemo(
    () => sumBy(formProps.values.packages, (p) => p.weight || 0),
    [formProps.values.packages],
  );

  const totalValue = useMemo(
    () => sumBy(formProps.values.packages, (p) => p.insuredValue || 0),
    [formProps.values.packages],
  );

  useEffect(() => {
    if (rateMutation.data?.shipmentGetRates?.chosen_rate) {
      formProps.setFieldValue(
        'chosenRate',
        rateMutation.data.shipmentGetRates.chosen_rate._id,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formProps.setFieldValue,
    rateMutation.data?.shipmentGetRates?.chosen_rate,
  ]);

  const handleGetRates = useCallback(async () => {
    try {
      if (formProps.values.shipDate) {
        await getRates({
          variables: {
            id: _shipment._id,
            shipDate: formProps.values.shipDate,
            packages: map(
              formProps.values.packages,
              (
                {
                  externalId,
                  weight,
                  length,
                  width,
                  height,
                  insuredValue,
                  packageType,
                  packaging,
                },
                idx,
              ) => ({
                external_id:
                  externalId ||
                  `${orderId}-${shipment.vendor?.external_id}-${idx + 1}`,
                insured_value: insuredValue,
                dimensions: { length, width, height },
                packaging:
                  packageType === 'farmsharePackaging' ? packaging : undefined,
                weight,
              }),
            ),
          },
        });
        setActiveKey('2');
      }
    } catch (error) {
      if (error instanceof ApolloError) {
        console.error(error);
      }
    }
  }, [
    shipment.vendor?.external_id,
    formProps.values.shipDate,
    formProps.values.packages,
    getRates,
    _shipment._id,
    orderId,
  ]);

  return (
    <div>
      {rateMutation.error && (
        <Alert variant="danger">{rateMutation.error.message}</Alert>
      )}
      <Accordion activeKey={activeKey} onSelect={setActiveKey}>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Shipment</Accordion.Header>
          <Accordion.Body>
            <Row md={2} className="m-1 mb-3 g-3">
              <Col>
                <InputText
                  label="Ship Date"
                  type="date"
                  hint={
                    requestedDate !== '-'
                      ? `Customer requested delivery on ${requestedDate}.`
                      : undefined
                  }
                  floatingLabel
                  required
                />
              </Col>
            </Row>
            <hr />
            <FieldArray
              name="packages"
              render={(arrayHelpers: ArrayHelpers) => (
                <div>
                  {map(formProps.values.packages, (_, idx) => (
                    <Card key={idx} className="mb-3">
                      <Card.Header>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>Package #{idx + 1}</div>
                          {idx > 0 && (
                            <Button
                              content="Remove"
                              size="sm"
                              variant="secondary"
                              icon={faTrash}
                              className="me-2"
                              onClick={() => arrayHelpers.remove(idx)}
                            />
                          )}
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <ShipmentPackageForm
                          formikProps={formProps}
                          idx={idx}
                        />
                      </Card.Body>
                    </Card>
                  ))}
                  <div className="d-flex justify-content-end">
                    <Button
                      content="Add Package"
                      size="sm"
                      variant="secondary"
                      icon={faPlus}
                      className="me-2"
                      onClick={() => arrayHelpers.push({})}
                      disabled={rateMutation.loading}
                    />
                    <Button
                      content="Get Rates"
                      size="sm"
                      variant="primary"
                      icon={faTruckFast}
                      isLoading={rateMutation.loading}
                      disabled={!formProps.isValid}
                      onClick={handleGetRates}
                    />
                  </div>
                </div>
              )}
            />
            <InputText label="Chosen Rate" type="text" hidden />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Rates</Accordion.Header>
          <Accordion.Body>
            <Card className="mb-3" body>
              <Row className="align-items-center">
                {map(
                  [
                    { label: 'Ship Date', value: formProps.values.shipDate },
                    {
                      label: 'Total Packages',
                      value: formProps.values.packages?.length,
                    },
                    {
                      label: 'Total Weight',
                      value: (
                        <span>
                          {totalWeight}&nbsp;
                          <span className="small text-muted">
                            {totalWeight > 1 ? '(lbs.)' : '(lb.)'}
                          </span>
                        </span>
                      ),
                    },
                    {
                      label: 'Declared Value',
                      value: (
                        <span>
                          <span className="small text-muted">$&nbsp;</span>
                          {totalValue}&nbsp;
                        </span>
                      ),
                    },
                  ],
                  (v, i) => (
                    <Col key={i} className="text-center">
                      <div className="small">{v.label}:</div>
                      <div className="fw-bold">{v.value}</div>
                    </Col>
                  ),
                )}
                <Col className="text-end">
                  <Button
                    content="Refresh Rates"
                    size="sm"
                    variant="primary"
                    icon={faTruckFast}
                    isLoading={rateMutation.loading}
                    disabled={
                      !formProps.isValid ||
                      isArrayNullOrEmpty(shipment.all_rates)
                    }
                    onClick={handleGetRates}
                  />
                </Col>
              </Row>
            </Card>
            <RatesTables<LabelModalForm>
              rates={_shipment.all_rates}
              formikProps={formProps}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
