import {
  DataDetailListMinimal,
  InputSelect,
  InputTextArea,
} from '@farmshare/ui-components';
import { map } from 'lodash';
import moment from 'moment';

import { CancellationReasonManyQuery, ProcessorScheduling } from 'lib/graphql';

export interface SubmitForm {
  reason: string;
  description: string;
}

export default function CancelBookingModalContents({
  row,
  cancellationReasonData,
}: {
  row: ProcessorScheduling;
  cancellationReasonData: CancellationReasonManyQuery | undefined;
}) {
  return (
    <div>
      <DataDetailListMinimal
        heading="Booking Details"
        rows={[
          {
            label: 'Name',
            value: `${row.requestedBy?.first_name} ${row.requestedBy?.last_name}`,
          },
          {
            label: 'Email',
            value: row.requestedBy?.email,
          },
          {
            label: 'Item',
            value: `${row.headCount} ${row.animalSpecies}`,
          },
          {
            label: 'Drop Off Date',
            value: moment.utc(row.dropoffDate).format('LL'),
          },
        ]}
      />
      <hr />
      <h6 className="pb-2 fw-bold">Reason</h6>
      <InputSelect
        label="Select One"
        nameOveride="reason"
        options={
          cancellationReasonData
            ? map(cancellationReasonData.cancellationReasonMany, (reason) => ({
                label: reason.reason,
                value: reason.reason,
              }))
            : []
        }
        floatingLabel
        required
      />
      <h6 className="pb-2 fw-bold mt-3">Additional Details</h6>
      <InputTextArea
        label="Additional Details"
        nameOveride="details"
        rows={2}
        required
      />
    </div>
  );
}
