import { BigCalendar } from '@farmshare/ui-components';
import { animalSpeciesHelper } from '@farmshare/utils';
import { MoreLinkArg } from '@fullcalendar/core';
import moment, { Moment } from 'moment';
import { useCallback, useState } from 'react';
import { Container, Stack } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { userState, vendorState } from 'state';

import {
  EnumProcessorSchedulingStatus,
  ProcessorScheduling,
  ProcessorSchedulingCalendarQuery,
  ProcessorSchedulingCalendarQueryVariables,
  useProcessorSchedulingCalendarLazyQuery,
} from 'lib/graphql';

import { CalendarDateCell } from './_views/calendar-date-cell';
import { CalendarDateDetails } from './_views/calendar-date-details';
import { CalendarEventContent } from './_views/calendar-event-content';
import { CalendarLegend } from './_views/calendar-legend';
import { MobileCustomView } from './_views/calendar-mobile';
import { GenerateKillsheetButton } from '../lib/_views/generate-killsheet-button';
import {
  ALL_SHOWN_STATUSES,
  getStatusColor,
} from '../lib/helpers/processorStatusPresentation';
import { useProcessorSchedulingModals } from '../lib/hooks/useProcessorSchedulingModals';
import { useStatusChanges } from '../lib/hooks/useStatusChanges';

const eventsAccessor = (data: ProcessorSchedulingCalendarQuery) =>
  data.getProcessorSchedulingCalendar?.map((processorScheduling) => {
    if (!processorScheduling) {
      return [];
    }

    const {
      statusLastUpdated,
      requestedBy,
      headCount,
      animalSpecies,
      status,
      dropoffDate,
    } = processorScheduling;

    return {
      start:
        status === EnumProcessorSchedulingStatus.Scheduled
          ? moment.utc(dropoffDate).format('YYYY-MM-DD')
          : statusLastUpdated,
      title: `${requestedBy?.last_name}: ${headCount} ${
        animalSpeciesHelper(animalSpecies).label
      }`,
      allDay: true,
      color: getStatusColor(status),
      ...processorScheduling,
    };
  });

export default function ProcessorCalendar() {
  const user = useRecoilValue(userState);
  const vendor = useRecoilValue(vendorState);

  const processorSchedulingCalendarQuery =
    useProcessorSchedulingCalendarLazyQuery({ fetchPolicy: 'network-only' });

  const [selectedDate, setSelectedDate] = useState<Date>();
  const [selectedDateProcessors, setSelectedDateProcessors] =
    useState<ProcessorScheduling[]>();

  const [isSideOverVisible, setIsSideOverVisible] = useState(false);

  const buildQueryVariables = useCallback(
    (startDate: string | undefined, endDate: string | undefined) => ({
      endDate,
      startDate,
      vendorId: vendor?._id,
      filter: {
        OR: ALL_SHOWN_STATUSES.map((s) => ({
          status: s as EnumProcessorSchedulingStatus,
        })),
      },
    }),
    [vendor?._id],
  );

  const statusChanges = useStatusChanges(() => {
    return buildQueryVariables(
      moment(selectedDate).startOf('months').format('YYYY-MM-DD'),
      moment(selectedDate).endOf('months').format('YYYY-MM-DD'),
    );
  });

  const onProcessorChange = (
    processsorId: string,
    newStatus: EnumProcessorSchedulingStatus,
  ) => {
    let updatedProcessors: ProcessorScheduling[];

    if (isSideOverVisible) {
      if (moment().isSame(moment(selectedDate), 'day')) {
        updatedProcessors = selectedDateProcessors!.map((processor) => {
          return processor._id === processsorId
            ? Object.assign(processor, { status: newStatus })
            : processor;
        });
      } else {
        updatedProcessors = selectedDateProcessors!.filter((processor) => {
          return processor._id !== processsorId;
        });
      }

      setSelectedDateProcessors(updatedProcessors);
    }
  };

  const { openCancelModal, openDetailsModal } = useProcessorSchedulingModals(
    statusChanges.updateScheduling,
    onProcessorChange,
  );

  const openSlider = useCallback(
    (date: Moment, events: ProcessorScheduling[]) => {
      setSelectedDate(date.toDate());
      setSelectedDateProcessors(events);

      setIsSideOverVisible(true);
    },
    [],
  );

  const moreLinkClick = useCallback(
    (eventArgs: MoreLinkArg) => {
      const { date, allSegs } = eventArgs;

      const events = allSegs.map(
        (seg) => seg.event.extendedProps as ProcessorScheduling,
      );

      openSlider(moment(date), events);
    },
    [openSlider],
  );

  return (
    <>
      <div className="pb-5 h-100">
        <Stack
          direction="horizontal"
          className="justify-content-between pb-2 mb-4"
        >
          <div>
            <h2 className="fw-bold">
              {user?.first_name ? `Welcome, ${user.first_name}!` : 'Welcome!'}
            </h2>
            <p className="m-0">
              Check out your current bookings and update the status of ongoing
              processes here.
            </p>
          </div>
        </Stack>
        <Container fluid className="p-0">
          <BigCalendar<
            ProcessorScheduling,
            ProcessorSchedulingCalendarQuery,
            ProcessorSchedulingCalendarQueryVariables
          >
            query={processorSchedulingCalendarQuery}
            buildQueryVariables={buildQueryVariables}
            dateClick={openSlider}
            eventsAccessor={eventsAccessor}
            eventContent={CalendarEventContent}
            dayMaxEventRows={4}
            mobileOptions={{
              component: MobileCustomView({
                openCancelModal,
                openDetailsModal,
                processorChangeCallback: onProcessorChange,
                statusChanges,
              }),
            }}
            eventClick={(arg) =>
              openDetailsModal(arg.event.extendedProps as ProcessorScheduling)
            }
            moreLinkClick={moreLinkClick}
            headerRightPanel={CalendarLegend}
            headerLeftPanel={GenerateKillsheetButton}
            dayCellContent={CalendarDateCell}
          />
        </Container>
      </div>

      <CalendarDateDetails
        events={selectedDateProcessors}
        hideSideOver={() => setIsSideOverVisible(false)}
        isOpen={isSideOverVisible}
        openCancelModal={openCancelModal}
        openDetailsModal={openDetailsModal}
        processorChangeCallback={onProcessorChange}
        statusChanges={statusChanges}
        date={selectedDate}
      />
    </>
  );
}
