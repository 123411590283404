import { Error, Loading } from '@farmshare/ui-components';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userState } from 'state';

import { EnumIntegrationName, useIntegrationInitMutation } from 'lib/graphql';

export default function ShopifyIntegrationPage() {
  const [params] = useSearchParams();

  const user = useRecoilValue(userState);

  const [init, initOp] = useIntegrationInitMutation();

  useEffect(() => {
    if (
      params.has('shop') &&
      user &&
      !initOp.called &&
      !initOp.data &&
      !initOp.loading
    ) {
      init({
        variables: {
          integrationName: EnumIntegrationName.Shopify,
          shop: params.get('shop'),
        },
      });
    }
  }, [init, initOp.called, initOp.data, initOp.loading, params, user]);

  useEffect(() => {
    if (initOp.data?.integrationInit) {
      window.open('/profile', '_self');
    }
  }, [initOp.data?.integrationInit]);

  if (initOp.loading) {
    return <Loading />;
  }

  if (initOp.error) {
    return <Error error={initOp.error} />;
  }

  return <div />;
}
