import { Button, useModal } from '@farmshare/ui-components';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { kebabCase } from 'lodash';

import { addressFormToCamelCase } from 'lib/addressFormToCamelCase';
import {
  type TenantByIdQuery,
  useVendorCreateMutation,
  EnumAddressType,
} from 'lib/graphql';

import { VendorModal, type VendorModalForm } from './vendor-modal';

interface AddVendorButtonProps {
  tenant: TenantByIdQuery['tenantById'];
  refetch: () => Promise<unknown>;
}

export function AddVendorButton(props: AddVendorButtonProps) {
  const [createVendor, { loading }] = useVendorCreateMutation();

  const { save } = useModal();

  return (
    <Button
      content="Add Vendor"
      size="sm"
      icon={faPlus}
      isLoading={loading}
      onClick={() =>
        save<VendorModalForm>({
          type: 'save',
          title: 'Add Vendor',
          icon: faPlus,
          size: 'lg',
          initialValues: {
            shopName: props.tenant?.name || '',
            shopUrl: '',
            address: {
              address1: '',
              company: props.tenant?.name,
              city: '',
              state: '',
              postcode: '',
              type: EnumAddressType.Commercial,
            },
          },
          body: <VendorModal />,
          isLoading: loading,
          onSubmit: async ({ shopName, shopUrl, tagline, address }) => {
            await createVendor({
              variables: {
                newVendor: {
                  tenant: props.tenant?._id || props.tenant,
                  shop_name: shopName,
                  shop_url: shopUrl,
                  shop_slug: kebabCase(shopName),
                  address: addressFormToCamelCase(address),
                  date_created: new Date(),
                  enabled: true,
                  tagline,
                },
              },
            });
            await props.refetch();
          },
        })
      }
    />
  );
}
