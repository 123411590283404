import { DataDetailList, Table } from '@farmshare/ui-components';
import { isArrayNullOrEmpty } from '@farmshare/utils';
import { faStopCircle, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map, round, startCase } from 'lodash';
import { useCallback } from 'react';
import { Alert, Card, Col, Row, Stack } from 'react-bootstrap';

import type { ShipmentGetPackingTipsMutation } from 'lib/graphql';

export interface ShipmentPackingTipsProps {
  shipment: ShipmentGetPackingTipsMutation['shipmentGetPackingTips'];
}

export function ShipmentPackingTips(props: ShipmentPackingTipsProps) {
  const renderTemperature = useCallback(
    (temp?: number | null) =>
      temp ? (
        <div>
          {round(temp, 1)}&nbsp;<span className="small text-muted">&deg;F</span>
        </div>
      ) : (
        '-'
      ),
    [],
  );

  return (
    <Stack gap={3}>
      {map(
        props.shipment?.packages,
        ({ external_id, packing_tip }, i) =>
          packing_tip && (
            <Card key={i}>
              <Card.Header>
                Package {external_id}: {packing_tip.carrier_service?.name}
              </Card.Header>
              <Card.Body>
                <Stack gap={3}>
                  {!isArrayNullOrEmpty(packing_tip.error_details) && (
                    <Alert variant="danger">
                      <div>
                        <FontAwesomeIcon icon={faStopCircle} className="me-2" />
                        Errors:
                      </div>
                      <ul>
                        {map(packing_tip.error_details, (e, i) => (
                          <li key={i}>{e}</li>
                        ))}
                      </ul>
                    </Alert>
                  )}
                  {!isArrayNullOrEmpty(packing_tip.warning_details) && (
                    <Alert variant="warning">
                      <div>
                        <FontAwesomeIcon icon={faWarning} className="me-2" />
                        Warnings:
                      </div>
                      <ul>
                        {map(packing_tip.warning_details, (w, i) => (
                          <li key={i}>{w}</li>
                        ))}
                      </ul>
                    </Alert>
                  )}
                  <Row md={2}>
                    <Col>
                      <h6>Coolant Recommendations</h6>
                      <hr />
                      {packing_tip.coolant && (
                        <Table
                          rows={packing_tip.coolant}
                          columns={[
                            {
                              label: 'Type',
                              field: 'type',
                              formatter: (row) => startCase(row?.type),
                            },
                            { label: 'Quantity', field: 'quantity' },
                            {
                              label: 'Weight',
                              field: 'weight',
                              formatter: (row) => (
                                <span>
                                  {row?.weight}&nbsp;
                                  <span className="small text-muted">lbs.</span>
                                </span>
                              ),
                            },
                          ]}
                        />
                      )}
                    </Col>
                    <Col>
                      <DataDetailList
                        heading="Estimated Exposure"
                        rows={[
                          {
                            label: 'Transit Duration',
                            value: (
                              <div>
                                {packing_tip.expected_duration_hours}&nbsp;
                                <span className="small text-muted">hrs.</span>
                              </div>
                            ),
                          },
                          {
                            label: 'Min Temp',
                            value: renderTemperature(packing_tip.min_temp_f),
                          },
                          {
                            label: 'Max Temp',
                            value: renderTemperature(packing_tip.max_temp_f),
                          },
                          {
                            label: 'Avg Temp',
                            value: renderTemperature(packing_tip.avg_temp_f),
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                </Stack>
              </Card.Body>
            </Card>
          ),
      )}
    </Stack>
  );
}
