import { createPlugin, Duration } from '@fullcalendar/core';
import { DateProfile, ViewProps } from '@fullcalendar/core/internal';

export interface PluginOptions {
  component: (
    props: ViewProps & {
      dateProfile: DateProfile;
      nextDayThreshold: Duration;
    },
  ) => JSX.Element;
}

export const mobilePlugin = ({ component }: PluginOptions) =>
  createPlugin({
    name: 'farmshare/mobile',
    views: {
      mobileView: {
        component: component,
        type: 'dayGridWeek',
        duration: { days: 1 },
        dateIncrement: { days: 7 },
      },
    },
  });
