import { Field, ErrorMessage, useFormikContext, FieldAttributes } from 'formik';
import { camelCase, isFinite } from 'lodash';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { FloatingLabel, Form, FormControlProps } from 'react-bootstrap';

import { InputComponent } from '.';

export interface InputTextProps extends InputComponent {
  type: HTMLInputElement['type'];
  min?: number | string;
  max?: number | string;
  step?: number;
  onChange?: (name: string, value: number | string) => void;
}

export function InputText({
  label,
  disabled,
  hidden,
  required,
  type,
  className,
  hint,
  nameOveride,
  min,
  max,
  placeholder,
  size,
  onChange,
  step = 1,
  floatingLabel = false,
  showLabel = !hidden,
  readOnly = false,
}: InputTextProps) {
  const { errors, handleChange } = useFormikContext<Record<string, string>>();

  const validate = useCallback(
    (value: string | number | undefined) => {
      if (type === 'number') {
        if (required && !isFinite(value)) {
          return `${label} is required.`;
        }
      } else {
        if (required && (!value || value === undefined)) {
          return `${label} is required.`;
        }
      }
    },
    [label, required, type],
  );

  const name = useMemo(
    () => nameOveride ?? camelCase(label),
    [label, nameOveride],
  );

  const inputPlaceholder = useMemo(() => {
    return placeholder ?? label?.replace(/[?:]/g, '');
  }, [placeholder, label]);

  const fieldProps: FieldAttributes<FormControlProps> = useMemo(() => {
    const props: any = {
      name,
      disabled,
      hidden,
      min,
      max,
      size,
      step,
      validate,
      readOnly,
      type,
      maxLength: max,
      required: required && !readOnly,
      plaintext: readOnly,
      as: Form.Control,
      placeholder: inputPlaceholder,
      isInvalid: !!errors[name],
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        onChange?.(name, e.target.value);

        handleChange(e);
      },
    };

    // disable the scroll wheel from changing number inputs https://stackoverflow.com/questions/63224459/disable-scrolling-on-input-type-number-in-react
    if (type === 'number') {
      props.onWheel = (e: any) => e.target?.blur();
    }

    return props;
  }, [
    disabled,
    errors,
    hidden,
    max,
    min,
    name,
    inputPlaceholder,
    readOnly,
    required,
    size,
    step,
    type,
    validate,
    handleChange,
    onChange,
  ]);

  const field =
    fieldProps.type === 'password' ? (
      <Field {...fieldProps} />
    ) : (
      <Field {...fieldProps} data-1p-ignore data-lpignore="true" />
    );

  return (
    <Form.Group controlId={`form.${name}`} className={className}>
      {floatingLabel ? (
        <FloatingLabel
          controlId={`floatingInput.${name}`}
          label={
            <>
              {label}
              {fieldProps.required && (
                <sup className="text-danger fw-bold">&nbsp;*</sup>
              )}
            </>
          }
        >
          {field}
        </FloatingLabel>
      ) : (
        <>
          {showLabel && (
            <Form.Label className="fw-light">
              {label}
              {fieldProps.required && (
                <sup className="text-danger fw-bold">&nbsp;*</sup>
              )}
            </Form.Label>
          )}
          {field}
        </>
      )}
      {hint && <Form.Text>{hint}</Form.Text>}
      <ErrorMessage
        name={name}
        render={(msg: string) => {
          return (
            <div>
              <Form.Control.Feedback
                type="invalid"
                // Since we are not using bootstrap Form elements but formik we should force this to display the error message
                style={{ display: 'block' }}
              >
                <div>{msg}</div>
              </Form.Control.Feedback>
            </div>
          );
        }}
      />
    </Form.Group>
  );
}
