import {
  ErrorMessage,
  Field,
  useFormikContext,
  type FieldAttributes,
} from 'formik';
import { camelCase, isObject, isString } from 'lodash';
import { useCallback, useMemo } from 'react';
import { FloatingLabel, Form, type FormControlProps } from 'react-bootstrap';

import { InputComponent } from '.';

export type InputSelectOption =
  | {
      value: string | number;
      label: string;
      disabled?: boolean;
    }
  | string
  | number;

export interface InputSelectProps extends InputComponent {
  options?: InputSelectOption[];
}

export function InputSelect({
  label,
  options,
  disabled,
  hint,
  required,
  nameOveride,
  className,
  hidden,
  floatingLabel = false,
  readOnly = false,
}: InputSelectProps) {
  const { errors } = useFormikContext<Record<string, string>>();

  const validate = useCallback(
    (value: string | number | undefined) => {
      if (required && (!value || value === undefined)) {
        return `${label} is required.`;
      }
    },
    [label, required],
  );

  const name = useMemo(
    () => nameOveride ?? camelCase(label),
    [label, nameOveride],
  );

  const fieldProps: FieldAttributes<FormControlProps> = useMemo(
    () => ({
      as: Form.Select,
      placeholder: label?.replace(/[?:]/g, ''),
      isInvalid: !!errors[name],
      // plaintext: !!readOnly,
      name,
      className,
      hidden,
      disabled,
      validate,
      required,
    }),
    [className, disabled, errors, hidden, label, name, required, validate],
  );

  const _options = useMemo(
    () => (
      <>
        <option defaultValue={undefined}>Select one...</option>
        {options?.map((option, idx) =>
          isObject(option) ? (
            <option key={idx} value={option.value} disabled={option.disabled}>
              {option.label}
            </option>
          ) : (
            <option
              key={idx}
              value={isString(option) ? camelCase(option) : option}
            >
              {option}
            </option>
          ),
        )}
      </>
    ),
    [options],
  );

  return readOnly ? (
    <>
      <Form.Label className="fw-light">
        {label}:
        {fieldProps.required && (
          <sup className="text-danger fw-bold">&nbsp;*</sup>
        )}
      </Form.Label>
      <Field {...fieldProps} as={Form.Control} />
    </>
  ) : (
    <Form.Group controlId={`form.${name}`} className={className}>
      {floatingLabel ? (
        <FloatingLabel
          controlId={`floatingInput.${name}`}
          label={
            <>
              {label}
              {fieldProps.required && (
                <sup className="text-danger fw-bold">&nbsp;*</sup>
              )}
            </>
          }
        >
          <Field {...fieldProps}>{_options}</Field>
        </FloatingLabel>
      ) : (
        <div>
          {label && (
            <Form.Label>
              {label}:
              {required && <sup className="text-danger fw-bold">&nbsp;*</sup>}
            </Form.Label>
          )}
          <Field {...fieldProps}>{_options}</Field>
        </div>
      )}
      {hint && <Form.Text>{hint}</Form.Text>}
      <ErrorMessage
        name={name}
        render={(msg: string) => (
          <Form.Control.Feedback
            type="invalid"
            // Since we are not using bootstrap Form elements but formik we should force this to display the error message
            style={{ display: 'block' }}
          >
            {msg}
          </Form.Control.Feedback>
        )}
      />
    </Form.Group>
  );
}
